import React, { useState } from "react";
import { ListItem, List, Container } from "@material-ui/core";
import "./footer.css";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { BASE_URL } from "../../config/config";
import { Link } from "react-router-dom";
import { createPopup } from "@typeform/embed";
import "@typeform/embed/build/css/popup.css";

const Footer = () => {
  const [aboutShow, setAboutShow] = useState(false);
  const [strategyShow, setStrategyShow] = useState(false);
  const [designShow, setDesignShow] = useState(false);
  const [developmentShow, setDevelopmentShow] = useState(false);
  const [marketingShow, setMarketingShow] = useState(false);

  const openPopup = (event) => {
    createPopup("r3BLnIaA").open();
  };

  return (
    <React.Fragment>
      <div className="footerSectionDesk">
        <Container>
          <div className="footerinnerone">
            <List>
              <ListItem>About</ListItem>
              <ListItem>
                <Link to="/best-digital-agency">
                  <h3 className="hoverLine subMenuFotterLink">Who We Are</h3>
                </Link>
              </ListItem>
              <ListItem>
                <a href="https://domaincer.com/blog/">
                  <h3 className="hoverLine subMenuFotterLink">Blog</h3>
                </a>
              </ListItem>
              <ListItem>
                <Link>
                  <h3
                    className="hoverLine subMenuFotterLink"
                    onClick={() => {
                      openPopup();
                    }}
                  >
                    Get Quote
                  </h3>
                </Link>
              </ListItem>
              <ListItem>
                <Link to="/contact-digital-marketing-agency">
                  <h3 className="hoverLine subMenuFotterLink">Contact Us</h3>
                </Link>
              </ListItem>
            </List>

            <List>
              <ListItem>Design</ListItem>
              <ListItem>
                <Link to="/best-branding-company">
                  <h3 className="hoverLine subMenuFotterLink">Branding</h3>
                </Link>
              </ListItem>
              <ListItem>
                <Link to="/ui-ux-design">
                  <h3 className="hoverLine subMenuFotterLink">UI / UX</h3>
                </Link>
              </ListItem>
              <ListItem>
                <Link to="/motion-graphics-design">
                  <h3 className="hoverLine subMenuFotterLink">
                    Motion Graphics
                  </h3>
                </Link>
              </ListItem>
            </List>
            <List>
              <ListItem>Web 2.0</ListItem>
              <ListItem>
                <Link to="/mobile-app-development-company">
                  <h3 className="hoverLine subMenuFotterLink">
                    Mobile App Development
                  </h3>
                </Link>
              </ListItem>
              <ListItem>
                <Link to="/web-portal-development">
                  <h3 className="hoverLine subMenuFotterLink">
                    Web Portal Development
                  </h3>
                </Link>
              </ListItem>
              <ListItem>
                <Link to="/website-development-company">
                  <h3 className="hoverLine subMenuFotterLink">
                    Website Development
                  </h3>
                </Link>
              </ListItem>
              <ListItem>
                <Link to="/devops-consulting-company">
                  <h3 className="hoverLine subMenuFotterLink">DevOps</h3>
                </Link>
              </ListItem>
            </List>
            <List>
              <ListItem>Web 3.0</ListItem>
              <ListItem>
                <Link to="/blockchain-service">
                  <h3 className="hoverLine subMenuFotterLink">Blockchain</h3>
                </Link>
              </ListItem>
              <ListItem>
                <Link to="/metaverse-service">
                  <h3 className="hoverLine subMenuFotterLink">Metaverse</h3>
                </Link>
              </ListItem>
              {/* <ListItem>
                <Link to='/workflow-automation-company'>
                  <h3 className='hoverLine subMenuFotterLink'>
                    Workflow Automation
                  </h3>
                </Link>
              </ListItem> */}
            </List>

            <List>
              <ListItem>Marketing</ListItem>
              <ListItem>
                <Link to="/social-media-marketing-service">
                  <h3 className="hoverLine subMenuFotterLink">
                    Social Media Marketing
                  </h3>
                </Link>
              </ListItem>
              <ListItem>
                <Link to="/search-engine-optimization-service">
                  <h3 className="hoverLine subMenuFotterLink">
                    Search Engine Optimisation
                  </h3>
                </Link>
              </ListItem>
            </List>
          </div>

          <div className="footerinnertwo">
            <div
              style={{
                textAlign: "center",
              }}
            >
              <img
                src={`${BASE_URL}/revamp_domaincer_logo_horizontal.svg`}
                alt="domaincer official logo"
                className="domaincer__logoFooter"
              />
            </div>

            <div className="privacyContainer">
              <Link to="/user-agreement" className="footer_container_link">
                <h3 className="privacylist0">User Agreement</h3>
              </Link>
              <Link to="/privacy-policy" className="footer_container_link">
                <h3 className="privacylist1">Privacy Policy</h3>
              </Link>
              <Link to="/cookies-policy" className="footer_container_link">
                <h3 className="privacylist2">Cookies Disclosure Statement</h3>
              </Link>
            </div>
            <h3 className="copyrighttext">
              © ShootUp Technologies Private Limited
            </h3>
          </div>
        </Container>
      </div>
      <div className="footerSectionMobile">
        <div style={{ padding: 20 }}>
          <div style={{ marginBottom: 30 }}>
            <div
              className="footerMenuContainer"
              onClick={() => setAboutShow(!aboutShow)}
            >
              <h3 className="footerMenuHeading">About</h3>
              <ExpandMoreIcon style={{ color: "#fff", alignSelf: "center" }} />
            </div>
            {aboutShow && (
              <List>
                <ListItem>
                  <Link to="/best-digital-agency" className="subMenuFotterLink">
                    Who We Are
                  </Link>
                </ListItem>
                <ListItem>
                  <a
                    href="https://domaincer.com/blog/"
                    className="subMenuFotterLink"
                  >
                    Blog
                  </a>
                </ListItem>
                <ListItem>
                  <a
                    className="subMenuFotterLink"
                    onClick={() => {
                      openPopup();
                    }}
                  >
                    Get Quote
                  </a>
                </ListItem>
                <ListItem>
                  <Link
                    to="/contact-digital-marketing-agency"
                    className="subMenuFotterLink"
                  >
                    Contact Us
                  </Link>
                </ListItem>
              </List>
            )}
          </div>
          <div style={{ marginBottom: 30 }}>
            <div
              className="footerMenuContainer"
              onClick={() => setDesignShow(!designShow)}
            >
              <h3 className="footerMenuHeading">Design</h3>
              <ExpandMoreIcon style={{ color: "#fff", alignSelf: "center" }} />
            </div>
            {designShow && (
              <List>
                <ListItem>
                  <Link
                    to="/best-branding-company"
                    className="subMenuFotterLink"
                  >
                    Branding
                  </Link>
                </ListItem>
                <ListItem>
                  {" "}
                  <Link to="/ui-ux-design" className="subMenuFotterLink">
                    UI / UX{" "}
                  </Link>
                </ListItem>
                <ListItem>
                  <Link
                    to="/motion-graphics-design"
                    className="subMenuFotterLink"
                  >
                    Motion Graphics{" "}
                  </Link>
                </ListItem>
              </List>
            )}
          </div>
          <div style={{ marginBottom: 30 }}>
            <div
              className="footerMenuContainer"
              onClick={() => setDevelopmentShow(!developmentShow)}
            >
              <h3 className="footerMenuHeading">Web 2.0</h3>
              <ExpandMoreIcon style={{ color: "#fff", alignSelf: "center" }} />
            </div>
            {developmentShow && (
              <List>
                <ListItem>
                  <Link
                    to="/mobile-app-development-company"
                    className="subMenuFotterLink"
                  >
                    Mobile App Development
                  </Link>
                </ListItem>
                <ListItem>
                  <Link
                    to="/web-portal-development"
                    className="subMenuFotterLink"
                  >
                    Web Portal Development
                  </Link>
                </ListItem>
                <ListItem>
                  <Link
                    to="/website-development-company"
                    className="subMenuFotterLink"
                  >
                    Website Development
                  </Link>
                </ListItem>
                <ListItem>
                  <Link
                    to="/devops-consulting-company"
                    className="subMenuFotterLink"
                  >
                    DevOps
                  </Link>
                </ListItem>
              </List>
            )}
          </div>
          <div style={{ marginBottom: 30 }}>
            <div
              className="footerMenuContainer"
              onClick={() => setStrategyShow(!strategyShow)}
            >
              <h3 className="footerMenuHeading">Web 3.0</h3>
              <ExpandMoreIcon style={{ color: "#fff", alignSelf: "center" }} />
            </div>
            {strategyShow && (
              <List>
                <ListItem>
                  <Link to="/blockchain-service" className="subMenuFotterLink">
                    Blockchain
                  </Link>
                </ListItem>
                <ListItem>
                  {" "}
                  <Link to="/metaverse-service" className="subMenuFotterLink">
                    Metaverse
                  </Link>
                </ListItem>
                {/* <ListItem>
                  {' '}
                  <Link
                    to='/workflow-automation-company'
                    className='subMenuFotterLink'
                  >
                    Workflow Automation
                  </Link>
                </ListItem> */}
              </List>
            )}
          </div>
          <div style={{ marginBottom: 30 }}>
            <div
              className="footerMenuContainer"
              onClick={() => setMarketingShow(!marketingShow)}
            >
              <h3 className="footerMenuHeading">Marketing</h3>
              <ExpandMoreIcon style={{ color: "#fff", alignSelf: "center" }} />
            </div>
            {marketingShow && (
              <List>
                <ListItem>
                  <Link
                    to="/social-media-marketing-service"
                    className="subMenuFotterLink"
                  >
                    Social Media Marketing
                  </Link>
                </ListItem>
                <ListItem>
                  <Link
                    to="/search-engine-optimization-service"
                    className="subMenuFotterLink"
                  >
                    Search Engine Optimisation
                  </Link>
                </ListItem>
              </List>
            )}
          </div>
        </div>

        <div className="footerinnertwo">
          <div
            style={{
              textAlign: "center",
            }}
          >
            <img
              style={{ width: 280, height: 100, marginLeft: -5 }}
              src={`${BASE_URL}/revamp_domaincer_logo_horizontal.svg`}
              alt="domaincer official logo"
            />
          </div>

          <div className="privacyContainer">
            <h3 style={{ marginBottom: 5, textAlign: "center" }}>
              User Agreement
            </h3>
            <h3 style={{ marginBottom: 5, textAlign: "center" }}>
              Privacy Statement
            </h3>
            <h3 style={{ textAlign: "center" }}>
              Cookies Disclosure Statement
            </h3>
          </div>
          <h3 className="copyrighttext" style={{ marginTop: 15 }}>
            © ShootUp Technologies Private Limited
          </h3>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Footer;
