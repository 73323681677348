import React from "react";
import "../uiux.css";
import { Container, Grid, Card } from "@material-ui/core";
import Hidden from "@material-ui/core/Hidden";
import { BASE_URL } from "../../../../config/config";

const WhyChooseUs = () => {
  const WHY_CHOOSE_US_IMAGE = `${BASE_URL}/about_why_choose_us.png`;

  return (
    <div className="uiux_whyChooseUsSection">
      <Container>
        <h1 className="uiux_whyChooseUsTitle">Why Domaincer</h1>
        <Grid container spacing={2} style={{ justifyContent: "center" }}>
          <Grid item xs={12} sm={3}>
            <div
              className="uiux_whyChooseColumnUpContainer"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <h2 className="uiux_chooseTitle">UX Driven Engineering</h2>
              <h3 className="uiux_choosePara">
                Our tech-driven UX processes are a well-oiled machine,
                delivering efficiency and effectiveness. Elevate your business
                and delight end-users.
              </h3>
            </div>
            <div
              className="uiux_whyChooseColumnDownContainer"
              data-aos="fade-right"
              data-aos-duration="1400"
            >
              <h2 className="uiux_chooseTitle">
                Proven Experience & Expertise
              </h2>
              <h3 className="uiux_choosePara">
                Trust our team of UI design experts to guide you towards
                successful, user-friendly solutions. Our track record speaks for
                itself.
              </h3>
            </div>
          </Grid>
          <Grid item xs={12} sm={4} className="uiux_about__chooseUsImageRow">
            <img
              src={WHY_CHOOSE_US_IMAGE}
              alt="why choose us image 3d domaincer"
              className="uiux_about_chooseUsMiddleImg floatingUpDown"
            />
          </Grid>

          <Hidden only={["sm", "lg", "md", "xl"]}>
            <Grid item xs={12} sm={3}>
              <div
                className="uiux_whyChooseColumn3UpContainer"
                data-aos="fade-up"
                data-aos-duration="1200"
              >
                <h2 className="uiux_chooseTitle">User-Centric</h2>
                <h3 className="uiux_choosePara">
                  Achieve success with our data-driven, user-focused approach.
                  We prioritize user satisfaction for an exceptional experience.
                </h3>
              </div>
              <div
                className="uiux_whyChooseColumn3DownContainer"
                data-aos="fade-up"
                data-aos-duration="1400"
              >
                <h2 className="uiux_chooseTitle">Design for Impact</h2>
                <h3 className="uiux_choosePara">
                  Transform your business with our cutting-edge UI/UX design.
                  Our commitment to excellence guarantees a lasting impact.
                </h3>
              </div>
            </Grid>
          </Hidden>
          <Hidden only={["xs"]}>
            <Grid item xs={12} sm={3}>
              <div
                className="uiux_whyChooseColumn3UpContainer"
                data-aos="fade-left"
                data-aos-duration="1200"
              >
                <h2 className="uiux_chooseTitle">User-Centric</h2>
                <h3 className="uiux_choosePara">
                  Achieve success with our data-driven, user-focused approach.
                  We prioritize user satisfaction for an exceptional experience.
                </h3>
              </div>
              <div
                className="uiux_whyChooseColumn3DownContainer"
                data-aos="fade-left"
                data-aos-duration="1600"
              >
                <h2 className="uiux_chooseTitle">Design for Impact</h2>
                <h3 className="uiux_choosePara">
                  Transform your business with our cutting-edge UI/UX design.
                  Our commitment to excellence guarantees a lasting impact.
                </h3>
              </div>
            </Grid>
          </Hidden>
        </Grid>
      </Container>
    </div>
  );
};
export default WhyChooseUs;
