import React, { useState } from "react";
import "./blockchainTech.css";
import { BASE_URL } from "../../../../config/config";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import TelegramIcon from "@material-ui/icons/Telegram";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";

const groupOne = `${BASE_URL}/groupOne.png`;
const groupTwo = `${BASE_URL}/groupTwo.png`;

const Data = [
  {
    header: "DeFi",
    content: `Say goodbye to the middlemen and hello to DeFi! Decentralized finance is shaking up the traditional financial system by using blockchain technology to bypass the need for banks and other institutions. This means you can take control of your own money and financial products without being held back. DeFi is like a financial revolution, empowering individuals and communities to shape their own financial future.`,
    img: `${BASE_URL}/defi.png`,
    alt: "DeFi",
  },
  {
    header: "DAO",
    content:
      "Welcome to the world of DAOs, where the power is in the hands of the people! A decentralized autonomous organization is a computer program that operates transparently, governed by its members and free from central authority. Using blockchain technology, a DAO can keep track of its financial transactions and rules, creating a truly decentralized system. Think of it as a corporation with a conscience, working for the benefit of its members rather than a select few at the top.",
    img: `${BASE_URL}/dao.png`,
    alt: "DAO",
  },
  {
    header: "NFT",
    content:
      "Get ready to meet the ultimate one-of-a-kind collectable: the non-fungible token! An NFT is a unique data unit stored on a blockchain, and it can be linked to all kinds of digital items like images, movies, and audio. Not only does an NFT provide a public certificate of authenticity or proof of ownership, but it also doesn't stop you from sharing or copying the underlying digital files, or creating more NFTs. Imagine having a digital item that is truly your own, with a special certificate to prove it. That's the magic of non-fungible tokens!",
    img: `${BASE_URL}/nft.png`,
    alt: "NFT",
  },
];

const BlockchainTech = () => {
  const [cursorHover, setCursorHover] = useState(false);
  const [handShakeHover, setHankShakeHover] = useState(false);
  const scrollLeftFunc = () => {
    document.getElementById("creator_list").scrollLeft -= 800;
  };

  const scrollRightFunc = () => {
    const element = document.getElementById("creator_list");
let x = element.scrollLeft;
if(x>=794){
  document.getElementById("creator_list").scrollLeft -= 2500;

}else{
  document.getElementById("creator_list").scrollLeft += 2500;

}
  };
  return (
    <>
      <div className="blockTech-container">
        <div className="blockTech-groupTwo-container">
          <img src={groupTwo} className="blockTech-group-image"></img>
        </div>

        <div className="inner">
          <div className="left-one">
            <ChevronLeftIcon
              style={{ position: "relative", zIndex: "9999" }}
              className="leftCaroselIcon-benefits"
              onClick={scrollLeftFunc}
            />
          </div>

          <div id="creator_list" className="blockTech-innerContainer">
            {Data.map((each) => (
              <>
                <div className="blockTech-card">
                  <div className="blockTech-content-container">
                    <h3 className="blockTech-header">{each.header}</h3>
                    <p className="blockTech-content">{each.content}</p>
                    {/* <div className="blockTech-buttonContainer">
                      <Link to="/get-quote">
                        <Button
                          className="blockTech-button"
                          onMouseEnter={() => {
                            setHankShakeHover(true);
                            setCursorHover(true);
                          }}
                          onMouseLeave={() => {
                            setCursorHover(false);
                            setHankShakeHover(false);
                          }}
                        >
                          {handShakeHover === true ? (
                            <TelegramIcon
                              style={{
                                paddingLeft: 30,
                                paddingRight: 30,
                                fontSize: 22,
                              }}
                            />
                          ) : (
                            <span style={{ paddingLeft: 5, paddingRight: 5 }}>
                              Get Quote
                            </span>
                          )}
                        </Button>
                      </Link>
                    </div> */}
                  </div>
                  <div className="blockTech-img-container">
                    <img
                      src={each.img}
                      alt={each.alt}
                      className="blockTech-img"
                    ></img>
                  </div>
                </div>
              </>
            ))}
          </div>

          <div className="right-icon">
            <ChevronRightIcon
              className="rightCaroselIcon_benefits"
              onClick={scrollRightFunc}
            />
          </div>
        </div>

        <div className="blockTech-groupOne-container">
          <img src={groupOne} className="blockTech-group-image"></img>
        </div>
      </div>
    </>
  );
};

export default BlockchainTech;
