import React, { useState, useEffect } from "react";
import { Container, Grid, Paper, Tab, Tabs } from "@material-ui/core";
import AOS from "aos";
import "./process_styles.css";
import Hidden from "@material-ui/core/Hidden";
import { BASE_URL } from "../../../../config/config";

const SPRINTS_IMG = `${BASE_URL}/Designsprint.png`;
const FULL_CYCLE_PRODUCT_IMG = `${BASE_URL}/Fullcycleproductdevelopment.png`;
const MVP_IMG = `${BASE_URL}/mvpdevelopment.png`;
const PUBLISHING_IMG = `${BASE_URL}/Publishing.png`;
const TESTING_IMG = `${BASE_URL}/RigorousTesting.png`;
const UXUI_IMG = `${BASE_URL}/uiux.png`;

const ProcessSection = (props) => {
  const [value, setValue] = useState(0);

  useEffect(() => {
    AOS.init();
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <React.Fragment>
      {/* <Hidden only={["xs"]}> */}
      <div className="ksXnbQ">
        <div className="kgegep">
          <div className="processTabsSection">
            <Paper square className="MTabsWidthProcess_mobileapp">
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                aria-label="tabs example"
                className="MTabsContainer"
              >
                <Tab
                  label="Design sprints"
                  className="MTabBtn "
                  onClick={() => setValue(0)}
                />
                <Tab
                  label="UI/UX"
                  className="MTabBtn"
                  onClick={() => setValue(1)}
                />
                <Tab
                  label="MVP"
                  className="MTabBtn"
                  onClick={() => setValue(2)}
                />
                <Tab
                  label="Full cycle product"
                  className="MTabBtn"
                  onClick={() => setValue(3)}
                />
                <Tab
                  label="Rigorous Testing"
                  className="MTabBtn"
                  onClick={() => setValue(4)}
                />
                <Tab
                  label="Publishing"
                  className="MTabBtn"
                  onClick={() => setValue(5)}
                />
              </Tabs>
            </Paper>
          </div>
          {value === 0 && (
            <div className="movingMobile">
              <img src={SPRINTS_IMG} alt="" className="processbg" />
            </div>
          )}
          {value === 0 && (
            <div className="processContentSection">
              <h1 className="sprintText">
                A five-phase design process that uses design thinking with the
                aim of reducing the risk when bringing a new product
              </h1>
            </div>
          )}
          {value === 1 && (
            <div className="movingMobile">
              <img src={UXUI_IMG} alt="" className="processbg" />
            </div>
          )}
          {value === 1 && (
            <div className="processContentSection">
              <h1 className="sprintText">
                To Design dream product in finding the best solution
              </h1>
            </div>
          )}
          {value === 2 && (
            <div className="movingMobile">
              <img src={MVP_IMG} alt="" className="processbg" />
            </div>
          )}
          {value === 2 && (
            <div className="processContentSection">
              <h1 className="sprintText">
                A minimum Viable Product enable your customer to early access of
                enough feature to make future development
              </h1>
            </div>
          )}
          {value === 3 && (
            <div className="movingMobile">
              <img src={FULL_CYCLE_PRODUCT_IMG} alt="" className="processbg" />
            </div>
          )}
          {value === 3 && (
            <div className="processContentSection">
              <h1 className="sprintText">
                A Full cycle product development from Idea to Live project. We
                are your one-stop for web, mobile and desktop application
                development
              </h1>
            </div>
          )}
          {value === 4 && (
            <div className="movingMobile">
              <img src={TESTING_IMG} alt="" className="processbg" />
            </div>
          )}
          {value === 4 && (
            <div className="processContentSection">
              <h1 className="sprintText">
                A complete testing where, we follow strict entry and exit
                criteria and alsi we deal with all possible combinations of test
                cases and test Data
              </h1>
            </div>
          )}
          {value === 5 && (
            <div className="movingMobile">
              <img src={PUBLISHING_IMG} alt="" className="processbg" />
            </div>
          )}
          {value === 5 && (
            <div className="processContentSection">
              <h1 className="sprintText">
                Ensure the App is available in the App market with respect to
                android and IOS, by fulfilling all the requirement and
                Functionalities.
              </h1>
            </div>
          )}
        </div>
      </div>
      {/* </Hidden> */}
    </React.Fragment>
  );
};

export default ProcessSection;
