import React, { useState } from 'react';
import { Radio, Grid, IconButton, Card, CardContent } from '@material-ui/core';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { BASE_URL } from '../../config/config';

const GetQuoteStepOne = (props) => {
  const [selectedValue, setSelectedValue] = useState('EXPRESSED');

  const handleChange = (e) => {
    setSelectedValue(e.target.value);
  };

  const handleClick = (e) => {
    e.preventDefault();
    props.nextStep(
      [],
      [],
      {},
      { selectedValue: selectedValue },
      'submissionScreen'
    );
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div className='quotePage__body'>
      <React.Fragment>
        <div style={{ marginTop: 20 }}>
          <h1 className='quote__question'>Get Quote</h1>
        </div>
        <Grid
          container
          direction='row'
          justify='center'
          spacing={6}
          style={{ marginTop: 20 }}
        >
          {/* {SUBMISSION_DETAILS.map((submission) => ( */}
          <Grid
            item
            xs={12}
            sm={4}
            md={4}
            lg={3}
            className='custom__stepOneGrid'
          >
            <Card className='quotePage__card' variant='outlined'>
              <CardContent>
                <Radio
                  checked={selectedValue === 'EXPRESSED'}
                  onChange={handleChange}
                  value={'EXPRESSED'}
                  name='radio-button-demo'
                  inputProps={{ 'aria-label': 'A' }}
                  className='submission_radioBtn'
                />
                <div className='quotePage_cardContainer'>
                  <img
                    className='submissionCard_img'
                    src={`${BASE_URL}/getquote_express.png`}
                    alt='enquiry submit icon 3d domaincer'
                  />
                  <p className='quotePage__cardTitle'>Express Submission</p>
                  <p className='quotePage__cardText'>
                    If you are running out time, ping us with basic details of
                    your project
                  </p>
                </div>
                <div
                  style={{
                    marginTop: 10,
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                  }}
                >
                  <AccessTimeIcon style={{ fontSize: 14, color: '#999999' }} />
                  <p style={{ fontSize: 14, color: '#999999', marginLeft: 5 }}>
                    1 min
                  </p>
                </div>
              </CardContent>
            </Card>
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            md={4}
            lg={3}
            className='custom__stepOneGrid'
          >
            <Card className='quotePage__card' variant='outlined'>
              <CardContent>
                <Radio
                  checked={selectedValue === 'DETAILED'}
                  onChange={handleChange}
                  value={'DETAILED'}
                  name='radio-button-demo'
                  inputProps={{ 'aria-label': 'B' }}
                  className='submission_radioBtn'
                />
                <div className='quotePage_cardContainer'>
                  <img
                    className='submissionCard_img'
                    src={`${BASE_URL}/getquote_detailed.png`}
                    alt='enquiry info icon 3d domaincer'
                  />
                  <p className='quotePage__cardTitle'>Detailed Submission</p>
                  <p className='quotePage__cardText'>
                    We understand you're too serious about your project, take
                    your time in describing your requirements
                  </p>
                </div>
                <div
                  style={{
                    marginTop: 10,
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                  }}
                >
                  <AccessTimeIcon style={{ fontSize: 14, color: '#999999' }} />
                  <p style={{ fontSize: 14, color: '#999999', marginLeft: 5 }}>
                    3 - 5 mins
                  </p>
                </div>
              </CardContent>
            </Card>
          </Grid>
          {/* ))} */}
        </Grid>

        <div className='nextStep__btnContainer'>
          <h5 className='nextStep__btn' onClick={handleClick}>
            Continue
          </h5>
          <IconButton>
            <DoubleArrowIcon className='nextStep__btnIcon arrowani' />
          </IconButton>
        </div>
      </React.Fragment>
    </div>
  );
};

export default GetQuoteStepOne;
