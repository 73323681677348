import React, { useState } from 'react';
import { Grid, IconButton } from '@material-ui/core';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Hidden from '@material-ui/core/Hidden';
import { BASE_URL } from '../../../../config/config';

const BrandStrategyQuestionsPage = (props) => {
  const UIUX_SLIDER = `${BASE_URL}/service_strategy_header_image.png`;

  Array.prototype.removeValue = function (val) {
    for (var i = 0; i < this.length; i++) {
      if (this[i] === val) {
        this.splice(i, 1);
        i--;
      }
    }
    return this;
  };

  const [pageName] = useState('brandStrategyScreen');
  const [errorStatus, setErrorStatus] = useState(false);
  const [Q1A, setQ1A] = useState([]);

  const handleClick = (e) => {
    e.preventDefault();
    const { userDetails } = props;
    const qadata = {
      id: 'brandStrategyQA',
      Q1: {
        question: 'Which sector do you need to develop strategy?',
        answer: Q1A,
      },
    };

    if (Q1A.length > 0) {
      const data = props.serviceData.removeValue(pageName);
      props.prevServiceData.push(pageName);
      props.nextStep(
        data,
        props.prevServiceData,
        qadata,
        userDetails,
        pageName
      );
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    } else {
      setErrorStatus(true);
    }
  };

  const handleBack = (e) => {
    e.preventDefault();
    const data = props.prevServiceData.removeValue(pageName);
    props.prevStep(data);
  };

  return (
    <div className='quotePage__body'>
      <React.Fragment>
        <Grid container direction='row' justify='center' spacing={2}>
          <Grid item xs={12} sm={10} className='custom__stepOneGrid'>
            <div className='prevStep__div'>
              <IconButton>
                <ArrowBackIcon className='prevStep__btn' onClick={handleBack} />
              </IconButton>
            </div>
            <div>
              <h2 className='quote__question'>Brand Strategy</h2>
            </div>
            <Grid
              container
              direction='row'
              justify='space-between'
              alignItems='center'
            >
              <Hidden only={['sm', 'lg', 'md', 'xl']}>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  className='animated__gif'
                  style={{ textAlign: 'center' }}
                >
                  <img
                    src={UIUX_SLIDER}
                    alt='Brand strategy landing page image domaincer'
                    className='getQuote_brandingImage floatingUpDown'
                  />
                </Grid>
              </Hidden>
              <Grid item xs={12} sm={8}>
                <div>
                  <div>
                    <h2 className='service__question'>
                      1. Which sector do you need to develop strategy?
                    </h2>
                  </div>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name='checkedLogoDesign'
                          className='custom__checkBox'
                          onChange={(evt, data) => {
                            if (data === true) {
                              setQ1A([...Q1A, 'Marketing']);
                              setErrorStatus(false);
                            } else {
                              const newQ1A = Q1A.removeValue('Marketing');
                              setQ1A(newQ1A);
                            }
                          }}
                        />
                      }
                      label='Marketing'
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name='checkedUIUX'
                          className='custom__checkBox'
                          onChange={(evt, data) => {
                            if (data === true) {
                              setQ1A([...Q1A, 'Branding']);
                              setErrorStatus(false);
                            } else {
                              const newQ1A = Q1A.removeValue('Branding');
                              setQ1A(newQ1A);
                            }
                          }}
                        />
                      }
                      label='Branding'
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name='checkedExplainerVideo'
                          className='custom__checkBox'
                          onChange={(evt, data) => {
                            if (data === true) {
                              setQ1A([...Q1A, 'Advertising']);
                              setErrorStatus(false);
                            } else {
                              const newQ1A = Q1A.removeValue('Advertising');
                              setQ1A(newQ1A);
                            }
                          }}
                        />
                      }
                      label='Advertising'
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name='checkedLogoAnimation'
                          className='custom__checkBox'
                          onChange={(evt, data) => {
                            if (data === true) {
                              setQ1A([...Q1A, 'Product']);
                              setErrorStatus(false);
                            } else {
                              const newQ1A = Q1A.removeValue('Product');
                              setQ1A(newQ1A);
                            }
                          }}
                        />
                      }
                      label='Product'
                    />
                  </FormGroup>
                </div>
                {errorStatus && (
                  <p style={{ color: 'red', fontSize: 13, marginTop: 7 }}>
                    Please select atleast one strategy.
                  </p>
                )}
              </Grid>
              <Hidden only={['xs']}>
                <Grid item xs={12} sm={4} className='animated__gif'>
                  <img
                    src={UIUX_SLIDER}
                    alt='Brand strategy landing page image domaincer'
                    className='getQuote_brandingImage floatingUpDown'
                  />
                </Grid>
              </Hidden>
            </Grid>
            <div className='nextStep__btnContainer'>
              <h5 className='nextStep__btn' onClick={handleClick}>
                Continue
              </h5>
              <IconButton>
                <DoubleArrowIcon className='nextStep__btnIcon arrowani' />
              </IconButton>
            </div>
          </Grid>
        </Grid>
      </React.Fragment>
    </div>
  );
};

export default BrandStrategyQuestionsPage;
