import React, { Component } from "react";
import { Container } from "@material-ui/core";
import {
  SUBMISSION_SCREEN,
  NAME_SCREEN,
  DETAILS_SCREEN,
  ORGANIZATION_SCREEN,
  ORGANIZATION_EXPRESS_SCREEN,
  SERVICES_SCREEN,
  BRAND_STRATEGY_SCREEN,
  DIGITAL_TRANSFORMATION_SCREEN,
  WORKFLOW_AUTOMATION_SCREEN,
  BRANDING_SCREEN,
  UIUX_SCREEN,
  MOTION_GRAPHICS_SCREEN,
  MOBILE_APPS_SCREEN,
  PORTAL_SCREEN,
  WEBSITE_SCREEN,
  DEVOPS_SCREEN,
  SOCIALMEDIA_MARKETING_SCREEN,
  SEARCH_ENGINE_OPTIMISATION,
  CONTENT_MARKETING,
  ONLINE_REPUTATION_MANAGEMENT,
  DEDICATED_RESOURCE,
  OUTSOURCING,
  MESSAGEBOX_SCREEN,
  THANKYOU_SCREEN,
} from "./quotepage.constant";
import BrandStrategyQuestionsPage from "./services/strategy/brandStrategyQA.component";
import DigitalTransformationQAPage from "./services/strategy/digitalTransformationQA.component";
import WorkflowQAPage from "./services/strategy/workflowQA.component";
import BrandingQAPage from "./services/design/brandingQA.component";
import UIUXQAPage from "./services/design/uiuxQA.component";
import MotionGraphicsQAPage from "./services/design/motionGraphicsQA.component";
import MobileAppsQAPage from "./services/development/mobileAppsQA.component";
import PortalQAPage from "./services/development/portalQA.component";
import WebsiteQAPage from "./services/development/websiteQA.component";
import DevopsQAPage from "./services/development/devopsQA.component";
import SocialMediaMarketingQAPage from "./services/marketing/socialMediaMarketingQA.component";
import SearchEngineQAPage from "./services/marketing/searchEngineQA.component";
import ContentMarketingQAPage from "./services/marketing/contentMarketingQA.component";
import OnlineReputationQAPage from "./services/marketing/onlineReputationQA.component";
import DedicatedresourceQAPage from "./services/humanresource/dedicatedResourceQA.component";
import OutsourcingQAPage from "./services/humanresource/outsourcingQA.component";
import QuoteThankyouPage from "./thankyou.component";
import "./quotepage.css";
import GetQuoteStepFive from "./stepFive.component";
import GetQuoteStepFour from "./stepFour.component";
import GetQuoteStepThree from "./stepThree.component";
import GetQuoteStepTwo from "./stepTwo.component";
import GetQuoteStepOne from "./stepOne.component";
import MessageBoxScreen from "./messagebox.component";

class QuotePage extends Component {
  state = {
    stepCount: 1,
    step: "submissionScreen",
    services: [],
    previousServices: [],
    QAData: {},
    userData: {},
  };

  // Proceed to next stepCount
  // nextStep = (selectedServices, prevServices, qadata, userdata, screen) => {
  //   const id = qadata.id;
  //   const { stepCount } = this.state;
  //   if (screen === SUBMISSION_SCREEN) {
  //     this.setState({ step: NAME_SCREEN, userData: userdata });
  //   } else if (screen === NAME_SCREEN) {
  //     this.setState({ step: DETAILS_SCREEN, userData: userdata });
  //   } else if (screen === DETAILS_SCREEN) {
  //     this.setState({ step: ORGANIZATION_SCREEN, userData: userdata });
  //   } else if (screen === ORGANIZATION_SCREEN) {
  //     this.setState({ step: SERVICES_SCREEN, userData: userdata });
  //   } else if (screen === ORGANIZATION_EXPRESS_SCREEN) {
  //     this.setState({ step: SERVICES_SCREEN, userData: userdata });
  //   } else if (screen === MESSAGEBOX_SCREEN) {
  //     this.setState({ step: MESSAGEBOX_SCREEN, userData: userdata });
  //   } else if (screen === THANKYOU_SCREEN) {
  //     this.setState({ step: THANKYOU_SCREEN, userData: userdata });
  //   } else {
  //     if (selectedServices.length > 0) {
  //       if (qadata.id !== undefined) {
  //         this.setState((prevState) => ({
  //           QAData: {
  //             ...prevState.QAData,
  //             [id]: qadata,
  //           },
  //         }));
  //       } else {
  //         this.setState({ QAData: qadata });
  //       }
  //       this.setState({
  //         step: selectedServices[0],
  //         services: selectedServices,
  //         previousServices: prevServices,
  //         stepCount: stepCount + 1,
  //         userData: userdata,
  //       });
  //     } else {
  //       this.setState((prevState) => ({
  //         QAData: {
  //           ...prevState.QAData,
  //           [id]: qadata,
  //         },
  //         step: MESSAGEBOX_SCREEN,
  //         userData: userdata,
  //       }));
  //     }
  //   }
  // };

  nextStep = (selectedServices, prevServices, qadata, userdata, screen) => {
    const id = qadata.id;
    const { stepCount } = this.state;
    if (screen === SUBMISSION_SCREEN) {
      this.setState({ step: SERVICES_SCREEN, userData: userdata });
    } else if (screen === SERVICES_SCREEN) {
      this.setState({ step: NAME_SCREEN, userData: userdata });
    } else if (screen === NAME_SCREEN) {
      this.setState({ step: DETAILS_SCREEN, userData: userdata });
    } else if (screen === DETAILS_SCREEN) {
      this.setState({ step: ORGANIZATION_SCREEN, userData: userdata });
    } else if (screen === ORGANIZATION_SCREEN) {
      this.setState({ step: MESSAGEBOX_SCREEN, userData: userdata });
    } else if (screen === MESSAGEBOX_SCREEN) {
      this.setState({ step: THANKYOU_SCREEN, userData: userdata });
    } else {
      if (selectedServices.length > 0) {
        if (qadata.id !== undefined) {
          this.setState((prevState) => ({
            QAData: {
              ...prevState.QAData,
              [id]: qadata,
            },
          }));
        } else {
          this.setState({ QAData: qadata });
        }
        this.setState({
          step: selectedServices[0],
          services: selectedServices,
          previousServices: prevServices,
          stepCount: stepCount + 1,
          userData: userdata,
        });
      } else {
        this.setState((prevState) => ({
          QAData: {
            ...prevState.QAData,
            [id]: qadata,
          },
          step: NAME_SCREEN,
          userData: userdata,
        }));
      }
    }
  };

  // Go back to prev stepCount
  prevStep = (data) => {
    const { stepCount } = this.state;
    this.setState({
      stepCount: stepCount - 1,
      step: data[data.length - 1],
    });
  };

  // Handle fields change
  handleStep = (input) => (e) => {
    this.setState({ [input]: e.target.value });
  };

  render() {
    const { step, services, previousServices, QAData } = this.state;
    switch (step) {
      case SUBMISSION_SCREEN:
        return (
          <div className="quotePage__container">
            <Container fixed>
              <GetQuoteStepOne
                nextStep={this.nextStep}
                handleChange={this.handleStep}
              />
            </Container>
          </div>
        );

      case NAME_SCREEN:
        return (
          <div className="quotePage__container">
            <Container fixed>
              <GetQuoteStepTwo
                nextStep={this.nextStep}
                handleChange={this.handleStep}
                userDetails={this.state.userData}
              />
            </Container>
          </div>
        );

      case DETAILS_SCREEN:
        return (
          <div className="quotePage__container">
            <Container>
              <GetQuoteStepThree
                nextStep={this.nextStep}
                prevStep={this.prevStep}
                handleChange={this.handleStep}
                userDetails={this.state.userData}
              />
            </Container>
          </div>
        );
      case ORGANIZATION_SCREEN:
        return (
          <div className="quotePage__container">
            <Container>
              <GetQuoteStepFour
                nextStep={this.nextStep}
                prevStep={this.prevStep}
                handleChange={this.handleStep}
                userDetails={this.state.userData}
              />
            </Container>
          </div>
        );
      case SERVICES_SCREEN:
        return (
          <div className="quotePage__container">
            <Container>
              <GetQuoteStepFive
                nextStep={this.nextStep}
                prevStep={this.prevStep}
                handleChange={this.handleStep}
                userDetails={this.state.userData}
                prevServiceData={previousServices}
              />
            </Container>
          </div>
        );
      case BRAND_STRATEGY_SCREEN:
        return (
          <div className="quotePage__container">
            <Container>
              <BrandStrategyQuestionsPage
                nextStep={this.nextStep}
                prevStep={this.prevStep}
                handleChange={this.handleStep}
                userDetails={this.state.userData}
                serviceData={services}
                prevServiceData={previousServices}
              />
            </Container>
          </div>
        );
      case DIGITAL_TRANSFORMATION_SCREEN:
        return (
          <div className="quotePage__container">
            <Container>
              <DigitalTransformationQAPage
                nextStep={this.nextStep}
                prevStep={this.prevStep}
                handleChange={this.handleStep}
                userDetails={this.state.userData}
                serviceData={services}
                prevServiceData={previousServices}
              />
            </Container>
          </div>
        );
      case WORKFLOW_AUTOMATION_SCREEN:
        return (
          <div className="quotePage__container">
            <Container>
              <WorkflowQAPage
                nextStep={this.nextStep}
                prevStep={this.prevStep}
                handleChange={this.handleStep}
                userDetails={this.state.userData}
                serviceData={services}
                prevServiceData={previousServices}
              />
            </Container>
          </div>
        );
      case BRANDING_SCREEN:
        return (
          <div className="quotePage__container">
            <Container>
              <BrandingQAPage
                nextStep={this.nextStep}
                prevStep={this.prevStep}
                handleChange={this.handleStep}
                userDetails={this.state.userData}
                serviceData={services}
                prevServiceData={previousServices}
              />
            </Container>
          </div>
        );
      case UIUX_SCREEN:
        return (
          <div className="quotePage__container">
            <Container>
              <UIUXQAPage
                nextStep={this.nextStep}
                prevStep={this.prevStep}
                handleChange={this.handleStep}
                userDetails={this.state.userData}
                serviceData={services}
                prevServiceData={previousServices}
              />
            </Container>
          </div>
        );
      case MOTION_GRAPHICS_SCREEN:
        return (
          <div className="quotePage__container">
            <Container>
              <MotionGraphicsQAPage
                nextStep={this.nextStep}
                prevStep={this.prevStep}
                handleChange={this.handleStep}
                userDetails={this.state.userData}
                serviceData={services}
                prevServiceData={previousServices}
              />
            </Container>
          </div>
        );
      case MOBILE_APPS_SCREEN:
        return (
          <div className="quotePage__container">
            <Container>
              <MobileAppsQAPage
                nextStep={this.nextStep}
                prevStep={this.prevStep}
                handleChange={this.handleStep}
                userDetails={this.state.userData}
                serviceData={services}
                prevServiceData={previousServices}
              />
            </Container>
          </div>
        );
      case PORTAL_SCREEN:
        return (
          <div className="quotePage__container">
            <Container>
              <PortalQAPage
                nextStep={this.nextStep}
                prevStep={this.prevStep}
                handleChange={this.handleStep}
                userDetails={this.state.userData}
                serviceData={services}
                prevServiceData={previousServices}
              />
            </Container>
          </div>
        );
      case WEBSITE_SCREEN:
        return (
          <div className="quotePage__container">
            <Container>
              <WebsiteQAPage
                nextStep={this.nextStep}
                prevStep={this.prevStep}
                handleChange={this.handleStep}
                userDetails={this.state.userData}
                serviceData={services}
                prevServiceData={previousServices}
              />
            </Container>
          </div>
        );
      case DEVOPS_SCREEN:
        return (
          <div className="quotePage__container">
            <Container>
              <DevopsQAPage
                nextStep={this.nextStep}
                prevStep={this.prevStep}
                handleChange={this.handleStep}
                userDetails={this.state.userData}
                serviceData={services}
                prevServiceData={previousServices}
              />
            </Container>
          </div>
        );
      case SOCIALMEDIA_MARKETING_SCREEN:
        return (
          <div className="quotePage__container">
            <Container>
              <SocialMediaMarketingQAPage
                nextStep={this.nextStep}
                prevStep={this.prevStep}
                handleChange={this.handleStep}
                userDetails={this.state.userData}
                serviceData={services}
                prevServiceData={previousServices}
              />
            </Container>
          </div>
        );
      case SEARCH_ENGINE_OPTIMISATION:
        return (
          <div className="quotePage__container">
            <Container>
              <SearchEngineQAPage
                nextStep={this.nextStep}
                prevStep={this.prevStep}
                handleChange={this.handleStep}
                userDetails={this.state.userData}
                serviceData={services}
                prevServiceData={previousServices}
              />
            </Container>
          </div>
        );
      case CONTENT_MARKETING:
        return (
          <div className="quotePage__container">
            <Container>
              <ContentMarketingQAPage
                nextStep={this.nextStep}
                prevStep={this.prevStep}
                handleChange={this.handleStep}
                userDetails={this.state.userData}
                serviceData={services}
                prevServiceData={previousServices}
              />
            </Container>
          </div>
        );
      case ONLINE_REPUTATION_MANAGEMENT:
        return (
          <div className="quotePage__container">
            <Container>
              <OnlineReputationQAPage
                nextStep={this.nextStep}
                prevStep={this.prevStep}
                handleChange={this.handleStep}
                userDetails={this.state.userData}
                serviceData={services}
                prevServiceData={previousServices}
              />
            </Container>
          </div>
        );
      case DEDICATED_RESOURCE:
        return (
          <div className="quotePage__container">
            <Container>
              <DedicatedresourceQAPage
                nextStep={this.nextStep}
                prevStep={this.prevStep}
                handleChange={this.handleStep}
                userDetails={this.state.userData}
                serviceData={services}
                prevServiceData={previousServices}
              />
            </Container>
          </div>
        );
      case OUTSOURCING:
        return (
          <div className="quotePage__container">
            <Container>
              <OutsourcingQAPage
                nextStep={this.nextStep}
                prevStep={this.prevStep}
                handleChange={this.handleStep}
                userDetails={this.state.userData}
                serviceData={services}
                prevServiceData={previousServices}
              />
            </Container>
          </div>
        );
      case MESSAGEBOX_SCREEN:
        return (
          <div className="quotePage__container">
            <Container>
              <MessageBoxScreen
                nextStep={this.nextStep}
                prevStep={this.prevStep}
                handleChange={this.handleStep}
                userDetails={this.state.userData}
                serviceData={services}
                prevServiceData={previousServices}
                QuestioniareData={QAData}
              />
            </Container>
          </div>
        );
      case THANKYOU_SCREEN:
        return (
          <div className="quotePage__container">
            <Container>
              <QuoteThankyouPage
                nextStep={this.nextStep}
                prevStep={this.prevStep}
                handleChange={this.handleStep}
                userDetails={this.state.userData}
              />
            </Container>
          </div>
        );
      default:
        console.log("Get Quote Page.");
    }
  }
}

export default QuotePage;
