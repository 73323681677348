import React, { useState, useEffect } from "react";
import "./uiux.css";
import { Container, Grid, Hidden, Paper, Tab, Tabs } from "@material-ui/core";
import WhyChooseUs from "./uiux_whyChooseUs/uiux_whychooseus.component";
// import OurProcess from './uiux_ourProcess/uiux_ourprocess.component';
import CaseStudySection from "../../homepage/casestudyssection/casestudysection.component";
import HowServiceWillHelpYou from "./uiux_howServiceWillHelpYou/uiux_howservicewillhelpyou.component";
import BlogSection from "./uiux_blogSection/uiux_blogsection.component";
import ServiceContentSection from "./uiux_serviceContentSection/uiux_servicecontentsection.component";
import AOS from "aos";
import FollowUs from "../../../commons/followus/followus.component";
import { BASE_URL } from "../../../config/config";

const UiUxService = (props) => {
  const UIUX_SLIDER = `${BASE_URL}/service_uiux_header_image.png`;

  useEffect(() => {
    AOS.init();
  });
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleTabScroll = (value) => {
    if (value === "OVER_VIEW") {
      window.scrollTo({
        top: 950,
        behavior: "smooth",
      });
    } else if (value === "BLOG") {
      window.scrollTo({
        top: 1550,
        behavior: "smooth",
      });
    } else if (value === "WHY_DOMAINCER") {
      window.scrollTo({
        top: 2350,
        behavior: "smooth",
      });
    } else if (value === "RECENT_WORKS") {
      window.scrollTo({
        top: 3000,
        behavior: "smooth",
      });
    }
  };

  const handleTabScrollMobile = (value) => {
    if (value === "OVER_VIEW") {
      window.scrollTo({
        top: 1500,
        behavior: "smooth",
      });
    } else if (value === "BLOG") {
      window.scrollTo({
        top: 2000,
        behavior: "smooth",
      });
    } else if (value === "WHY_DOMAINCER") {
      window.scrollTo({
        top: 2800,
        behavior: "smooth",
      });
    } else if (value === "RECENT_WORKS") {
      window.scrollTo({
        top: 4000,
        behavior: "smooth",
      });
    }
  };

  return (
    <div class="mobileMarginTop">
      <FollowUs history={props.history} />
      <Container>
        <Grid container>
          <Grid
            xs={12}
            sm={6}
            className="uiux_BSSliderImgContainer"
            // data-aos="fade-up"
            data-aos-duration="1000"
          >
            <img
              src={UIUX_SLIDER}
              alt="best ui/ux icon 3d domaincer"
              className="uiux_SliderImg floatingUpDown"
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <div
              className="uiux_BSSliderRightSection"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <h1 className="uiux_sliderHeading">UI/UX</h1>
              <h2 className="uiux_sliderSubHeading">
                <b>Clarity Trump's consistency - </b> Opulent user experiences, driven by innovation.  App-solutely fabulous user experiences await with UX design that puts the user first.  From solving business challenges to discovering new possibilities, We’ll turn your application into a valuable asset for your business.
              </h2>
              <Hidden only={["xs"]}>
                <div className="uiux_serviceTabsSection">
                  <Paper square className="uiux_MTabsWidth">
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      indicatorColor="primary"
                      textColor="primary"
                      aria-label="tabs example"
                      className="uiux_MTabsContainer"
                    >
                      <Tab label="About" className="uiux_MTabBtn" />
                      <Tab
                        label="Overview"
                        className="uiux_MTabBtn"
                        onClick={() => handleTabScroll("OVER_VIEW")}
                      />
                      <Tab
                        label="Blog"
                        className="uiux_MTabBtn"
                        onClick={() => handleTabScroll("BLOG")}
                      />
                      <Tab
                        label="Why Domaincer"
                        className="uiux_MTabBtn"
                        onClick={() => handleTabScroll("WHY_DOMAINCER")}
                      />
                      {/* <Tab
                        label="Recent Works"
                        className="uiux_MTabBtn"
                        onClick={() => handleTabScroll("RECENT_WORKS")}
                      /> */}
                    </Tabs>
                  </Paper>
                </div>
              </Hidden>
              <Hidden only={["sm", "md", "lg", "xl"]}>
                <div className="uiux_serviceTabsSection">
                  <Paper square className="uiux_MTabsWidth">
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      indicatorColor="primary"
                      textColor="primary"
                      aria-label="tabs example"
                      className="uiux_MTabsContainer"
                    >
                      <Tab label="About" className="uiux_MTabBtn" />
                      <Tab
                        label="Overview"
                        className="uiux_MTabBtn"
                        onClick={() => handleTabScrollMobile("OVER_VIEW")}
                      />
                      <Tab
                        label="Blog"
                        className="uiux_MTabBtn"
                        onClick={() => handleTabScrollMobile("BLOG")}
                      />
                      <Tab
                        label="Why Domaincer"
                        className="uiux_MTabBtn"
                        onClick={() => handleTabScrollMobile("WHY_DOMAINCER")}
                      />
                      {/* <Tab
                        label="Recent Works"
                        className="uiux_MTabBtn"
                        onClick={() => handleTabScrollMobile("RECENT_WORKS")}
                      /> */}
                    </Tabs>
                  </Paper>
                </div>
              </Hidden>
            </div>
          </Grid>
        </Grid>
      </Container>
      <HowServiceWillHelpYou />
      <ServiceContentSection />
      <BlogSection />
      <WhyChooseUs />
      {/* <OurProcess /> */}
      {/* <CaseStudySection /> */}
    </div>
  );
};

export default UiUxService;
