import React from "react";
import "../portal.css";
import { Container, Grid } from "@material-ui/core";
import Particles from "react-particles-js";

const HowServiceWillHelpYouPortal = () => {
  return (
    <div>
      <Grid container>
        <Particles
          params={{
            particles: {
              number: {
                value: 350,
              },
              size: {
                value: 1,
              },
              color: {
                value: "#fff",
              },
              line_linked: {
                enable: false,
                distance: 120,
                color: "#fff",
                width: 1,
              },
            },
            interactivity: {
              events: {
                onhover: {
                  enable: true,
                  mode: "repulse",
                },
              },
            },
          }}
          className="particalsSection_portal"
        />
        <Grid
          xs={12}
          sm={12}
          style={{ zIndex: -1, position: "relative", textAlign: "center" }}
        >
          <div className="BSHelpYouRightSection_portal">
            <h1 className="BSHelpYouHeading_portal">Why Metaverse</h1>
            <h2
              className="BSHelpYouPara_portal"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              The metaverse will be a game changer for the average person,
              providing access to once unimaginable luxuries and experiences.
              Just like the internet opened up a world of knowledge and
              entertainment, the metaverse will allow us to virtually travel the
              globe, connect with loved ones across the world, and indulge in
              experiences previously reserved for the wealthy elite. Imagine
              having the world at your fingertips, all within the comfort of
              your own home. The metaverse will surely be a playground for the
              masses, breaking down barriers and bringing people closer
              together.
            </h2>
            <div
              data-aos="fade-up"
              data-aos-duration="1000"
              className="circleBSHelpYou_portal"
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default HowServiceWillHelpYouPortal;
