import React, { useState } from 'react';
import { Grid, IconButton, TextField } from '@material-ui/core';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Hidden from '@material-ui/core/Hidden';
import { BASE_URL } from '../../../../config/config';

const MotionGraphicsQAPage = (props) => {
  const MOTION_GRAPHICS_SLIDER = `${BASE_URL}/service_graphics_header_image.png`;

  Array.prototype.removeValue = function (val) {
    for (var i = 0; i < this.length; i++) {
      if (this[i] === val) {
        this.splice(i, 1);
        i--;
      }
    }
    return this;
  };

  const [pageName] = useState('motionGraphicsScreen');
  const [errorStatus, setErrorStatus] = useState(false);
  const [Q1A, setQ1A] = useState([]);
  const [Q2A, setQ2A] = useState('');
  const [Q3A, setQ3A] = useState('');

  const handleClick = (e) => {
    e.preventDefault();
    const { userDetails } = props;
    const qadata = {
      id: 'motionGraphicsQA',
      Q1: {
        question: 'Type of Video?',
        answer: Q1A,
      },
      Q2: {
        question: 'Product?',
        answer: Q2A,
      },
      Q3: {
        question: 'Length of the video?',
        answer: Q3A,
      },
    };
    if (Q1A.length === 0) {
      setErrorStatus(true);
    } else if (Q2A === '') {
      setErrorStatus(true);
    } else if (Q3A === '') {
      setErrorStatus(true);
    } else {
      const data = props.serviceData.removeValue(pageName);
      props.prevServiceData.push(pageName);
      props.nextStep(
        data,
        props.prevServiceData,
        qadata,
        userDetails,
        pageName
      );
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  const handleBack = (e) => {
    e.preventDefault();
    const data = props.prevServiceData.removeValue(pageName);
    props.prevStep(data);
  };

  return (
    <div className='quotePage__body'>
      <React.Fragment>
        <Grid container direction='row' justify='center' spacing={2}>
          <Grid item sm={10} xs={12} className='custom__stepOneGrid'>
            <div className='prevStep__div'>
              <IconButton>
                <ArrowBackIcon className='prevStep__btn' onClick={handleBack} />
              </IconButton>
            </div>
            <div>
              <h2 className='quote__question'>Motion Graphics</h2>
            </div>
            <Grid
              container
              direction='row'
              justify='space-between'
              alignItems='center'
            >
              <Hidden only={['sm', 'lg', 'md', 'xl']}>
                <Grid
                  item
                  sm={4}
                  xs={12}
                  className='animated__gif'
                  style={{ textAlign: 'center' }}
                >
                  <img
                    src={MOTION_GRAPHICS_SLIDER}
                    alt='Best motion graphics image domaincer'
                    className='getQuote_brandingImage floatingUpDown'
                  />
                </Grid>
              </Hidden>
              <Grid item sm={8} sm={8}>
                <div>
                  <div>
                    <h2 className='service__question'>1. Type of Video?</h2>
                  </div>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name='checkedExplainer'
                          className='custom__checkBox'
                          onChange={(evt, data) => {
                            if (data === true) {
                              setQ1A([...Q1A, 'Explainer']);
                              setErrorStatus(false);
                            } else {
                              const newQ1A = Q1A.removeValue('Explainer');
                              setQ1A(newQ1A);
                            }
                          }}
                        />
                      }
                      label='Explainer'
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name='checked2D'
                          className='custom__checkBox'
                          onChange={(evt, data) => {
                            if (data === true) {
                              setQ1A([...Q1A, '2D']);
                              setErrorStatus(false);
                            } else {
                              const newQ1A = Q1A.removeValue('2D');
                              setQ1A(newQ1A);
                            }
                          }}
                        />
                      }
                      label='2D'
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name='checkedWhiteboard'
                          className='custom__checkBox'
                          onChange={(evt, data) => {
                            if (data === true) {
                              setQ1A([...Q1A, 'Whiteboard']);
                              setErrorStatus(false);
                            } else {
                              const newQ1A = Q1A.removeValue('Whiteboard');
                              setQ1A(newQ1A);
                            }
                          }}
                        />
                      }
                      label='Whiteboard'
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name='checkedTypography'
                          className='custom__checkBox'
                          onChange={(evt, data) => {
                            if (data === true) {
                              setQ1A([...Q1A, 'Typography']);
                              setErrorStatus(false);
                            } else {
                              const newQ1A = Q1A.removeValue('Typography');
                              setQ1A(newQ1A);
                            }
                          }}
                        />
                      }
                      label='Typography'
                    />
                  </FormGroup>
                  <div>
                    <h2 className='service__question'>2. Product?</h2>
                    <TextField
                      id='outlined-multiline-static'
                      label='Enter product'
                      multiline
                      rows={1}
                      variant='outlined'
                      className='custom__textField'
                      onChange={(e) => {
                        setErrorStatus(false);
                        setQ2A(e.target.value);
                      }}
                    />
                  </div>
                  <div>
                    <h2 className='service__question'>
                      3. Length of the video?
                    </h2>
                    <TextField
                      id='outlined-multiline-static'
                      label='Enter length of the video'
                      multiline
                      rows={1}
                      variant='outlined'
                      className='custom__textField'
                      onChange={(e) => {
                        setErrorStatus(false);
                        setQ3A(e.target.value);
                      }}
                    />
                  </div>
                </div>
                {errorStatus && (
                  <p style={{ color: 'red', fontSize: 13, marginTop: 7 }}>
                    Please fill all the fields
                  </p>
                )}
              </Grid>
              <Hidden only={['xs']}>
                <Grid
                  item
                  sm={4}
                  xs={12}
                  className='animated__gif'
                  style={{ textAlign: 'center' }}
                >
                  <img
                    src={MOTION_GRAPHICS_SLIDER}
                    alt='Best motion graphics image domaincer'
                    className='getQuote_brandingImage floatingUpDown'
                  />
                </Grid>
              </Hidden>
            </Grid>
            <div className='nextStep__btnContainer'>
              <h5 className='nextStep__btn' onClick={handleClick}>
                Continue
              </h5>
              <IconButton>
                <DoubleArrowIcon className='nextStep__btnIcon arrowani' />
              </IconButton>
            </div>
          </Grid>
        </Grid>
      </React.Fragment>
    </div>
  );
};

export default MotionGraphicsQAPage;
