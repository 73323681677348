import React from 'react';
import '../brandstrategy.css';
import { Container, Grid } from '@material-ui/core';
import Particles from 'react-particles-js';

const HowServiceWillHelpYou = () => {
  return (
    <div>
      <Grid container>
        <Particles
          params={{
            particles: {
              number: {
                value: 500,
              },
              size: {
                value: 1,
              },
              color: {
                value: '#fff',
              },
              line_linked: {
                enable: false,
                distance: 120,
                color: '#fff',
                width: 1,
              },
            },
            interactivity: {
              events: {
                onhover: {
                  enable: true,
                  mode: 'repulse',
                },
              },
            },
          }}
          className='particalsSection_strategy'
        />
        <Grid
          xs={12}
          sm={12}
          style={{ zIndex: -1, position: 'relative', textAlign: 'center' }}
        >
          <div className='BSHelpYouRightSection_strategy'>
            <h1 className='BSHelpYouHeading_strategy'>
              How Brand Strategy will help your Brand?
            </h1>
            <h2
              className='BSHelpYouPara_strategy'
              data-aos='fade-up'
              data-aos-duration='1000'
            >
              We strongly believe that brand is the one significant asset of an
              organisation that will have a layer of communication to many
              audiences. Understanding your assert and primary need. We are
              propelled to think to your greatest advantage, to convey
              astonishing outcomes that are adjusted to your association's
              vision and key objectives.
            </h2>
            <div
              data-aos='fade-up'
              data-aos-duration='1000'
              className='circleBSHelpYou_strategy'
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default HowServiceWillHelpYou;
