import React from "react";
import "../socialmediamarketing.css";
import { Container, Grid, Card } from "@material-ui/core";
import Hidden from "@material-ui/core/Hidden";
import { BASE_URL } from "../../../../config/config";

const WhyChooseUsSocialMedia = () => {
  const WHY_CHOOSE_US_IMAGE = `${BASE_URL}/about_why_choose_us.png`;

  return (
    <div className="whyChooseUsSection_socialmedia">
      <Container>
        <h1 className="whyChooseUsTitle_socialmedia">Why Domaincer</h1>
        <Grid container spacing={2} style={{ justifyContent: "center" }}>
          <Grid item xs={12} sm={3}>
            <div
              className="whyChooseColumnUpContainer_socialmedia"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <h2 className="chooseTitle_socialmedia">Signiﬁcant Results</h2>
              <h3 className="choosePara_socialmedia">
                Maximize your online success with our reliable, scalable
                strategy for driving quality traffic to your website.
              </h3>
            </div>
            <div
              className="whyChooseColumnDownContainer_socialmedia"
              data-aos="fade-right"
              data-aos-duration="1400"
            >
              <h2 className="chooseTitle_socialmedia">
                Professional Management
              </h2>
              <h3 className="choosePara_socialmedia">
                Trust the most creative minds to drive your campaigns with our
                commitment to continuous optimization.
              </h3>
            </div>
          </Grid>
          <Grid item xs={12} sm={4} className="chooseUsImageRow_socialmedia">
            <img
              src={WHY_CHOOSE_US_IMAGE}
              alt="why choose us image 3d domaincer"
              className="chooseUsMiddleImg_socialmedia floatingUpDown"
            />
          </Grid>

          <Hidden only={["sm", "lg", "md", "xl"]}>
            <Grid item xs={12} sm={3}>
              <div
                className="whyChooseColumn3UpContainer_socialmedia"
                data-aos="fade-up"
                data-aos-duration="1200"
              >
                <h2 className="chooseTitle_socialmedia">
                  Powerful Social Media Insights
                </h2>
                <h3 className="choosePara_socialmedia">
                  Gain crucial insights into your social media marketing with
                  our company's in-depth analytics and optimization strategies.
                </h3>
              </div>
              <div
                className="whyChooseColumn3DownContainer_socialmedia"
                data-aos="fade-up"
                data-aos-duration="1400"
              >
                <h2 className="chooseTitle_socialmedia">
                  Captivating Content Strategies
                </h2>
                <h3 className="choosePara_socialmedia">
                  Increase brand awareness, engagement, and conversions with our
                  captivating content strategies and team of creative
                  professionals.
                </h3>
              </div>
            </Grid>
          </Hidden>
          <Hidden only={["xs"]}>
            <Grid item xs={12} sm={3}>
              <div
                className="whyChooseColumn3UpContainer_socialmedia"
                data-aos="fade-left"
                data-aos-duration="1200"
              >
                <h2 className="chooseTitle_socialmedia">
                  Powerful Social Media Insights
                </h2>
                <h3 className="choosePara_socialmedia">
                  Gain crucial insights into your social media marketing with
                  our company's in-depth analytics and optimization strategies.
                </h3>
              </div>
              <div
                className="whyChooseColumn3DownContainer_socialmedia"
                data-aos="fade-left"
                data-aos-duration="1600"
              >
                <h2 className="chooseTitle_socialmedia">
                  Captivating Content Strategies
                </h2>
                <h3 className="choosePara_socialmedia">
                  Increase brand awareness, engagement, and conversions with our
                  captivating content strategies and team of creative
                  professionals.
                </h3>
              </div>
            </Grid>
          </Hidden>
        </Grid>
      </Container>
    </div>
  );
};
export default WhyChooseUsSocialMedia;
