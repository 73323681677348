import React from "react";
import { Grid, IconButton } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Link } from "react-router-dom";
// import LOGO_ANIMATED_GIF from "../../images/getquote_handshake.png";

const QuoteThankyouPage = (props) => {
  return (
    <div className="quotePage__body">
      <React.Fragment>
        <Grid container direction="row" justify="center" spacing={2}>
          <Grid item xs={10} className="custom__stepOneGrid">
            <div className="service__container">
              {/* <img
                src={LOGO_ANIMATED_GIF}
                className="handshake_image floatingUpDown"
              /> */}
              <h1 className="thankYou_title">
                Awesome {props.userDetails.name}!{" "}
              </h1>
              <p className="thankYou_text">
                We've received your response. We'll contact you soon..
              </p>
            </div>
            <Link to="/">
              <div className="nextStep__btnContainer">
                <IconButton>
                  <ArrowBackIcon className="nextStep__btnIcon arrowani" />
                </IconButton>
                <h5 className="nextStep__btn">Back to home</h5>
              </div>
            </Link>
          </Grid>
        </Grid>
      </React.Fragment>
    </div>
  );
};

export default QuoteThankyouPage;
