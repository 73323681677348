import React, { useState, useEffect } from "react";
import { Container, Grid, Paper, Tabs, Tab, Hidden } from "@material-ui/core";
import "./aboutus.css";
import WhyChooseUs from "./whychooseus/whychooseus.component";
import AOS from "aos";
import ValubleStats from "./valublestats/valublestats.component";
import DirectorStatement from "./directorstatement/directorstatement.component";
import VisionMission from "./visionmission/visionmission.component";
import FollowUs from "../../commons/followus/followus.component";
import { BASE_URL } from "../../config/config";
import Skeleton from "react-loading-skeleton";
import fifteenPng from "../../commons/assets/15.2.png";

const AboutUs = (props) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleTabScroll = (value) => {
    if (value === "OUR_STORY") {
      window.scrollTo({
        top: 1300,
        behavior: "smooth",
      });
    } else if (value === "WHY_DOMAINCER") {
      window.scrollTo({
        top: 2600,
        behavior: "smooth",
      });
    }
  };

  const handleTabScrollMobile = (value) => {
    if (value === "OUR_STORY") {
      window.scrollTo({
        top: 2000,
        behavior: "smooth",
      });
    } else if (value === "WHY_DOMAINCER") {
      window.scrollTo({
        top: 4200,
        behavior: "smooth",
      });
    }
  };

  return (
    <div class="mobileMarginTop">
      <FollowUs history={props.history} />
      <Container fixed>
        <Grid container>
          <Grid xs={12} sm={6} style={{ textAlign: "center" }}>
            <img
              src={`${BASE_URL}/15.2.png`}
              className="aboutusImg"
              data-aos="fade-right"
              alt="12 year 3d domaincer"
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <div className="aboutusBannerRightSection">
              <h1 className="aboutusBannerTitle">
                The Story of Domaincer
                <br /> Starts with Trust
              </h1>
              <h3 className="aboutusBannerSubTitle">
                Domaincer is a leading digital agency based in Chennai with a
                strong track record of over 15 years in the industry. The
                company has been recognized for its high standards of quality,
                having received ISO 9001:2015 certification for its Quality
                Management System. As a subsidiary of Shootup Technologies
                Private Limited, Domaincer leverages its wealth of industry
                knowledge and experience to deliver innovative and effective
                solutions for brands seeking to thrive in the digital age. These
                solutions are designed to be both creative and strategic, with a
                focus on maximizing return on investment.
              </h3>
            </div>
            <Hidden only={["xs"]}>
              <div className="aboutusTabsSection">
                <Paper
                  square
                  style={{ width: "fit-content", boxShadow: "none" }}
                >
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    aria-label="tabs example"
                    className="MTabsContainer"
                  >
                    <Tab label="About" className="MTabBtn" />
                    <Tab
                      label="Brief"
                      className="MTabBtn"
                      onClick={() => handleTabScroll("OUR_STORY")}
                    />
                    <Tab
                      label="Why Domaincer"
                      className="MTabBtn"
                      onClick={() => handleTabScroll("WHY_DOMAINCER")}
                    />
                  </Tabs>
                </Paper>
              </div>
            </Hidden>
            <Hidden only={["sm", "md", "lg", "xl"]}>
              <div className="aboutusTabsSection">
                <Paper
                  square
                  style={{ width: "fit-content", boxShadow: "none" }}
                >
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    aria-label="tabs example"
                    className="MTabsContainer"
                  >
                    <Tab label="About" className="MTabBtn" />
                    <Tab
                      label="Brief"
                      className="MTabBtn"
                      onClick={() => handleTabScrollMobile("OUR_STORY")}
                    />
                    <Tab
                      label="Why Domaincer"
                      className="MTabBtn"
                      onClick={() => handleTabScrollMobile("WHY_DOMAINCER")}
                    />
                  </Tabs>
                </Paper>
              </div>
            </Hidden>
          </Grid>
        </Grid>
      </Container>
      <ValubleStats />
      <VisionMission />
      <DirectorStatement />
      <WhyChooseUs />
    </div>
  );
};

export default AboutUs;
