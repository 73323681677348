import React, { useState, useEffect } from 'react';
import './valublestats.css';
import { Grid, Modal, Fade, Backdrop } from '@material-ui/core';
import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite';
import CloseIcon from '@material-ui/icons/Close';
import AOS from 'aos';
import { BASE_URL } from '../../../config/config';
import Skeleton from "react-loading-skeleton";


const ValubleStats = () => {
  useEffect(() => {
    AOS.init();
  });

  const [openModal, setModalOpen] = useState(false);

  const handleOpen = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  return (
    <div className='valubleStatsContainer'>
      <Grid container style={{ justifyContent: 'center' }}>
        <Grid xs={12} sm={7}>
          <div className='valubleStatsLeftSection'>
            <h1 className='valubleStatsTitle'>4 - Pillars of Our Success</h1>
            <div style={{ marginTop: 20 }}>
              <div className='statsFirstRow'>
                <div
                  className='firstStatsContainer'
                  data-aos='fade-left'
                  data-aos-duration='1000'
                >
                  <div className='statsIcon__row'>
                    <img
                      src={`${BASE_URL}/about_excellence_icon.png`}
                      alt='about excellence image 3d domaincer'
                      className='statsIcon'
                    />
                    <h2 className='statsCount'>15+</h2>
                  </div>
                  <h3 className='statsName'>Years of Experience</h3>
                </div>
                <div
                  className='secondStatsContainer'
                  data-aos='fade-right'
                  data-aos-duration='1200'
                >
                  <div className='statsIcon__row'>
                    <h2 className='statsCount'>100+</h2>
                    <img
                      src={`${BASE_URL}/about_project_icon.png`}
                      alt='project completed image 3d domaincer'
                      className='statsIcon'
                    />
                  </div>
                  <h3 className='statsName'>Projects Completed</h3>
                </div>
              </div>

              <div className='statsFirstRow'>
                <div
                  className='thirdStatsContainer'
                  data-aos='fade-left'
                  data-aos-duration='1400'
                >
                  <div className='statsIcon__row'>
                    <img
                      src={`${BASE_URL}/about_review_icon.png`}
                      alt='review image 3d domaincer'
                      className='statsIcon'
                    />
                    <h2 className='statsStarCount'>250+</h2>
                  </div>
                  <h3 className='statsName'>5 Star Reviews</h3>
                </div>

                <div
                  className='fourthStatsContainer'
                  data-aos='fade-right'
                  data-aos-duration='1600'
                >
                  <div className='statsIcon__row'>
                    <h2 className='statsCount'>300%</h2>
                    <img
                      src={`${BASE_URL}/about_growth_icon.png`}
                      alt='statistics image 3d domaincer'
                      className='statsIcon'
                    />
                  </div>{' '}
                  <h3 className='statsName'>Year-on-Year Growth</h3>
                </div>
              </div>
            </div>
            <div
              data-aos='fade-up'
              data-aos-duration='1000'
              className='telegramImgValubleStats'
            >
              <img
                className='telegramImg floatingVerticalIcon'
                alt='rocket image 3d domaincer'
                src={`${BASE_URL}/about_rocket_image.png`}
              />
            </div>
            <div
              data-aos='fade-up'
              data-aos-duration='1000'
              className='circleValubleStats__about'
            />
          </div>
        </Grid>
        <Grid xs={12} sm={5} style={{ zIndex: 999 }}>
          <div className='statusVideoContainer'>
            <PlayCircleFilledWhiteIcon
              className='playIcon'
              onClick={handleOpen}
            />
          </div>
        </Grid>
      </Grid>
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        className='modal_about_section'
        open={openModal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModal}>
          <div className='caseModalContainer__about'>
            <CloseIcon
              onClick={handleClose}
              className='about_youtubeCloseButton'
            />
            <iframe
              src='https://www.youtube.com/embed/OnfsklAlxMA?rel=0'
              frameborder='0'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
              allowfullscreen
              title='video'
              className='youtubeAboutVideo'
            />
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default ValubleStats;
