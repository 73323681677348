import React, { useState, useEffect } from "react";
import { Container, Grid, Button, Link } from "@material-ui/core";
import "../searchengineoptimisation.css";
import Hidden from "@material-ui/core/Hidden";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { BASE_URL } from "../../../../config/config";

const STRATEGY = [
  {
    key: "CONTENT_SECTION_ONE",
    name: "In-depth Audit",
    subtext: "Where it all begins: the search",
    image: `${BASE_URL}/tab_seo_icon1.png`,
    alt: "website audit image domaincer",
  },
  {
    key: "CONTENT_SECTION_TWO",
    name: "SEO Strategy",
    subtext: "Details, details, details – the key to success",
    image: `${BASE_URL}/tab_seo_icon2.png`,
    alt: "SEO strategy image domaincer",
  },
  {
    key: "CONTENT_SECTION_THREE",
    name: "Ranking Optimisation",
    subtext: "Maximizing results through smart strategy",
    image: `${BASE_URL}/tab_seo_icon3.png`,
    alt: "Ranking optimisation image domaincer",
  },
];

const ServiceContentSectionSearchEngine = () => {
  const [activeTabService, setActiveTabService] = useState(
    "CONTENT_SECTION_ONE"
  );

  return (
    <div>
      <div style={{ paddingTop: 100, paddingBottom: 30 }}>
        <h1 className="service_subheading_searchengine">
          Search Engine Optimization
        </h1>
        <h2 className="service_subtext_searchengine">
          By optimizing your website for search engines, you can drive more
          targeted traffic to your site and make a big impact on your sales.
          Don't waste money on paid ads.
        </h2>
      </div>
      {/* visible only on desktop, laptop */}
      <Hidden only={["xs"]}>
        <div className="subServiceTabSection_searchengine">
          {STRATEGY.map((service, index) => (
            <div
              className="subServiceContaincer_searchengine"
              style={{
                borderColor:
                  service.key === activeTabService ? "#0077FF" : "#FFF",
              }}
              onClick={() => setActiveTabService(service.key)}
            >
              <img
                className="subServiceImg_searchengine"
                src={service.image}
                alt={service.alt}
              />
              <div>
                <h1 className="subServiceTitle_searchengine">{service.name}</h1>
                <h2 className="subServiceSubTitle_searchengine">
                  {service.subtext}
                </h2>
              </div>
            </div>
          ))}
        </div>
        <div>
          {activeTabService === "CONTENT_SECTION_ONE" && (
            <div className="subServiceParaContainer_searchengine">
              <Container
                maxWidth="lg"
                className="subServiceFixedSection_searchengine"
              >
                <div className="glowEffectBrand_searchengine"></div>

                <div className="serviceContent_searchengine">
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <h3 className="subServicePara_searchengine">
                        Attract, engage, and convert with our expert inbound
                        marketing funnel strategy! By blending valuable content
                        and lead magnets, we'll help you build a strong rapport
                        with your customers and understand their decision-making
                        process.We guide potential customers through the buying
                        journey with our valuable resources and warm them up for
                        the sale.
                      </h3>
                    </Grid>
                  </Grid>
                </div>
              </Container>
            </div>
          )}
          {activeTabService === "CONTENT_SECTION_TWO" && (
            <div className="subServiceParaContainer_searchengine">
              <Container
                maxWidth="lg"
                className="subServiceFixedSection_searchengine"
              >
                <div className="glowEffectDigital_searchengine"></div>

                <div className="serviceContent_searchengine">
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <h3 className="subServicePara_searchengine">
                        Boost your organic traffic and search engine visibility
                        with our expert on-page and off-page optimization
                        strategy! By combining marketing and technological
                        tactics, we'll help you improve the quality and quantity
                        of traffic to your site.Don't let poor visibility hold
                        you back.
                      </h3>
                    </Grid>
                  </Grid>
                </div>
              </Container>
            </div>
          )}
          {activeTabService === "CONTENT_SECTION_THREE" && (
            <div className="subServiceParaContainer_searchengine">
              <Container
                maxWidth="lg"
                className="subServiceFixedSection_searchengine"
              >
                <div className="glowEffectWorkFlow_searchengine"></div>

                <div className="serviceContent_searchengine">
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <h3 className="subServicePara_searchengine">
                        Step into the spotlight and boost your brand's
                        visibility with our expert PR and SEO skills! Whether
                        through digital or traditional channels, our team has
                        the expertise to effectively communicate with the
                        public. From leveraging media and online influencers to
                        promote your brand on social media and through local and
                        national reporting, we'll help you stay top of mind.
                      </h3>
                    </Grid>
                  </Grid>
                </div>
              </Container>
            </div>
          )}
        </div>
      </Hidden>

      {/* visible only on mobile */}
      <Hidden only={["sm", "lg", "md", "xl"]}>
        <div style={{ position: "relative" }}>
          <ArrowBackIosIcon
            className="subServiceTabLeftArrowHome"
            onClick={() => {
              document.getElementById("strategyScroll").scrollLeft -= 400;
            }}
          />
          <div
            className="subServiceTabSection_searchengine"
            id="strategyScroll"
          >
            <div>
              <div className="subServiceContaincer_searchengine">
                <img
                  className="subServiceImg_searchengine"
                  src={`${BASE_URL}/tab_seo_icon1.png`}
                  alt="website audit image domaincer"
                />
                <div>
                  <h1 className="subServiceTitle_searchengine">
                    In-depth Audit
                  </h1>
                  <h2 className="subServiceSubTitle_searchengine">
                    Where it all begins: the search
                  </h2>
                </div>
              </div>
              <div className="subServiceParaContainer_searchengine">
                <Container
                  maxWidth="lg"
                  className="subServiceFixedSection_searchengine"
                >
                  <div className="glowEffectBrand_searchengine"></div>

                  <div className="serviceContent_searchengineOne">
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <h3 className="subServicePara_searchengine">
                          Attract, engage, and convert with our expert inbound
                          marketing funnel strategy! By blending valuable
                          content and lead magnets, we'll help you build a
                          strong rapport with your customers and understand
                          their decision-making process.We guide potential
                          customers through the buying journey with our valuable
                          resources and warm them up for the sale.
                        </h3>
                      </Grid>
                    </Grid>
                  </div>
                </Container>
              </div>
            </div>

            <div>
              <div className="subServiceContaincer_searchengine">
                <img
                  className="subServiceImg_searchengine"
                  src={`${BASE_URL}/tab_seo_icon2.png`}
                  alt="SEO strategy image domaincer"
                />
                <div>
                  <h1 className="subServiceTitle_searchengine">SEO Strategy</h1>
                  <h2 className="subServiceSubTitle_searchengine">
                    Details, details, details – the key to success
                  </h2>
                </div>
              </div>

              <div className="subServiceParaContainer_searchengine">
                <Container
                  maxWidth="lg"
                  className="subServiceFixedSection_searchengine"
                >
                  <div className="glowEffectDigital_searchengine"></div>

                  <div className="serviceContent_searchengineTwo">
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <h3 className="subServicePara_searchengine">
                          Boost your organic traffic and search engine
                          visibility with our expert on-page and off-page
                          optimization strategy! By combining marketing and
                          technological tactics, we'll help you improve the
                          quality and quantity of traffic to your site.Don't let
                          poor visibility hold you back.
                        </h3>
                      </Grid>
                    </Grid>
                  </div>
                </Container>
              </div>
            </div>

            <div>
              <div className="subServiceContaincer_searchengine">
                <img
                  className="subServiceImg_searchengine"
                  src={`${BASE_URL}/tab_seo_icon3.png`}
                  alt="Ranking optimisation image domaincer"
                />
                <div>
                  <h1 className="subServiceTitle_searchengine">
                    Ranking Optimisation
                  </h1>
                  <h2 className="subServiceSubTitle_searchengine">
                  Maximizing results through smart strategy
                  </h2>
                </div>
              </div>
              <div className="subServiceParaContainer_searchengine">
                <Container
                  maxWidth="lg"
                  className="subServiceFixedSection_searchengine"
                >
                  <div className="glowEffectWorkFlow_searchengine"></div>

                  <div className="serviceContent_searchengine">
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <h3 className="subServicePara_searchengine">
                          Step into the spotlight and boost your brand's
                          visibility with our expert PR and SEO skills! Whether
                          through digital or traditional channels, our team has
                          the expertise to effectively communicate with the
                          public. From leveraging media and online influencers
                          to promote your brand on social media and through
                          local and national reporting, we'll help you stay top
                          of mind.
                        </h3>
                      </Grid>
                    </Grid>
                  </div>
                </Container>
              </div>
            </div>
          </div>
          <ArrowForwardIosIcon
            className="subServiceTabRightArrowHome"
            onClick={() => {
              document.getElementById("strategyScroll").scrollLeft += 400;
            }}
          />
        </div>
      </Hidden>
    </div>
  );
};

export default ServiceContentSectionSearchEngine;
