import React, { useEffect, useState } from "react";
import { Container, Grid, Modal, Fade, Backdrop } from "@material-ui/core";
import { BASE_URL } from "../../../config/config";
import Carousel from "./carousel";
import "./carousel.css";
import CloseIcon from "@material-ui/icons/Close";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import { AutoRotatingCarousel, Slide } from "material-auto-rotating-carousel";
import LazyLoad from "react-lazyload";
import Skeleton from "react-loading-skeleton";
import styled, { keyframes } from "styled-components";
import AOS from "aos";
import { RadioButtonCheckedSharp } from "@material-ui/icons";

const STRATEGY = [
  {
    id: 8798,
    url: `${BASE_URL}/metaverse_main.png`,
    large_url: `${BASE_URL}/metaverse_main.png`,
    source_id: null,
    path: "/metaverse-service",
    name: "Metaverse",
  },
  {
    id: 788,
    url: `${BASE_URL}/blockchain_main.png`,
    large_url: `${BASE_URL}/blockchain_main.png`,
    source_id: null,
    path: "/blockchain-service",
    name: "Blockchain",
  },
];

const DESIGN = [
  {
    id: 8798,
    url: `${BASE_URL}/service_branding_header_image.png`,
    large_url: `${BASE_URL}/service_branding_header_image.png`,
    source_id: null,
    path: "/best-branding-company",
    name: "Branding",
  },
  {
    id: 788,
    url: `${BASE_URL}/service_uiux_header_image.png`,
    large_url: `${BASE_URL}/service_uiux_header_image.png`,
    source_id: null,
    path: "/ui-ux-design",
    name: "UI/UX",
  },
  {
    id: 8802,
    url: `${BASE_URL}/service_graphics_header_image.png`,
    large_url: `${BASE_URL}/service_graphics_header_image.png`,
    source_id: null,
    path: "/motion-graphics-design",
    name: "Motion Graphics",
  },
];

const DEVELOPMENT = [
  {
    id: 8798,
    url: `${BASE_URL}/service_mobile_header_image.png`,
    large_url: `${BASE_URL}/service_mobile_header_image.png`,
    source_id: null,
    path: "/mobile-app-development-company",
    name: "Mobile App Development",
  },
  {
    id: 788,
    url: `${BASE_URL}/service_portals_header_image.png`,
    large_url: `${BASE_URL}/service_portals_header_image.png`,
    source_id: null,
    path: "/web-portal-development",
    name: "Web Portal Development",
  },
  {
    id: 8802,
    url: `${BASE_URL}/service_website_header_image.png`,
    large_url: `${BASE_URL}/service_website_header_image.png`,
    source_id: null,
    path: "/website-development-company",
    name: "Website Development",
  },
  {
    id: 8234,
    url: `${BASE_URL}/service_devops_header_image.png`,
    large_url: `${BASE_URL}/service_devops_header_image.png`,
    source_id: null,
    path: "/devops-consulting-company",
    name: "Devops Consulting",
  },
];

const MARKETING = [
  {
    id: 8798,
    url: `${BASE_URL}/service_social_header_image.png`,
    large_url: `${BASE_URL}/service_social_header_image.png`,
    source_id: null,
    path: "/social-media-marketing-service",
    name: "Social Media Marketing",
  },
  {
    id: 788,
    url: `${BASE_URL}/service_seo_header_image.png`,
    large_url: `${BASE_URL}/service_seo_header_image.png`,
    source_id: null,
    path: "/search-engine-optimization-service",
    name: "Search Engine Optimization",
  },
];

const STRATEGY_URL = "https://www.youtube.com/embed/OnfsklAlxMA?rel=0";
const DESIGN_URL = "https://www.youtube.com/embed/OnfsklAlxMA?rel=0";
const DEVELOPMENT_URL = "https://www.youtube.com/embed/OnfsklAlxMA?rel=0";
const MARKETING_URL = "https://www.youtube.com/embed/OnfsklAlxMA?rel=0";

const ImageWrapper = styled.div`
  position: relative;
`;

const Placeholder = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
`;

const ServicesV2Component = (props) => {
  const [mainImg, setMainImg] = useState([]);
  const [activeDot, setActiveDot] = useState(1);
  const [showStrategyModal, setShowStrategyModal] = useState(false);
  const [showDesignModal, setShowDesignModal] = useState(false);
  const [showDevelopmentModal, setShowDevelopmentModal] = useState(false);
  const [showMarketingModal, setShowMarketingModal] = useState(false);
  const [strategyActive, setstrategyActive] = useState(true);
  const [designActive, setdesignActive] = useState(false);
  const [developmentActive, setdevelopmentActive] = useState(false);
  const [marketingActive, setmarketingActive] = useState(false);

  const [skeletonLoader, setskeletonLoader] = useState(false);

  const refPlaceholder = React.useRef();

  useEffect(() => {
    setMainImg(STRATEGY);
    AOS.init();
  }, []);

  const removePlaceholder = () => {
    refPlaceholder.current.remove();
    setskeletonLoader(false);
  };

  return (
    <React.Fragment>
      <div
        className="mobileMarginTop"
        style={{
          paddingBottom: 100,
          paddingTop: 100,
          marginBottom: 100,
          backgroundColor: "#111111",
        }}
        data-aos="fade-up"
        data-aos-duration="3000"
      >
        <Container>
          <div className="categoryHomeTitle_section">
            <div className="categroyHomeTitle_row">
              <h1 className="categroyHomeTitle_text">Our Services</h1>
              <div className="categroyHomeMenu_row">
                <React.Fragment>
                  <p
                    className={
                      designActive === true
                        ? "categoryHomeMenu_active"
                        : "categoryHomeMenu_inActive"
                    }
                    onClick={() => {
                      setdesignActive(true);
                      setstrategyActive(false);
                      setdevelopmentActive(false);
                      setmarketingActive(false);
                      setActiveDot(2);
                      setMainImg(DESIGN);
                    }}
                  >
                    Design
                  </p>
                  <p
                    className={
                      developmentActive === true
                        ? "categoryHomeMenu_active"
                        : "categoryHomeMenu_inActive"
                    }
                    onClick={() => {
                      setdevelopmentActive(true);
                      setdesignActive(false);
                      setstrategyActive(false);
                      setmarketingActive(false);
                      setActiveDot(3);
                      setMainImg(DEVELOPMENT);
                    }}
                  >
                    Web 2.0
                  </p>
                  <p
                    className={
                      strategyActive === true
                        ? "categoryHomeMenu_active"
                        : "categoryHomeMenu_inActive"
                    }
                    onClick={() => {
                      setstrategyActive(true);
                      setdesignActive(false);
                      setdevelopmentActive(false);
                      setmarketingActive(false);
                      setActiveDot(1);
                      setMainImg(STRATEGY);
                    }}
                  >
                    Web 3.0
                  </p>
                  <p
                    className={
                      marketingActive === true
                        ? "categoryHomeMenu_active"
                        : "categoryHomeMenu_inActive"
                    }
                    onClick={() => {
                      setmarketingActive(true);
                      setdevelopmentActive(false);
                      setdesignActive(false);
                      setstrategyActive(false);
                      setActiveDot(4);
                      setMainImg(MARKETING);
                    }}
                  >
                    Marketing
                  </p>
                </React.Fragment>
              </div>
            </div>
          </div>
          <Grid container style={{ alignItems: "center" }}>
            {activeDot === 1 && (
              <Grid xs={12} sm={6} style={{ alignSelf: "center" }}>
                <div
                  className="servicev2_headingContainer"
                  data-aos="fade-down"
                  data-aos-duration="1000"
                >
                  <h2 className="servicev2Home_heading">Web 3.0</h2>
                  <div
                    className="playVideoContainer_Home"
                    onClick={() => setShowStrategyModal(true)}
                  >
                    {/* <div className="blob"></div>
                    <PlayCircleFilledIcon className="playVideoStrIcon" />
                    <h4 className="playVideoTextHome">Play Video</h4> */}
                  </div>
                </div>

                <h3
                  className="servicev2Home_subtext"
                  data-aos="fade-down"
                  data-aos-duration="1200"
                >
                  This new generation of web services for websites and apps is
                  all about leveraging data to drive innovation and utilizing
                  machine-based data understanding for a Semantic Web. The
                  endgame? More intelligent, connected, and open websites.
                </h3>
              </Grid>
            )}
            {activeDot === 1 && (
              <Grid xs={12} sm={6} data-aos="fade-up" data-aos-duration="1000">
                <React.Fragment>
                  <ImageWrapper>
                    <Placeholder ref={refPlaceholder} />
                    <LazyLoad>
                      {mainImg.length > 0 && (
                        <Carousel
                          imgList={mainImg}
                          img_width={300}
                          img_height={300}
                          m_img_width={120}
                          m_img_height={120}
                          visibleImages={3}
                          duration={750}
                          activeDot={1}
                        />
                      )}
                    </LazyLoad>
                  </ImageWrapper>
                </React.Fragment>
              </Grid>
            )}

            {activeDot === 2 && (
              <Grid xs={12} sm={6} style={{ alignSelf: "center" }}>
                <div
                  className="servicev2_headingContainer"
                  data-aos="fade-down"
                  data-aos-duration="1000"
                >
                  <h2 className="servicev2Home_heading">Design</h2>
                  <div
                    className="playVideoContainer_Home"
                    onClick={() => setShowStrategyModal(true)}
                  >
                    {/* <div className="blob"></div>
                    <PlayCircleFilledIcon className="playVideoDesIcon" />
                    <h4 className="playVideoTextHome">Play Video</h4> */}
                  </div>
                </div>
                <h3
                  className="servicev2Home_subtext"
                  data-aos="fade-down"
                  data-aos-duration="1200"
                >
                  Our team at Domaincer is second to none when it comes to
                  identifying opportunities and designing the way forward for
                  businesses. We're passionate about transforming companies and
                  playing a vital role in people's lives.
                </h3>
              </Grid>
            )}
            {activeDot === 2 && (
              <Grid xs={12} sm={6} data-aos="fade-up" data-aos-duration="1000">
                <React.Fragment>
                  <ImageWrapper>
                    <Placeholder ref={refPlaceholder} />
                    <LazyLoad>
                      {mainImg.length > 0 && (
                        <Carousel
                          imgList={mainImg}
                          img_width={300}
                          img_height={300}
                          m_img_width={120}
                          m_img_height={120}
                          visibleImages={3}
                          duration={750}
                          activeDot={2}
                        />
                      )}
                    </LazyLoad>
                  </ImageWrapper>
                </React.Fragment>
              </Grid>
            )}

            {activeDot === 3 && (
              <Grid xs={12} sm={6} style={{ alignSelf: "center" }}>
                <div
                  className="servicev2_headingContainer"
                  data-aos="fade-down"
                  data-aos-duration="1000"
                >
                  <h2 className="servicev2Home_heading">Development</h2>
                  <div
                    className="playVideoContainer_Home"
                    onClick={() => setShowDevelopmentModal(true)}
                  >
                    {/* <div className="blob"></div>
                    <PlayCircleFilledIcon className="playVideoDevIcon" />
                    <h4 className="playVideoTextHome">Play Video</h4> */}
                  </div>
                </div>
                <h3
                  className="servicev2Home_subtext"
                  data-aos="fade-down"
                  data-aos-duration="1200"
                >
                  The process of developing a top-notch project can be a maze of
                  challenges. At Domaincer, we specialize in expertly guiding
                  our clients through the process and delivering world-class
                  results. Plus, our offshore web and software development
                  services are both premium and budget-friendly.
                </h3>
              </Grid>
            )}
            {activeDot === 3 && (
              <Grid xs={12} sm={6} data-aos="fade-up" data-aos-duration="1000">
                <React.Fragment>
                  <ImageWrapper>
                    <Placeholder ref={refPlaceholder} />
                    <LazyLoad>
                      {mainImg.length > 0 && (
                        <Carousel
                          imgList={mainImg}
                          img_width={300}
                          img_height={300}
                          m_img_width={120}
                          m_img_height={120}
                          visibleImages={3}
                          duration={750}
                          activeDot={3}
                        />
                      )}
                    </LazyLoad>
                  </ImageWrapper>
                </React.Fragment>
              </Grid>
            )}

            {activeDot === 4 && (
              <Grid xs={12} sm={6} style={{ alignSelf: "center" }}>
                <div
                  className="servicev2_headingContainer"
                  data-aos="fade-down"
                  data-aos-duration="1000"
                >
                  <h2 className="servicev2Home_heading">Marketing</h2>
                  <div
                    className="playVideoContainer_Home"
                    onClick={() => setShowMarketingModal(true)}
                  >
                    {/* <div className="blob"></div>
                    <PlayCircleFilledIcon className="playVideoMarIcon" />
                    <h4 className="playVideoTextHome">Play Video</h4> */}
                  </div>
                </div>
                <h3
                  className="servicev2Home_subtext"
                  data-aos="fade-down"
                  data-aos-duration="1200"
                >
                  Get ready to elevate your online presence with Domaincer, the
                  premier digital media organization. Our services will boost
                  your brand recognition and make it easier for customers, both
                  current and potential, to access your products or services.
                  With Domaincer by your side, you can expect to see an increase
                  in profits and a noticeable improvement in your online
                  presence.
                </h3>
              </Grid>
            )}
            {activeDot === 4 && (
              <Grid xs={12} sm={6} data-aos="fade-up" data-aos-duration="1000">
                <React.Fragment>
                  <ImageWrapper>
                    <Placeholder ref={refPlaceholder} />
                    <LazyLoad>
                      {mainImg.length > 0 && (
                        <Carousel
                          imgList={mainImg}
                          img_width={300}
                          img_height={300}
                          m_img_width={120}
                          m_img_height={120}
                          visibleImages={3}
                          duration={750}
                          activeDot={4}
                        />
                      )}
                    </LazyLoad>
                  </ImageWrapper>
                </React.Fragment>
              </Grid>
            )}

            <div className="dotsContainer_Home">
              <div
                style={{
                  borderWidth: 2,
                  borderColor: "#fff",
                  borderStyle: activeDot === 1 ? "solid" : "none",
                }}
                className="dotFirstParentDiv_Home"
                onClick={() => {
                  setActiveDot(1);
                  setMainImg(STRATEGY);
                }}
              >
                <div
                  className="FirstDot_Home"
                  style={{ margin: activeDot !== 1 ? 4 : 2 }}
                ></div>
              </div>
              <div
                style={{
                  borderWidth: 2,
                  borderColor: "#fff",
                  borderStyle: activeDot === 2 ? "solid" : "none",
                }}
                className="dotsParentDiv_Home"
                onClick={() => {
                  setActiveDot(2);
                  setMainImg(DESIGN);
                }}
              >
                <div
                  className="SecondDot_Home"
                  style={{ margin: activeDot !== 2 ? 4 : 2 }}
                ></div>
              </div>
              <div
                style={{
                  borderWidth: 2,
                  borderColor: "#fff",
                  borderStyle: activeDot === 3 ? "solid" : "none",
                }}
                className="dotsParentDiv_Home"
                onClick={() => {
                  setActiveDot(3);
                  setMainImg(DEVELOPMENT);
                }}
              >
                <div
                  className="ThirdDot_Home"
                  style={{ margin: activeDot !== 3 ? 4 : 2 }}
                ></div>
              </div>
              <div
                style={{
                  borderWidth: 2,
                  borderColor: "#fff",
                  borderStyle: activeDot === 4 ? "solid" : "none",
                }}
                className="dotsParentDiv_Home"
                onClick={() => {
                  setActiveDot(4);
                  setMainImg(MARKETING);
                }}
              >
                <div
                  className="FourthDot_Home"
                  style={{ margin: activeDot !== 4 ? 4 : 2 }}
                ></div>
              </div>
            </div>
          </Grid>
        </Container>

        {showStrategyModal && (
          <AutoRotatingCarousel
            // label="Get started"
            open={showStrategyModal}
            onClose={() => setShowStrategyModal(false)}
            onStart={() => setShowStrategyModal(false)}
            mobile
            autoplay={false}
            style={{ position: "absolute" }}
          >
            <Slide
              media={
                <React.Fragment>
                  <iframe
                    src="https://www.youtube.com/embed/OnfsklAlxMA?rel=0"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                    title="video"
                    className="youtubeVideo_carosel"
                  />
                  <CloseIcon
                    onClick={() => setShowStrategyModal(false)}
                    className="youtubeCloseButtonSlide"
                  />
                </React.Fragment>
              }
              mediaBackgroundStyle={{
                backgroundColor: "black",
                width: "75%",
                marginLeft: "auto",
                marginRight: "auto",
                paddingTop: 70,
              }}
              // style={{ backgroundColor: 'black', margin: '10%' }}
              className="SlideContainer"
              title="Metaverse"
            />
            <Slide
              media={
                <React.Fragment>
                  <iframe
                    src="https://www.youtube.com/embed/OnfsklAlxMA?rel=0"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                    title="video"
                    className="youtubeVideo_carosel"
                  />
                  <CloseIcon
                    onClick={() => setShowStrategyModal(false)}
                    className="youtubeCloseButtonSlide"
                  />
                </React.Fragment>
              }
              mediaBackgroundStyle={{
                backgroundColor: "black",
                width: "75%",
                marginLeft: "auto",
                marginRight: "auto",
                paddingTop: 70,
              }}
              // style={{ backgroundColor: 'black',  margin: '10%' }}
              className="SlideContainer"
              title="Blockchain"
            />
            <Slide
              media={
                <React.Fragment>
                  <iframe
                    src="https://www.youtube.com/embed/OnfsklAlxMA?rel=0"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                    title="video"
                    className="youtubeVideo_carosel"
                  />
                  <CloseIcon
                    onClick={() => setShowStrategyModal(false)}
                    className="youtubeCloseButtonSlide"
                  />
                </React.Fragment>
              }
              mediaBackgroundStyle={{
                backgroundColor: "black",
                width: "75%",
                marginLeft: "auto",
                marginRight: "auto",
                paddingTop: 70,
              }}
              className="SlideContainer"
              title="Workflow Automation"
            />
          </AutoRotatingCarousel>
        )}

        {showDesignModal && (
          <AutoRotatingCarousel
            // label="Get started"
            open={showDesignModal}
            onClose={() => setShowDesignModal(false)}
            onStart={() => setShowDesignModal(false)}
            mobile
            autoplay={false}
            style={{ position: "absolute" }}
          >
            <Slide
              media={
                <React.Fragment>
                  <iframe
                    src="https://www.youtube.com/embed/OnfsklAlxMA?rel=0"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                    title="video"
                    className="youtubeVideo_carosel"
                  />
                  <CloseIcon
                    onClick={() => setShowDesignModal(false)}
                    className="youtubeCloseButtonSlide"
                  />
                </React.Fragment>
              }
              mediaBackgroundStyle={{
                backgroundColor: "black",
                width: "75%",
                marginLeft: "auto",
                marginRight: "auto",
                paddingTop: 70,
              }}
              // style={{ backgroundColor: 'black', margin: '10%' }}
              className="SlideContainer"
              title="Branding"
            />
            <Slide
              media={
                <React.Fragment>
                  <iframe
                    src="https://www.youtube.com/embed/OnfsklAlxMA?rel=0"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                    title="video"
                    className="youtubeVideo_carosel"
                  />
                  <CloseIcon
                    onClick={() => setShowDesignModal(false)}
                    className="youtubeCloseButtonSlide"
                  />
                </React.Fragment>
              }
              mediaBackgroundStyle={{
                backgroundColor: "black",
                width: "75%",
                marginLeft: "auto",
                marginRight: "auto",
                paddingTop: 70,
              }}
              // style={{ backgroundColor: 'black',  margin: '10%' }}
              className="SlideContainer"
              title="UI/UX"
            />
            <Slide
              media={
                <React.Fragment>
                  <iframe
                    src="https://www.youtube.com/embed/OnfsklAlxMA?rel=0"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                    title="video"
                    className="youtubeVideo_carosel"
                  />
                  <CloseIcon
                    onClick={() => setShowDesignModal(false)}
                    className="youtubeCloseButtonSlide"
                  />
                </React.Fragment>
              }
              mediaBackgroundStyle={{
                backgroundColor: "black",
                width: "75%",
                marginLeft: "auto",
                marginRight: "auto",
                paddingTop: 70,
              }}
              className="SlideContainer"
              title="Motion Graphics"
            />
          </AutoRotatingCarousel>
        )}

        {showDevelopmentModal && (
          <AutoRotatingCarousel
            // label="Get started"
            open={showDevelopmentModal}
            onClose={() => setShowDevelopmentModal(false)}
            onStart={() => setShowDevelopmentModal(false)}
            mobile
            autoplay={false}
            style={{ position: "absolute" }}
          >
            <Slide
              media={
                <React.Fragment>
                  <iframe
                    src="https://www.youtube.com/embed/OnfsklAlxMA?rel=0"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                    title="video"
                    className="youtubeVideo_carosel"
                  />
                  <CloseIcon
                    onClick={() => setShowDevelopmentModal(false)}
                    className="youtubeCloseButtonSlide"
                  />
                </React.Fragment>
              }
              mediaBackgroundStyle={{
                backgroundColor: "black",
                width: "75%",
                marginLeft: "auto",
                marginRight: "auto",
                paddingTop: 70,
              }}
              // style={{ backgroundColor: 'black', margin: '10%' }}
              className="SlideContainer"
              title="Mobile App Development"
            />
            <Slide
              media={
                <React.Fragment>
                  <iframe
                    src="https://www.youtube.com/embed/OnfsklAlxMA?rel=0"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                    title="video"
                    className="youtubeVideo_carosel"
                  />
                  <CloseIcon
                    onClick={() => setShowDevelopmentModal(false)}
                    className="youtubeCloseButtonSlide"
                  />
                </React.Fragment>
              }
              mediaBackgroundStyle={{
                backgroundColor: "black",
                width: "75%",
                marginLeft: "auto",
                marginRight: "auto",
                paddingTop: 70,
              }}
              // style={{ backgroundColor: 'black', margin: '10%' }}
              className="SlideContainer"
              title="Web Portal Development"
            />
            <Slide
              media={
                <React.Fragment>
                  <iframe
                    src="https://www.youtube.com/embed/OnfsklAlxMA?rel=0"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                    title="video"
                    className="youtubeVideo_carosel"
                  />
                  <CloseIcon
                    onClick={() => setShowDevelopmentModal(false)}
                    className="youtubeCloseButtonSlide"
                  />
                </React.Fragment>
              }
              mediaBackgroundStyle={{
                backgroundColor: "black",
                width: "75%",
                marginLeft: "auto",
                marginRight: "auto",
                paddingTop: 70,
              }}
              // style={{ backgroundColor: 'black',  margin: '10%' }}
              className="SlideContainer"
              title="Website Development"
            />
            <Slide
              media={
                <React.Fragment>
                  <iframe
                    src="https://www.youtube.com/embed/OnfsklAlxMA?rel=0"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                    title="video"
                    className="youtubeVideo_carosel"
                  />
                  <CloseIcon
                    onClick={() => setShowDevelopmentModal(false)}
                    className="youtubeCloseButtonSlide"
                  />
                </React.Fragment>
              }
              mediaBackgroundStyle={{
                backgroundColor: "black",
                width: "75%",
                marginLeft: "auto",
                marginRight: "auto",
                paddingTop: 70,
              }}
              className="SlideContainer"
              title="Devops Consulting"
            />
          </AutoRotatingCarousel>
        )}

        {showMarketingModal && (
          <AutoRotatingCarousel
            // label="Get started"
            open={showMarketingModal}
            onClose={() => setShowMarketingModal(false)}
            onStart={() => setShowMarketingModal(false)}
            mobile
            autoplay={false}
            style={{ position: "absolute" }}
          >
            <Slide
              media={
                <React.Fragment>
                  <iframe
                    src="https://www.youtube.com/embed/OnfsklAlxMA?rel=0"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                    title="video"
                    className="youtubeVideo_carosel"
                  />
                  <CloseIcon
                    onClick={() => setShowMarketingModal(false)}
                    className="youtubeCloseButtonSlide"
                  />
                </React.Fragment>
              }
              mediaBackgroundStyle={{
                backgroundColor: "black",
                width: "75%",
                marginLeft: "auto",
                marginRight: "auto",
                paddingTop: 70,
              }}
              // style={{ backgroundColor: 'black', margin: '10%' }}
              className="SlideContainer"
              title="Social Media Marketing"
            />
            <Slide
              media={
                <React.Fragment>
                  <iframe
                    src="https://www.youtube.com/embed/OnfsklAlxMA?rel=0"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                    title="video"
                    className="youtubeVideo_carosel"
                  />
                  <CloseIcon
                    onClick={() => setShowMarketingModal(false)}
                    className="youtubeCloseButtonSlide"
                  />
                </React.Fragment>
              }
              mediaBackgroundStyle={{
                backgroundColor: "black",
                width: "75%",
                marginLeft: "auto",
                marginRight: "auto",
                paddingTop: 70,
              }}
              // style={{ backgroundColor: 'black',  margin: '10%' }}
              className="SlideContainer"
              title="Social Media Marketing"
            />
          </AutoRotatingCarousel>
        )}
      </div>
    </React.Fragment>
  );
};

export default ServicesV2Component;
