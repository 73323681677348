import React from "react";
import "../branding.css";
import { Container, Grid } from "@material-ui/core";
import Particles from "react-particles-js";

const HowServiceWillHelpYou = () => {
  return (
    <div>
      <Grid container>
        <Particles
          params={{
            particles: {
              number: {
                value: 350,
              },
              size: {
                value: 1,
              },
              color: {
                value: "#fff",
              },
              line_linked: {
                enable: false,
                distance: 120,
                color: "#fff",
                width: 1,
              },
            },
            interactivity: {
              events: {
                onhover: {
                  enable: true,
                  mode: "repulse",
                },
              },
            },
          }}
          className="branding_particalsSection"
        />
        <Grid
          xs={12}
          sm={12}
          style={{ zIndex: -1, position: "relative", textAlign: "center" }}
        >
          <div className="branding_BSHelpYouRightSection">
            <h1 className="branding_BSHelpYouHeading">Branding & Identity</h1>
            <h2
              className="branding_BSHelpYouPara"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              Transform your ideas into a successful business with our expert
              team of strategists and innovators. From the ground up, we'll help
              you turn your vision into a multi-million-dollar reality.
            </h2>
            <div
              data-aos="fade-up"
              data-aos-duration="1000"
              className="branding_circleBSHelpYou"
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default HowServiceWillHelpYou;
