import React, { useEffect } from "react";
import "./directorstatement.css";
import { Container, Grid, Hidden } from "@material-ui/core";
import AOS from "aos";
import { BASE_URL } from "../../../config/config";

const DirectorStatement = () => {
  useEffect(() => {
    AOS.init();
  });
  return (
    <div className="directorStatementSection">
      <Container>
        <Grid container>
          <Hidden only={["xs"]}>
            <Grid
              xs={12}
              sm={2}
              style={{ position: "relative" }}
              data-aos="fade-down"
              data-aos-duration="1400"
            >
              <div className="circleDirector"></div>

              <div className="dottedLineContainer__about">
                <p className="dottedLine1">. . . . . . . . .</p>
                <p className="dottedLine1">. . . . . . . . .</p>
                <p className="dottedLine1">. . . . . . . . .</p>
                <p className="dottedLine1">. . . . . . . . .</p>
                <p className="dottedLine1">. . . . . . . . .</p>
                <p className="dottedLine1">. . . . . . . . .</p>
                <p className="dottedLine1">. . . . . . . . .</p>
                <p className="dottedLine1">. . . . . . . . .</p>
              </div>
            </Grid>
          </Hidden>
          <Hidden only={["sm", "lg", "md", "xl"]}>
            <Grid
              xs={12}
              sm={4}
              data-aos="fade-up"
              data-aos-duration="1400"
              style={{ textAlign: "end" }}
            >
              <img
                src={`${BASE_URL}/about_statement.png`}
                alt="quote image 3d domaincer"
                className="directorMobileImg"
              />
            </Grid>
          </Hidden>
          <Grid
            xs={12}
            sm={7}
            md={7}
            lg={6}
            data-aos="fade-up"
            data-aos-duration="1400"
          >
            <h1 className="directorHeading">Director's Statement</h1>
            <h2 className="directorPara">
              Since its humble beginnings 15 years ago, we have progressively
              demonstrated a consistent feat of growth in the organisational
              capacity, size, complexity and variety of the information
              technology services that we undertake. As it stands, our company
              is regarded by its esteemed customers and partners as a reliable
              brand that ensures the deliverance of optimum value. In this
              regard, our firm has embarked upon the implementation of a
              five-year strategic business plan (2023 – 2028) that envisions
              achieving a multitude of clearly defined corporate core objectives
              to become the world’s leading digital product company & service
              agency.
            </h2>
            <h2 className="directorPara">
              We endeavour to improve our market share and revenue by
              diversifying our business portfolio towards Digital Marketing,
              Artificial Intelligence, and building institutional capacity in
              terms of human resources, project management and process
              management.
            </h2>
          </Grid>
          <Hidden only={["xs"]}>
            <Grid
              xs={12}
              sm={3}
              md={3}
              lg={4}
              data-aos="fade-up"
              data-aos-duration="1400"
            >
              <img
                src={`${BASE_URL}/about_statement.png`}
                alt="quote image 3d domaincer"
                className="directorImg"
              />
            </Grid>
          </Hidden>

          <Hidden only={["sm", "lg", "md", "xl"]}>
            <Grid
              xs={12}
              sm={2}
              style={{
                position: "relative",
                display: "flex",
                justifyContent: "space-between",
              }}
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <div className="circleDirector"></div>

              <div className="dottedLineMobileContainer__about">
                <p className="dottedLine1">. . . . . . . . .</p>
                <p className="dottedLine1">. . . . . . . . .</p>
                <p className="dottedLine1">. . . . . . . . .</p>
                <p className="dottedLine1">. . . . . . . . .</p>
                <p className="dottedLine1">. . . . . . . . .</p>
                <p className="dottedLine1">. . . . . . . . .</p>
                <p className="dottedLine1">. . . . . . . . .</p>
                <p className="dottedLine1">. . . . . . . . .</p>
              </div>
            </Grid>
          </Hidden>
        </Grid>
      </Container>
    </div>
  );
};

export default DirectorStatement;
