import React, { useState, useEffect } from "react";
import "./visionmission.css";
import { Container, Grid } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AOS from "aos";
import { BASE_URL } from "../../../config/config";
import Skeleton from "react-loading-skeleton";
import LazyLoad from "react-lazyload";
import styled, { keyframes } from "styled-components";

const ImageWrapper = styled.div`
  position: relative;
`;

const Placeholder = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
`;

const VisionMission = () => {
  const [showVision, setShowVision] = useState(false);
  const [showMission, setShowMission] = useState(false);
  const [skeletonLoader, setskeletonLoader] = useState(true);
  const refPlaceholder = React.useRef();

  const removePlaceholder = () => {
    refPlaceholder.current.remove();
    setskeletonLoader(false);
  };

  useEffect(() => {
    AOS.init();
  });

  return (
    <div className="visionMissionContainer">
      <Container>
        <Grid container spacing={3}>
          <Grid xs={12} sm={6} style={{ textAlign: "center" }}>
            {skeletonLoader === true && (
              <Skeleton width="400px" height="350px" />
            )}
            <React.Fragment>
              <ImageWrapper>
                <Placeholder ref={refPlaceholder} />
                <LazyLoad>
                  <img
                    onLoad={removePlaceholder}
                    onError={removePlaceholder}
                    src={`${BASE_URL}/about_brief.png`}
                    alt="bulls eye image 3d domaincer"
                    className="visionMissionImg"
                  />
                </LazyLoad>
              </ImageWrapper>
            </React.Fragment>
          </Grid>
          <Grid xs={12} sm={6}>
            <h1 className="vissionMissionHeading">Brief</h1>
            <h2 className="vissionMissionPara">
              Domaincer is a multi-faceted digital agency with over 15 years of
              experience. Our team of young, dynamic, and creative professionals
              specializes in a range of services, including branding,
              development, digital marketing, and cybersecurity. Since 2008, we
              have completed hundreds of projects across diverse industries,
              including healthcare, finance, education, manufacturing, and
              ecommerce.
            </h2>
            <div
              className="visionContainer"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <div
                className="visionTitleContainer"
                onClick={() => setShowVision(!showVision)}
              >
                <h1 className="visionTitle">Vision</h1>
                <ExpandMoreIcon className="DownArrowIcon" />
              </div>
              {showVision && (
                <div>
                  <h2 className="visionText">
                    To establish itself as the world’s most-trusted digital
                    product company & service agency.
                  </h2>
                </div>
              )}
            </div>
            <div
              className="missionContainer"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <div
                className="visionTitleContainer"
                onClick={() => setShowMission(!showMission)}
              >
                <h1 className="visionTitle">Mission</h1>
                <ExpandMoreIcon className="DownArrowIcon" />
              </div>
              {showMission && (
                <div>
                  <h2 className="missionText">
                    To deliver revolutionary products & services with a
                    commitment to sustainable growth, the highest standard of
                    quality, constant innovation, robust technology & social
                    responsibility thereby strengthening mutually beneficial
                    relationships with all our stakeholders.
                  </h2>
                </div>
              )}
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default VisionMission;
