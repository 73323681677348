import React, { useEffect } from 'react';
import { Container } from '@material-ui/core';
import './pageNotFound.css';
import AOS from 'aos';
import { BASE_URL } from '../../config/config';

const PageNotFoundComponent = () => {
  useEffect(() => {
    AOS.init();
  });

  return (
    <div>
      <Container>
        <div
          className='pageNotFound_imageContainer'
          data-aos='fade-up'
          data-aos-duration='500'
        >
          <img
            src={`${BASE_URL}/page_not_found_image.png`}
            className='pageNotFound_image floatingVerticalIcon'
            alt='page not found 3d domaincer'
          />
          <h1 className='pageNotFound_Title'>404 ERROR</h1>
          <h1 className='pageNotFound_subTitle'>Page not found</h1>
          <p className='pageNotFound_description'>
            The page you are looking for does not exist.
          </p>
        </div>
      </Container>
    </div>
  );
};

export default PageNotFoundComponent;
