import React, { useEffect, useState } from "react";
import "./languages_styles.css";
import {
  Container,
  Grid,
  Card,
  Button,
  Paper,
  Tab,
  Tabs,
} from "@material-ui/core";
import AOS from "aos";
import Hidden from "@material-ui/core/Hidden";
import { BASE_URL } from "../../../../config/config";
import { Link } from "react-router-dom";
import TelegramIcon from "@material-ui/icons/Telegram";
import LazyLoad from "react-lazyload";
import styled, { keyframes } from "styled-components";
import { createPopup } from "@typeform/embed";
import "@typeform/embed/build/css/popup.css";

const DEMO_IMG1 = `${BASE_URL}/Flutter_lang.png`;
const DEMO_IMG2 = `${BASE_URL}/Reactimage.png`;
const DEMO_IMG3 = `${BASE_URL}/Native.png`;

const ImageWrapper = styled.div`
  position: relative;
`;

const Placeholder = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
`;

const LanguagesSection = () => {
  const [showFirstIMG, setShowFirstIMG] = useState(true);
  const [showSecondIMG, setShowSecondIMG] = useState(false);
  const [showThirdIMG, setShowThirdIMG] = useState(false);
  const [cursorHover, setCursorHover] = useState(false);
  const [handShakeHover, setHankShakeHover] = useState(false);
  const [value, setValue] = useState(0);

  const refPlaceholder = React.useRef();

  const removePlaceholder = () => {
    // refPlaceholder.current.remove();
  };

  useEffect(() => {
    AOS.init();
    window.addEventListener("scroll", listenScrollEvent);
  }, []);

  const listenScrollEvent = (event) => {
    if (window.scrollY < 2800) {
      setShowFirstIMG(true);
      setShowSecondIMG(false);
      setShowThirdIMG(false);
    } else if (window.scrollY > 2800 && window.scrollY < 3600) {
      setShowFirstIMG(false);
      setShowSecondIMG(true);
      setShowThirdIMG(false);
    } else if (window.scrollY > 3600 && window.scrollY < 4200) {
      setShowFirstIMG(false);
      setShowSecondIMG(false);
      setShowThirdIMG(true);
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const openPopup = (event) => {
    createPopup("r3BLnIaA").open();
  };

  return (
    <React.Fragment>
      <Hidden only={["xs"]}>
        <div className="ourProcessContainer">
          <div className="fpFnAT">
            {/* <p className='ourProcessMainHeading'>Our Process</p> */}
            {/* <div className="ijZHsr" data-aos="fade-up" data-aos-duration="1000">
              <div className="cKSIpx_lang">We Specialise in: On Demand</div>
              <div className="cAPXpg">
                We are in an era of On-demand mobile app development, which is
                creating a buzz nowadays, and we are constantly rolling and is
                reaching new heights with every passing day.
              </div>
            </div> */}
            <div className="ijZHsr" data-aos="fade-up" data-aos-duration="1000">
              <h1 className="cKSIpx_lang">Flutter Mobile App Development</h1>
              <div className="cAPXpg">
                Experience the power of full-cycle Flutter app development with
                Domaincer. Our team specializes in creating native Android and
                iOS apps with a single codebase and a delightful user
                experience. Trust us to bring your app ideas to life with
                innovative technology and a native touch.
              </div>
              <div style={{ marginLeft: -30 }}>
                {/* <Link to="/get-quote"> */}
                <Button
                  className="contactUs__button"
                  onMouseEnter={() => {
                    setHankShakeHover(true);
                    setCursorHover(true);
                  }}
                  onMouseLeave={() => {
                    setCursorHover(false);
                    setHankShakeHover(false);
                  }}
                  onClick={() => {
                    openPopup();
                  }}
                >
                  {handShakeHover === true ? (
                    <TelegramIcon
                      style={{
                        paddingLeft: 30,
                        paddingRight: 30,
                        fontSize: 22,
                      }}
                    />
                  ) : (
                    <span style={{ paddingLeft: 5, paddingRight: 5 }}>
                      Get Quote
                    </span>
                  )}
                </Button>
                {/* </Link> */}
              </div>
            </div>
            <div className="ijZHsr" data-aos="fade-up" data-aos-duration="1000">
              <h1 className="cKSIpx_lang">
                React Native Mobile App Development
              </h1>
              <div className="cAPXpg">
                Streamline your app development process with React Native. This
                powerful front-end ecosystem is continuously evolving and offers
                cross-platform compatibility, making it an ideal solution for
                businesses wanting to launch on multiple platforms with ease. We
                strive to bring your app vision to life with a seamless and
                efficient process.
              </div>
              <div style={{ marginLeft: -30 }}>
                {/* <Link to="/get-quote"> */}
                <Button
                  className="contactUs__button"
                  onMouseEnter={() => {
                    setHankShakeHover(true);
                    setCursorHover(true);
                  }}
                  onMouseLeave={() => {
                    setCursorHover(false);
                    setHankShakeHover(false);
                  }}
                  onClick={() => {
                    openPopup();
                  }}
                >
                  {handShakeHover === true ? (
                    <TelegramIcon
                      style={{
                        paddingLeft: 30,
                        paddingRight: 30,
                        fontSize: 22,
                      }}
                    />
                  ) : (
                    <span style={{ paddingLeft: 5, paddingRight: 5 }}>
                      Get Quote
                    </span>
                  )}
                </Button>
                {/* </Link> */}
              </div>
            </div>
            <div className="ijZHsr" data-aos="fade-up" data-aos-duration="1000">
              <h1 className="cKSIpx_lang">Native Mobile App Development</h1>
              <div className="cAPXpg">
                Get your business on the fast track to success with a custom
                native mobile app. Our team specializes in creating world-class
                solutions that cater to your specific needs and requirements.
                Don't let your competition get ahead - make a conventional
                mobile app a necessity for your business and let us help you
                stand out in a crowded market.
              </div>
              <div style={{ marginLeft: -30 }}>
                {/* <Link to="/get-quote"> */}
                <Button
                  className="contactUs__button"
                  onMouseEnter={() => {
                    setHankShakeHover(true);
                    setCursorHover(true);
                  }}
                  onMouseLeave={() => {
                    setCursorHover(false);
                    setHankShakeHover(false);
                  }}
                  onClick={() => {
                    openPopup();
                  }}
                >
                  {handShakeHover === true ? (
                    <TelegramIcon
                      style={{
                        paddingLeft: 30,
                        paddingRight: 30,
                        fontSize: 22,
                      }}
                    />
                  ) : (
                    <span style={{ paddingLeft: 5, paddingRight: 5 }}>
                      Get Quote
                    </span>
                  )}
                </Button>
                {/* </Link> */}
              </div>
            </div>
          </div>
          <div className="ffCCAC">
            <div>
              <React.Fragment>
                <div className="staticImgContainer">
                  {showFirstIMG && (
                    <div style={{ textAlign: "center", alignSelf: "center" }}>
                      <React.Fragment>
                        <ImageWrapper>
                          <Placeholder ref={refPlaceholder} />
                          <LazyLoad>
                            <img
                              src={DEMO_IMG1}
                              alt=""
                              className="hZWWsa_flutter"
                              onLoad={removePlaceholder}
                              onError={removePlaceholder}
                            />
                          </LazyLoad>
                        </ImageWrapper>
                      </React.Fragment>
                    </div>
                  )}
                  {showSecondIMG && (
                    <div
                      data-aos="fade-left"
                      data-aos-duration="500"
                      style={{ textAlign: "center", alignSelf: "center" }}
                    >
                      <ImageWrapper>
                        <Placeholder ref={refPlaceholder} />
                        <LazyLoad>
                          <img
                            src={DEMO_IMG2}
                            alt=""
                            className="hZWWsa_reactnative"
                            onLoad={removePlaceholder}
                            onError={removePlaceholder}
                          />
                        </LazyLoad>
                      </ImageWrapper>
                    </div>
                  )}
                  {showThirdIMG && (
                    <div
                      data-aos="fade-left"
                      data-aos-duration="500"
                      style={{ textAlign: "center", alignSelf: "center" }}
                    >
                      <ImageWrapper>
                        <Placeholder ref={refPlaceholder} />
                        <LazyLoad>
                          <img
                            src={DEMO_IMG3}
                            alt=""
                            className="hZWWsa_native"
                            onLoad={removePlaceholder}
                            onError={removePlaceholder}
                          />
                        </LazyLoad>
                      </ImageWrapper>
                    </div>
                  )}
                </div>
              </React.Fragment>
            </div>
          </div>
        </div>
      </Hidden>
      <Hidden only={["sm", "lg", "md", "xl"]}>
        <div className="languageContainer_mobileapp">
          <Paper
            square
            className="MTabsWidthProcess_mobileapp languagesMobileTabs"
          >
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              aria-label="tabs example"
              className="MTabsContainer"
            >
              <Tab
                label="Flutter"
                className="MTabBtn "
                onClick={() => setValue(0)}
              />
              <Tab
                label="React Native"
                className="MTabBtn"
                onClick={() => setValue(1)}
              />
              <Tab
                label="Native"
                className="MTabBtn"
                onClick={() => setValue(2)}
              />
            </Tabs>
          </Paper>
          {value === 0 && (
            <div>
              <div
                className="ijZHsr_strategy"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <div className="cKSIpx_strategy">
                  Flutter Mobile App Development
                </div>
                <div className="cAPXpg_strategy">
                  Experience the power of full-cycle Flutter app development
                  with Domaincer. Our team specializes in creating native
                  Android and iOS apps with a single codebase and a delightful
                  user experience. Trust us to bring your app ideas to life with
                  innovative technology and a native touch.
                </div>
              </div>
              <div className="staticImgContainer">
                <div>
                  <div
                    data-aos="fade-up"
                    data-aos-duration="500"
                    style={{ textAlign: "center", alignSelf: "center" }}
                  >
                    <ImageWrapper>
                      <Placeholder ref={refPlaceholder} />
                      <LazyLoad>
                        <img
                          src={DEMO_IMG1}
                          alt=""
                          className="hZWWsa_flutter"
                          onLoad={removePlaceholder}
                          onError={removePlaceholder}
                        />
                      </LazyLoad>
                    </ImageWrapper>
                  </div>
                </div>
              </div>
            </div>
          )}

          {value === 1 && (
            <div>
              <div
                className="ijZHsr_strategy"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <div className="cKSIpx_strategy">
                  React Native Mobile App Development
                </div>
                <div className="cAPXpg_strategy">
                  Streamline your app development process with React Native.
                  This powerful front-end ecosystem is continuously evolving and
                  offers cross-platform compatibility, making it an ideal
                  solution for businesses wanting to launch on multiple
                  platforms with ease. We strive to bring your app vision to
                  life with a seamless and efficient process.
                </div>
              </div>
              <div className="staticImgContainer">
                <div>
                  <div
                    data-aos="fade-up"
                    data-aos-duration="500"
                    style={{ textAlign: "center", alignSelf: "center" }}
                  >
                    <ImageWrapper>
                      <Placeholder ref={refPlaceholder} />
                      <LazyLoad>
                        <img
                          src={DEMO_IMG2}
                          alt=""
                          className="hZWWsa_reactnative"
                          onLoad={removePlaceholder}
                          onError={removePlaceholder}
                        />
                      </LazyLoad>
                    </ImageWrapper>
                  </div>
                </div>
              </div>
            </div>
          )}
          {value === 2 && (
            <div>
              <div
                className="ijZHsr_strategy"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <div className="cKSIpx_strategy">
                  Native Mobile App Development
                </div>
                <div className="cAPXpg_strategy">
                  Get your business on the fast track to success with a custom
                  native mobile app. Our team specializes in creating
                  world-class solutions that cater to your specific needs and
                  requirements. Don't let your competition get ahead - make a
                  conventional mobile app a necessity for your business and let
                  us help you stand out in a crowded market.
                </div>
              </div>
              <div className="staticImgContainer">
                <div>
                  <div
                    data-aos="fade-up"
                    data-aos-duration="500"
                    style={{ textAlign: "center", alignSelf: "center" }}
                  >
                    <ImageWrapper>
                      <Placeholder ref={refPlaceholder} />
                      <LazyLoad>
                        <img
                          src={DEMO_IMG3}
                          alt=""
                          className="hZWWsa_native"
                          onLoad={removePlaceholder}
                          onError={removePlaceholder}
                        />
                      </LazyLoad>
                    </ImageWrapper>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </Hidden>
    </React.Fragment>
  );
};

export default LanguagesSection;
