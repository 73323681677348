import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Button,
  IconButton,
  Container,
  MenuItem,
  ListItem,
  List,
  Hidden,
} from "@material-ui/core";
import "./navbar.css";
import ExpandMoreTwoToneIcon from "@material-ui/icons/ExpandMoreTwoTone";
import { Link } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CloseIcon from "@material-ui/icons/Close";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import MailIcon from "@material-ui/icons/Mail";
import TelegramIcon from "@material-ui/icons/Telegram";
import { BASE_URL } from "../../config/config";
import { createPopup } from "@typeform/embed";
import "@typeform/embed/build/css/popup.css";

const Navbar = () => {
  const [logoHover, setLogoHover] = useState(false);
  const [strategyNav, setStrategyNav] = useState(false);
  const [designNav, setDesignNav] = useState(false);
  const [developmentNav, setDevelopmentNav] = useState(false);
  const [marketingNav, setMarketingNav] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [showStrategy, setShowStrategy] = useState(false);
  const [showDesign, setShowDesign] = useState(false);
  const [showDevelopment, setShowDevelopment] = useState(false);
  const [showMarketing, setShowMarketing] = useState(false);
  const [cursorHover, setCursorHover] = useState(false);
  const [handShakeHover, setHankShakeHover] = useState(false);
  const [showStickyBar, setShowStickyBar] = useState(false);
  const [stickybarIcon, setStickybarIcon] = useState(false);

  useEffect(() => {
    if (cursorHover === true) {
      const cursorInnerLink = document.querySelector(".cursor-inner-link");
      document.addEventListener("mousemove", (e) => {
        cursorInnerLink.setAttribute(
          "style",
          "top: " + (e.pageY - 10) + "px; left: " + (e.pageX - 9) + "px; "
        );
      });
    }
    if (showMobileMenu === true) {
      console.log(showMobileMenu);
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }
    window.scrollTo(0, 5);
    window.addEventListener("scroll", listenScrollEvent);
    let timer1 = setTimeout(() => openPopup(), 25 * 1000);

      // this will clear Timeout
      // when component unmount like in willComponentUnmount
      // and show will not change to true
      return () => {
        clearTimeout(timer1);
      };
  }, [cursorHover, showMobileMenu]);

  const handleClose = () => {
    setShowMobileMenu(!showMobileMenu);
  };

  const handleOpen = () => {
    setShowMobileMenu(!showMobileMenu);
  };

  const listenScrollEvent = (event) => {
    if (window.scrollY > 100) {
      setShowStickyBar(true);
    } else {
      setShowStickyBar(false);
    }
  };
  const openPopup = (event) => {
    createPopup("r3BLnIaA").open();
  };

  return (
    <React.Fragment>
      {cursorHover && <div className="cursor-inner-link"></div>}
      {showStickyBar && (
        <React.Fragment>
          <div className="sticky_announcementSection">
            <h1 className="announcementText">
              Get a Free Proposal For Your Project.
            </h1>
            {/* <Link to="/get-quote"> */}
            <Button
              className="sticky_announcementBtn"
              onMouseEnter={() => {
                setStickybarIcon(true);
              }}
              onMouseLeave={() => {
                setStickybarIcon(false);
              }}
              onClick={() => {
                openPopup();
              }}
            >
              {stickybarIcon === true ? (
                <React.Fragment>
                  <TelegramIcon
                    style={{
                      paddingLeft: 30,
                      paddingRight: 30,
                      paddingTop: 3.8,
                      paddingBottom: 3.8,
                      fontSize: 22,
                    }}
                  />
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <span className="join_mover"></span>
                  <span style={{ paddingLeft: 5, paddingRight: 5 }}>
                    Get Quote
                  </span>
                </React.Fragment>
              )}
            </Button>
            {/* </Link> */}
          </div>
        </React.Fragment>
      )}
      <Hidden only={["xs"]}>
        {/* <div className="announcementSection">
          <h1 className="announcementText">
            2021, Here we come! We have revamped our Domaincer website.
          </h1>
        </div> */}
        <div className="announcementSection">
          <h1 className="announcementText">We're Hiring Super Heroes.</h1>
          <a
            href="https://www.linkedin.com/jobs/domaincer%C2%AE-jobs-worldwide?f_C=34047144&trk=top-card_top-card-primary-button-top-card-primary-cta&position=1&pageNum=0"
            target="_blank"
          >
            <Button className="announcementBtn">Join our Team</Button>
          </a>
        </div>
      </Hidden>
      <AppBar position="static" className="domaincer__navbar">
        <Container>
          <Toolbar className="tabpadiss">
            <div style={{ marginTop: "12px" }}>
              <Link to="/">
                <div
                  className="domaincerLogo__container"
                  onMouseEnter={() => {
                    setCursorHover(true);
                    setLogoHover(true);
                  }}
                  onMouseLeave={() => {
                    setCursorHover(false);
                    setLogoHover(false);
                  }}
                >
                  <img
                    src={`${BASE_URL}/revamp_domaincer_logo_horizontal_black.svg`}
                    alt="domaincer official logo"
                    className="domaincer__logo"
                  />
                </div>
                {logoHover && (
                  <img
                    src={`${BASE_URL}/revamp_domaincer_logo_horizontal_black.svg`}
                    alt="domaincer official logo"
                    className="domaincer__logo"
                  />
                )}
              </Link>
            </div>
            <Link
              to="/best-digital-agency"
              onMouseEnter={() => setCursorHover(true)}
              onMouseLeave={() => setCursorHover(false)}
            >
              <MenuItem className="about__menu">
                <h1 className="hoverLine aboutMenuText">About</h1>
              </MenuItem>
            </Link>
            <MenuItem
              className="menutabwidth"
              onClick={() => {
                setDesignNav(true);
                setStrategyNav(false);
                setDevelopmentNav(false);
                setMarketingNav(false);
              }}
            >
              <h1 className="dropdown-toggle">Design</h1>
              <IconButton className="icon__button">
                <ExpandMoreTwoToneIcon className="header__dropDownIcon" />
              </IconButton>
            </MenuItem>
            <MenuItem
              className="menutabwidth"
              onClick={() => {
                setDevelopmentNav(true);
                setDesignNav(false);
                setStrategyNav(false);
                setMarketingNav(false);
              }}
            >
              <h1 className="dropdown-toggle">Web 2.0</h1>
              <IconButton className="icon__button">
                <ExpandMoreTwoToneIcon className="header__dropDownIcon" />
              </IconButton>
            </MenuItem>
            <MenuItem
              className="menutabwidth"
              onClick={() => {
                setStrategyNav(true);
                setDesignNav(false);
                setDevelopmentNav(false);
                setMarketingNav(false);
              }}
            >
              <h1 className="dropdown-toggle">Web 3.0</h1>
              <IconButton className="icon__button">
                <ExpandMoreTwoToneIcon className="header__dropDownIcon" />
              </IconButton>
            </MenuItem>
            <MenuItem
              className="menutabwidth"
              onClick={() => {
                setMarketingNav(true);
                setDevelopmentNav(false);
                setDesignNav(false);
                setStrategyNav(false);
              }}
            >
              <h1 className="dropdown-toggle">Marketing</h1>
              <IconButton className="icon__button">
                <ExpandMoreTwoToneIcon className="header__dropDownIcon" />
              </IconButton>
            </MenuItem>
            <Link
              to="/contact-digital-marketing-agency"
              onMouseEnter={() => setCursorHover(true)}
              onMouseLeave={() => setCursorHover(false)}
            >
              <MenuItem className="about__menu">
                <h1 className="hoverLine aboutMenuText">Contact Us</h1>
              </MenuItem>
            </Link>

            {/* <Link to="/get-quote"> */}
            <Button
              className="contactUs__button area"
              onMouseEnter={() => {
                setHankShakeHover(true);
                setCursorHover(true);
              }}
              onMouseLeave={() => {
                setCursorHover(false);
                setHankShakeHover(false);
              }}
              onClick={() => {
                openPopup();
              }}
            >
              {handShakeHover === true ? (
                <React.Fragment>
                  <TelegramIcon
                    style={{
                      paddingLeft: 30,
                      paddingRight: 30,
                      fontSize: 22,
                    }}
                  />
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <span className="mover"></span>
                  <span style={{ paddingLeft: 5, paddingRight: 5 }}>
                    Get Quote
                  </span>
                </React.Fragment>
              )}
            </Button>
            {/* </Link> */}
          </Toolbar>
        </Container>
      </AppBar>
      {/* Dropdown Container */}
      <div className="domaincer__NavbarDropdown">
        {/* changed to web 3.0 */}
        {strategyNav && (
          <Container className="strategy__Dropdown">
            <Link to="/blockchain-service" className="dropdown__menu">
              <img
                src={`${BASE_URL}/blockchain.png`}
                alt="Blockchain icon 3d domaincer"
                style={{ height: 60 }}
              />
              <div className="dropdownMenu__content">
                <div>
                  <h1 className="hoverLine dropdown__title">Blockchain</h1>
                </div>
                <p className="dropdown__subTitle">
                  more <ChevronRightIcon className="learnMoreArrow arrowani" />
                </p>
              </div>
            </Link>

            <Link to="/metaverse-service" className="dropdown__menu">
              <img
                src={`${BASE_URL}/metaverse.png`}
                alt="metaverse icon 3d domaincer"
                style={{ height: 60 }}
              />
              <div className="dropdownMenu__content">
                <div>
                  <h1 className="hoverLine dropdown__title"> Metaverse</h1>
                </div>
                <p className="dropdown__subTitle">
                  more <ChevronRightIcon className="learnMoreArrow arrowani" />
                </p>
              </div>
            </Link>

            {/* <Link to="/workflow-automation-company" className="dropdown__menu">
              <img
                src={`${BASE_URL}/3d_workflow_automation_icon.png`}
                alt="workflow automation icon 3d domaincer"
                style={{ height: 60 }}
              />
              <div className="dropdownMenu__content">
                <div>
                  <h1 className="hoverLine dropdown__title">
                    Workflow Automation
                  </h1>
                </div>
                <p className="dropdown__subTitle">
                  more <ChevronRightIcon className="learnMoreArrow arrowani" />
                </p>
              </div>
            </Link> */}
          </Container>
        )}
        {designNav && (
          <Container className="strategy__Dropdown">
            <Link to="/best-branding-company" className="dropdown__menu">
              <img
                src={`${BASE_URL}/3d_branding_icon.png`}
                alt="branding icon 3d domaincer"
                style={{ height: 60 }}
              />
              <div className="dropdownMenu__content">
                <div>
                  <h1 className="hoverLine dropdown__title">Branding</h1>
                </div>
                <p className="dropdown__subTitle">
                  more <ChevronRightIcon className="learnMoreArrow arrowani" />
                </p>
              </div>
            </Link>

            <Link to="/ui-ux-design" className="dropdown__menu">
              <img
                src={`${BASE_URL}/3d_uiux_icon.png`}
                alt="ui/ux icon 3d domaincer"
                style={{ height: 60 }}
              />
              <div className="dropdownMenu__content">
                <div>
                  <h1 className="hoverLine dropdown__title">UI / UX</h1>
                </div>
                <p className="dropdown__subTitle">
                  more <ChevronRightIcon className="learnMoreArrow arrowani" />
                </p>
              </div>
            </Link>
            <Link to="/motion-graphics-design" className="dropdown__menu">
              <img
                src={`${BASE_URL}/3d_motion_graphics_icon.png`}
                alt="motion graphics icon 3d domaincer"
                style={{ height: 60 }}
              />
              <div className="dropdownMenu__content">
                <div>
                  <h1 className="hoverLine dropdown__title">Motion Graphics</h1>
                </div>
                <p className="dropdown__subTitle">
                  more <ChevronRightIcon className="learnMoreArrow arrowani" />
                </p>
              </div>
            </Link>
          </Container>
        )}

        {developmentNav && (
          <Container className="strategy__Dropdown">
            <Link
              to="/mobile-app-development-company"
              className="dropdown__menu"
            >
              <img
                src={`${BASE_URL}/3d_mobile_apps_icon.png`}
                alt="Mobile App icon 3d domaincer"
                style={{ height: 60 }}
              />
              <div className="dropdownMenu__content">
                <div>
                  <h1 className="hoverLine dropdown__title">Mobile Apps</h1>
                </div>
                <p className="dropdown__subTitle">
                  more <ChevronRightIcon className="learnMoreArrow arrowani" />
                </p>
              </div>
            </Link>

            <Link to="/web-portal-development" className="dropdown__menu">
              <img
                src={`${BASE_URL}/3d_portals_icon.png`}
                alt="Portal icon 3d domaincer"
                style={{ height: 60 }}
              />
              <div className="dropdownMenu__content">
                <div>
                  <h1 className="hoverLine dropdown__title">Portals</h1>
                </div>
                <p className="dropdown__subTitle">
                  more <ChevronRightIcon className="learnMoreArrow arrowani" />
                </p>
              </div>
            </Link>
            <Link to="/website-development-company" className="dropdown__menu">
              <img
                src={`${BASE_URL}/3d_web_development_icon.png`}
                alt="web development icon 3d domaincer"
                style={{ height: 60 }}
              />
              <div className="dropdownMenu__content">
                <div>
                  <h1 className="hoverLine dropdown__title">Website</h1>
                </div>
                <p className="dropdown__subTitle">
                  more <ChevronRightIcon className="learnMoreArrow arrowani" />
                </p>
              </div>
            </Link>
            <Link to="/devops-consulting-company" className="dropdown__menu">
              <img
                src={`${BASE_URL}/3d_devops_icon.png`}
                alt="devops icon 3d domaincer"
                style={{ height: 60 }}
              />
              <div className="dropdownMenu__content">
                <div>
                  <h1 className="hoverLine dropdown__title">DevOps</h1>
                </div>
                <p className="dropdown__subTitle">
                  more <ChevronRightIcon className="learnMoreArrow arrowani" />
                </p>
              </div>
            </Link>
          </Container>
        )}
        {marketingNav && (
          <Container className="humanResource__Dropdown">
            <Link
              to="/social-media-marketing-service"
              className="dropdown__menu"
            >
              <img
                src={`${BASE_URL}/3d_socialmedia_marketing_icon.png`}
                alt="social media icon 3d domaincer"
                style={{ height: 60 }}
              />
              <div className="dropdownMenu__content">
                <div>
                  <h1 className="hoverLine dropdown__title">
                    Social Media Marketing
                  </h1>
                </div>
                <p className="dropdown__subTitle">
                  more <ChevronRightIcon className="learnMoreArrow arrowani" />
                </p>
              </div>
            </Link>
            <Link
              to="/search-engine-optimization-service"
              className="dropdown__menu"
            >
              <img
                src={`${BASE_URL}/3d_seo_icon.png`}
                alt="search engine optimization icon 3d domaincer"
                style={{ height: 60 }}
              />
              <div className="dropdownMenu__content">
                <div>
                  <h1 className="hoverLine dropdown__title">
                    Search Engine Optimisation
                  </h1>
                </div>
                <p className="dropdown__subTitle">
                  more <ChevronRightIcon className="learnMoreArrow arrowani" />
                </p>
              </div>
            </Link>
          </Container>
        )}
      </div>

      {/* Mobile navbar 
      ___________________________________________________
      ___________________________________________________*/}
      {/* <Hidden only={['sm', 'lg', 'md', 'xl']}>
        <div className='announcementSection_mobile'>
          <h1 className='announcementText_mobile'>
            2021, Here we come! We have revamped our Domaincer website.
          </h1>
        </div>
      </Hidden> */}
      <div className="mobile_navbar_display">
        <div className="sticky_mobileHeader">
          <div className="m_navbar_logo_bar">
            {showMobileMenu ? (
              <IconButton style={{ paddingLeft: 0 }} onClick={handleClose}>
                <CloseIcon className="menubar" />
              </IconButton>
            ) : (
              <IconButton style={{ paddingLeft: 0 }} onClick={handleOpen}>
                <MenuIcon className="menubar" />
              </IconButton>
            )}

            <Link to="/">
              <img
                src={`${BASE_URL}/revamp_domaincer_logo_mobile_nav.png`}
                alt="domaincer logo"
                className="domaincer_m_logo"
              />
            </Link>
            <div>
              <Link to="/contact-digital-marketing-agency">
                <IconButton className="contact__us">
                  <MailIcon className="arrowani" />{" "}
                </IconButton>
              </Link>
            </div>
          </div>
        </div>
        {showStickyBar && (
          <React.Fragment>
            <div className="sticky_announcementSection_mobile">
              <h1 className="announcementText">
                Get a Free Proposal For Your Project.
              </h1>
              {/* <Link to="/get-quote"> */}
              <Button
                className="announcementBtn_mobile"
                onHover={() => {
                  setStickybarIcon(true);
                }}
                onMouseLeave={() => {
                  setStickybarIcon(false);
                }}
                onClick={() => {
                  openPopup();
                }}
              >
                {stickybarIcon === true ? (
                  <React.Fragment>
                    <TelegramIcon
                      style={{
                        paddingLeft: 30,
                        paddingRight: 30,
                        paddingTop: 4,
                        paddingBottom: 4,
                        fontSize: 22,
                      }}
                    />
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <span className="join_mover"></span>
                    <span style={{ paddingLeft: 5, paddingRight: 5 }}>
                      Get Quote
                    </span>
                  </React.Fragment>
                )}
              </Button>
              {/* </Link> */}
            </div>
          </React.Fragment>
        )}
        <div>
          {showMobileMenu && (
            <div data-aos="fade-down" className="menuSectionMobile">
              <div style={{ padding: 30 }}>
                <div style={{ marginBottom: 30 }}>
                  <Link
                    className="mobileMenuContainer"
                    data-aos="fade-down"
                    to="/best-digital-agency"
                  >
                    <h1 className="mobileMenuHeading" onClick={handleClose}>
                      About Us
                    </h1>
                  </Link>
                </div>
                <div style={{ marginBottom: 30 }}>
                  <div
                    className="mobileMenuContainer"
                    data-aos="fade-down"
                    data-aos-duration="400"
                    onClick={() => {
                      setShowDesign(!showDesign);
                      setShowStrategy(false);
                      setShowDevelopment(false);
                      setShowMarketing(false);
                    }}
                  >
                    <h1 className="mobileMenuHeading">Design</h1>
                    <ExpandMoreIcon
                      style={{ color: "#fff", alignSelf: "center" }}
                    />
                  </div>
                  {showDesign && (
                    <List className="MobileMenuList">
                      <ListItem>
                        <Link
                          to="/best-branding-company"
                          className="subMenuMobileLink"
                          onClick={handleClose}
                        >
                          Branding
                        </Link>
                      </ListItem>
                      <ListItem>
                        <Link
                          to="/ui-ux-design"
                          className="subMenuMobileLink"
                          onClick={handleClose}
                        >
                          UI / UX
                        </Link>
                      </ListItem>
                      <ListItem>
                        <Link
                          to="/motion-graphics-design"
                          className="subMenuMobileLink"
                          onClick={handleClose}
                        >
                          Motion Graphics{" "}
                        </Link>
                      </ListItem>
                    </List>
                  )}
                </div>
                <div style={{ marginBottom: 30 }}>
                  <div
                    className="mobileMenuContainer"
                    data-aos="fade-down"
                    data-aos-duration="500"
                    onClick={() => {
                      setShowDevelopment(!showDevelopment);
                      setShowStrategy(false);
                      setShowDesign(false);
                      setShowMarketing(false);
                    }}
                  >
                    <h1 className="mobileMenuHeading">Web 2.0</h1>
                    <ExpandMoreIcon
                      style={{ color: "#fff", alignSelf: "center" }}
                    />
                  </div>
                  {showDevelopment && (
                    <List className="MobileMenuList">
                      <ListItem>
                        <Link
                          to="/mobile-app-development-company"
                          className="subMenuMobileLink"
                          onClick={handleClose}
                        >
                          Mobile Apps{" "}
                        </Link>
                      </ListItem>
                      <ListItem>
                        <Link
                          to="/web-portal-development"
                          className="subMenuMobileLink"
                          onClick={handleClose}
                        >
                          Portals{" "}
                        </Link>
                      </ListItem>
                      <ListItem>
                        <Link
                          to="/website-development-company"
                          className="subMenuMobileLink"
                          onClick={handleClose}
                        >
                          Website
                        </Link>
                      </ListItem>
                      <ListItem>
                        <Link
                          to="/devops-consulting-company"
                          className="subMenuMobileLink"
                          onClick={handleClose}
                        >
                          DevOps
                        </Link>
                      </ListItem>
                    </List>
                  )}
                </div>
                <div style={{ marginBottom: 30 }}>
                  <div
                    className="mobileMenuContainer"
                    data-aos="fade-down"
                    data-aos-duration="350"
                    onClick={() => {
                      setShowStrategy(!showStrategy);
                      setShowDesign(false);
                      setShowDevelopment(false);
                      setShowMarketing(false);
                    }}
                  >
                    <h1 className="mobileMenuHeading">Web 3.0</h1>
                    <ExpandMoreIcon
                      style={{ color: "#fff", alignSelf: "center" }}
                    />
                  </div>
                  {showStrategy && (
                    <List className="MobileMenuList">
                      <ListItem>
                        <Link
                          to="/blockchain-service"
                          className="subMenuMobileLink"
                          onClick={handleClose}
                        >
                          Blockchain
                        </Link>{" "}
                      </ListItem>
                      <ListItem>
                        {" "}
                        <Link
                          to="/metaverse-service"
                          className="subMenuMobileLink"
                          onClick={handleClose}
                        >
                          {" "}
                          Metaverse
                        </Link>
                      </ListItem>
                    </List>
                  )}
                </div>

                <div style={{ marginBottom: 30 }}>
                  <div
                    className="mobileMenuContainer"
                    data-aos="fade-down"
                    data-aos-duration="550"
                    onClick={() => {
                      setShowMarketing(!showMarketing);
                      setShowStrategy(false);
                      setShowDesign(false);
                      setShowDevelopment(false);
                    }}
                  >
                    <h1 className="mobileMenuHeading">Marketing</h1>
                    <ExpandMoreIcon
                      style={{ color: "#fff", alignSelf: "center" }}
                    />
                  </div>

                  {showMarketing && (
                    <List className="MobileMenuList">
                      <ListItem>
                        <Link
                          to="/social-media-marketing-service"
                          className="subMenuMobileLink"
                          onClick={handleClose}
                        >
                          Social Media Marketing
                        </Link>
                      </ListItem>
                      <ListItem>
                        <Link
                          to="/search-engine-optimization-service"
                          className="subMenuMobileLink"
                          onClick={handleClose}
                        >
                          Search Engine Optimisation
                        </Link>
                      </ListItem>
                    </List>
                  )}
                </div>

                <div style={{ marginBottom: 30 }}>
                  <Link
                    className="mobileMenuContainer"
                    data-aos="fade-down"
                    to="/contact-digital-marketing-agency"
                  >
                    <h1 className="mobileMenuHeading" onClick={handleClose}>
                      Contact Us
                    </h1>
                  </Link>
                </div>
                <div style={{ marginBottom: 30 }}>
                  {/* <Link to="/get-quote"> */}
                  <Button
                    className="contactUs__button"
                    onClick={() => {
                      handleClose();
                      openPopup();
                    }}
                  >
                    <span style={{ paddingLeft: 5, paddingRight: 5 }}>
                      Get Quote
                    </span>
                  </Button>
                  {/* </Link> */}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Navbar;
