import React, { useState, useEffect } from "react";
import { Container, Grid, Button, Link } from "@material-ui/core";
import "../branding.css";
import Hidden from "@material-ui/core/Hidden";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { BASE_URL } from "../../../../config/config";

const STRATEGY = [
  {
    key: "CONTENT_SECTION_ONE",
    name: "Brand Analytics",
    subtext: "Brand analytics virtuoso",
    image: `${BASE_URL}/tab_branding_icon1.png`,
    alt: "brand analytics image domaincer",
  },
  {
    key: "CONTENT_SECTION_TWO",
    name: "Brand Identity",
    subtext: "Keep your audience in the know-how",
    image: `${BASE_URL}/tab_branding_icon2.png`,
    alt: "brand identity image domaincer",
  },
  {
    key: "CONTENT_SECTION_THREE",
    name: "Brand Refresh",
    subtext: "Spread the word",
    image: `${BASE_URL}/tab_branding_icon3.png`,
    alt: "branding image domaincer",
  },
];

const ServiceContentSection = () => {
  const [activeTabService, setActiveTabService] = useState(
    "CONTENT_SECTION_ONE"
  );

  return (
    <div>
      <div style={{ paddingTop: 100, paddingBottom: 30 }}>
        <h1 className="branding_service_subheading">About Branding</h1>
        <h2 className="branding_service_subtext">
          We specialize in delivering convincing business communication and
          implementing effective delivery strategies. Let us help you influence
          your clients and maintain a strong brand image."
        </h2>
      </div>
      {/* visible only on desktop, laptop */}
      <Hidden only={["xs"]}>
        <div className="branding_subServiceTabSection">
          {STRATEGY.map((service, index) => (
            <div
              className="branding_subServiceContaincer"
              style={{
                borderColor:
                  service.key === activeTabService ? "#0077FF" : "#FFF",
              }}
              onClick={() => setActiveTabService(service.key)}
            >
              <img
                className="branding_subServiceImg"
                src={service.image}
                alt={service.alt}
              />
              <div>
                <h1 className="branding_subServiceTitle">{service.name}</h1>
                <h2 className="branding_subServiceSubTitle">
                  {service.subtext}
                </h2>
              </div>
            </div>
          ))}
        </div>
        <div>
          {activeTabService === "CONTENT_SECTION_ONE" && (
            <div className="branding_subServiceParaContainer">
              <Container
                maxWidth="lg"
                className="branding_subServiceFixedSection"
              >
                <div className="branding_glowEffectBrand"></div>

                <div className="branding_serviceContent">
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <h3 className="branding_subServicePara">
                        Our team specializes in crafting compelling brand
                        communication, from catchy ads to memorable taglines. By
                        implementing a strategic positioning plan, we help
                        position your brand in the minds of your target
                        audience, maximizing its appeal and overall value.
                      </h3>
                    </Grid>
                  </Grid>
                </div>
              </Container>
            </div>
          )}
          {activeTabService === "CONTENT_SECTION_TWO" && (
            <div className="branding_subServiceParaContainer">
              <Container
                maxWidth="lg"
                className="branding_subServiceFixedSection"
              >
                <div className="branding_glowEffectDigital"></div>

                <div className="branding_serviceContent">
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <h3 className="branding_subServicePara">
                        Your brand is like a fingerprint - unique and
                        one-of-a-kind. Let us help you enhance its strength by
                        crafting a service branding strategy that truly stands
                        out. Reach your audience like never before with our
                        service branding services. We use research, insights,
                        and knowledge to craft a strategy that defines and
                        elevates your business in all the right ways.
                      </h3>
                    </Grid>
                  </Grid>
                </div>
              </Container>
            </div>
          )}
          {activeTabService === "CONTENT_SECTION_THREE" && (
            <div className="branding_subServiceParaContainer">
              <Container
                maxWidth="lg"
                className="branding_subServiceFixedSection"
              >
                <div className="branding_glowEffectWorkFlow"></div>

                <div className="branding_serviceContent">
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <h3 className="branding_subServicePara">
                        At Domaincer, our event branding services are designed
                        to help you craft and nurture a brand that truly
                        reflects your core identity. Whether it's through
                        collaborative content creation or forward-thinking
                        strategies, we're committed to helping you communicate
                        your brand's message effectively.
                      </h3>
                    </Grid>
                  </Grid>
                </div>
              </Container>
            </div>
          )}
        </div>
      </Hidden>

      {/* visible only on mobile */}
      <Hidden only={["sm", "lg", "md", "xl"]}>
        <div style={{ position: "relative" }}>
          <ArrowBackIosIcon
            className="subServiceTabLeftArrowHome"
            onClick={() => {
              document.getElementById("strategyScroll").scrollLeft -= 400;
            }}
          />
          <div className="branding_subServiceTabSection" id="strategyScroll">
            <div>
              <div className="branding_subServiceContaincer">
                <img
                  className="branding_subServiceImg"
                  src={`${BASE_URL}/tab_branding_icon1.png`}
                  alt="brand analytics image domaincer"
                />
                <div>
                  <h1 className="branding_subServiceTitle">Brand Analytics</h1>
                  <h2 className="branding_subServiceSubTitle">
                    Brand analytics virtuoso
                  </h2>
                </div>
              </div>
              <div className="branding_subServiceParaContainer">
                <Container
                  maxWidth="lg"
                  className="branding_subServiceFixedSection"
                >
                  <div className="branding_glowEffectBrand"></div>

                  <div className="branding_serviceContent">
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <h3 className="branding_subServicePara">
                          Our team specializes in crafting compelling brand
                          communication, from catchy ads to memorable taglines.
                          By implementing a strategic positioning plan, we help
                          position your brand in the minds of your target
                          audience, maximizing its appeal and overall value.
                        </h3>
                      </Grid>
                    </Grid>
                  </div>
                </Container>
              </div>
            </div>
            <div>
              <div className="branding_subServiceContaincer">
                <img
                  className="branding_subServiceImg"
                  src={`${BASE_URL}/tab_branding_icon2.png`}
                  alt="brand identity image domaincer"
                />
                <div>
                  <h1 className="branding_subServiceTitle">Brand Identity</h1>
                  <h2 className="branding_subServiceSubTitle">
                    Keep your audience in the know-how
                  </h2>
                </div>
              </div>

              <div className="branding_subServiceParaContainer">
                <Container
                  maxWidth="lg"
                  className="branding_subServiceFixedSection"
                >
                  <div className="branding_glowEffectDigital"></div>

                  <div className="branding_serviceContentTwo">
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <h3 className="branding_subServicePara">
                          Your brand is like a fingerprint - unique and
                          one-of-a-kind. Let us help you enhance its strength by
                          crafting a service branding strategy that truly stands
                          out. Reach your audience like never before with our
                          service branding services. We use research, insights,
                          and knowledge to craft a strategy that defines and
                          elevates your business in all the right ways.
                        </h3>
                      </Grid>
                    </Grid>
                  </div>
                </Container>
              </div>
            </div>

            <div>
              <div className="branding_subServiceContaincer">
                <img
                  className="branding_subServiceImg"
                  src={`${BASE_URL}/tab_branding_icon1.png`}
                  alt="branding image domaincer"
                />
                <div>
                  <h1 className="branding_subServiceTitle">Brand Refresh</h1>
                  <h2 className="branding_subServiceSubTitle">
                    Spread the word
                  </h2>
                </div>
              </div>
              <div className="branding_subServiceParaContainer">
                <Container
                  maxWidth="lg"
                  className="branding_subServiceFixedSection"
                >
                  <div className="branding_glowEffectWorkFlow"></div>

                  <div className="branding_serviceContentThree">
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <h3 className="branding_subServicePara">
                          At Domaincer, our event branding services are designed
                          to help you craft and nurture a brand that truly
                          reflects your core identity. Whether it's through
                          collaborative content creation or forward-thinking
                          strategies, we're committed to helping you communicate
                          your brand's message effectively.
                        </h3>
                      </Grid>
                    </Grid>
                  </div>
                </Container>
              </div>
            </div>
          </div>
          <ArrowForwardIosIcon
            className="subServiceTabRightArrowHome"
            onClick={() => {
              document.getElementById("strategyScroll").scrollLeft += 400;
            }}
          />
        </div>
      </Hidden>
    </div>
  );
};

export default ServiceContentSection;
