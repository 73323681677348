import React from "react";
import "../uiux.css";
import { Container, Grid } from "@material-ui/core";
import Particles from "react-particles-js";

const HowServiceWillHelpYou = () => {
  return (
    <div>
      <Grid container>
        <Particles
          params={{
            particles: {
              number: {
                value: 350,
              },
              size: {
                value: 1,
              },
              color: {
                value: "#fff",
              },
              line_linked: {
                enable: false,
                distance: 120,
                color: "#fff",
                width: 1,
              },
            },
            interactivity: {
              events: {
                onhover: {
                  enable: true,
                  mode: "repulse",
                },
              },
            },
          }}
          className="uiux_particalsSection"
        />
        <Grid
          xs={12}
          sm={12}
          style={{ zIndex: -1, position: "relative", textAlign: "center" }}
        >
          <div className="uiux_BSHelpYouRightSection">
            <h1 className="uiux_BSHelpYouHeading">
              Setting the Industry Standard for Exceptional Experiences.
            </h1>
            <h2
              className="uiux_BSHelpYouPara"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              At Domaincer, Our priority is your success, and our impactful and
              inspiring workshops are the rocket fuel for your journey. Whether
              you're a small business owner, startup founder, or a growing SME,
              our team is ready to guide you towards success and leave a lasting
              impact on both your business and your heart.
            </h2>
            <div
              data-aos="fade-up"
              data-aos-duration="1000"
              className="uiux_circleBSHelpYou"
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default HowServiceWillHelpYou;
