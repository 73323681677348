import React, { useState, useEffect } from "react";
import { Container, Grid, Button, Link } from "@material-ui/core";
import "../uiux.css";
import Hidden from "@material-ui/core/Hidden";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { BASE_URL } from "../../../../config/config";

const STRATEGY = [
  {
    key: "CONTENT_SECTION_ONE",
    name: "Wireframes",
    subtext: "Unlimited possibilities",
    image: `${BASE_URL}/tab_uiux_icon1.png`,
    alt: "best wireframing image domaincer",
  },
  {
    key: "CONTENT_SECTION_TWO",
    name: "Prototypes",
    subtext: "A Revitalized Perspective ",
    image: `${BASE_URL}/tab_uiux_icon2.png`,
    alt: "best prototype image domaincer",
  },
  // {
  //   key: "CONTENT_SECTION_THREE",
  //   name: "UI UX",
  //   subtext: "Great design for a better story",
  //   image: `${BASE_URL}/tab_uiux_icon3.png`,
  //   alt: "top ui ux image domaincer",
  // },
];

const ServiceContentSection = () => {
  const [activeTabService, setActiveTabService] = useState(
    "CONTENT_SECTION_ONE"
  );

  return (
    <div>
      <div style={{ paddingTop: 100, paddingBottom: 30 }}>
        <h1 className="uiux_service_subheading">UI/UX</h1>
        <h2 className="uiux_service_subtext">
          Join the revolution with Domaincer ! We specialize in blending design
          and refined experiences to craft exceptional digital products,
          websites, apps, and more for your brand. Our focus on delivering
          positive ROI ensures that your business will thrive in the digital
          world and reach the pinnacle of your industry.
        </h2>
      </div>

      {/* visible only on desktop, laptop */}
      <Hidden only={["xs"]}>
        <div className="uiux_subServiceTabSection">
          {STRATEGY.map((service, index) => (
            <div
              className="uiux_subServiceContaincer"
              style={{
                borderColor:
                  service.key === activeTabService ? "#0077FF" : "#FFF",
              }}
              onClick={() => setActiveTabService(service.key)}
            >
              <img
                className="uiux_subServiceImg"
                src={service.image}
                alt={service.alt}
              />
              <div>
                <h1 className="uiux_subServiceTitle">{service.name}</h1>
                <h2 className="uiux_subServiceSubTitle">{service.subtext}</h2>
              </div>
            </div>
          ))}
        </div>
        <div>
          {activeTabService === "CONTENT_SECTION_ONE" && (
            <div className="uiux_subServiceParaContainer">
              <Container maxWidth="lg" className="uiux_subServiceFixedSection">
                <div className="uiux_glowEffectBrand"></div>

                <div className="uiux_serviceContent">
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <h3 className="uiux_subServicePara">
                        Are you tired of falling in the 80% of app designs that
                        fail to engage users? We know that design is a
                        combination of science, psychology, and art. We apply
                        this knowledge to craft apps that not only look good but
                        also effectively engage and motivate your users.
                      </h3>
                    </Grid>
                  </Grid>
                </div>
              </Container>
            </div>
          )}
          {activeTabService === "CONTENT_SECTION_TWO" && (
            <div className="uiux_subServiceParaContainer">
              <Container maxWidth="lg" className="uiux_subServiceFixedSection">
                <div className="uiux_glowEffectDigital"></div>

                <div className="uiux_serviceContent">
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <h3 className="uiux_subServicePara">
                        We're more than just developers - we're artists. Our
                        hearts are filled with creativity and our hands are
                        skilled in turning ideas into reality. From healthcare
                        to finance, we cater to a wide range of industries and
                        are dedicated to crafting custom masterpieces that
                        perfectly fit your needs. Take a look at our portfolio
                        and see how we can help turn your vision into a work of
                        art.
                      </h3>
                    </Grid>
                  </Grid>
                </div>
              </Container>
            </div>
          )}
          {/* {activeTabService === "CONTENT_SECTION_THREE" && (
            <div className="uiux_subServiceParaContainer">
              <Container maxWidth="lg" className="uiux_subServiceFixedSection">
                <div className="uiux_glowEffectWorkFlow"></div>

                <div className="uiux_serviceContent">
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <h3 className="uiux_subServicePara">
                        Our UI/UX Designs that transform and scaling businesses
                        successfully. We sketch wireframes-prototypes, Landing
                        pages for your business website and, Android as well as
                        iOS app design services. We design productive and
                        practical user interfaces that lay a strong foundation
                        for great user experience.
                      </h3>
                    </Grid>
                  </Grid>
                </div>
              </Container>
            </div>
          )} */}
        </div>
      </Hidden>

      {/* visible only on mobile */}
      <Hidden only={["sm", "lg", "md", "xl"]}>
        <div style={{ position: "relative" }}>
          <ArrowBackIosIcon
            className="subServiceTabLeftArrowHome"
            onClick={() => {
              document.getElementById("strategyScroll").scrollLeft -= 400;
            }}
          />
          <div className="uiux_subServiceTabSection" id="strategyScroll">
            <div>
              <div className="uiux_subServiceContaincer">
                <img
                  className="uiux_subServiceImg"
                  src={`${BASE_URL}/tab_uiux_icon1.png`}
                  alt="best wireframing image domaincer"
                />
                <div>
                  <h1 className="uiux_subServiceTitle">Wireframes</h1>
                  <h2 className="uiux_subServiceSubTitle">
                    Unlimited possibilities
                  </h2>
                </div>
              </div>
              <div className="uiux_subServiceParaContainer">
                <Container
                  maxWidth="lg"
                  className="uiux_subServiceFixedSection"
                >
                  <div className="uiux_glowEffectBrand"></div>

                  <div className="uiux_serviceContentOne">
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <h3 className="uiux_subServicePara">
                          Are you tired of falling in the 80% of app designs
                          that fail to engage users? We know that design is a
                          combination of science, psychology, and art. We apply
                          this knowledge to craft apps that not only look good
                          but also effectively engage and motivate your users.
                        </h3>
                      </Grid>
                    </Grid>
                  </div>
                </Container>
              </div>
            </div>

            <div>
              <div className="uiux_subServiceContaincer">
                <img
                  className="uiux_subServiceImg"
                  src={`${BASE_URL}/tab_uiux_icon2.png`}
                  alt="best prototype image domaincer"
                />
                <div>
                  <h1 className="uiux_subServiceTitle">Prototypes</h1>
                  <h2 className="uiux_subServiceSubTitle">
                    A Revitalized Perspective
                  </h2>
                </div>
              </div>

              <div className="uiux_subServiceParaContainer">
                <Container
                  maxWidth="lg"
                  className="uiux_subServiceFixedSection"
                >
                  <div className="uiux_glowEffectDigital"></div>

                  <div className="uiux_serviceContentTwo">
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <h3 className="uiux_subServicePara">
                          We're more than just developers - we're artists. Our
                          hearts are filled with creativity and our hands are
                          skilled in turning ideas into reality. From healthcare
                          to finance, we cater to a wide range of industries and
                          are dedicated to crafting custom masterpieces that
                          perfectly fit your needs. Take a look at our portfolio
                          and see how we can help turn your vision into a work
                          of art.
                        </h3>
                      </Grid>
                    </Grid>
                  </div>
                </Container>
              </div>
            </div>

            {/* <div>
              <div className="uiux_subServiceContaincer">
                <img
                  className="uiux_subServiceImg"
                  src={`${BASE_URL}/tab_uiux_icon3.png`}
                  alt="top ui ux image domaincer"
                />
                <div>
                  <h1 className="uiux_subServiceTitle">UI UX</h1>
                  <h2 className="uiux_subServiceSubTitle">
                    Great design for a better story
                  </h2>
                </div>
              </div>
              <div className="uiux_subServiceParaContainer">
                <Container
                  maxWidth="lg"
                  className="uiux_subServiceFixedSection"
                >
                  <div className="uiux_glowEffectWorkFlow"></div>

                  <div className="uiux_serviceContent">
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <h3 className="uiux_subServicePara">
                          Our UI/UX Designs that transform and scaling
                          businesses successfully. We sketch
                          wireframes-prototypes, Landing pages for your business
                          website and, Android as well as iOS app design
                          services. We design productive and practical user
                          interfaces that lay a strong foundation for great user
                          experience.
                        </h3>
                      </Grid>
                    </Grid>
                  </div>
                </Container>
              </div>
            </div> */}
          </div>
          <ArrowForwardIosIcon
            className="subServiceTabRightArrowHome"
            onClick={() => {
              document.getElementById("strategyScroll").scrollLeft += 400;
            }}
          />
        </div>
      </Hidden>
    </div>
  );
};

export default ServiceContentSection;
