import React, { useState, useEffect } from "react";
import { Avatar, Container, Grid, Card, CardContent } from "@material-ui/core";
import "./techstacksection.css";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import Hidden from "@material-ui/core/Hidden";
import { BASE_URL } from "../../../config/config";
import Skeleton from "react-loading-skeleton";
import LazyLoad from "react-lazyload";
import styled, { keyframes } from "styled-components";
import AOS from "aos";

const ImageWrapper = styled.div`
  position: relative;
`;

const Placeholder = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
`;

const TechStackSection = () => {
  const [skeletonLoader, setskeletonLoader] = useState(true);
  const refPlaceholder = React.useRef();

  const removePlaceholder = () => {
    refPlaceholder.current.remove();
    setskeletonLoader(false);
  };

  useEffect(() => {
    AOS.init();
  });

  return (
    <div data-aos="fade-up" data-aos-duration="3000">
      <Container>
        <div className="mollio-section ">
          <h1 data-aos="fade-up" className="mollio__textLight">
            Tech-tastic Products!{" "}
            <span className=" mollio__textDark">
              Cutting-edge development with the trendiest Technology &
              Programming languages for a best-in-class experience.
            </span>
          </h1>
        </div>
        <div data-aos="fade-up" className="mollio-sectiontow">
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6} className="leftSide__grid">
              <h3 className="mollio__textSmall">
                Web Development.{" "}
                <Hidden only={["xs"]}>
                  <span className="mollio__textBlue">
                    Tech Stack{" "}
                    <DoubleArrowIcon className="viewAllArrow arrowani" />
                  </span>
                </Hidden>
              </h3>
              <Hidden only={["sm", "lg", "md", "xl"]}>
                <h2 className="mollio__textBlue">
                  Tech Stack{" "}
                  <DoubleArrowIcon className="viewAllArrow arrowani" />
                </h2>
              </Hidden>
              <Grid container spacing={4} style={{ marginTop: 30 }}>
                <Grid item xs={6} sm={6}>
                  <Card className="mollio__card" variant="outlined">
                    <CardContent>
                      <React.Fragment>
                        {skeletonLoader === true && (
                          <Skeleton circle={true} width="80px" height="80px" />
                        )}
                        <ImageWrapper>
                          <Placeholder ref={refPlaceholder} />
                          <LazyLoad>
                            <Avatar
                              className="sectionTwoImg"
                              src={`${BASE_URL}/tech_wordpress_icon.png`}
                              alt="wordpress icon 3d domaincer"
                              onLoad={removePlaceholder}
                              onError={removePlaceholder}
                            />
                          </LazyLoad>
                        </ImageWrapper>
                      </React.Fragment>
                      <CardContent className="cardContent">
                        <h3 className="mollio__textXXsmallDark">
                          Wordpress.{" "}
                          <span className="mollio__textXXsmallLight">
                            A Robust platform to drive competitive edge.
                          </span>
                        </h3>
                      </CardContent>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Card className="mollio__card" variant="outlined">
                    <CardContent>
                      <React.Fragment>
                        {skeletonLoader === true && (
                          <Skeleton circle={true} width="80px" height="80px" />
                        )}
                        <ImageWrapper>
                          <Placeholder ref={refPlaceholder} />
                          <LazyLoad>
                            <Avatar
                              className="sectionTwoImg"
                              src={`${BASE_URL}/tech_react_icon.png`}
                              alt="react icon 3d domaincer"
                              onLoad={removePlaceholder}
                              onError={removePlaceholder}
                            />
                          </LazyLoad>
                        </ImageWrapper>
                      </React.Fragment>
                      <CardContent className="cardContent">
                        <h3 className="mollio__textXXsmallDark">
                          React.{" "}
                          <span className="mollio__textXXsmallLight">
                            Exceed the power of user interface & user
                            experience.
                          </span>
                        </h3>
                      </CardContent>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>

              <Grid container spacing={4} style={{ marginTop: 30 }}>
                <Grid item xs={6} sm={6}>
                  <Card className="mollio__card" variant="outlined">
                    <CardContent>
                      <React.Fragment>
                        {skeletonLoader === true && (
                          <Skeleton circle={true} width="80px" height="80px" />
                        )}
                        <ImageWrapper>
                          <Placeholder ref={refPlaceholder} />
                          <LazyLoad>
                            <Avatar
                              className="sectionTwoImg"
                              src={`${BASE_URL}/tech_nodejs_icon.png`}
                              alt="nodejs icon 3d domaincer"
                              onLoad={removePlaceholder}
                              onError={removePlaceholder}
                            />
                          </LazyLoad>
                        </ImageWrapper>
                      </React.Fragment>
                      <CardContent className="cardContent">
                        <h3 className="mollio__textXXsmallDark">
                          NodeJs.{" "}
                          <span className="mollio__textXXsmallLight">
                            The solution to upscale the face of your company.
                          </span>
                        </h3>
                      </CardContent>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Card className="mollio__card" variant="outlined">
                    <CardContent>
                      <React.Fragment>
                        {skeletonLoader === true && (
                          <Skeleton circle={true} width="80px" height="80px" />
                        )}
                        <ImageWrapper>
                          <Placeholder ref={refPlaceholder} />
                          <LazyLoad>
                            <Avatar
                              className="sectionTwoImg"
                              src={`${BASE_URL}/tech_laravel_icon.png`}
                              alt="laravel icon 3d domaincer"
                              onLoad={removePlaceholder}
                              onError={removePlaceholder}
                            />
                          </LazyLoad>
                        </ImageWrapper>
                      </React.Fragment>
                      <CardContent className="cardContent">
                        <h3 className="mollio__textXXsmallDark">
                          Laravel.{" "}
                          <span className="mollio__textXXsmallLight">
                            A modish solution for your online business.
                          </span>
                        </h3>
                      </CardContent>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={6} className="rightSide__grid">
              <h3 className="mollio__textSmall">
                Mobile App Development.
                <span className="mollio__textBlue" style={{ marginLeft: 10 }}>
                  Tech Stack{" "}
                  <DoubleArrowIcon className="viewAllArrow arrowani" />
                </span>
              </h3>

              <Grid container spacing={4} style={{ marginTop: 30 }}>
                <Grid item xs={6} sm={6}>
                  <Card className="mollio__card" variant="outlined">
                    <CardContent>
                      <React.Fragment>
                        {skeletonLoader === true && (
                          <Skeleton circle={true} width="80px" height="80px" />
                        )}
                        <ImageWrapper>
                          <Placeholder ref={refPlaceholder} />
                          <LazyLoad>
                            <Avatar
                              className="sectionTwoImg"
                              src={`${BASE_URL}/tech_reactnative_icon.png`}
                              alt="react native icon 3d domaincer"
                              onLoad={removePlaceholder}
                              onError={removePlaceholder}
                            />
                          </LazyLoad>
                        </ImageWrapper>
                      </React.Fragment>
                      <CardContent className="cardContent">
                        <h3 className="mollio__textXXsmallDark">
                          React Native.{" "}
                          <span className="mollio__textXXsmallLight">
                            Don't have to create an iOS & Android app
                            separately.
                          </span>
                        </h3>
                      </CardContent>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Card className="mollio__card" variant="outlined">
                    <CardContent>
                      <React.Fragment>
                        {skeletonLoader === true && (
                          <Skeleton circle={true} width="80px" height="80px" />
                        )}
                        <ImageWrapper>
                          <Placeholder ref={refPlaceholder} />
                          <LazyLoad>
                            <Avatar
                              className="sectionTwoImg"
                              src={`${BASE_URL}/tech_flutter_icon.png`}
                              alt="flutter icon 3d domaincer"
                              onLoad={removePlaceholder}
                              onError={removePlaceholder}
                            />
                          </LazyLoad>
                        </ImageWrapper>
                      </React.Fragment>
                      <CardContent className="cardContent">
                        <h3 className="mollio__textXXsmallDark">
                          Flutter.{" "}
                          <span className="mollio__textXXsmallLight">
                            A sturdy platform to enrich the engagement.
                          </span>
                        </h3>
                      </CardContent>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>

              <Grid container spacing={4} style={{ marginTop: 30 }}>
                <Grid item xs={6} sm={6}>
                  <Card className="mollio__card" variant="outlined">
                    <CardContent>
                      <React.Fragment>
                        {skeletonLoader === true && (
                          <Skeleton circle={true} width="80px" height="80px" />
                        )}
                        <ImageWrapper>
                          <Placeholder ref={refPlaceholder} />
                          <LazyLoad>
                            <Avatar
                              className="sectionTwoImg"
                              src={`${BASE_URL}/tech_ionic_icon.png`}
                              alt="ionic icon 3d domaincer"
                              onLoad={removePlaceholder}
                              onError={removePlaceholder}
                            />
                          </LazyLoad>
                        </ImageWrapper>
                      </React.Fragment>
                      <CardContent className="cardContent">
                        <h3 className="mollio__textXXsmallDark">
                          Ionic.{" "}
                          <span className="mollio__textXXsmallLight">
                            unleash your digital presence in every device.
                          </span>
                        </h3>
                      </CardContent>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Card className="mollio__card" variant="outlined">
                    <CardContent>
                      <React.Fragment>
                        {skeletonLoader === true && (
                          <Skeleton circle={true} width="80px" height="80px" />
                        )}
                        <ImageWrapper>
                          <Placeholder ref={refPlaceholder} />
                          <LazyLoad>
                            <Avatar
                              className="sectionTwoImg"
                              src={`${BASE_URL}/tech_native_icon.png`}
                              alt="native icon 3d domaincer"
                              onLoad={removePlaceholder}
                              onError={removePlaceholder}
                            />
                          </LazyLoad>
                        </ImageWrapper>
                      </React.Fragment>
                      <CardContent className="cardContent">
                        <h3 className="mollio__textXXsmallDark">
                          Native Apps.{" "}
                          <span className="mollio__textXXsmallLight">
                            A specified scaffold to construct your application.
                          </span>
                        </h3>
                      </CardContent>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Container>
    </div>
  );
};

export default TechStackSection;
