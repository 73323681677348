import { Widget } from "@typeform/embed-react";
import React from "react";
import { PopupButton } from "@typeform/embed-react";

const TypeFormComponent = () => {
  return (
    <PopupButton id="r3BLnIaA" style={{ fontSize: 20 }} className="my-button">
      click to open form in popup
    </PopupButton>
  );
  //   return (
  //     <Widget
  //       id="r3BLnIaA"
  //       style={{ width: "100%", height: "100%" }}
  //       className="my-form"
  //     />
  //   );
};

export default TypeFormComponent;
