import React, { useState, useEffect } from "react";
import { Container, Grid, Button, Link } from "@material-ui/core";
import "../portal.css";
import Hidden from "@material-ui/core/Hidden";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { BASE_URL } from "../../../../config/config";

const STRATEGY = [
  {
    key: "CONTENT_SECTION_ONE",
    name: "Web Portal",
    subtext: "Plan for Online Success",
    image: `${BASE_URL}/tab_portal_icon1.png`,
    alt: "web portal image domaincer",
  },
  {
    key: "CONTENT_SECTION_TWO",
    name: "Dashboards",
    subtext: "Result driven onsite",
    image: `${BASE_URL}/tab_portal_icon2.png`,
    alt: "Dashboards image domaincer",
  },
  {
    key: "CONTENT_SECTION_THREE",
    name: "Workflow Automation",
    subtext: "Powerful creation for Growth",
    image: `${BASE_URL}/tab_portal_icon3.png`,
    alt: "Automation image domaincer",
  },
];

const ServiceContentSectionPortal = () => {
  const [activeTabService, setActiveTabService] = useState(
    "CONTENT_SECTION_ONE"
  );

  return (
    <div>
      <div style={{ paddingTop: 100, paddingBottom: 30 }}>
        <h1 className="service_subheading_portal">Web Portal Development</h1>
        <h2 className="service_subtext_portal">
          Enhance your business capabilities with tailored web applications
          featuring advanced dashboards, analytics, and data management.
          Streamline your workflows and increase efficiency with automation.
          Upgrade your operations and thrive in the digital age.
        </h2>
      </div>

      {/* visible only on desktop, laptop */}
      <Hidden only={["xs"]}>
        <div className="subServiceTabSection_portal">
          {STRATEGY.map((service, index) => (
            <div
              className="subServiceContaincer_portal"
              style={{
                borderColor:
                  service.key === activeTabService ? "#0077FF" : "#FFF",
              }}
              onClick={() => setActiveTabService(service.key)}
            >
              <img
                className="subServiceImg_portal"
                src={service.image}
                alt={service.alt}
              />
              <div>
                <h1 className="subServiceTitle_portal">{service.name}</h1>
                <h2 className="subServiceSubTitle_portal">{service.subtext}</h2>
              </div>
            </div>
          ))}
        </div>
        <div>
          {activeTabService === "CONTENT_SECTION_ONE" && (
            <div className="subServiceParaContainer_portal">
              <Container
                maxWidth="lg"
                className="subServiceFixedSection_portal"
              >
                <div className="glowEffectBrand_portal"></div>

                <div className="serviceContent_portal">
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <h3 className="subServicePara_portal">
                        Get your business noticed online with a marketplace
                        portal from Domaincer! Our experienced developers have
                        the skills to create a user-friendly, SEO-optimized
                        platform that will help you stand out in the crowded
                        world of e-commerce. Whether you're a small business
                        looking to advertise your products or a large
                        corporation seeking a unique strategy for success, we
                        have the expertise to help you reach your goals.
                      </h3>
                    </Grid>
                  </Grid>
                </div>
              </Container>
            </div>
          )}
          {activeTabService === "CONTENT_SECTION_TWO" && (
            <div className="subServiceParaContainer_portal">
              <Container
                maxWidth="lg"
                className="subServiceFixedSection_portal"
              >
                <div className="glowEffectDigital_portal"></div>

                <div className="serviceContent_portal">
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <h3 className="subServicePara_portal">
                        Domaincer's team of expert web portal developers are
                        here to help you create or revamp your company's online
                        presence. Our designers have a keen eye for intuitive,
                        visually stunning designs that will enhance the user
                        experience and interaction on your website.
                      </h3>
                    </Grid>
                  </Grid>
                </div>
              </Container>
            </div>
          )}
          {activeTabService === "CONTENT_SECTION_THREE" && (
            <div className="subServiceParaContainer_portal">
              <Container
                maxWidth="lg"
                className="subServiceFixedSection_portal"
              >
                <div className="glowEffectWorkFlow_portal"></div>

                <div className="serviceContent_portal">
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <h3 className="subServicePara_portal">
                        Unlock the power of connections with Domaincer's access
                        portals.Our expert developers specialize in creating
                        custom access-based portals that cater to your specific
                        needs and preferences. Whether you're looking to trade
                        or just network, our portals provide a convenient
                        platform for you to connect and collaborate with others
                        in your field. Don't miss out on this opportunity to
                        grow and succeed - choose Domaincer to help you access
                        the connections you need.
                      </h3>
                    </Grid>
                  </Grid>
                </div>
              </Container>
            </div>
          )}
        </div>
      </Hidden>

      {/* visible only on mobile */}
      <Hidden only={["sm", "lg", "md", "xl"]}>
        <div style={{ position: "relative" }}>
          <ArrowBackIosIcon
            className="subServiceTabLeftArrowHome"
            onClick={() => {
              document.getElementById("strategyScroll").scrollLeft -= 400;
            }}
          />
          <div className="subServiceTabSection_portal" id="strategyScroll">
            <div>
              <div className="subServiceContaincer_portal">
                <img
                  className="subServiceImg_portal"
                  src={`${BASE_URL}/tab_portal_icon1.png`}
                  alt="web portal image domaincer"
                />
                <div>
                  <h1 className="subServiceTitle_portal">Web Portal</h1>
                  <h2 className="subServiceSubTitle_portal">
                    Dominate the digital realm
                  </h2>
                </div>
              </div>
              <div className="subServiceParaContainer_portal">
                <Container
                  maxWidth="lg"
                  className="subServiceFixedSection_portal"
                >
                  <div className="glowEffectBrand_portal"></div>

                  <div className="serviceContent_portalOne">
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <h3 className="subServicePara_portal">
                          Get your business noticed online with a marketplace
                          portal from Domaincer! Our experienced developers have
                          the skills to create a user-friendly, SEO-optimized
                          platform that will help you stand out in the crowded
                          world of e-commerce. Whether you're a small business
                          looking to advertise your products or a large
                          corporation seeking a unique strategy for success, we
                          have the expertise to help you reach your goals.
                        </h3>
                      </Grid>
                    </Grid>
                  </div>
                </Container>
              </div>
            </div>
            <div>
              <div className="subServiceContaincer_portal">
                <img
                  className="subServiceImg_portal"
                  src={`${BASE_URL}/tab_portal_icon2.png`}
                  alt="Dashboards image domaincer"
                />
                <div>
                  <h1 className="subServiceTitle_portal">Dashboards</h1>
                  <h2 className="subServiceSubTitle_portal">
                    Onsite domination achieved
                  </h2>
                </div>
              </div>
              <div className="subServiceParaContainer_portal">
                <Container
                  maxWidth="lg"
                  className="subServiceFixedSection_portal"
                >
                  <div className="glowEffectDigital_portal"></div>

                  <div className="serviceContent_portal">
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <h3 className="subServicePara_portal">
                          Domaincer's team of expert web portal developers are
                          here to help you create or revamp your company's
                          online presence. Our designers have a keen eye for
                          intuitive, visually stunning designs that will enhance
                          the user experience and interaction on your website.
                        </h3>
                      </Grid>
                    </Grid>
                  </div>
                </Container>
              </div>
            </div>
            <div>
              <div className="subServiceContaincer_portal">
                <img
                  className="subServiceImg_portal"
                  src={`${BASE_URL}/tab_portal_icon3.png`}
                  alt="Automation image domaincer"
                />
                <div>
                  <h1 className="subServiceTitle_portal">
                    Workflow Automation
                  </h1>
                  <h2 className="subServiceSubTitle_portal">
                    Creation for explosive growth
                  </h2>
                </div>
              </div>
              <div className="subServiceParaContainer_portal">
                <Container
                  maxWidth="lg"
                  className="subServiceFixedSection_portal"
                >
                  <div className="glowEffectWorkFlow_portal"></div>

                  <div className="serviceContent_portalThree">
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <h3 className="subServicePara_portal">
                          Unlock the power of connections with Domaincer's
                          access portals.Our expert developers specialize in
                          creating custom access-based portals that cater to
                          your specific needs and preferences. Whether you're
                          looking to trade or just network, our portals provide
                          a convenient platform for you to connect and
                          collaborate with others in your field. Don't miss out
                          on this opportunity to grow and succeed - choose
                          Domaincer to help you access the connections you need.
                        </h3>
                      </Grid>
                    </Grid>
                  </div>
                </Container>
              </div>
            </div>
          </div>
          <ArrowForwardIosIcon
            className="subServiceTabRightArrowHome"
            onClick={() => {
              document.getElementById("strategyScroll").scrollLeft += 400;
            }}
          />
        </div>
      </Hidden>
    </div>
  );
};

export default ServiceContentSectionPortal;
