import React from "react";
import "./metaverseApplications.css";
import { Grid, Card } from "@material-ui/core";
import { BASE_URL } from "../../../../config/config";
import MetaverseBenefit from "../metaverse_benefits/metaverseBenefits.component";

const Applications = [
  {
    img: `${BASE_URL}/vrlearning.png`,
    heading: "Virtual work and learning spaces",
    description:
      "Enter the Metaverse and upgrade your virtual meetings from bland to grand! With stunning graphics and 3D avatars, you'll feel like you're truly present in an immersive, interactive world. Say goodbye to boring video calls and hello to a more engaging experience.",
  },
  {
    img: `${BASE_URL}/vrbuisness.png`,
    heading: `Virtual businesses and markets\u00A0\u00A0\u00A0\u00A0`,
    description:
      "Technology is the ultimate business booster! Say goodbye to flat e-commerce and hello to new opportunities. With the help of tech, businesses can showcase their services and offerings in a whole new light.",
  },
  {
    img: `${BASE_URL}/vrsocial.png`,
    heading: "Expansion of social media platforms",
    description:
      "Dive into the Metaverse and take your social media game to new heights! With an immersive platform, you'll feel like you're truly present with your connections. Say goodbye to mundane scrolling and hello to an enticing, interactive experience.",
  },
  {
    img: `${BASE_URL}/metaTour.png`,
    heading: "Virtual Tourism",
    description:
      "Technology has developed to an extent where you can enjoy the experience of traveling without visiting the destinations physically.virtual reality (VR) and augmented reality (AR) could come together for creating an immersive digital environment.",
  },
  {
    img: `${BASE_URL}/metaCommunication.png`,
    heading: "Real Time Communication",
    description:
      "Web real-time communication refers to an open source initiative which offers real-time communication capabilities to mobile applications and web browsers. ",
  },
  {
    img: `${BASE_URL}/metaBuisness.png`,
    heading: "Digital Business Models",
    description:
      "Technology creates new opportunities for businesses, helping them promote their services and offerings effectively. Auctions could be experienced from anywhere in the world, everyone would have access to all art he wants and much more.",
  },
];

const MetaverseApplications = () => {
  return (
    <>
      <div className="benefits-container-blockchain">
        <div className="card-header-blockchain">
          <h4 className="card-header">Metaverse Use Cases</h4>
        </div>
        <div>
          <Grid container spacing={1}>
            {Applications.map((each) => (
              <>
                <Grid xs={12} sm={4} className="card-grid">
                  <Card className="card-blockchain">
                    <div className="card-container">
                      <div className="image-container">
                        <img src={each.img} className="block-images"></img>
                      </div>
                      {/* <hr className="card-line"></hr> */}

                      <div className="card-innerContainer">
                        <h4 className="each-header-block">{each.heading}</h4>
                        <p className="each-description-block">
                          {each.description}
                        </p>
                      </div>
                    </div>
                  </Card>
                </Grid>
              </>
            ))}
          </Grid>
          <div style={{ marginTop: "60px" }}>
            <MetaverseBenefit />
          </div>
        </div>
      </div>
    </>
  );
};

export default MetaverseApplications;
