import React, { useState } from "react";
import { Grid, IconButton } from "@material-ui/core";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Hidden from '@material-ui/core/Hidden';
import { BASE_URL } from '../../../../config/config';

const DevopsQAPage = (props) => {
  const DEVOPS_SLIDER = `${BASE_URL}/service_devops_header_image.png`;

  Array.prototype.removeValue = function (val) {
    for (var i = 0; i < this.length; i++) {
      if (this[i] === val) {
        this.splice(i, 1);
        i--;
      }
    }
    return this;
  };

  const [pageName] = useState("devopsScreen");
  const [errorStatus, setErrorStatus] = useState(false);
  const [Q1A, setQ1A] = useState([]);
  const [Q2A, setQ2A] = useState([]);

  const handleClick = (e) => {
    e.preventDefault();
    const { userDetails } = props;
    const qadata = {
      id: "devopsQA",
      Q1: {
        question: "What do you want to deploy?",
        answer: Q1A,
      },
      Q2: {
        question: "Do you have any specific cloud provider in mind?",
        answer: Q2A,
      },
    };
    if (Q1A.length === 0) {
      setErrorStatus(true);
    } else if (Q2A.length === 0) {
      setErrorStatus(true);
    } else {
      const data = props.serviceData.removeValue(pageName);
      props.prevServiceData.push(pageName);
      props.nextStep(
        data,
        props.prevServiceData,
        qadata,
        userDetails,
        pageName
      );
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  const handleBack = (e) => {
    e.preventDefault();
    const data = props.prevServiceData.removeValue(pageName);
    props.prevStep(data);
  };

  return (
    <div className="quotePage__body">
      <React.Fragment>
        <Grid container direction="row" justify="center" spacing={2}>
          <Grid item xs={12} sm={10} className="custom__stepOneGrid">
            <div className="prevStep__div">
              <IconButton>
                <ArrowBackIcon className="prevStep__btn" onClick={handleBack} />
              </IconButton>
            </div>
            <div>
              <h2 className="quote__question">Devops</h2>
            </div>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
             <Hidden only={['sm', 'lg', 'md', 'xl']}>
              <Grid item xs={12} sm={4} className="animated__gif" style={{ textAlign: 'center' }}>
                <img
                  src={DEVOPS_SLIDER}
                  alt="Best DevOps image domaincer"
                  className="getQuote_brandingImage floatingUpDown"
                />
              </Grid>
              </Hidden>
              <Grid item xs={12} sm={8}>
                <div>
                  <div>
                    <h2 className="service__question">
                      1. What do you want to deploy?
                    </h2>
                  </div>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="checkedWebsite"
                          className="custom__checkBox"
                          onChange={(evt, data) => {
                            if (data === true) {
                              setQ1A([...Q1A, "Website"]);
                              setErrorStatus(false);
                            } else {
                              const newQ1A = Q1A.removeValue("Website");
                              setQ1A(newQ1A);
                            }
                          }}
                        />
                      }
                      label="Website"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="checkedMobileApp"
                          className="custom__checkBox"
                          onChange={(evt, data) => {
                            if (data === true) {
                              setQ1A([...Q1A, "Mobile App"]);
                              setErrorStatus(false);
                            } else {
                              const newQ1A = Q1A.removeValue("Mobile App");
                              setQ1A(newQ1A);
                            }
                          }}
                        />
                      }
                      label="Mobile App"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="checkedDatabase"
                          className="custom__checkBox"
                          onChange={(evt, data) => {
                            if (data === true) {
                              setQ1A([...Q1A, "Database"]);
                              setErrorStatus(false);
                            } else {
                              const newQ1A = Q1A.removeValue("Database");
                              setQ1A(newQ1A);
                            }
                          }}
                        />
                      }
                      label="Database"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="checkedFiles"
                          className="custom__checkBox"
                          onChange={(evt, data) => {
                            if (data === true) {
                              setQ1A([...Q1A, "Files"]);
                              setErrorStatus(false);
                            } else {
                              const newQ1A = Q1A.removeValue("Files");
                              setQ1A(newQ1A);
                            }
                          }}
                        />
                      }
                      label="Files"
                    />
                  </FormGroup>
                  <div>
                    <h2 className="service__question">
                      2. Do you have any specific cloud provider in mind.
                    </h2>
                  </div>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="checkedGoogleCloud"
                          className="custom__checkBox"
                          onChange={(evt, data) => {
                            if (data === true) {
                              setQ2A([...Q2A, "Google Cloud"]);
                              setErrorStatus(false);
                            } else {
                              const newQ2A = Q2A.removeValue("Google Cloud");
                              setQ2A(newQ2A);
                            }
                          }}
                        />
                      }
                      label="Google Cloud"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="checkedAWS"
                          className="custom__checkBox"
                          onChange={(evt, data) => {
                            if (data === true) {
                              setQ2A([...Q2A, "AWS"]);
                              setErrorStatus(false);
                            } else {
                              const newQ2A = Q2A.removeValue("AWS");
                              setQ2A(newQ2A);
                            }
                          }}
                        />
                      }
                      label="AWS"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="checkedDigitalOcean"
                          className="custom__checkBox"
                          onChange={(evt, data) => {
                            if (data === true) {
                              setQ2A([...Q2A, "Digital Ocean"]);
                              setErrorStatus(false);
                            } else {
                              const newQ2A = Q2A.removeValue("Digital Ocean");
                              setQ2A(newQ2A);
                            }
                          }}
                        />
                      }
                      label="Digital Ocean"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="checkedLinode"
                          className="custom__checkBox"
                          onChange={(evt, data) => {
                            if (data === true) {
                              setQ2A([...Q2A, "Linode"]);
                              setErrorStatus(false);
                            } else {
                              const newQ2A = Q2A.removeValue("Linode");
                              setQ2A(newQ2A);
                            }
                          }}
                        />
                      }
                      label="Linode"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="checkedOthers"
                          className="custom__checkBox"
                          onChange={(evt, data) => {
                            if (data === true) {
                              setQ2A([...Q2A, "Others"]);
                              setErrorStatus(false);
                            } else {
                              const newQ2A = Q2A.removeValue("Others");
                              setQ2A(newQ2A);
                            }
                          }}
                        />
                      }
                      label="Others"
                    />
                  </FormGroup>
                </div>
                {errorStatus && (
                  <p style={{ color: "red", fontSize: 13, marginTop: 7 }}>
                    Please fill all the fields
                  </p>
                )}
              </Grid>

              <Hidden only={['xs']}>
              <Grid item xs={12} sm={4} className="animated__gif" style={{ textAlign: 'center' }}>
                <img
                  src={DEVOPS_SLIDER}
                  alt="Best DevOps image domaincer"

                  className="getQuote_brandingImage floatingUpDown"
                />
              </Grid>
              </Hidden>
            </Grid>
            <div className="nextStep__btnContainer">
              <h5 className="nextStep__btn" onClick={handleClick}>
                Continue
              </h5>
              <IconButton>
                <DoubleArrowIcon className="nextStep__btnIcon arrowani" />
              </IconButton>
            </div>
          </Grid>
        </Grid>
      </React.Fragment>
    </div>
  );
};

export default DevopsQAPage;
