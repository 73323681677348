import React, { useState, useEffect } from "react";
import "./website_specialised.css";
import { Grid, Hidden, Container } from "@material-ui/core";
import Particles from "react-particles-js";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import { Link } from "react-router-dom";
import AOS from "aos";
import { BASE_URL } from "../../../../config/config";
import LazyLoad from "react-lazyload";
import styled, { keyframes } from "styled-components";

const BUSINESS = `${BASE_URL}/web_business.png`;
const EDUCATION = `${BASE_URL}/web_education.png`;
const ECOMMERCE = `${BASE_URL}/ecommere.png`;
const PORTAL = `${BASE_URL}/web_portal.png`;

const ImageWrapper = styled.div`
  position: relative;
`;

const Placeholder = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
`;

const WebsiteSpecializedIn = () => {
  const DOMAINCER_VIDEO = `${BASE_URL}/domaincer_banner_video.mp4`;

  const [onDemandVideo, setOnDemandVideo] = useState(false);
  const [lifeStyleVideo, setLifeStyleVideo] = useState(false);
  const [ecommerceVideo, setEcommerceVideo] = useState(false);
  const [infoVideo, setInfoVideo] = useState(false);
  const [fintechVideo, setFintechVideo] = useState(false);
  const refPlaceholder = React.useRef();

  /* const removePlaceholder = () => {
    refPlaceholder.current.remove();
  }; */

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <React.Fragment>
      <div className="specializedInSection">
        <h1 className="WeSpecailizeHeading">We Specialise In</h1>

        <div data-aos="fade-up" data-aos-duration="2000">
          <Grid container>
            <Grid
              xs={12}
              sm={12}
              style={{ position: "relative", textAlign: "center" }}
            >
              <div>
                <Hidden only={["xs"]}>
                  <div style={{ paddingTop: 80, paddingBottom: 50 }}>
                    <Grid container spacing={3}>
                      <Grid item xs={2}>
                        <div></div>
                      </Grid>
                      <Grid item xs={2}>
                        <div
                          onMouseEnter={() => setOnDemandVideo(true)}
                          onMouseLeave={() => setOnDemandVideo(false)}
                        >
                          <React.Fragment>
                            <div className="about_videoSectionContainer">
                              <div>
                                <h2
                                  className={
                                    onDemandVideo === true
                                      ? "about_customTextFieldHover"
                                      : "about_customTextField"
                                  }
                                >
                                  Business Website
                                </h2>
                              </div>
                              <React.Fragment>
                                <ImageWrapper>
                                  <Placeholder ref={refPlaceholder} />
                                  <LazyLoad>
                                    <img
                                      src={BUSINESS}
                                      alt=""
                                      className={
                                        onDemandVideo === true
                                          ? "about_hoverVideoShow scale-in-center"
                                          : "about_hoverVideo scale-out-center"
                                      }
                                    />
                                  </LazyLoad>
                                </ImageWrapper>
                              </React.Fragment>
                            </div>
                          </React.Fragment>
                        </div>
                      </Grid>
                      <Grid item xs={2} style={{marginRight:"40px"}}>
                        <div
                          onMouseEnter={() => setLifeStyleVideo(true)}
                          onMouseLeave={() => setLifeStyleVideo(false)}
                        >
                          <React.Fragment>
                            <div className="about_videoSectionContainer">
                              <div>
                                <h2
                                  className={
                                    lifeStyleVideo === true
                                      ? "about_customTextFieldHover"
                                      : "about_customTextField"
                                  }
                                >
                                  Educational Website
                                </h2>
                              </div>
                              <React.Fragment>
                                <ImageWrapper>
                                  <Placeholder ref={refPlaceholder} />
                                  <LazyLoad>
                                    <img
                                      src={EDUCATION}
                                      alt=""
                                      className={
                                        lifeStyleVideo === true
                                          ? "about_hoverVideoShow scale-in-center"
                                          : "about_hoverVideo scale-out-center"
                                      }
                                    />
                                  </LazyLoad>
                                </ImageWrapper>
                              </React.Fragment>
                            </div>
                          </React.Fragment>
                        </div>
                      </Grid>
                      <Grid item xs={2}>
                        <div
                          onMouseEnter={() => setEcommerceVideo(true)}
                          onMouseLeave={() => setEcommerceVideo(false)}
                        >
                          <React.Fragment>
                            <div className="about_videoSectionContainer">
                              <div>
                                <h2
                                  className={
                                    ecommerceVideo === true
                                      ? "about_customTextFieldHover"
                                      : "about_customTextField"
                                  }
                                >
                                  E-Commerce Website
                                </h2>
                              </div>
                              <React.Fragment>
                                <ImageWrapper>
                                  <Placeholder ref={refPlaceholder} />
                                  <LazyLoad>
                                    <img
                                      src={ECOMMERCE}
                                      alt=""
                                      className={
                                        ecommerceVideo === true
                                          ? "about_hoverVideoShow scale-in-center"
                                          : "about_hoverVideo scale-out-center"
                                      }
                                    />
                                  </LazyLoad>
                                </ImageWrapper>
                              </React.Fragment>
                            </div>
                          </React.Fragment>
                        </div>
                      </Grid>
                      <Grid item xs={2}>
                        <div
                          onMouseEnter={() => setInfoVideo(true)}
                          onMouseLeave={() => setInfoVideo(false)}
                        >
                          <React.Fragment>
                            <div className="about_videoSectionContainer">
                              <div>
                                <h2
                                  className={
                                    infoVideo === true
                                      ? "about_customTextFieldHover"
                                      : "about_customTextField"
                                  }
                                >
                                  Portal Website
                                </h2>
                              </div>
                              <React.Fragment>
                                <ImageWrapper>
                                  <Placeholder ref={refPlaceholder} />
                                  <LazyLoad>
                                    <img
                                      src={PORTAL}
                                      alt=""
                                      className={
                                        infoVideo === true
                                          ? "about_hoverVideoShow scale-in-center"
                                          : "about_hoverVideo scale-out-center"
                                      }
                                    />
                                  </LazyLoad>
                                </ImageWrapper>
                              </React.Fragment>
                            </div>
                          </React.Fragment>
                        </div>
                      </Grid>
                      {/* <Grid item xs={2}>
                        <div
                          onMouseEnter={() => setFintechVideo(true)}
                          onMouseLeave={() => setFintechVideo(false)}
                        >
                          <React.Fragment>
                            <div className="about_videoSectionContainer">
                              <div>
                                <h2
                                  className={
                                    fintechVideo === true
                                      ? "about_customTextFieldHover"
                                      : "about_customTextField"
                                  }
                                >
                                  Fintech
                                </h2>
                              </div>
                              <React.Fragment>
                                <ImageWrapper>
                                  <Placeholder ref={refPlaceholder} />
                                  <LazyLoad>
                                    <img
                                      src={FINTECH}
                                      alt=""
                                      className={
                                        fintechVideo === true
                                          ? "about_hoverVideoShow scale-in-center"
                                          : "about_hoverVideo scale-out-center"
                                      }
                                    />
                                  </LazyLoad>
                                </ImageWrapper>
                              </React.Fragment>
                            </div>
                          </React.Fragment>
                        </div>
                      </Grid> */}
                      <Grid item xs={1}>
                        <div></div>
                      </Grid>
                    </Grid>
                  </div>
                </Hidden>

                {/* ------------------------------------- */}
                {/* Mobile */}
                {/* ------------------------------------- */}

                <Hidden only={["sm", "lg", "md", "xl"]}>
                  <div style={{ marginTop: 30 }}>
                    <Grid container style={{ marginBottom: 30 }}>
                      <Grid
                        xs={6}
                        data-aos="fade-right"
                        data-aos-duration="1000"
                      >
                        <React.Fragment>
                          <ImageWrapper>
                            <Placeholder ref={refPlaceholder} />
                            <LazyLoad>
                              <img
                                src={BUSINESS}
                                alt=""
                                style={{ width: 75, height: "auto" }}
                              />
                              <h1 className="specializeInNames"> Business Website</h1>
                            </LazyLoad>
                          </ImageWrapper>
                        </React.Fragment>
                      </Grid>
                      <Grid
                        xs={6}
                        data-aos="fade-right"
                        data-aos-duration="1500"
                      >
                        <React.Fragment>
                          <ImageWrapper>
                            <Placeholder ref={refPlaceholder} />
                            <LazyLoad>
                              <img
                                src={EDUCATION}
                                alt=""
                                style={{ width: 75, height: "auto" }}
                              />
                              <h1 className="specializeInNames">Educational Website</h1>
                            </LazyLoad>
                          </ImageWrapper>
                        </React.Fragment>
                      </Grid>
                    </Grid>
                    <Grid container style={{ marginBottom: 30 }}>
                      <Grid
                        xs={6}
                        data-aos="fade-right"
                        data-aos-duration="1000"
                      >
                        <React.Fragment>
                          <ImageWrapper>
                            <Placeholder ref={refPlaceholder} />
                            <LazyLoad>
                              <img
                                src={ECOMMERCE}
                                alt=""
                                style={{ width: 75, height: "auto" }}
                              />
                              <h1 className="specializeInNames">E-Commerce Website</h1>
                            </LazyLoad>
                          </ImageWrapper>
                        </React.Fragment>
                      </Grid>

                      <Grid
                        xs={6}
                        data-aos="fade-right"
                        data-aos-duration="1500"
                      >
                        <React.Fragment>
                          <ImageWrapper>
                            <Placeholder ref={refPlaceholder} />
                            <LazyLoad>
                              <img
                                src={PORTAL}
                                alt=""
                                style={{ width: 75, height: "auto" }}
                              />
                              <h1 className="specializeInNames"> Portal Website</h1>
                            </LazyLoad>
                          </ImageWrapper>
                        </React.Fragment>
                      </Grid>
                    </Grid>
                    {/* <Grid container>
                      <Grid
                        xs={12}
                        data-aos="fade-right"
                        data-aos-duration="1500"
                      >
                        <React.Fragment>
                          <ImageWrapper>
                            <Placeholder ref={refPlaceholder} />
                            <LazyLoad>
                              <img
                                src={INFORMATION_OUTLETS}
                                alt=""
                                style={{ width: 75, height: "auto" }}
                              />
                              <h1 className="specializeInNames">
                                Information outlets
                              </h1>
                            </LazyLoad>
                          </ImageWrapper>
                        </React.Fragment>
                      </Grid>
                    </Grid> */}
                  </div>
                </Hidden>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </React.Fragment>
  );
};

export default WebsiteSpecializedIn;
