import React, { useState, useEffect } from "react";
import "./brandstrategy.css";
import { Container, Grid, Hidden, Paper, Tab, Tabs } from "@material-ui/core";
import WhyChooseUs from "./whychooseus/whychooseus.component";
// import OurProcess from './ourprocess/ourprocess.component';
import CaseStudySection from "../../homepage/casestudyssection/casestudysection.component";
import HowServiceWillHelpYou from "./howservicewillhelpyou/howservicewillhelpyou.component";
import BlogSection from "./blogsection/blogsection.component";
import ServiceContentSection from "./servicecontentsection/servicecontentsection.component";
import AOS from "aos";
import FollowUs from "../../../commons/followus/followus.component";
import { BASE_URL } from "../../../config/config";

const BrandStrategy = (props) => {
  const BRAND_STRATEGY_SLIDER = `${BASE_URL}/service_strategy_header_image.png`;

  useEffect(() => {
    AOS.init();
  });
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleTabScroll = (value) => {
    if (value === "OVER_VIEW") {
      window.scrollTo({
        top: 950,
        behavior: "smooth",
      });
    } else if (value === "BLOG") {
      window.scrollTo({
        top: 1650,
        behavior: "smooth",
      });
    } else if (value === "WHY_DOMAINCER") {
      window.scrollTo({
        top: 2500,
        behavior: "smooth",
      });
    } else if (value === "RECENT_WORKS") {
      window.scrollTo({
        top: 3000,
        behavior: "smooth",
      });
    }
  };

  const handleTabScrollMobile = (value) => {
    if (value === "OVER_VIEW") {
      window.scrollTo({
        top: 1500,
        behavior: "smooth",
      });
    } else if (value === "BLOG") {
      window.scrollTo({
        top: 2300,
        behavior: "smooth",
      });
    } else if (value === "WHY_DOMAINCER") {
      window.scrollTo({
        top: 3100,
        behavior: "smooth",
      });
    } else if (value === "RECENT_WORKS") {
      window.scrollTo({
        top: 4350,
        behavior: "smooth",
      });
    }
  };

  return (
    <div class="mobileMarginTop">
  
      <FollowUs history={props.history} />
      <Container>
        <Grid container>
          <Grid
            xs={12}
            sm={6}
            className="BSSliderImgContainer"
            // data-aos='fade-up'
            data-aos-duration="1000"
          >
            <img
              src={BRAND_STRATEGY_SLIDER}
              alt="Brand strategy landing page image domaincer"
              className="brandStrategySliderImg floatingUpDown"
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <div
              className="BSSliderRightSection"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <h1 className="sliderHeading">Brand Strategy</h1>
              <h2 className="sliderSubHeading">
                We take your Brand as ours to improve business outcomes and
                growth velocity by creating meaningful strategies and nurturing
                them from ideation to implementation.
              </h2>
              <Hidden only={["xs"]}>
                <div className="serviceTabsSection">
                  <Paper square className="MTabsWidth">
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      indicatorColor="primary"
                      textColor="primary"
                      aria-label="tabs example"
                      className="MTabsContainer"
                    >
                      <Tab label="About" className="MTabBtn" />
                      <Tab
                        label="Overview"
                        className="MTabBtn"
                        onClick={() => handleTabScroll("OVER_VIEW")}
                      />
                      <Tab
                        label="Blog"
                        className="MTabBtn"
                        onClick={() => handleTabScroll("BLOG")}
                      />
                      <Tab
                        label="Why Domaincer"
                        className="MTabBtn"
                        onClick={() => handleTabScroll("WHY_DOMAINCER")}
                      />
                      {/* <Tab
                        label="Recent Works"
                        className="MTabBtn"
                        onClick={() => handleTabScroll("RECENT_WORKS")}
                      /> */}
                    </Tabs>
                  </Paper>
                </div>
              </Hidden>
              <Hidden only={["sm", "md", "lg", "xl"]}>
                <div className="serviceTabsSection">
                  <Paper square className="MTabsWidth">
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      indicatorColor="primary"
                      textColor="primary"
                      aria-label="tabs example"
                      className="MTabsContainer"
                    >
                      <Tab label="About" className="MTabBtn" />
                      <Tab
                        label="Overview"
                        className="MTabBtn"
                        onClick={() => handleTabScrollMobile("OVER_VIEW")}
                      />
                      <Tab
                        label="Blog"
                        className="MTabBtn"
                        onClick={() => handleTabScrollMobile("BLOG")}
                      />
                      <Tab
                        label="Why Domaincer"
                        className="MTabBtn"
                        onClick={() => handleTabScrollMobile("WHY_DOMAINCER")}
                      />
                      {/* <Tab
                        label="Recent Works"
                        className="MTabBtn"
                        onClick={() => handleTabScrollMobile("RECENT_WORKS")}
                      /> */}
                    </Tabs>
                  </Paper>
                </div>
              </Hidden>
            </div>
          </Grid>
        </Grid>
      </Container>
      <HowServiceWillHelpYou />
      <ServiceContentSection />
      <BlogSection />
      <WhyChooseUs />
      {/* <OurProcess /> */}
      {/* <CaseStudySection /> */}
    </div>
  );
};

export default BrandStrategy;
