import React from 'react';
import '../digitalTransformation.css';
import { Container, Grid, Card } from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';
import { BASE_URL } from '../../../../config/config';

const WhyChooseUs = () => {
  const WHY_CHOOSE_US_IMAGE = `${BASE_URL}/about_why_choose_us.png`;

  return (
    <div className='digital_whyChooseUsSection'>
      <Container>
        <h1 className='digital_whyChooseUsTitle'>Why Domaincer</h1>
        <Grid container spacing={2} style={{ justifyContent: 'center' }}>
          <Grid item xs={12} sm={3}>
            <div
              className='digital_whyChooseColumnUpContainer'
              data-aos='fade-right'
              data-aos-duration='1000'
            >
              <h2 className='digital_chooseTitle'>Strategy</h2>
              <h3 className='digital_choosePara'>
                We provide strategy that combines right integration of Latest
                Technology
              </h3>
            </div>
            <div
              className='digital_whyChooseColumnDownContainer'
              data-aos='fade-right'
              data-aos-duration='1400'
            >
              <h2 className='digital_chooseTitle'>Methodology</h2>
              <h3 className='digital_choosePara'>
                We strongly believe in seamless workflow that covers the
                feasibility of limitation
              </h3>
            </div>
          </Grid>
          <Grid item xs={12} sm={4} className='digital_about__chooseUsImageRow'>
            <img
              src={WHY_CHOOSE_US_IMAGE}
              alt = "why choose us image 3d domaincer"
              className='digital_about_chooseUsMiddleImg floatingUpDown'
            />
          </Grid>

          <Hidden only={['sm', 'lg', 'md', 'xl']}>
            <Grid item xs={12} sm={3}>
              <div
                className='digital_whyChooseColumn3UpContainer'
                data-aos='fade-up'
                data-aos-duration='1200'
              >
                <h2 className='digital_chooseTitle'>Brainstrom</h2>
                <h3 className='digital_choosePara'>
                  we have artistic brains to bring your digital workspace in
                  creative process.
                </h3>
              </div>
              <div
                className='digital_whyChooseColumn3DownContainer'
                data-aos='fade-up'
                data-aos-duration='1400'
              >
                <h2 className='digital_chooseTitle'>Experience</h2>
                <h3 className='digital_choosePara'>
                  Collaborate in an intuitive ideation environment. We work
                  together in real time, to build a great product experience.
                </h3>
              </div>
            </Grid>
          </Hidden>
          <Hidden only={['xs']}>
            <Grid item xs={12} sm={3}>
              <div
                className='digital_whyChooseColumn3UpContainer'
                data-aos='fade-left'
                data-aos-duration='1200'
              >
                <h2 className='digital_chooseTitle'>Brainstrom</h2>
                <h3 className='digital_choosePara'>
                  we have artistic brains to bring your digital workspace in
                  creative process.
                </h3>
              </div>
              <div
                className='digital_whyChooseColumn3DownContainer'
                data-aos='fade-left'
                data-aos-duration='1600'
              >
                <h2 className='digital_chooseTitle'>Experience</h2>
                <h3 className='digital_choosePara'>
                  Collaborate in an intuitive ideation environment. We work
                  together in real time, to build a great product experience.
                </h3>
              </div>
            </Grid>
          </Hidden>
        </Grid>
      </Container>
    </div>
  );
};
export default WhyChooseUs;
