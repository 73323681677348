import React from "react";
import "../devops.css";
import { Container, Grid } from "@material-ui/core";
import Particles from "react-particles-js";

const HowServiceWillHelpYouDevOps = () => {
  return (
    <div>
      <Grid container>
        <Particles
          params={{
            particles: {
              number: {
                value: 350,
              },
              size: {
                value: 1,
              },
              color: {
                value: "#fff",
              },
              line_linked: {
                enable: false,
                distance: 120,
                color: "#fff",
                width: 1,
              },
            },
            interactivity: {
              events: {
                onhover: {
                  enable: true,
                  mode: "repulse",
                },
              },
            },
          }}
          className="particalsSection_devops"
        />
        <Grid
          xs={12}
          sm={12}
          style={{ zIndex: -1, position: "relative", textAlign: "center" }}
        >
          <div className="BSHelpYouRightSection_devops">
            <h1 className="BSHelpYouHeading_devops">
              Minimize the cycle, maximize your success
            </h1>
            <h2
              className="BSHelpYouPara_devops"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              Maximize your business agility and speed up your delivery times
              with DevOps. Our expert team helps you transform large-scale,
              project-based systems into a continuous development process,
              allowing for greater flexibility and success.
            </h2>
            <div
              data-aos="fade-up"
              data-aos-duration="1000"
              className="circleBSHelpYou_devops"
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default HowServiceWillHelpYouDevOps;
