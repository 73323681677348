import React, { useEffect, useState } from "react";
import { Container, Grid } from "@material-ui/core";
import { BASE_URL } from "../../../../config/config";
import Carousel from "./carousel";
import "./carousel.css";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";

const FLUTTER_IMG = `${BASE_URL}/flutterlang.png`;
const NATIVE_IMG = `${BASE_URL}/nativelang.png`;

const MOBILE_APP = [
  {
    id: 8798,
    url: FLUTTER_IMG,
    large_url: FLUTTER_IMG,
    source_id: null,
    path: "",
    name: "Flutter"
  },
  {
    id: 788,
    url: `${BASE_URL}/tab_mobile_icon1.png`,
    large_url: `${BASE_URL}/tab_mobile_icon1.png`,
    source_id: null,
    path: "",
    name: "React Native"
  },
  {
    id: 8802,
    url: NATIVE_IMG,
    large_url: NATIVE_IMG,
    source_id: null,
    path: "",
    name: "Native"
  }
];

const ServiceContentV2MobileApp = props => {
  const [mainImg, setMainImg] = useState([]);
  const [activeDot, setActiveDot] = useState(0);



  useEffect(() => {
    setMainImg(MOBILE_APP);
  }, []);

  return (
    <React.Fragment>
      <div
        className="mobileMarginTop"
        style={{ marginTop: 70, marginBottom: 80 }}
      >
        <Container>
          <Grid container style={{ alignItems: "center" }}>
            <Grid xs={12} sm={6} style={{ alignSelf: "center" }}>
              <div
                className="serviceMobilev2_headingContainer"
                data-aos="fade-down"
                data-aos-duration="1000"
              >
                <h1 className="serviceMobilev2_heading">
                  World Class Technologies
                </h1>
              </div>

              <h3
                className="serviceMobilev2_subtext"
                data-aos="fade-down"
                data-aos-duration="1200"
              >
                Mobile app development is on the tip of hot digital footprint.
                Capitalise on making your mobile application & win business in
                both Android Play Store & iOS App Store.
                <br /><br />
                Invest your business into a leading mobile app development
                company in Chennai, and reap amazing profits. The limelight of
                the website has now changed to Mobile Apps. We provide
                Client-centric Innovative mobile application development
                solutions.
              </h3>
            </Grid>
            <Grid xs={12} sm={6} data-aos="fade-up" data-aos-duration="1000">
              {mainImg.length > 0 && (
                <Carousel
                  imgList={mainImg}
                  img_width={300}
                  img_height={300}
                  m_img_width={120}
                  m_img_height={120}
                  visibleImages={3}
                  duration={750}
                />
              )}
            </Grid>
          </Grid>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ServiceContentV2MobileApp;
