import React, { useState, useEffect } from 'react';
import { Container, Grid, Button, Link } from '@material-ui/core';
import '../digitalTransformation.css';
import Hidden from '@material-ui/core/Hidden';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { BASE_URL } from '../../../../config/config';

const STRATEGY = [
  {
    key: 'CONTENT_SECTION_ONE',
    name: 'Design Transformation',
    subtext: 'Make your workflow seamless',
    image: `${BASE_URL}/tab_digital_icon1.png`,
    alt: 'digital transformation image domaincer',
  },
  {
    key: 'CONTENT_SECTION_TWO',
    name: 'Process Transformation',
    subtext: 'Equip ingenious Technology',
    image: `${BASE_URL}/tab_digital_icon2.png`,
    alt: 'process transformation image domaincer',
  },
  {
    key: 'CONTENT_SECTION_THREE',
    name: 'Marketing Transformation',
    subtext: 'Specialised Intelligence',
    image: `${BASE_URL}/tab_digital_icon3.png`,
    alt: 'Marketing transformation image domaincer',
  },
];

const ServiceContentSection = () => {
  const [activeTabService, setActiveTabService] = useState(
    'CONTENT_SECTION_ONE'
  );

  return (
    <div>
      <div style={{ paddingTop: 100, paddingBottom: 30 }}>
        <h1 className='digital_service_subheading'>
          About Digital Transformation
        </h1>
        <h2 className='digital_service_subtext'>
          Data, information and experience empower the culture of digital
          transformation, that gives seamless experience for your customer and
          measureable ROI for your business
        </h2>
      </div>

      {/* visible only on desktop, laptop */}
      <Hidden only={['xs']}>
        <div className='digital_subServiceTabSection'>
          {STRATEGY.map((service, index) => (
            <div
              className='digital_subServiceContaincer'
              style={{
                borderColor:
                  service.key === activeTabService ? '#0077FF' : '#FFF',
              }}
              onClick={() => setActiveTabService(service.key)}
            >
              <img
                className='digital_subServiceImg'
                src={service.image}
                alt={service.alt}
              />
              <div>
                <h1 className='digital_subServiceTitle'>{service.name}</h1>
                <h2 className='digital_subServiceSubTitle'>
                  {service.subtext}
                </h2>
              </div>
            </div>
          ))}
        </div>
        <div>
          {activeTabService === 'CONTENT_SECTION_ONE' && (
            <div className='digital_subServiceParaContainer'>
              <Container
                maxWidth='lg'
                className='digital_subServiceFixedSection'
              >
                <div className='digital_glowEffectBrand'></div>

                <div className='digital_serviceContent'>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <h3 className='digital_subServicePara'>
                        Our systematic approach leaves no room for gaps in
                        requirements understanding, and we direct a progressive
                        workshop with business owners for the business zones
                        that can be considered for automation. Based on the
                        technology used, process depth, data structure and the
                        application characteristics, we derive the complexity
                        levels for Process assessment.
                      </h3>
                    </Grid>
                  </Grid>
                </div>
              </Container>
            </div>
          )}
          {activeTabService === 'CONTENT_SECTION_TWO' && (
            <div className='digital_subServiceParaContainer'>
              <Container
                maxWidth='lg'
                className='digital_subServiceFixedSection'
              >
                <div className='digital_glowEffectDigital'></div>

                <div className='digital_serviceContent'>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <h3 className='digital_subServicePara'>
                        Blockchain is a new Intelligence asset that provides a
                        combination of Blockchain space, balance stability, and
                        growth. We offer a lot of opportunities to set up
                        transparency, utilising Intelligence assets and records.
                        We provide a distinctive dimension to amplify the
                        capability of new Blockchain advancements by giving the
                        base a feasible item.
                      </h3>
                    </Grid>
                  </Grid>
                </div>
              </Container>
            </div>
          )}
          {activeTabService === 'CONTENT_SECTION_THREE' && (
            <div className='digital_subServiceParaContainer'>
              <Container
                maxWidth='lg'
                className='digital_subServiceFixedSection'
              >
                <div className='digital_glowEffectWorkFlow'></div>

                <div className='digital_serviceContent'>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <h3 className='digital_subServicePara'>
                        We are providing complete IoT development services,
                        end-to-end implementation and integration of the
                        internet of things into client’s Infrastructure. As
                        increase audience size & decrease spend by deploying IoT
                        features into your business and full development service
                        at an affordable cost.
                      </h3>
                    </Grid>
                  </Grid>
                </div>
              </Container>
            </div>
          )}
        </div>
      </Hidden>

      {/* visible only on mobile */}
      <Hidden only={['sm', 'lg', 'md', 'xl']}>
        <div style={{ position: 'relative' }}>
          <ArrowBackIosIcon
            className='subServiceTabLeftArrowHome'
            onClick={() => {
              document.getElementById('strategyScroll').scrollLeft -= 400;
            }}
          />
          <div className='digital_subServiceTabSection' id='strategyScroll'>
            <div>
              <div className='digital_subServiceContaincer'>
                <img
                  className='digital_subServiceImg'
                  src={`${BASE_URL}/tab_digital_icon1.png`}
                  alt='digital transformation image domaincer'
                />
                <div>
                  <h1 className='digital_subServiceTitle'>
                    Design Transformation
                  </h1>
                  <h2 className='digital_subServiceSubTitle'>
                    Make your workflow seamless
                  </h2>
                </div>
              </div>
              <div className='digital_subServiceParaContainer'>
                <Container
                  maxWidth='lg'
                  className='digital_subServiceFixedSection'
                >
                  <div className='digital_glowEffectBrand'></div>

                  <div className='digital_serviceContent'>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <h3 className='digital_subServicePara'>
                          Our systematic approach leaves no room for gaps in
                          requirements understanding, and we direct a
                          progressive workshop with business owners for the
                          business zones that can be considered for automation.
                          Based on the technology used, process depth, data
                          structure and the application characteristics, we
                          derive the complexity levels for Process assessment.
                        </h3>
                      </Grid>
                    </Grid>
                  </div>
                </Container>
              </div>
            </div>

            <div>
              <div className='digital_subServiceContaincer'>
                <img
                  className='digital_subServiceImg'
                  src={`${BASE_URL}/tab_digital_icon2.png`}
                  alt='process transformation image domaincer'
                />
                <div>
                  <h1 className='digital_subServiceTitle'>
                    Process Transformation
                  </h1>
                  <h2 className='digital_subServiceSubTitle'>
                    Equip ingenious Technology
                  </h2>
                </div>
              </div>
              <div className='digital_subServiceParaContainer'>
                <Container
                  maxWidth='lg'
                  className='digital_subServiceFixedSection'
                >
                  <div className='digital_glowEffectDigital'></div>

                  <div className='digital_serviceContentTwo'>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <h3 className='digital_subServicePara'>
                          Blockchain is a new Intelligence asset that provides a
                          combination of Blockchain space, balance stability,
                          and growth. We offer a lot of opportunities to set up
                          transparency, utilising Intelligence assets and
                          records. We provide a distinctive dimension to amplify
                          the capability of new Blockchain advancements by
                          giving the base a feasible item.
                        </h3>
                      </Grid>
                    </Grid>
                  </div>
                </Container>
              </div>
            </div>

            <div>
              <div className='digital_subServiceContaincer'>
                <img
                  className='digital_subServiceImg'
                  src={`${BASE_URL}/tab_digital_icon3.png`}
                  alt='Marketing transformation image domaincer'
                />
                <div>
                  <h1 className='digital_subServiceTitle'>
                    Marketing Transformation
                  </h1>
                  <h2 className='digital_subServiceSubTitle'>
                    Specialised Intelligence
                  </h2>
                </div>
              </div>
              <div className='digital_subServiceParaContainer'>
                <Container
                  maxWidth='lg'
                  className='digital_subServiceFixedSection'
                >
                  <div className='digital_glowEffectWorkFlow'></div>

                  <div className='digital_serviceContentThree'>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <h3 className='digital_subServicePara'>
                          We are providing complete IoT development services,
                          end-to-end implementation and integration of the
                          internet of things into client’s Infrastructure. As
                          increase audience size & decrease spend by deploying
                          IoT features into your business and full development
                          service at an affordable cost.
                        </h3>
                      </Grid>
                    </Grid>
                  </div>
                </Container>
              </div>
            </div>
          </div>
          <ArrowForwardIosIcon
            className='subServiceTabRightArrowHome'
            onClick={() => {
              document.getElementById('strategyScroll').scrollLeft += 400;
            }}
          />
        </div>
      </Hidden>
    </div>
  );
};

export default ServiceContentSection;
