import React from "react";
import { BASE_URL } from "../../config/config";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import "./thankyou.css"

const QuoteThankYou = () => {
  const LOGO_ANIMATED_GIF = `${BASE_URL}/getquote_handshake.png`;
  return (
    <div className="handshake_container">
      <img
        src={LOGO_ANIMATED_GIF}
        alt="Hand shake icon domaincer"
        className="handshake_image_thanku floatingUpDown"
      />
      <div className="thank_msg_container">
        <p className="thank_msg_one">We've received your response.</p>
        <p className="thank_msg">We'll contact you soon.</p>
      </div>

      <Link to="/">
        <Button className="back_home">Back to home</Button>
      </Link>
    </div>
  );
};

export default QuoteThankYou;
