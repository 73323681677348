import React, { useEffect } from "react";
import { Container, Grid, Avatar, Button, Card } from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import AOS from "aos";
import FollowUs from "../../../commons/followus/followus.component";

import { BASE_URL } from "../../../config/config";


const CaseStudyPraxisHolidays = (props) => {
  const RYZONE_REACT_IMAGE = `${BASE_URL}/fpm_casestudy_design_image.png`;
  const RYZONE_MOBILE_IMAGE = `${BASE_URL}/ryzone_casestudy_mobile_image.png`;
  const RYZONE_PARTNER_IMAGE = `${BASE_URL}/fpm_casestudy_marketing_image.png`;
  const RYZONE_PARTNER_ICON = `${BASE_URL}/fpm_casestudy_marketing_icon.png`;
  const RYZONE_PHONE_ICON = `${BASE_URL}/ryzone_casestudy_mobile_icon.png`;
  const PRAXIS_BRANDING_ICON = `${BASE_URL}/fpm_casestudy_design_icon.png`;
  const RYZONE_LOGO_ICON = `${BASE_URL}/ryzone_casestudy_logo.png`;
  const RYZONE_IMAGE_HEADER = `${BASE_URL}/praxis_casestudy_header.png`;

  useEffect(() => {
    AOS.init();
  });

  return (
    <div class="mobileMarginTop">
    
      <FollowUs history={props.history} />
      <Container>
        <Grid container>
          <Grid xs={12} sm={6} style={{ alignSelf: "center" }}>
            <img
              src={RYZONE_IMAGE_HEADER}
              alt="Parixs holidays image domaincer"
              className="praxisHeaderImage"
            />
          </Grid>
          <Grid xs={12} sm={5}>
            <div className="logoTextContaincer">
              <Avatar src={RYZONE_LOGO_ICON} className="caseStudiesLogo" />
              <h1 className="caseStudieTitle">Praxis Holidays</h1>
            </div>
            <h2 className="caseStudiesSubTitle">
              <strong>Digital Suite for leading Tour Operator in India</strong>
            </h2>
            <p className="caseStudiesDescript">
              Being the director of GMR group & playing a key role in
              establishing the "Delhi Dare Devils", Mr. PB Vanchi chose to
              handover the digital responsibility to Domaincer.com for his new
              venture Praxis Holidays.
            </p>
            <div className="labelsSection" style={{ alignItems: "center" }}>
              <div className="labelsContainerReact_ryzone">
                <img
                  src={PRAXIS_BRANDING_ICON}
                  className="labelsIcon"
                  style={{ height: "87%" }}
                  alt="design icon domaincer"
                />
                <p className="labelsText">Branding</p>
              </div>

              <div className="labelsContainer_ryzone">
                <img
                  src={RYZONE_PHONE_ICON}
                  alt="mobile app development icon domaincer"
                  className="labelsIcon"
                />
                <p className="labelsText">Landing pages</p>
              </div>

              <div className="labelsContainer_ryzone">
                <img
                  src={RYZONE_PARTNER_ICON}
                  alt="digital marekting icon domaincer"
                  className="labelsIcon"
                />
                <p className="labelsText">Digital Presense</p>
              </div>
            </div>

            <div style={{ marginBottom: 100 }}>
              <a href="https://praxisholidays.com/" target="_blank">
                <Button className="viewWebsiteContainer">
                  Visit Website
                  <ChevronRightIcon className="viewWebsite__arrow arrowani" />
                </Button>
              </a>
            </div>
          </Grid>
        </Grid>
      </Container>

      <div className="caseStudiesSectionTwo_ryzone">
        <Container>
          <Grid container>
            <Grid
              xs={12}
              sm={6}
              style={{ position: "relative" }}
              data-aos="fade-down"
              data-aos-duration="1000"
            >
              <div className="logoTextSectionTwo">
                <div className="circleDirector__caseStudy"></div>
                <div className="dottedLineContainer">
                  <p className="dottedLine1">. . . . . . . . .</p>
                  <p className="dottedLine1">. . . . . . . . .</p>
                  <p className="dottedLine1">. . . . . . . . .</p>
                  <p className="dottedLine1">. . . . . . . . .</p>
                  <p className="dottedLine1">. . . . . . . . .</p>
                  <p className="dottedLine1">. . . . . . . . .</p>
                  <p className="dottedLine1">. . . . . . . . .</p>
                  <p className="dottedLine1">. . . . . . . . .</p>
                </div>
                <div className="casestudy_icon_row">
                  <h1 className="caseStudieSectionTwoTitle">Branding</h1>
                </div>
              </div>
              <p className="caseStudiesSectionTwoDescript">
                Logo & Stationery such as Letterhead, business cards were
                designed for getting the brand started. With a unique & catchy
                logo crafted to Praxis Holidays, it was a break through for the
                brand to get marketing done easily and effectively. Adding to
                the branding, travel duffle bag, Cap, T-Shirts, Badges were
                designed to establish their brand throughout India.
              </p>
            </Grid>
            <Grid xs={12} sm={6}>
              <img
                src={RYZONE_REACT_IMAGE}
                className="caseStudiesRightImg"
                alt="design stage icon domaincer"
              />
            </Grid>
          </Grid>
        </Container>
      </div>

      <div className="caseStudiesSectionThree_ryzone">
        <Container>
          <Grid container>
            <Grid
              xs={12}
              sm={6}
              style={{ position: "relative" }}
              data-aos="fade-down"
              data-aos-duration="1000"
            >
              <img
                src={RYZONE_MOBILE_IMAGE}
                className="caseStudiesleftImg"
                alt="mobile app stage icon domaincer"
              />
            </Grid>
            <Grid xs={12} sm={6}>
              <div className="logoTextSectionTwo">
                <div className="casestudy_icon_row">
                  <h1 className="caseStudieSectionThreeTitle">Landing Pages</h1>
                </div>
              </div>
              <p className="caseStudiesSectionThreeDescript">
                {" "}
                For Google Ads, Facebook Ads and Instagram Ads, an unique
                landing page architecture was setup. This one page landing
                website helped Praxis holidays to drive sales through the
                digital marketing campaigns with lead form integration and
                automated emails.
              </p>
            </Grid>
          </Grid>
        </Container>
      </div>

      <div className="caseStudiesSectionTwo_ryzone">
        <Container>
          <Grid container>
            <Grid
              xs={12}
              sm={6}
              style={{ position: "relative" }}
              data-aos="fade-down"
              data-aos-duration="1000"
            >
              <div className="logoTextSectionTwo">
                <div className="circleDirector__caseStudy"></div>
                <div className="dottedLineContainer">
                  <p className="dottedLine1">. . . . . . . . .</p>
                  <p className="dottedLine1">. . . . . . . . .</p>
                  <p className="dottedLine1">. . . . . . . . .</p>
                  <p className="dottedLine1">. . . . . . . . .</p>
                  <p className="dottedLine1">. . . . . . . . .</p>
                  <p className="dottedLine1">. . . . . . . . .</p>
                  <p className="dottedLine1">. . . . . . . . .</p>
                  <p className="dottedLine1">. . . . . . . . .</p>
                </div>
                <div className="casestudy_icon_row">
                  <h1 className="caseStudieSectionTwoTitle">
                    Digital Presence
                  </h1>
                </div>
              </div>

              <p className="caseStudiesSectionTwoDescript">
                {" "}
                Wordpress website with Content Management system were developed
                to update the tour packages, add categories and monitor the tour
                pilgrimages data into one unified platform. With an excellent
                server architecture, Praxis Holidays' customers were able to
                launch their website with blazing fast experience.
              </p>
            </Grid>
            <Grid xs={12} sm={6}>
              <img
                src={RYZONE_PARTNER_IMAGE}
                className="caseStudiesRightImg"
                alt="digital marketing stage icon domaincer"
              />
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
};

export default CaseStudyPraxisHolidays;
