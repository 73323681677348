import React, { useState, useEffect } from 'react';
import { Container, Grid, Button, Link } from '@material-ui/core';
import '../brandstrategy.css';
import Hidden from '@material-ui/core/Hidden';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { BASE_URL } from '../../../../config/config';

const STRATEGY = [
  {
    key: 'CONTENT_SECTION_ONE',
    name: 'Brand Positioning',
    subtext: 'Evaluate Brand performance',
    image: `${BASE_URL}/tab_brandstrategy_icon1.png`,
    alt: 'Brand position image domaincer',
  },
  {
    key: 'CONTENT_SECTION_TWO',
    name: 'Marketing Strategy',
    subtext: 'Understand Brand reputation',
    image: `${BASE_URL}/tab_brandstrategy_icon2.png`,
    alt: 'Marketing strategy image domaincer',
  },
  {
    key: 'CONTENT_SECTION_THREE',
    name: 'Advertising Strategy',
    subtext: 'Showcase your Brand',
    image: `${BASE_URL}/tab_brandstrategy_icon3.png`,
    alt: 'Advertising strategy image domaincer',
  },
];

const ServiceContentSection = () => {
  const [activeTabService, setActiveTabService] = useState(
    'CONTENT_SECTION_ONE'
  );

  return (
    <div>
      <div style={{ paddingTop: 100, paddingBottom: 30 }}>
        <h1 className='service_subheading_strategy'>About Brand Strategy</h1>
        <h2 className='service_subtext_strategy'>
          We believe that brand strategy + Brand identity is an essential
          foundation for success. At Domaincer, we bring together the right mix
          of people, with the right technical and strategic knowledge to help
          you make an impact.
        </h2>
      </div>
      {/* visible only on desktop, laptop */}
      <Hidden only={['xs']}>
        <div className='subServiceTabSection_strategy'>
          {STRATEGY.map((service, index) => (
            <div
              className='subServiceContaincer_strategy'
              style={{
                borderColor:
                  service.key === activeTabService ? '#0077FF' : '#FFF',
              }}
              onClick={() => setActiveTabService(service.key)}
            >
              <img
                className='subServiceImg_strategy'
                src={service.image}
                alt={service.alt}
              />
              <div>
                <h1 className='subServiceTitle_strategy'>{service.name}</h1>
                <h2 className='subServiceSubTitle_strategy'>
                  {service.subtext}
                </h2>
              </div>
            </div>
          ))}
        </div>

        <div>
          {activeTabService === 'CONTENT_SECTION_ONE' && (
            <div className='subServiceParaContainer_strategy'>
              <Container
                maxWidth='lg'
                className='subServiceFixedSection_strategy'
              >
                <div className='glowEffectBrand_strategy'></div>

                <div className='serviceContent_strategy'>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <h3 className='subServicePara_strategy'>
                        Our years of experience in the industry allows us to
                        study and evaluate your strategic outlook, identify gaps
                        in branding, and recommend revisions that will allow you
                        to capitalize on the power of your brand fully.
                      </h3>
                    </Grid>
                  </Grid>
                </div>
              </Container>
            </div>
          )}
          {activeTabService === 'CONTENT_SECTION_TWO' && (
            <div className='subServiceParaContainer_strategy'>
              <Container
                maxWidth='lg'
                className='subServiceFixedSection_strategy'
              >
                <div className='glowEffectDigital_strategy'></div>

                <div className='serviceContent_strategy'>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <h3 className='subServicePara_strategy'>
                        We promise to work with you all the way to deploy a
                        holistic and formidable online presence and build a
                        cohesive digital strategy for building your brand
                        reputation, generating new leads and grooming them into
                        a loyal customer base.
                      </h3>
                    </Grid>
                  </Grid>
                </div>
              </Container>
            </div>
          )}
          {activeTabService === 'CONTENT_SECTION_THREE' && (
            <div className='subServiceParaContainer_strategy'>
              <Container
                maxWidth='lg'
                className='subServiceFixedSection_strategy'
              >
                <div className='glowEffectWorkFlow_strategy'></div>

                <div className='serviceContent_strategy'>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <h3 className='subServicePara_strategy'>
                        Our brand strategist can help you build more
                        relationships, reach out to more people and sell more
                        products. Our team assists you in building extensive
                        relationships, reaching out to targeted people and
                        creating a loyal customer base.
                      </h3>
                    </Grid>
                  </Grid>
                </div>
              </Container>
            </div>
          )}
        </div>
      </Hidden>

      {/* visible only on mobile */}
      <Hidden only={['sm', 'lg', 'md', 'xl']}>
        <div style={{ position: 'relative' }}>
          <ArrowBackIosIcon
            className='subServiceTabLeftArrowHome'
            onClick={() => {
              document.getElementById('strategyScroll').scrollLeft -= 400;
            }}
          />
          <div className='subServiceTabSection_strategy' id='strategyScroll'>
            <div>
              <div className='subServiceContaincer_strategy'>
                <img
                  className='subServiceImg_strategy'
                  src={`${BASE_URL}/tab_brandstrategy_icon1.png`}
                  alt='Brand position image domaincer'
                />
                <div>
                  <h1 className='subServiceTitle_strategy'>
                    Brand Positioning
                  </h1>
                  <h2 className='subServiceSubTitle_strategy'>
                    Evaluate Brand performance
                  </h2>
                </div>
              </div>
              <div className='subServiceParaContainer_strategy'>
                <Container
                  maxWidth='lg'
                  className='subServiceFixedSection_strategy'
                >
                  <div className='glowEffectBrand_strategy'></div>

                  <div className='serviceContentOne_strategy'>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <h3 className='subServicePara_strategy'>
                          Our years of experience in the industry allows us to
                          study and evaluate your strategic outlook, identify
                          gaps in branding, and recommend revisions that will
                          allow you to capitalize on the power of your brand
                          fully.
                        </h3>
                      </Grid>
                    </Grid>
                  </div>
                </Container>
              </div>
            </div>
            <div>
              <div className='subServiceContaincer_strategy'>
                <img
                  className='subServiceImg_strategy'
                  src={`${BASE_URL}/tab_brandstrategy_icon2.png`}
                  alt='Marketing strategy image domaincer'
                />
                <div>
                  <h1 className='subServiceTitle_strategy'>
                    Marketing Strategy
                  </h1>
                  <h2 className='subServiceSubTitle_strategy'>
                    Understand Brand reputation
                  </h2>
                </div>
              </div>
              <div className='subServiceParaContainer_strategy'>
                <Container
                  maxWidth='lg'
                  className='subServiceFixedSection_strategy'
                >
                  <div className='glowEffectDigital_strategy'></div>

                  <div className='serviceContentTwo_strategy'>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <h3 className='subServicePara_strategy'>
                          We promise to work with you all the way to deploy a
                          holistic and formidable online presence and build a
                          cohesive digital strategy for building your brand
                          reputation, generating new leads and grooming them
                          into a loyal customer base.
                        </h3>
                      </Grid>
                    </Grid>
                  </div>
                </Container>
              </div>
            </div>
            <div>
              <div className='subServiceContaincer_strategy'>
                <img
                  className='subServiceImg_strategy'
                  src={`${BASE_URL}/tab_brandstrategy_icon3.png`}
                  alt='Advertising strategy image domaincer'
                />
                <div>
                  <h1 className='subServiceTitle_strategy'>
                    Advertising Strategy
                  </h1>
                  <h2 className='subServiceSubTitle_strategy'>
                    Showcase your Brand
                  </h2>
                </div>
              </div>
              <div className='subServiceParaContainer_strategy'>
                <Container
                  maxWidth='lg'
                  className='subServiceFixedSection_strategy'
                >
                  <div className='glowEffectWorkFlow_strategy'></div>

                  <div className='serviceContentThree_strategy'>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <h3 className='subServicePara_strategy'>
                          Our brand strategist can help you build more
                          relationships, reach out to more people and sell more
                          products. Our team assists you in building extensive
                          relationships, reaching out to targeted people and
                          creating a loyal customer base.
                        </h3>
                      </Grid>
                    </Grid>
                  </div>
                </Container>
              </div>
            </div>
          </div>
          <ArrowForwardIosIcon
            className='subServiceTabRightArrowHome'
            onClick={() => {
              document.getElementById('strategyScroll').scrollLeft += 400;
            }}
          />
        </div>
      </Hidden>
    </div>
  );
};

export default ServiceContentSection;
