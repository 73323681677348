import React, { useState, useEffect } from "react";
import "./blogsectionhome.css";
import { Container, Grid, Button, Hidden } from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Skeleton from "react-loading-skeleton";
import LazyLoad from "react-lazyload";
import styled, { keyframes } from "styled-components";
import AOS from "aos";

const ImageWrapper = styled.div`
  position: relative;
`;

const Placeholder = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
`;

const BlogSectionMollie = () => {
  const [posts, setPosts] = useState([]);
  const [showDomaincerBlogTag, setshowDomaincerBlogTag] = useState({
    id: "",
  });
  const [skeletonLoader, setskeletonLoader] = useState(true);
  const refPlaceholder = React.useRef();

  const removePlaceholder = () => {
    refPlaceholder.current.remove();
    setskeletonLoader(false);
  };

  useEffect(() => {
    fetch("https://domaincer.com/blog/getlatestpost.php")
      .then((res) => res.json())
      .then((json) => {
        const data = json;
        setPosts(data);
        // const data = json.reverse();
        // const newData = data.slice(0, 6);
        // setPosts(newData);
      }).catch((err)=> console.log(err))
    AOS.init();
  }, []);

  const capitalize = (str) => {
    const newStr = str.replace(/-/g, " ");
    return newStr.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  return (


    <div
      className="home_blogSection"
      data-aos="fade-up"
      data-aos-duration="3000"
    >
      <Container>
        <div className="home_blogHeaderContainer">
          <div className="home_blogTitleRow">
            <h1 className="home_blogHeading">Blog</h1>
            <Hidden only={["xs"]}>
              <div
                style={{
                  alignSelf: "center",
                  textAlign: "center",
                  marginTop: 10,
                  marginLeft: 20,
                }}
              >
                <ChevronLeftIcon
                  onClick={() => {
                    document.getElementById("homeBlogScroll").scrollLeft -= 400;
                  }}
                  className="leftCaroselIcon"
                />
              </div>
              <div
                style={{
                  alignSelf: "center",
                  textAlign: "center",
                  marginTop: 10,
                }}
              >
                <ChevronRightIcon
                  onClick={() => {
                    document.getElementById("homeBlogScroll").scrollLeft += 400;
                  }}
                  className="rightCaroselIcon"
                />
              </div>
            </Hidden>
          </div>
          <h1 className="home_blogViewAll">
            <a href="https://domaincer.com/blog/" target="_blank">
              View all
            </a>
          </h1>
        </div>
      </Container>
      <div className="home_blogSectionContainer">
        <div className="blogMiddleSection" id="homeBlogScroll">
          {posts.map((post, index) => (
            <Grid
              item
              xs={12}
              sm={4}
              data-aos="fade-right"
              data-aos-duration="1000"
              key={index}
            >
              <a href={post.link} target="_blank">
                <div className="home_blogSectionCardContainer">
                  <div>
                    <React.Fragment>
                      {skeletonLoader === true && (
                        <Skeleton width="100%" height="250px" />
                      )}
                      <ImageWrapper>
                        <Placeholder ref={refPlaceholder} />
                        <LazyLoad>
                          <img
                            alt=""
                            src={post.image}
                            className="blogSectionImg"
                            onLoad={removePlaceholder}
                            onError={removePlaceholder}
                          />
                        </LazyLoad>
                      </ImageWrapper>
                    </React.Fragment>
                  </div>
                  <h1 className="blogSectionHeading_home">
                    {capitalize(post.slug)}
                  </h1>
                  <h2 className="blogSectionSubText_home">
                    {post.excerpt.substring(0, 108)}...
                  </h2>
                </div>
              </a>
            </Grid>
          ))}
        </div>

        {/* <div style={{ alignSelf: "center", textAlign: "center" }}>
            <ChevronRightIcon
              onClick={() => {
                document.getElementById("homeBlogScroll").scrollLeft += 400;
              }}
              className="rightCaroselIcon"
            />
          </div> */}
      </div>
    </div>
  );
};

export default BlogSectionMollie;
