import React from 'react';
import "../digitalTransformation.css";
import { Container, Grid } from '@material-ui/core';
import Particles from 'react-particles-js';

const HowServiceWillHelpYou = () => {
  return (
    <div>
      <Grid container>
        <Particles
          params={{
            particles: {
              number: {
                value: 350,
              },
              size: {
                value: 1,
              },
              color: {
                value: '#fff',
              },
              line_linked: {
                enable: false,
                distance: 120,
                color: '#fff',
                width: 1,
              },
            },
            interactivity: {
              events: {
                onhover: {
                  enable: true,
                  mode: 'repulse',
                },
              },
            },
          }}
          className='digital_particalsSection'
        />
        <Grid
          xs={12}
          sm={12}
          style={{ zIndex: -1, position: 'relative', textAlign: 'center' }}
        >
          <div className='digital_BSHelpYouRightSection'>
            <h1 className='digital_BSHelpYouHeading'>
              How digital transformation will help you Business?
            </h1>
            <h2
              className='digital_BSHelpYouPara'
              data-aos='fade-up'
              data-aos-duration='1000'
            >
              Evoluation of business models and customer expecations challenge are resolved
              by digital transformation. Our insight-led, unique, and mindful thinking process
              helps organizations realize Digital Capital from business outcomes.
            </h2>
            <div
              data-aos='fade-up'
              data-aos-duration='1000'
              className='digital_circleBSHelpYou'
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default HowServiceWillHelpYou;
