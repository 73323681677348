import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "../index.css";
import ScrollToTop from "./scrolltop";
//guards
import DefaultLayout from "../guards/defaultlayout";
//Pages
import HomePage from "../pages/homepage/home.component";
import ContactUs from "../pages/contactus/contactus.component";
import AboutUs from "../pages/aboutus/aboutus.component";
import QuotePage from "../pages/quotepage/quotepage.component";
import LoaderComponent from "../pages/loader/loader.component";
//Service-Pages
import BrandStrategy from "../pages/services/brandStrategy_service/brandstrategy.component";
import DigitalTransformation from "../pages/services/digitalTransformation_service/digitalTransformation.component";
import WorkflowAutomation from "../pages/services/workflowAutomation_service/workflowAutomation.component";
import BrandingService from "../pages/services/branding_service/branding.component";
import UiUxService from "../pages/services/uiux_service/uiux.component";
import MotionGraphics from "../pages/services/motionGraphics_service/graphics.component";
import MobileApp from "../pages/services/mobileApp_service/mobileapp.component";
import Portal from "../pages/services/portal_service/portal.component";
import Website from "../pages/services/website_service/website.component";
import DevOps from "../pages/services/devops_service/devops.component";
import SocialMediaMarketing from "../pages/services/socialmediamarketing_service/socialmediamarketing.component";
import SearchEngineOptimisation from "../pages/services/searchengineoptimisation_service/searchengineoptimisation.component";
import CaseStudyRyzone from "../pages/casestudies/casestudy_ryzone/casestudy_ryzone.component";
import CaseStudyFpmarkets from "../pages/casestudies/casestudy_fpmarkets/casestudy_fpmarkets.component";
import CaseStudyKKRice from "../pages/casestudies/casestudy_kkrice/casestudy_kkrice.component";
import CaseStudyPraxisHolidays from "../pages/casestudies/casestudy_praxisholidays/casestudy_praxisholidays.component";
import GetQuoteThankyouPage from "../pages/quotepage/thankyouPage/thankyouPage.component";
import PageNotFoundComponent from "../pages/404Page/pageNotFound.component";
import NewQuotePage from "../pages/newQuotePage/new_quotePage.component";
import QuoteThankYou from "../pages/thankyoupage/quotePageThanku.component";
// new components
import Metaverse from "../pages/services/metaverse/metaverse.component";
import Blockchain from "../pages/services/blockchain/blockchain.component";
import UserAgreementComponent from "../pages/user-agreement/user.agreement.component";
import PrivacyStatementComponent from "../pages/user-agreement/privacy.statement.component";
import CookiesPolicyStatement from "../pages/user-agreement/cookies.statement.component";

export default class Routes extends Component {
  componentDidMount() {
    const cursorOuter = document.querySelector(".cursor-outer");
    document.addEventListener("mousemove", (e) => {
      cursorOuter.setAttribute(
        "style",
        "top: " + (e.pageY - 15) + "px; left: " + (e.pageX - 15) + "px; "
      );
    });
    const cursorInner = document.querySelector(".cursor-inner");
    document.addEventListener("mousemove", (e) => {
      cursorInner.setAttribute(
        "style",
        "top: " + (e.pageY - 3) + "px; left: " + (e.pageX - 3) + "px; "
      );
    });
  }

  render() {
    return (
      <div>
        <div className="cursor-outer"></div>
        <div className="cursor-inner"></div>
        <Router>
          <ScrollToTop>
            <Switch>
              <Route path="/loader" component={LoaderComponent} />
              <DefaultLayout path="/" component={HomePage} exact />
              <DefaultLayout
                path="/best-digital-agency"
                component={AboutUs}
                exact
              />
              <DefaultLayout
                path="/contact-digital-marketing-agency"
                component={ContactUs}
                exact
              />
              <DefaultLayout
                path="/case-study-ryzone"
                component={CaseStudyRyzone}
                exact
              />
              <DefaultLayout
                path="/case-study-kkrice"
                component={CaseStudyKKRice}
                exact
              />
              <DefaultLayout
                path="/case-study-fpmarkets"
                component={CaseStudyFpmarkets}
                exact
              />
              <DefaultLayout
                path="/case-study-praxis-holidays"
                component={CaseStudyPraxisHolidays}
                exact
              />
              <DefaultLayout
                path="/brand-strategy-company"
                component={BrandStrategy}
                exact
              />
              <DefaultLayout
                path="/digital-transformation-company"
                component={DigitalTransformation}
                exact
              />
              <DefaultLayout
                path="/workflow-automation-company"
                component={WorkflowAutomation}
                exact
              />
              <DefaultLayout
                path="/best-branding-company"
                component={BrandingService}
                exact
              />
              <DefaultLayout
                path="/ui-ux-design"
                component={UiUxService}
                exact
              />
              <DefaultLayout
                path="/motion-graphics-design"
                component={MotionGraphics}
                exact
              />
              <DefaultLayout
                path="/mobile-app-development-company"
                component={MobileApp}
                exact
              />
              <DefaultLayout
                path="/web-portal-development"
                component={Portal}
                exact
              />
              <DefaultLayout
                path="/website-development-company"
                component={Website}
                exact
              />
              <DefaultLayout
                path="/devops-consulting-company"
                component={DevOps}
                exact
              />
              <DefaultLayout
                path="/social-media-marketing-service"
                component={SocialMediaMarketing}
                exact
              />
              <DefaultLayout
                path="/search-engine-optimization-service"
                component={SearchEngineOptimisation}
                exact
              />
              <DefaultLayout
                path="/blockchain-service"
                component={Blockchain}
                exact
              />
              <DefaultLayout
                path="/metaverse-service"
                component={Metaverse}
                exact
              />
              {/* <DefaultLayout path='/get-quote' component={QuotePage} exact /> */}
              <DefaultLayout path="/get-quote" component={NewQuotePage} exact />
              <DefaultLayout
                path="/get-quote-thank-you"
                component={QuoteThankYou}
                exact
              />
              <DefaultLayout
                path="/user-agreement"
                component={UserAgreementComponent}
                exact
              />
              <DefaultLayout
                path="/privacy-policy"
                component={PrivacyStatementComponent}
                exact
              />
              <DefaultLayout
                path="/cookies-policy"
                component={CookiesPolicyStatement}
                exact
              />
              <DefaultLayout path="" component={PageNotFoundComponent} exact />
            </Switch>
          </ScrollToTop>
        </Router>
      </div>
    );
  }
}
