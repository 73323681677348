import React, { useState, useEffect } from "react";
import "./searchengineoptimisation.css";
import { Container, Grid, Hidden, Paper, Tab, Tabs } from "@material-ui/core";
import AOS from "aos";
import HowServiceWillHelpYouSearchEngine from "./howservicewillhelpyou_searchengine/howservicewillhelpyou_searchengine.component";
import ServiceContentSectionSearchEngine from "./servicecontentsection_searchengine/servicecontentsection_searchengine.component";
import BlogSectionSearchEngine from "./blogsection_searchengine/blogsection_searchengine.component";
import WhyChooseUsSearchEngine from "./whychooseus_searchengine/whychooseus_searchengine.component";
// import OurProcessSearchEngine from './ourprocess_searchengine/ourprocess_searchengine.component';
import CaseStudySection from "../../homepage/casestudyssection/casestudysection.component";
import FollowUs from "../../../commons/followus/followus.component";
import { BASE_URL } from "../../../config/config";

const SearchEngineOptimisation = (props) => {
  const SEO_SLIDER = `${BASE_URL}/service_seo_header_image.png`;

  useEffect(() => {
    AOS.init();
  });
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleTabScroll = (value) => {
    if (value === "OVER_VIEW") {
      window.scrollTo({
        top: 1100,
        behavior: "smooth",
      });
    } else if (value === "BLOG") {
      window.scrollTo({
        top: 1750,
        behavior: "smooth",
      });
    } else if (value === "WHY_DOMAINCER") {
      window.scrollTo({
        top: 2550,
        behavior: "smooth",
      });
    } else if (value === "RECENT_WORKS") {
      window.scrollTo({
        top: 3150,
        behavior: "smooth",
      });
    }
  };

  const handleTabScrollMobile = (value) => {
    if (value === "OVER_VIEW") {
      window.scrollTo({
        top: 1500,
        behavior: "smooth",
      });
    } else if (value === "BLOG") {
      window.scrollTo({
        top: 2300,
        behavior: "smooth",
      });
    } else if (value === "WHY_DOMAINCER") {
      window.scrollTo({
        top: 3200,
        behavior: "smooth",
      });
    } else if (value === "RECENT_WORKS") {
      window.scrollTo({
        top: 4400,
        behavior: "smooth",
      });
    }
  };

  return (
    <div class="mobileMarginTop">
      <FollowUs history={props.history} />
      <Container>
        <Grid container>
          <Grid
            xs={12}
            sm={6}
            className="BSSliderImgContainer_searchengine"
            // data-aos="fade-up"
            data-aos-duration="1000"
          >
            <img
              src={SEO_SLIDER}
              alt="Best SEO image domaincer"
              className="brandStrategySliderImg_searchengine floatingUpDown"
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <div
              className="BSSliderRightSection_searchengine"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <h1 className="sliderHeading_searchengine">
                Search Engine Optimization
              </h1>
              <h2 className="sliderSubHeading_searchengine">
                <strong>Building strong relationships and links -</strong> Get
                your brand seen by the right people with our top-notch SEO
                strategy! From keyword research and link building to positioning
                yourself as an authority in your field, we'll help you craft a
                solid online reputation and increase website visibility to drive
                a steady stream of high-quality organic traffic. With our
                tactics, we'll guide your leads straight to your sales team.
              </h2>
              <Hidden only={["xs"]}>
                <div className="serviceTabsSection_searchengine">
                  <Paper square className="MTabsWidth_searchengine">
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      indicatorColor="primary"
                      textColor="primary"
                      aria-label="tabs example"
                      className="MTabsContainer"
                    >
                      <Tab label="About" className="MTabBtn" />
                      <Tab
                        label="Overview"
                        className="MTabBtn"
                        onClick={() => handleTabScroll("OVER_VIEW")}
                      />
                      <Tab
                        label="Blog"
                        className="MTabBtn"
                        onClick={() => handleTabScroll("BLOG")}
                      />
                      <Tab
                        label="Why Domaincer"
                        className="MTabBtn"
                        onClick={() => handleTabScroll("WHY_DOMAINCER")}
                      />
                      {/* <Tab
                        label="Recent Works"
                        className="MTabBtn"
                        onClick={() => handleTabScroll("RECENT_WORKS")}
                      /> */}
                    </Tabs>
                  </Paper>
                </div>
              </Hidden>
              <Hidden only={["sm", "md", "lg", "xl"]}>
                <div className="serviceTabsSection_searchengine">
                  <Paper square className="MTabsWidth_searchengine">
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      indicatorColor="primary"
                      textColor="primary"
                      aria-label="tabs example"
                      className="MTabsContainer"
                    >
                      <Tab label="About" className="MTabBtn" />
                      <Tab
                        label="Overview"
                        className="MTabBtn"
                        onClick={() => handleTabScrollMobile("OVER_VIEW")}
                      />
                      <Tab
                        label="Blog"
                        className="MTabBtn"
                        onClick={() => handleTabScrollMobile("BLOG")}
                      />
                      <Tab
                        label="Why Domaincer"
                        className="MTabBtn"
                        onClick={() => handleTabScrollMobile("WHY_DOMAINCER")}
                      />
                      {/* <Tab
                        label="Recent Works"
                        className="MTabBtn"
                        onClick={() => handleTabScrollMobile("RECENT_WORKS")}
                      /> */}
                    </Tabs>
                  </Paper>
                </div>
              </Hidden>
            </div>
          </Grid>
        </Grid>
      </Container>
      <HowServiceWillHelpYouSearchEngine />
      <ServiceContentSectionSearchEngine />
      <BlogSectionSearchEngine />
      <WhyChooseUsSearchEngine />
      {/* <OurProcessSearchEngine /> */}
      {/* <CaseStudySection /> */}
    </div>
  );
};

export default SearchEngineOptimisation;
