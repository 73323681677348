import React from "react";
import "../branding.css";
import { Container, Grid, Card } from "@material-ui/core";
import Hidden from "@material-ui/core/Hidden";
import { BASE_URL } from "../../../../config/config";

const WhyChooseUs = () => {
  const WHY_CHOOSE_US_IMAGE = `${BASE_URL}/about_why_choose_us.png`;

  return (
    <div className="branding_whyChooseUsSection">
      <Container>
        <h1 className="branding_whyChooseUsTitle">Why Domaincer</h1>
        <Grid container spacing={2} style={{ justifyContent: "center" }}>
          <Grid item xs={12} sm={3}>
            <div
              className="branding_whyChooseColumnUpContainer"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <h2 className="branding_chooseTitle">Expertise</h2>
              <h3 className="branding_choosePara">
                Enhance your brand's connection with customers through
                emotionally impactful branding, design, and communication
                tactics.
              </h3>
            </div>
            <div
              className="branding_whyChooseColumnDownContainer"
              data-aos="fade-right"
              data-aos-duration="1400"
            >
              <h2 className="branding_chooseTitle">Creativity</h2>
              <h3 className="branding_choosePara">
                Enhance your brand's impact with our emotional and creative
                approach to communication. We expertly craft magic and
                connection with your audience.
              </h3>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            className="branding_about__chooseUsImageRow"
          >
            <img
              src={WHY_CHOOSE_US_IMAGE}
              alt="why choose us image 3d domaincer"
              className="branding_about_chooseUsMiddleImg floatingUpDown"
            />
          </Grid>

          <Hidden only={["sm", "lg", "md", "xl"]}>
            <Grid item xs={12} sm={3}>
              <div
                className="branding_whyChooseColumn3UpContainer"
                data-aos="fade-up"
                data-aos-duration="1200"
              >
                <h2 className="branding_chooseTitle">Premium Designs</h2>
                <h3 className="branding_choosePara">
                  Effortlessly Stylish Designs that are Hard to Look Away From.
                  We make a Visually Striking Impression on your Prospects.
                </h3>
              </div>
              <div
                className="branding_whyChooseColumn3DownContainer"
                data-aos="fade-up"
                data-aos-duration="1400"
              >
                <h2 className="branding_chooseTitle">Research</h2>
                <h3 className="branding_choosePara">
                  Dominate your industry and increase market share with our
                  market-leading research strategy. Win the game, don't just
                  stay in it
                </h3>
              </div>
            </Grid>
          </Hidden>
          <Hidden only={["xs"]}>
            <Grid item xs={12} sm={3}>
              <div
                className="branding_whyChooseColumn3UpContainer"
                data-aos="fade-left"
                data-aos-duration="1200"
              >
                <h2 className="branding_chooseTitle">Premium Designs</h2>
                <h3 className="branding_choosePara">
                  Effortlessly Stylish Designs that are Hard to Look Away From.
                  We make a Visually Striking Impression on your Prospects.
                </h3>
              </div>
              <div
                className="branding_whyChooseColumn3DownContainer"
                data-aos="fade-left"
                data-aos-duration="1600"
              >
                <h2 className="branding_chooseTitle">Research</h2>
                <h3 className="branding_choosePara">
                  Dominate your industry and increase market share with our
                  market-leading research strategy. Win the game, don't just
                  stay in it
                </h3>
              </div>
            </Grid>
          </Hidden>
        </Grid>
      </Container>
    </div>
  );
};
export default WhyChooseUs;
