import React from "react";
import "./statements.style.css";

const UserAgreementComponent = () => {
  return (
    <div className="user_agreement_container">
      <div className="user_agreement_inner_container">
        <p className="user_agreement_header">User Agreement</p>
        <div className="user_agreement_content_con">
          <div className="user_agreement_inital_container">
            <p className="user_agreement_content_para">
              By accessing or using the website of Domaincer (hereinafter
              referred to as "we," "us," "ShootUp Technologies Pvt Ltd," or
              "our"), you (hereinafter referred to as "user" or "you") agree to
              be bound by the terms and conditions set forth in this User
              Agreement (hereinafter referred to as "Agreement"). If you do not
              agree to all of the terms and conditions contained in this
              Agreement, do not use this website. We reserve the right, at our
              discretion, to change, modify, add, or remove portions of this
              Agreement at any time. Please check this page periodically for any
              changes. Your continued use of our website following the posting
              of any changes to this Agreement constitutes your acceptance of
              those changes.
            </p>
          </div>

          <div className="user_agreement_content_container">
            <h4 className="user_agreement_content_header">1. Services</h4>
            <p className="user_agreement_content_para">
              Our website provides a variety of digital marketing and design
              services, including but not limited to branding, UI/UX design,
              motion graphics, mobile app development, web portal development,
              website development, DevOps, blockchain solutions, social media
              marketing, and search engine optimization (collectively, the
              "Services").
            </p>
          </div>
          <div className="user_agreement_content_container">
            <h4 className="user_agreement_content_header">
              2. Use of our website
            </h4>
            <p className="user_agreement_content_para">
              You may use our website for lawful purposes only. You may not use
              our website to engage in any illegal or unauthorized activity. You
              also agree not to access or attempt to access, the website by any
              means other than through the interface provided by us.
            </p>
          </div>
          <div className="user_agreement_content_container">
            <h4 className="user_agreement_content_header">
              3. Intellectual property
            </h4>
            <p className="user_agreement_content_para">
              All content included on our website, such as text, graphics,
              logos, images, and software, is the property of Domaincer or its
              content suppliers and protected by Indian and international
              copyright and trademark laws. The compilation of all content on
              our website is the exclusive property of Domaincer and is
              protected by Indian and international copyright laws.
            </p>
          </div>
          <div className="user_agreement_content_container">
            <h4 className="user_agreement_content_header">
              4. Limitation of liability
            </h4>
            <p className="user_agreement_content_para">
              Domaincer shall not be liable for any damages whatsoever, and in
              particular, Domaincer shall not be liable for any special,
              indirect, consequential, or incidental damages, or damages for
              lost profits, loss of revenue, or loss of use, arising out of or
              related to this website or the information contained in it,
              whether such damages arise in contract, negligence, tort, under
              the statute, in equity, at law, or otherwise, even if Domaincer
              has been advised of the possibility of such damages.
            </p>
          </div>
          <div className="user_agreement_content_container">
            <h4 className="user_agreement_content_header">
              5. Indemnification
            </h4>
            <p className="user_agreement_content_para">
              You agree to indemnify and hold Domaincer and its affiliates,
              officers, agents, and employees, harmless from any claim or
              demand, including reasonable attorneys' fees, made by any third
              party due to or arising out of your use of our website, your
              violation of this Agreement, or your violation of any rights of
              another.
            </p>
          </div>
          <div className="user_agreement_content_container">
            <h4 className="user_agreement_content_header">6. Governing law</h4>
            <p className="user_agreement_content_para">
              This Agreement and your use of our website shall be governed by
              and construed in accordance with the laws of the State of Tamil
              Nadu, India, without giving effect to any principles of conflicts
              of law.
            </p>
          </div>
          <div className="user_agreement_content_container">
            <h4 className="user_agreement_content_header">7. Sevearibility</h4>
            <p className="user_agreement_content_para">
              If any provision of this Agreement is found to be invalid or
              unenforceable, that provision shall be enforced to the maximum
              extent possible, and the remaining provisions shall remain in full
              force and effect.
            </p>
          </div>
          <div className="user_agreement_content_container">
            <h4 className="user_agreement_content_header">8. No waiver</h4>
            <p className="user_agreement_content_para">
              The failure of Domaincer to exercise or enforce any right or
              provision of this Agreement shall not constitute a waiver of such
              right or provision.
            </p>
          </div>

          <div className="user_agreement_content_container">
            <h4 className="user_agreement_content_header">
              9. Entire agreement
            </h4>
            <p className="user_agreement_content_para">
              This Agreement constitutes the entire agreement between you and
              Domaincer and governs your use of our website, superseding any
              prior agreements between you and Domaincer (including, but not
              limited to, any prior versions of the Agreement).
            </p>
          </div>

          <div className="user_agreement_content_container">
            <h4 className="user_agreement_content_header">
              10. Contact information
            </h4>
            <p className="user_agreement_content_para">
              If you have any questions or concerns regarding this Agreement,
              please contact us at support@domaincer.com
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserAgreementComponent;
