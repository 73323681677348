import React, { useState, useEffect, Fragment } from "react";
import "./website.css";
import {
  Container,
  Button,
  Modal,
  Grid,
  Hidden,
  Paper,
  Tab,
  Tabs,
} from "@material-ui/core";
import AOS from "aos";
import HowServiceWillHelpYouWebsite from "./howservicewillhelpyou_website/howservicewillhelpyou_website.component";
import ServiceContentSectionWebsite from "./servicecontentsection_website/servicecontentsection_website.component";
import BlogSectionWebsite from "./blogsection_website/blogsection_website.component";
import WhyChooseUsWebsite from "./whychooseus_website/whychooseus_website.component";
import WebsiteStats from "./website_statsSection/websiteStats_section";
import WebsiteSpecializedIn from "./website_specializedIn/website_specialized";
import WebsiteDeploymentPartners from "./website_deployment/website_deployment";
// import OurProcessWebsite from './ourprocess_website/ourprocess_website.component';
import CaseStudySection from "../../homepage/casestudyssection/casestudysection.component";
import FollowUs from "../../../commons/followus/followus.component";
import { BASE_URL } from "../../../config/config";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";

const Website = (props) => {
  const WEBSITE_SLIDER = `${BASE_URL}/service_website_header_image.png`;
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    AOS.init();
  });
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleTabScroll = (value) => {
    if (value === "OVER_VIEW") {
      window.scrollTo({
        top: 1100,
        behavior: "smooth",
      });
    } else if (value === "BLOG") {
      window.scrollTo({
        top: 2500,
        behavior: "smooth",
      });
    } else if (value === "WHY_DOMAINCER") {
      window.scrollTo({
        top: 2900,
        behavior: "smooth",
      });
    } else if (value === "RECENT_WORKS") {
      window.scrollTo({
        top: 3600,
        behavior: "smooth",
      });
    } else if (value === "STATS") {
      window.scrollTo({
        top: 4100,
        behavior: "smooth",
      });
    }
  };

  const handleTabScrollMobile = (value) => {
    if (value === "OVER_VIEW") {
      window.scrollTo({
        top: 1550,
        behavior: "smooth",
      });
    } else if (value === "BLOG") {
      window.scrollTo({
        top: 3300,
        behavior: "smooth",
      });
    } else if (value === "WHY_DOMAINCER") {
      window.scrollTo({
        top: 3800,
        behavior: "smooth",
      });
    } else if (value === "RECENT_WORKS") {
      window.scrollTo({
        top: 5300,
        behavior: "smooth",
      });
    } else if (value === "STATS") {
      window.scrollTo({
        top: 6520,
        behavior: "smooth",
      });
    }
  };

  return (
    <div class="mobileMarginTop">
      <FollowUs history={props.history} />
      <Container>
        <Grid container>
          <Grid
            xs={12}
            sm={6}
            className="BSSliderImgContainer_website"
            // data-aos="fade-up"
            data-aos-duration="1000"
          >
            <img
              src={WEBSITE_SLIDER}
              alt="Best website development image domaincer"
              className="brandStrategySliderImg_website floatingUpDown"
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <div
              className="BSSliderRightSection_website"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <h1 className="sliderHeading_website">Website Development</h1>
              <h2 className="sliderSubHeading_website">
                <strong>Innovative Trailblazers -</strong> Scale up your success
                with our web development expertise! From enterprise solutions to
                e-commerce to custom web development, we use cutting-edge
                technologies and trend-forward thinking to boost the efficiency
                and profitability of your business. Let us help you soar to new
                heights with top-notch mobile backend and API services, and
                streamlined content management systems. Don't just stand on the
                sidelines - take your business to the next level with us.
              </h2>
              <Button className="video_play" onClick={handleOpen}>
                <PlayCircleOutlineIcon />
                <span style={{ paddingLeft: 5, paddingRight: 5 }}>
                  Watch video
                </span>
              </Button>
              <Modal open={open} onClose={handleClose}>
                <Fragment>
                  <div className="video_closeIcon_container">
                    <CloseOutlinedIcon
                      onClick={handleClose}
                      className="video_closeIcon"
                    ></CloseOutlinedIcon>
                  </div>
                  <div className="video_Second_container">
                    <iframe
                      width="760"
                      height="480"
                      className="video_iframe"
                      src="https://www.youtube.com/embed/HGG4K9jxzKg?rel=0&autoplay=1&cc_load_policy=1"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                  </div>
                </Fragment>
              </Modal>
              <Hidden only={["xs"]}>
                <div className="serviceTabsSection_website">
                  <Paper square className="MTabsWidth_website">
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      indicatorColor="transparent"
                      textColor="primary"
                      aria-label="tabs example"
                      className="MTabsContainer"
                    >
                      <Tab label="About" className="MTabBtn" />
                      <Tab
                        label="Overview"
                        className="MTabBtn"
                        onClick={() => handleTabScroll("OVER_VIEW")}
                      />
                      <Tab
                        label="Blog"
                        className="MTabBtn"
                        onClick={() => handleTabScroll("BLOG")}
                      />
                      <Tab
                        label="Why Domaincer"
                        className="MTabBtn"
                        onClick={() => handleTabScroll("WHY_DOMAINCER")}
                      />
                      {/* <Tab
                        label='Recent Works'
                        className='MTabBtn'
                        onClick={() => handleTabScroll('RECENT_WORKS')}
                      /> */}
                      <Tab
                        label="Stats"
                        className="MTabBtn"
                        onClick={() => handleTabScroll("STATS")}
                      />
                    </Tabs>
                  </Paper>
                </div>
              </Hidden>
              <Hidden only={["sm", "md", "lg", "xl"]}>
                <div className="serviceTabsSection_website">
                  <Paper square className="MTabsWidth_website">
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      indicatorColor="primary"
                      textColor="primary"
                      aria-label="tabs example"
                      className="MTabsContainer"
                    >
                      <Tab label="About" className="MTabBtn" />
                      <Tab
                        label="Overview"
                        className="MTabBtn"
                        onClick={() => handleTabScrollMobile("OVER_VIEW")}
                      />
                      <Tab
                        label="Blog"
                        className="MTabBtn"
                        onClick={() => handleTabScrollMobile("BLOG")}
                      />
                      <Tab
                        label="Why Domaincer"
                        className="MTabBtn"
                        onClick={() => handleTabScrollMobile("WHY_DOMAINCER")}
                      />
                      {/* <Tab
                        label='Recent Works'
                        className='MTabBtn'
                        onClick={() => handleTabScrollMobile('RECENT_WORKS')}
                      /> */}
                      <Tab
                        label="Stats"
                        className="MTabBtn"
                        onClick={() => handleTabScrollMobile("STATS")}
                      />
                    </Tabs>
                  </Paper>
                </div>
              </Hidden>
            </div>
          </Grid>
        </Grid>
      </Container>
      <HowServiceWillHelpYouWebsite />
      <ServiceContentSectionWebsite />
      <WebsiteSpecializedIn />
      <WebsiteDeploymentPartners />
      <BlogSectionWebsite />
      <WhyChooseUsWebsite />
      {/* <OurProcessWebsite /> */}
      {/* <CaseStudySection /> */}
      <WebsiteStats />
    </div>
  );
};

export default Website;
