import React, { useState } from "react";
import { Grid, IconButton } from "@material-ui/core";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";

const GetQuoteStepThree = (props) => {
  const [onChangeMobile, setOnChangeMobile] = useState("");
  const [onChangeEmail, setOnChangeEmail] = useState("");
  const [mobErrorStatus, setMobErrorStatus] = useState(false);
  const [emailErrorStatus, setEmailErrorStatus] = useState(false);
  const [mobErrorMsg, setMobErrorMsg] = useState(false);
  const [emailErrorMsg, setEmailErrorMsg] = useState(false);

  const handleSubmit_stepOne = (e) => {
    e.preventDefault();
    const { userDetails } = props;
    if (onChangeMobile === "") {
      setMobErrorMsg(true);
    } else if (onChangeEmail === "") {
      setEmailErrorMsg(true);
    } else {
      const userData = {
        name: userDetails.name,
        mobile: onChangeMobile,
        email: onChangeEmail,
        selectedValue: userDetails.selectedValue,
        selectedServices: props.userDetails.selectedServices,
      };
      props.nextStep([], [], {}, userData, "detailsScreen");
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  return (
    <div className="quotePage__body">
      <React.Fragment>
        <Grid container direction="row" justify="center" spacing={2}>
          <Grid item xs={12} sm={10} className="custom__stepOneGrid">
            <div>
              <h2 className="quote__question">Mention your contact details?</h2>
            </div>
            <div className="phonEmailContainer">
              <div className="textInput__container">
                <input
                  id="my-name"
                  // type='number'
                  placeholder="Your mobile"
                  className={
                    mobErrorStatus === true
                      ? "custom__onChangeInputFieldPhone"
                      : mobErrorMsg === true
                      ? "error__inputFieldPhone"
                      : "custom__inputFieldPhone"
                  }
                  onChange={(e) => {
                    const number = e.target.value;
                    if (
                      number.match(/^(\+\d{1,3}[- ]?)?\d{10}$/) &&
                      !number.match(/0{5,}/)
                    ) {
                      setOnChangeMobile(e.target.value);
                      setMobErrorMsg(false);
                    } else {
                      setMobErrorMsg(true);
                    }
                    if (number.length > 0) {
                      setMobErrorStatus(true);
                    } else {
                      setMobErrorStatus(false);
                    }
                  }}
                />
                {mobErrorMsg && (
                  <p style={{ color: "red", fontSize: 13, marginTop: 7 }}>
                    Please enter a valid mobile number
                  </p>
                )}
              </div>
              <div className="textInput__container">
                <input
                  id="my-name"
                  placeholder="Your e-mail"
                  className={
                    emailErrorStatus === true
                      ? "custom__onChangeInputFieldMail"
                      : emailErrorMsg === true
                      ? "error__inputFieldMail"
                      : "custom__inputFieldMail"
                  }
                  onChange={(e) => {
                    const email = e.target.value;
                    if (
                      email.match(
                        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
                      )
                    ) {
                      setOnChangeEmail(e.target.value);
                      setEmailErrorMsg(false);
                    } else {
                      setEmailErrorMsg(true);
                    }
                    if (email.length > 0) {
                      setEmailErrorStatus(true);
                    } else {
                      setEmailErrorStatus(false);
                    }
                  }}
                />
                {emailErrorMsg && (
                  <p style={{ color: "red", fontSize: 13, marginTop: 7 }}>
                    Please enter a valid email
                  </p>
                )}
              </div>
            </div>

            <div className="nextStep__btnContainer">
              <h5 className="nextStep__btn" onClick={handleSubmit_stepOne}>
                Continue
              </h5>
              <IconButton>
                <DoubleArrowIcon className="nextStep__btnIcon arrowani" />
              </IconButton>
            </div>
          </Grid>
        </Grid>
      </React.Fragment>
    </div>
  );
};

export default GetQuoteStepThree;
