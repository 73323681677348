import React, { useState } from 'react';
import { Grid, IconButton, TextField } from '@material-ui/core';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Hidden from '@material-ui/core/Hidden';
import { BASE_URL } from '../../../../config/config';

const BrandingQAPage = (props) => {
  const BRANDING_SLIDER = `${BASE_URL}/service_branding_header_image.png`;

  Array.prototype.removeValue = function (val) {
    for (var i = 0; i < this.length; i++) {
      if (this[i] === val) {
        this.splice(i, 1);
        i--;
      }
    }
    return this;
  };

  const [pageName] = useState('brandingScreen');
  const [errorStatus, setErrorStatus] = useState(false);
  const [Q1A, setQ1A] = useState('');
  const [Q2A, setQ2A] = useState('');
  const [Q3A, setQ3A] = useState('');
  const [Q4A, setQ4A] = useState('');
  const [Q5A, setQ5A] = useState([]);
  const [Q6A, setQ6A] = useState('');

  const handleClick = (e) => {
    e.preventDefault();
    const { userDetails } = props;
    const qadata = {
      id: 'brandingQA',
      Q1: {
        question: 'Describe about your Brand?',
        answer: Q1A,
      },
      Q2: {
        question: 'Which color suits your brand?',
        answer: Q2A,
      },
      Q3: {
        question: 'What is your Unique Selling Proposition (USP)?',
        answer: Q3A,
      },
      Q4: {
        question: 'Who is your target audience?',
        answer: Q4A,
      },
      Q5: {
        question: 'Type of Logo?',
        answer: Q5A,
      },
      Q6: {
        question: 'Which other brand logo resonates your brand?',
        answer: Q6A,
      },
    };

    if (Q1A === '') {
      setErrorStatus(true);
    } else if (Q2A === '') {
      setErrorStatus(true);
    } else if (Q3A === '') {
      setErrorStatus(true);
    } else if (Q4A === '') {
      setErrorStatus(true);
    } else if (Q5A.length === 0) {
      setErrorStatus(true);
    } else if (Q6A === '') {
      setErrorStatus(true);
    } else {
      const data = props.serviceData.removeValue(pageName);
      props.prevServiceData.push(pageName);
      props.nextStep(
        data,
        props.prevServiceData,
        qadata,
        userDetails,
        pageName
      );
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  const handleBack = (e) => {
    e.preventDefault();
    const data = props.prevServiceData.removeValue(pageName);
    props.prevStep(data);
  };

  return (
    <div className='quotePage__body'>
      <React.Fragment>
        <Grid container direction='row' justify='center' spacing={2}>
          <Grid item xs={12} sm={10} className='custom__stepOneGrid'>
            <div className='prevStep__div'>
              <IconButton>
                <ArrowBackIcon className='prevStep__btn' onClick={handleBack} />
              </IconButton>
            </div>
            <div>
              <h2 className='quote__question'>Branding</h2>
            </div>
            <Grid
              container
              direction='row'
              justify='space-between'
              alignItems='center'
            >
              <Hidden only={['sm', 'lg', 'md', 'xl']}>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  className='animated__gif'
                  style={{ textAlign: 'center' }}
                >
                  <img
                    src={BRANDING_SLIDER}
                    alt='best branding image domaincer'
                    className='getQuote_brandingImage floatingUpDown'
                  />
                </Grid>
              </Hidden>
              <Grid item sm={8} xs={12}>
                <div>
                  <div>
                    <h2 className='service__question'>
                      1. Describe about your Brand
                    </h2>
                    <TextField
                      id='outlined-multiline-static'
                      label='Enter about your brand'
                      multiline
                      rows={3}
                      variant='outlined'
                      className='custom__textField'
                      onChange={(e) => {
                        setErrorStatus(false);
                        setQ1A(e.target.value);
                      }}
                    />
                  </div>

                  <div>
                    <h2 className='service__question'>
                      2. Which color suits your brand?
                    </h2>
                    <TextField
                      id='outlined-multiline-static'
                      label='Enter color of yout brand'
                      multiline
                      rows={1}
                      variant='outlined'
                      className='custom__textField'
                      onChange={(e) => {
                        setErrorStatus(false);
                        setQ2A(e.target.value);
                      }}
                    />
                  </div>
                  <div>
                    <h2 className='service__question'>
                      3. What is your Unique Selling Proposition (USP)?
                    </h2>
                    <TextField
                      id='outlined-multiline-static'
                      label='Enter'
                      multiline
                      rows={1}
                      variant='outlined'
                      className='custom__textField'
                      onChange={(e) => {
                        setErrorStatus(false);
                        setQ3A(e.target.value);
                      }}
                    />
                  </div>
                  <div>
                    <h2 className='service__question'>
                      4. Who is your target audience?
                    </h2>
                    <TextField
                      id='outlined-multiline-static'
                      label='Enter your target audience'
                      multiline
                      rows={1}
                      variant='outlined'
                      className='custom__textField'
                      onChange={(e) => {
                        setErrorStatus(false);
                        setQ4A(e.target.value);
                      }}
                    />
                  </div>
                  <div>
                    <h2 className='service__question'>5. Type of Logo</h2>
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name='checkedLogoDesign'
                            className='custom__checkBox'
                            onChange={(evt, data) => {
                              if (data === true) {
                                setQ5A([...Q5A, 'The emblem']);
                                setErrorStatus(false);
                              } else {
                                const newQ5A = Q5A.removeValue('The emblem');
                                setQ5A(newQ5A);
                              }
                            }}
                          />
                        }
                        label='The emblem'
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name='checkedUIUX'
                            className='custom__checkBox'
                            onChange={(evt, data) => {
                              if (data === true) {
                                setQ5A([...Q5A, 'Mascots']);
                                setErrorStatus(false);
                              } else {
                                const newQ5A = Q5A.removeValue('Mascots');
                                setQ5A(newQ5A);
                              }
                            }}
                          />
                        }
                        label='Mascots'
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name='checkedExplainerVideo'
                            className='custom__checkBox'
                            onChange={(evt, data) => {
                              if (data === true) {
                                setQ5A([...Q5A, 'Wordmarks']);
                                setErrorStatus(false);
                              } else {
                                const newQ5A = Q5A.removeValue('Wordmarks');
                                setQ5A(newQ5A);
                              }
                            }}
                          />
                        }
                        label='Wordmarks'
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name='checkedLogoAnimation'
                            className='custom__checkBox'
                            onChange={(evt, data) => {
                              if (data === true) {
                                setQ5A([...Q5A, 'Pictorial marks']);
                                setErrorStatus(false);
                              } else {
                                const newQ5A = Q5A.removeValue(
                                  'Pictorial marks'
                                );
                                setQ5A(newQ5A);
                              }
                            }}
                          />
                        }
                        label='Pictorial marks'
                      />
                    </FormGroup>
                  </div>
                  <div>
                    <h2 className='service__question'>
                      6. Which other brand logo resonates your brand?
                    </h2>
                    <TextField
                      id='outlined-multiline-static'
                      label='Enter inspiration logo for your brand'
                      multiline
                      rows={1}
                      variant='outlined'
                      className='custom__textField'
                      onChange={(e) => {
                        setErrorStatus(false);
                        setQ6A(e.target.value);
                      }}
                    />
                  </div>
                </div>
                {errorStatus && (
                  <p style={{ color: 'red', fontSize: 13, marginTop: 7 }}>
                    Please fill all the fields
                  </p>
                )}
              </Grid>
              <Hidden only={['xs']}>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  className='animated__gif'
                  style={{ textAlign: 'center' }}
                >
                  <img
                    src={BRANDING_SLIDER}
                    alt='best branding image domaincer'
                    className='getQuote_brandingImage floatingUpDown'
                  />
                </Grid>
              </Hidden>
            </Grid>
            <div className='nextStep__btnContainer'>
              <h5 className='nextStep__btn' onClick={handleClick}>
                Continue
              </h5>
              <IconButton>
                <DoubleArrowIcon className='nextStep__btnIcon arrowani' />
              </IconButton>
            </div>
          </Grid>
        </Grid>
      </React.Fragment>
    </div>
  );
};

export default BrandingQAPage;
