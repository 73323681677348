import React, { useState, useEffect } from "react";
import { Container } from "@material-ui/core";
import "./followus.css";
import YouTubeIcon from "@material-ui/icons/YouTube";
import InstagramIcon from "@material-ui/icons/Instagram";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import FacebookIcon from "@material-ui/icons/Facebook";

const FollowUs = (props) => {
  const [black, setBlack] = useState("followUsTitle");

  const listenScrollEvent = (event) => {

    if (props.history.location.pathname === "/")
      if (window.scrollY > 300 && window.scrollY < 600) {
        return setBlack("followUsTitle2");
      } else if (window.scrollY > 1000 && window.scrollY < 2000) {
        return setBlack("followUsTitle2");
      } else if (window.scrollY > 3000 && window.scrollY < 3800) {
        return setBlack("followUsTitle2");
      } else {
        return setBlack("followUsTitle");
      }
    else if (props.history.location.pathname === "/best-digital-agency") {
      if (window.scrollY > 200 && window.scrollY < 900) {
        return setBlack("followUsTitle2");
      } else if (window.scrollY > 1400 && window.scrollY < 2200) {
        return setBlack("followUsTitle2");
      } else if (window.scrollY > 2800 && window.scrollY < 5500) {
        return setBlack("followUsTitle2");
      } else {
        return setBlack("followUsTitle");
      }
    } else if (
      props.history.location.pathname === "/contact-digital-marketing-agency"
    ) {
      if (window.scrollY > 900 && window.scrollY < 2000) {
        return setBlack("followUsTitle2");
      } else {
        return setBlack("followUsTitle");
      }
    } else if (props.history.location.pathname === "/best-branding-company") {
      if (window.scrollY > 100 && window.scrollY < 600) {
        return setBlack("followUsTitle2");
      } else if (window.scrollY > 1100 && window.scrollY < 1900) {
        return setBlack("followUsTitle2");
      } else if (window.scrollY > 2800 && window.scrollY < 5500) {
        return setBlack("followUsTitle2");
      } else {
        return setBlack("followUsTitle");
      }
    } else if (props.history.location.pathname === "/brand-strategy-company") {
      if (window.scrollY > 100 && window.scrollY < 600) {
        return setBlack("followUsTitle2");
      } else if (window.scrollY > 1100 && window.scrollY < 1900) {
        return setBlack("followUsTitle2");
      } else if (window.scrollY > 2800 && window.scrollY < 5500) {
        return setBlack("followUsTitle2");
      } else {
        return setBlack("followUsTitle");
      }
    } else if (
      props.history.location.pathname === "/devops-consulting-company"
    ) {
      if (window.scrollY > 100 && window.scrollY < 600) {
        return setBlack("followUsTitle2");
      } else if (window.scrollY > 1300 && window.scrollY < 2100) {
        return setBlack("followUsTitle2");
      } else if (window.scrollY > 3200 && window.scrollY < 5500) {
        return setBlack("followUsTitle2");
      } else {
        return setBlack("followUsTitle");
      }
    } else if (
      props.history.location.pathname === "/digital-transformation-company"
    ) {
      if (window.scrollY > 100 && window.scrollY < 600) {
        return setBlack("followUsTitle2");
      } else if (window.scrollY > 1300 && window.scrollY < 2100) {
        return setBlack("followUsTitle2");
      } else if (window.scrollY > 3000 && window.scrollY < 5500) {
        return setBlack("followUsTitle2");
      } else {
        return setBlack("followUsTitle");
      }
    } else if (
      props.history.location.pathname === "/mobile-app-development-company"
    ) {
      if (window.scrollY > 100 && window.scrollY < 3500) {
        return setBlack("followUsTitle2");
      } else if (window.scrollY > 4000 && window.scrollY < 4900) {
        return setBlack("followUsTitle2");
      } else if (window.scrollY > 5600 /* && window.scrollY < 5800 */) {
        return setBlack("followUsTitle2");
      } else {
        return setBlack("followUsTitle");
      }
    } else if (props.history.location.pathname === "/motion-graphics-design") {
      if (window.scrollY > 100 && window.scrollY < 600) {
        return setBlack("followUsTitle2");
      } else if (window.scrollY > 1300 && window.scrollY < 2100) {
        return setBlack("followUsTitle2");
      } else if (window.scrollY > 3000 && window.scrollY < 5500) {
        return setBlack("followUsTitle2");
      } else {
        return setBlack("followUsTitle");
      }
    } else if (props.history.location.pathname === "/web-portal-development") {
      if (window.scrollY > 200 && window.scrollY < 800) {
        return setBlack("followUsTitle2");
      } else if (window.scrollY > 1300 && window.scrollY < 2100) {
        return setBlack("followUsTitle2");
      } else if (window.scrollY > 3000 && window.scrollY < 5500) {
        return setBlack("followUsTitle2");
      } else {
        return setBlack("followUsTitle");
      }
    } else if (
      props.history.location.pathname === "/search-engine-optimization-service"
    ) {
      if (window.scrollY > 200 && window.scrollY < 800) {
        return setBlack("followUsTitle2");
      } else if (window.scrollY > 1300 && window.scrollY < 2100) {
        return setBlack("followUsTitle2");
      } else if (window.scrollY > 3000 && window.scrollY < 5500) {
        return setBlack("followUsTitle2");
      } else {
        return setBlack("followUsTitle");
      }
    } else if (
      props.history.location.pathname === "/social-media-marketing-service"
    ) {
      if (window.scrollY > 200 && window.scrollY < 800) {
        return setBlack("followUsTitle2");
      } else if (window.scrollY > 1300 && window.scrollY < 2100) {
        return setBlack("followUsTitle2");
      } else if (window.scrollY > 3000 && window.scrollY < 5500) {
        return setBlack("followUsTitle2");
      } else {
        return setBlack("followUsTitle");
      }
    } else if (props.history.location.pathname === "/ui-ux-design") {
      if (window.scrollY > 150 && window.scrollY < 700) {
        return setBlack("followUsTitle2");
      } else if (window.scrollY > 1200 && window.scrollY < 2000) {
        return setBlack("followUsTitle2");
      } else if (window.scrollY > 3000 && window.scrollY < 5500) {
        return setBlack("followUsTitle2");
      } else {
        return setBlack("followUsTitle");
      }
    } else if (
      props.history.location.pathname === "/website-development-company"
    ) {
      if (window.scrollY > 200 && window.scrollY < 700) {
        return setBlack("followUsTitle2");
      } else if (window.scrollY > 2000 && window.scrollY < 2500) {
        return setBlack("followUsTitle2");
      } else if (window.scrollY > 3500) {
        return setBlack("followUsTitle2");
      } else {
        return setBlack("followUsTitle");
      }
    } else if (
      props.history.location.pathname === "/workflow-automation-company"
    ) {
      if (window.scrollY > 250 && window.scrollY < 800) {
        return setBlack("followUsTitle2");
      } else if (window.scrollY > 1300 && window.scrollY < 2200) {
        return setBlack("followUsTitle2");
      } else if (window.scrollY > 3100 && window.scrollY < 5500) {
        return setBlack("followUsTitle2");
      } else {
        return setBlack("followUsTitle");
      }
    } else if (props.history.location.pathname === "/case-study-ryzone") {
      if (window.scrollY > 300 && window.scrollY < 800) {
        return setBlack("followUsTitle2");
      } else if (window.scrollY > 1300 && window.scrollY < 1800) {
        return setBlack("followUsTitle2");
      } else if (window.scrollY > 2400 && window.scrollY < 5500) {
        return setBlack("followUsTitle2");
      } else {
        return setBlack("followUsTitle");
      }
    } else if (props.history.location.pathname === "/case-study-fpmarkets") {
      if (window.scrollY > 300 && window.scrollY < 800) {
        return setBlack("followUsTitle2");
      } else if (window.scrollY > 1300 && window.scrollY < 3000) {
        return setBlack("followUsTitle2");
      } else if (window.scrollY > 2400 && window.scrollY < 5500) {
        return setBlack("followUsTitle2");
      } else {
        return setBlack("followUsTitle");
      }
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
  }, []);
  return (
    <Container maxWidth="xl">
      <div className="FollowUsSection">
        <h3 className={black}>Follow Us</h3>
        <p className="spacebar">-</p>
        <div>
          <a
            href="https://www.youtube.com/channel/UC_UC-pKGSDTlC6wtgMVY3kg"
            target="_blank"
            rel="noreferrer"
          >
            <YouTubeIcon className={["followUsIconBtn", black]} />
          </a>
        </div>
        <div>
          <a
            href="https://www.instagram.com/Domaincer/"
            target="_blank"
            rel="noreferrer"
          >
            <InstagramIcon className={["followUsIconBtn", black]} />
          </a>
        </div>
        <div>
          <a
            href="https://www.linkedin.com/company/domaincer/"
            target="_blank"
            rel="noreferrer"
          >
            <LinkedInIcon className={["followUsIconBtn", black]} />
          </a>
        </div>
        <div>
          <a
            href="https://api.whatsapp.com/send?phone=918190007007"
            target="_blank"
            rel="noreferrer"
          >
            <WhatsAppIcon className={["followUsIconBtn", black]} />
          </a>
        </div>
        <div>
          <a
            href="https://www.facebook.com/domaincer"
            target="_blank"
            rel="noreferrer"
          >
            <FacebookIcon className={["followUsIconBtn", black]} />
          </a>
        </div>
      </div>
    </Container>
  );
};

export default FollowUs;
