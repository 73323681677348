import React from "react";
import "../searchengineoptimisation.css";
import { Container, Grid } from "@material-ui/core";
import Particles from "react-particles-js";

const HowServiceWillHelpYouSearchEngine = () => {
  return (
    <div>
      <Grid container>
        <Particles
          params={{
            particles: {
              number: {
                value: 350,
              },
              size: {
                value: 1,
              },
              color: {
                value: "#fff",
              },
              line_linked: {
                enable: false,
                distance: 120,
                color: "#fff",
                width: 1,
              },
            },
            interactivity: {
              events: {
                onhover: {
                  enable: true,
                  mode: "repulse",
                },
              },
            },
          }}
          className="particalsSection_searchengine"
        />
        <Grid
          xs={12}
          sm={12}
          style={{ zIndex: -1, position: "relative", textAlign: "center" }}
        >
          <div className="BSHelpYouRightSection_searchengine">
            <h1 className="BSHelpYouHeading_searchengine">
              Get the #1 Spot in the Digital space
            </h1>
            <h2
              className="BSHelpYouPara_searchengine"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              Stand out in the sea of online searches with our expert search
              position success strategy! With over 40,000 searches happening
              every second on Google, it's crucial to optimize your website for
              the best possible results. From bounce rate and domain age to
              backlinks and search console configuration, we'll help you nail
              all the key parameters to get your brand noticed.
            </h2>
            <div
              data-aos="fade-up"
              data-aos-duration="1000"
              className="circleBSHelpYou_searchengine"
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default HowServiceWillHelpYouSearchEngine;
