import React from "react";
import { Grid, Container } from "@material-ui/core";
import "./newQuotePage.css";
import TypeFormComponent from "./newTypeForm.component";
const NewQuotePage = () => {
  return (
    <div className="newQuotePageContainer">
      <div className="mainTitle_div">
        <h1 className="quote_title">Get Quote</h1>
      </div>
      <TypeFormComponent />
    </div>
  );
};
export default NewQuotePage;
