import React from "react";
import "../graphics.css";
import { Container, Grid } from "@material-ui/core";
import Particles from "react-particles-js";

const HowServiceWillHelpYou = () => {
  return (
    <div>
      <Grid container>
        <Particles
          params={{
            particles: {
              number: {
                value: 350,
              },
              size: {
                value: 1,
              },
              color: {
                value: "#fff",
              },
              line_linked: {
                enable: false,
                distance: 120,
                color: "#fff",
                width: 1,
              },
            },
            interactivity: {
              events: {
                onhover: {
                  enable: true,
                  mode: "repulse",
                },
              },
            },
          }}
          className="graphics_particalsSection"
        />
        <Grid
          xs={12}
          sm={12}
          style={{ zIndex: -1, position: "relative", textAlign: "center" }}
        >
          <div className="graphics_BSHelpYouRightSection">
            <h1 className="graphics_BSHelpYouHeading">
              Transform your message into a visually stunning digital story with
              motion graphics!{" "}
            </h1>
            <h2
              className="graphics_BSHelpYouPara"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              Bring your product or service to life with motion graphics! Our
              team of visual storytellers will paint a vivid picture of your
              offering using the brushstrokes of animation and the magic of
              effects.
            </h2>
            <div
              data-aos="fade-up"
              data-aos-duration="1000"
              className="graphics_circleBSHelpYou"
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default HowServiceWillHelpYou;
