import React from "react";
import "../portal.css";
import { Container, Grid, Card } from "@material-ui/core";
import Hidden from "@material-ui/core/Hidden";
import { BASE_URL } from "../../../../config/config";

const WhyChooseUsPortal = () => {
  const WHY_CHOOSE_US_IMAGE = `${BASE_URL}/about_why_choose_us.png`;

  return (
    <div className="whyChooseUsSection_portal">
      <Container>
        <h1 className="whyChooseUsTitle_portal">Why Domaincer</h1>
        <Grid container spacing={2} style={{ justifyContent: "center" }}>
          <Grid item xs={12} sm={3}>
            <div
              className="whyChooseColumnUpContainer_portal"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <h1 className="chooseTitle_portal">User friendly</h1>
              <h2 className="choosePara_portal">
                Our team creates a strong, user-friendly web presence to make
                you stand out among competitors.
              </h2>
            </div>
            <div
              className="whyChooseColumnDownContainer_portal"
              data-aos="fade-right"
              data-aos-duration="1400"
            >
              <h1 className="chooseTitle_portal">Responsive</h1>
              <h2 className="choosePara_portal">
                We design and develop with the user in mind, creating successful
                online solutions that prioritize their needs and experience.
              </h2>
            </div>
          </Grid>
          <Grid item xs={12} sm={4} className="chooseUsImageRow_portal">
            <img
              src={WHY_CHOOSE_US_IMAGE}
              alt="why choose us image 3d domaincer"
              className="chooseUsMiddleImg_portal floatingUpDown"
            />
          </Grid>

          <Hidden only={["sm", "lg", "md", "xl"]}>
            <Grid item xs={12} sm={3}>
              <div
                className="whyChooseColumn3UpContainer_portal"
                data-aos="fade-up"
                data-aos-duration="1200"
              >
                <h1 className="chooseTitle_portal">Source code rights</h1>
                <h2 className="choosePara_portal">
                  Ensuring the protection of your creative assets through legal
                  ownership and other means is a top priority.
                </h2>
              </div>
              <div
                className="whyChooseColumn3DownContainer_portal"
                data-aos="fade-up"
                data-aos-duration="1400"
              >
                <h2 className="chooseTitle_portal">Outstanding Support</h2>
                <h3 className="choosePara_portal">
                  Achieve success with Domaincer's professional support and
                  top-notch features, covering everything you need from start to
                  finish.
                </h3>
              </div>
            </Grid>
          </Hidden>
          <Hidden only={["xs"]}>
            <Grid item xs={12} sm={3}>
              <div
                className="whyChooseColumn3UpContainer_portal"
                data-aos="fade-left"
                data-aos-duration="1200"
              >
                <h2 className="chooseTitle_portal">Source code rights</h2>
                <h3 className="choosePara_portal">
                  Ensuring the protection of your creative assets through legal
                  ownership and other means is a top priority.
                </h3>
              </div>
              <div
                className="whyChooseColumn3DownContainer_portal"
                data-aos="fade-left"
                data-aos-duration="1600"
              >
                <h2 className="chooseTitle_portal">Outstanding Support</h2>
                <h3 className="choosePara_portal">
                  Achieve success with Domaincer's professional support and
                  top-notch features, covering everything you need from start to
                  finish.
                </h3>
              </div>
            </Grid>
          </Hidden>
        </Grid>
      </Container>
    </div>
  );
};
export default WhyChooseUsPortal;
