import React, { useState } from "react";
import { Grid, IconButton } from "@material-ui/core";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";

const GetQuoteStepTwo = (props) => {
  console.log(props.userDetails, "Name_Screen");
  const [name, setName] = useState("");
  const [errorStatus, setErrorStatus] = useState(false);

  const handleClick = (e) => {
    e.preventDefault();
    if (name === "") {
      setErrorStatus(true);
    } else {
      props.nextStep(
        [],
        [],
        {},
        {
          name: name,
          selectedValue: props.userDetails.selectedValue,
          selectedServices: props.userDetails.selectedServices,
        },
        "nameScreen"
      );
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className="quotePage__body">
      <React.Fragment>
        <Grid container direction="row" justify="center" spacing={2}>
          <Grid item xs={12} sm={10} className="custom__stepOneGrid">
            <div style={{ marginTop: 20 }}>
              <h2 className="quote__question">What's your name ?</h2>
            </div>

            <div className="textInput__container">
              <input
                id="my-name"
                placeholder="Your name"
                className={
                  name.length > 0
                    ? "custom__onChangeInputField"
                    : errorStatus === true
                    ? "error__inputField"
                    : "custom__inputField"
                }
                onChange={(e) => setName(e.target.value)}
              />
            </div>

            <div className="nextStep__btnContainer">
              <h5 className="nextStep__btn" onClick={handleClick}>
                Continue
              </h5>
              <IconButton>
                <DoubleArrowIcon className="nextStep__btnIcon arrowani" />
              </IconButton>
            </div>
          </Grid>
        </Grid>
      </React.Fragment>
    </div>
  );
};

export default GetQuoteStepTwo;
