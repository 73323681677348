import React, { useEffect } from "react";
import { Container, Grid, Avatar, Button, Card } from "@material-ui/core";
import "../casestudy.css";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import AOS from "aos";
import FollowUs from "../../../commons/followus/followus.component";
import { BASE_URL } from "../../../config/config";

const CaseStudyRyzone = (props) => {
  const RYZONE_REACT_IMAGE = `${BASE_URL}/ryzone_casestudy_react_image.png`;
  const RYZONE_MOBILE_IMAGE = `${BASE_URL}/ryzone_casestudy_mobile_image.png`;
  const RYZONE_PARTNER_IMAGE = `${BASE_URL}/ryzone_casestudy_partner_image.png`;
  const RYZONE_PARTNER_ICON = `${BASE_URL}/ryzone_casestudy_partner_icon.png`;
  const RYZONE_PHONE_ICON = `${BASE_URL}/ryzone_casestudy_mobile_icon.png`;
  const RYZONE_REACT_ICON = `${BASE_URL}/ryzone_casestudy_react_icon.png`;
  const RYZONE_LOGO_ICON = `${BASE_URL}/ryzone_casestudy_logo.png`;
  const RYZONE_IMAGE_HEADER = `${BASE_URL}/ryzone_casestudy_header.png`;

  useEffect(() => {
    AOS.init();
  });

  return (
    <div class="mobileMarginTop">
      <FollowUs history={props.history} />
      <Container>
        <Grid container>
          <Grid xs={12} sm={6} style={{ alignSelf: "center" }}>
            <img
              src={RYZONE_IMAGE_HEADER}
              alt="Ryzone mobile app domaincer"
              className="ryzoneHeaderImage"
            />
          </Grid>
          <Grid xs={12} sm={5}>
            <div className="logoTextContaincer">
              <Avatar src={RYZONE_LOGO_ICON} className="caseStudiesLogo" />
              <h1 className="caseStudieTitle">Ryzone</h1>
            </div>
            <h2 className="caseStudiesSubTitle">
              <strong>On-Demand Rice Delivery</strong>
            </h2>
            <p className="caseStudiesDescript">
              On-Demand rice ordering app with a mission to deliver high quality
              natural rice direct from farm to the doorstep of your home.
              Eliminating the middleman in the rice industry with an innovative
              concept of direct sale to consumers through mobile app.
            </p>
            <div className="labelsSection" style={{ alignItems: "center" }}>
              <div className="labelsContainerReact_ryzone">
                <img
                  src={RYZONE_REACT_ICON}
                  className="labelsIcon"
                  style={{ height: "87%" }}
                  alt="react development icon domaincer"
                />
                <p className="labelsText">React</p>
              </div>

              <div className="labelsContainer_ryzone">
                <img
                  src={RYZONE_PHONE_ICON}
                  alt="mobile app development icon domaincer"
                  className="labelsIcon"
                />
                <p className="labelsText">Mobile App</p>
              </div>

              <div className="labelsContainer_ryzone">
                <img
                  src={RYZONE_PARTNER_ICON}
                  alt="partner app  icon domaincer"
                  className="labelsIcon"
                />
                <p className="labelsText">Partner App</p>
              </div>
            </div>

            <div style={{ marginBottom: 100 }}>
              <a
                href="https://play.google.com/store/apps/details?id=com.ryzone"
                target="_blank"
              >
                <Button className="viewWebsiteContainer">
                  Download App
                  <ChevronRightIcon className="viewWebsite__arrow arrowani" />
                </Button>
              </a>
            </div>
          </Grid>
        </Grid>
      </Container>

      <div className="caseStudiesSectionTwo_ryzone">
        <Container>
          <Grid container>
            <Grid
              xs={12}
              sm={6}
              style={{ position: "relative" }}
              data-aos="fade-down"
              data-aos-duration="1000"
            >
              <div className="logoTextSectionTwo">
                <div className="circleDirector__caseStudy"></div>
                <div className="dottedLineContainer">
                  <p className="dottedLine1">. . . . . . . . .</p>
                  <p className="dottedLine1">. . . . . . . . .</p>
                  <p className="dottedLine1">. . . . . . . . .</p>
                  <p className="dottedLine1">. . . . . . . . .</p>
                  <p className="dottedLine1">. . . . . . . . .</p>
                  <p className="dottedLine1">. . . . . . . . .</p>
                  <p className="dottedLine1">. . . . . . . . .</p>
                  <p className="dottedLine1">. . . . . . . . .</p>
                </div>
                <div className="casestudy_icon_row">
                  <h1 className="caseStudieSectionTwoTitle">
                    React Technology
                  </h1>
                </div>
              </div>
              <p className="caseStudiesSectionTwoDescript">
                Portal is developed in the latest React technology where super
                admin can manage orders, customers, bills, invoices, feedback,
                coupons, vendors, delivery agents & more.
              </p>
            </Grid>
            <Grid xs={12} sm={6}>
              <img
                src={RYZONE_REACT_IMAGE}
                className="caseStudiesRightImg"
                alt="react stage icon domaincer"
              />
            </Grid>
          </Grid>
        </Container>
      </div>

      <div className="caseStudiesSectionThree_ryzone">
        <Container>
          <Grid container>
            <Grid
              xs={12}
              sm={6}
              style={{ position: "relative" }}
              data-aos="fade-down"
              data-aos-duration="1000"
            >
              <img
                src={RYZONE_MOBILE_IMAGE}
                className="caseStudiesleftImg"
                alt="mobile app stage icon domaincer"
              />
            </Grid>
            <Grid xs={12} sm={6}>
              <div className="logoTextSectionTwo">
                <div className="casestudy_icon_row">
                  <h1 className="caseStudieSectionThreeTitle">Mobile App</h1>
                </div>
              </div>
              <p className="caseStudiesSectionThreeDescript">
                {" "}
                Mobile App is developed in world class React Native hybrid
                development which makes the app blazing fast without consuming
                more of server resources & cost effective. App is equipped with
                live ordering, contests, payment gateway, loyalty points games,
                live tracking of delivery & more.
              </p>
            </Grid>
          </Grid>
        </Container>
      </div>

      <div className="caseStudiesSectionTwo_ryzone">
        <Container>
          <Grid container>
            <Grid
              xs={12}
              sm={6}
              style={{ position: "relative" }}
              data-aos="fade-down"
              data-aos-duration="1000"
            >
              <div className="logoTextSectionTwo">
                <div className="circleDirector__caseStudy"></div>
                <div className="dottedLineContainer">
                  <p className="dottedLine1">. . . . . . . . .</p>
                  <p className="dottedLine1">. . . . . . . . .</p>
                  <p className="dottedLine1">. . . . . . . . .</p>
                  <p className="dottedLine1">. . . . . . . . .</p>
                  <p className="dottedLine1">. . . . . . . . .</p>
                  <p className="dottedLine1">. . . . . . . . .</p>
                  <p className="dottedLine1">. . . . . . . . .</p>
                  <p className="dottedLine1">. . . . . . . . .</p>
                </div>
                <div className="casestudy_icon_row">
                  <h1 className="caseStudieSectionTwoTitle">Minimal Design</h1>
                </div>
              </div>

              <p className="caseStudiesSectionTwoDescript">
                {" "}
                The whole app is designed with minimalistic touch and aesthetics
                to have it in a premium ground. Other advantage of choosing it
                to make it minimal and flat is because of the fact to reduce the
                file size of images, videos and GIFs to make it load within 90
                milliseconds (~1 second).
              </p>
            </Grid>
            <Grid xs={12} sm={6}>
              <img
                src={RYZONE_PARTNER_IMAGE}
                className="caseStudiesRightImg"
                alt="partner app stage icon domaincer"
              />
            </Grid>
          </Grid>
        </Container>
      </div>

      {/* <div className='caseStudiesSectionTestimonial_ryzone'>
        <Container>
          <Grid container style={{ justifyContent: 'center' }}>
            <Grid xs={12}>
              <div style={{ textAlign: 'center' }}></div>
            </Grid>
            <Grid
              xs={12}
              sm={12}
              style={{ marginBottom: 90, position: 'relative' }}
              data-aos='fade-down'
              data-aos-duration='1000'
            >
              <div style={{ textAlign: 'end', width: '97%' }}></div>
              <Card className='testimonialsCard_ryzone' cardShadow={3}>
                <img
                  src={DIRECTOR_STATEMENT}
                  alt=''
                  className='directorImgLeft'
                />
                <p className='caseStudiesTestimonialText'>
                  {' '}
                  I got my mobile app and master admin web app for our dream
                  project - Ryzone. Our aim is to connect the farmers to direct
                  buyer. They have provided dedicated point of contact and
                  prompt support at every instance of journey with them. They
                  are the one of best service provider with respect to its
                  diversity. Best wishes and my appreciation to the Domaincer
                  Team for creating our app and making it a grand success.
                </p>
                <Grid>
                  <Grid item xs={12} sm={3} className='ownerAlign'>
                    <div style={{ textAlign: 'left' }}>
                      <img
                        src='https://hurrah.group/wp-content/uploads/2016/11/man-testimonial-png.png'
                        className='testimonialImg'
                        alt=''
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <p className='OwnerText'>Balasubramanian</p>
                    <p className='companyName'>
                      {' '}
                      Founder, KK Rice Group & Ryzone
                    </p>
                    <div>
                      <StarIcon className='StarRating' />
                      <StarIcon className='StarRating' />
                      <StarIcon className='StarRating' />
                      <StarIcon className='StarRating' />
                      <StarIcon className='StarRating' />
                    </div>
                  </Grid>
                </Grid>
                <div style={{ textAlign: 'end', width: '97%' }}>
                  <img
                    style={{ position: 'relative' }}
                    data-aos='fade-down'
                    data-aos-duration='1000'
                    src={DIRECTOR_STATEMENT}
                    className='directorImgRight'
                    alt=''
                  />
                </div>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </div> */}
    </div>
  );
};

export default CaseStudyRyzone;
