import React, { useState, useEffect } from "react";
import { Container, Grid, Paper, Tab, Tabs } from "@material-ui/core";
import AOS from "aos";
import "./process_styles.css";
import Hidden from "@material-ui/core/Hidden";
import { BASE_URL } from "../../../../config/config";

const SPRINTS_IMG = `${BASE_URL}/Designsprint.png`;
const FULL_CYCLE_PRODUCT_IMG = `${BASE_URL}/Fullcycleproductdevelopment.png`;
const MVP_IMG = `${BASE_URL}/mvpdevelopment.png`;
const PUBLISHING_IMG = `${BASE_URL}/Publishing.png`;
const TESTING_IMG = `${BASE_URL}/RigorousTesting.png`;
const UXUI_IMG = `${BASE_URL}/uiux.png`;

const ProcessSection = (props) => {
  const [value, setValue] = useState(0);

  useEffect(() => {
    AOS.init();
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <React.Fragment>
      {/* <Hidden only={["xs"]}> */}
      <div className="ksXnbQ">
        <div className="kgegep">
          <div className="processTabsSection">
            <Paper square className="MTabsWidthProcess_mobileapp">
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                aria-label="tabs example"
                className="MTabsContainer"
              >
                <Tab
                  label="Design sprints"
                  className="MTabBtn "
                  onClick={() => setValue(0)}
                />
                <Tab
                  label="UI/UX"
                  className="MTabBtn"
                  onClick={() => setValue(1)}
                />
                <Tab
                  label="MVP"
                  className="MTabBtn"
                  onClick={() => setValue(2)}
                />
                <Tab
                  label="Full cycle product"
                  className="MTabBtn"
                  onClick={() => setValue(3)}
                />
                <Tab
                  label="Rigorous Testing"
                  className="MTabBtn"
                  onClick={() => setValue(4)}
                />
                <Tab
                  label="Publishing"
                  className="MTabBtn"
                  onClick={() => setValue(5)}
                />
              </Tabs>
            </Paper>
          </div>
          {value === 0 && (
            <div className="movingMobile">
              <img src={SPRINTS_IMG} alt="" className="processbg" />
            </div>
          )}
          {value === 0 && (
            <div className="processContentSection">
              <h1 className="sprintText">
                Innovate with confidence using our proven five-phase design
                process. Our approach combines design thinking and risk
                reduction to bring your new product to life with success.
              </h1>
            </div>
          )}
          {value === 1 && (
            <div className="movingMobile">
              <img src={UXUI_IMG} alt="" className="processbg" />
            </div>
          )}
          {value === 1 && (
            <div className="processContentSection">
              <h1 className="sprintText">
                Design your dream product with us and find the best solution.
              </h1>
            </div>
          )}
          {value === 2 && (
            <div className="movingMobile">
              <img src={MVP_IMG} alt="" className="processbg" />
            </div>
          )}
          {value === 2 && (
            <div className="processContentSection">
              <h1 className="sprintText">
                Get your customers involved early on and let them access a
                Minimum Viable Product with just enough features to make future
                development a breeze.
              </h1>
            </div>
          )}
          {value === 3 && (
            <div className="movingMobile">
              <img src={FULL_CYCLE_PRODUCT_IMG} alt="" className="processbg" />
            </div>
          )}
          {value === 3 && (
            <div className="processContentSection">
              <h1 className="sprintText">
                Bring your product from an idea to a live project with our
                full-cycle development services. As your one-stop shop for web,
                mobile, and desktop application development, we have the
                expertise and resources to bring your vision to life.
              </h1>
            </div>
          )}
          {value === 4 && (
            <div className="movingMobile">
              <img src={TESTING_IMG} alt="" className="processbg" />
            </div>
          )}
          {value === 4 && (
            <div className="processContentSection">
              <h1 className="sprintText">
                Experience thorough and reliable testing with us. Our strict
                entry and exit criteria ensure that every test case and test
                data combination is thoroughly covered.
              </h1>
            </div>
          )}
          {value === 5 && (
            <div className="movingMobile">
              <img src={PUBLISHING_IMG} alt="" className="processbg" />
            </div>
          )}
          {value === 5 && (
            <div className="processContentSection">
              <h1 className="sprintText">
                Get your app market-ready with our team of experts. We ensure
                that all requirements and functionalities are met for successful
                deployment on both Android and iOS.
              </h1>
            </div>
          )}
        </div>
      </div>
      {/* </Hidden> */}
    </React.Fragment>
  );
};

export default ProcessSection;
