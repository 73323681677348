import React, { useState, useEffect } from "react";
import { Container, Grid, Button, Link } from "@material-ui/core";
import "../graphics.css";
import Hidden from "@material-ui/core/Hidden";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { BASE_URL } from "../../../../config/config";

const STRATEGY = [
  {
    key: "CONTENT_SECTION_ONE",
    name: "Explainer",
    subtext: "Clear, concise storytelling",
    image: `${BASE_URL}/tab_graphics_icon1.png`,
    alt: "Explainer video image domaincer",
  },
  {
    key: "CONTENT_SECTION_TWO",
    name: "2D Animation",
    subtext: "Storyboard Your Way Forward",
    image: `${BASE_URL}/tab_graphics_icon2.png`,
    alt: "2D animation image domaincer",
  },
  {
    key: "CONTENT_SECTION_THREE",
    name: "Motion Graphics",
    subtext: "Simple tales, big impact!",
    image: `${BASE_URL}/tab_graphics_icon3.png`,
    alt: "motion graphics image domaincer",
  },
];

const ServiceContentSection = () => {
  const [activeTabService, setActiveTabService] = useState(
    "CONTENT_SECTION_ONE"
  );

  return (
    <div>
      <div style={{ paddingTop: 100, paddingBottom: 30 }}>
        <h1 className="graphics_service_subheading">About Motion Graphics</h1>
        <h2 className="graphics_service_subtext">
          Unlock the power of video and watch your message take off! With the
          impact of 1000 images and the depth of 1000 words, video is the
          ultimate content medium.
        </h2>
      </div>
      {/* visible only on desktop, laptop */}
      <Hidden only={["xs"]}>
        <div className="graphics_subServiceTabSection">
          {STRATEGY.map((service, index) => (
            <div
              className="graphics_subServiceContaincer"
              style={{
                borderColor:
                  service.key === activeTabService ? "#0077FF" : "#FFF",
              }}
              onClick={() => setActiveTabService(service.key)}
            >
              <img
                className="graphics_subServiceImg"
                src={service.image}
                alt={service.alt}
              />
              <div>
                <h1 className="graphics_subServiceTitle">{service.name}</h1>
                <h2 className="graphics_subServiceSubTitle">
                  {service.subtext}
                </h2>
              </div>
            </div>
          ))}
        </div>
        <div>
          {activeTabService === "CONTENT_SECTION_ONE" && (
            <div className="graphics_subServiceParaContainer">
              <Container
                maxWidth="lg"
                className="graphics_subServiceFixedSection"
              >
                <div className="graphics_glowEffectBrand"></div>

                <div className="graphics_serviceContent">
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <h3 className="graphics_subServicePara">
                        These short, animated videos pack a punch, explaining
                        your products or services in an engaging and appealing
                        way. With their eye-catching visuals and easy-to-follow
                        narration, explainer videos have been proven to drive
                        conversions and make a big impact on your marketing
                        efforts.
                      </h3>
                    </Grid>
                  </Grid>
                </div>
              </Container>
            </div>
          )}
          {activeTabService === "CONTENT_SECTION_TWO" && (
            <div className="graphics_subServiceParaContainer">
              <Container
                maxWidth="lg"
                className="graphics_subServiceFixedSection"
              >
                <div className="graphics_glowEffectDigital"></div>

                <div className="graphics_serviceContent">
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <h3 className="graphics_subServicePara">
                        Infographic videos are like a visual feast for your
                        brain! They're a delicious way to digest complex
                        information and ideas, with a side of eye-catching
                        graphics and animation. Imagine your favorite data and
                        insights served up in an irresistible,
                        easy-to-understand format. These videos are the ultimate
                        way to engage your audience and leave a lasting
                        impression.
                      </h3>
                    </Grid>
                  </Grid>
                </div>
              </Container>
            </div>
          )}
          {activeTabService === "CONTENT_SECTION_THREE" && (
            <div className="graphics_subServiceParaContainer">
              <Container
                maxWidth="lg"
                className="graphics_subServiceFixedSection"
              >
                <div className="graphics_glowEffectWorkFlow"></div>

                <div className="graphics_serviceContent">
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <h3 className="graphics_subServicePara">
                        Bring your message to life with whiteboard animation!
                        Imagine a skilled artist taking you on a journey,
                        drawing and narrating as they go. It's like a
                        live-action colouring book that brings your ideas to
                        life in a dynamic and engaging way. Perfect for
                        education, communication, and persuasion, It is the
                        ultimate tool for making a lasting impact.
                      </h3>
                    </Grid>
                  </Grid>
                </div>
              </Container>
            </div>
          )}
        </div>
      </Hidden>

      {/* visible only on mobile */}
      <Hidden only={["sm", "lg", "md", "xl"]}>
        <div style={{ position: "relative" }}>
          <ArrowBackIosIcon
            className="subServiceTabLeftArrowHome"
            onClick={() => {
              document.getElementById("strategyScroll").scrollLeft -= 400;
            }}
          />
          <div className="graphics_subServiceTabSection" id="strategyScroll">
            <div>
              <div className="graphics_subServiceContaincer">
                <img
                  className="graphics_subServiceImg"
                  src={`${BASE_URL}/tab_graphics_icon1.png`}
                  alt="Explainer video image domaincer"
                />
                <div>
                  <h1 className="graphics_subServiceTitle">Explainer</h1>
                  <h2 className="graphics_subServiceSubTitle">
                    Clear, concise storytelling
                  </h2>
                </div>
              </div>
              <div className="graphics_subServiceParaContainer">
                <Container
                  maxWidth="lg"
                  className="graphics_subServiceFixedSection"
                >
                  <div className="graphics_glowEffectBrand"></div>

                  <div className="graphics_serviceContentOne">
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <h3 className="graphics_subServicePara">
                          These short, animated videos pack a punch, explaining
                          your products or services in an engaging and appealing
                          way. With their eye-catching visuals and
                          easy-to-follow narration, explainer videos have been
                          proven to drive conversions and make a big impact on
                          your marketing efforts.
                        </h3>
                      </Grid>
                    </Grid>
                  </div>
                </Container>
              </div>
            </div>

            <div>
              <div className="graphics_subServiceContaincer">
                <img
                  className="graphics_subServiceImg"
                  src={`${BASE_URL}/tab_graphics_icon2.png`}
                  alt="2D animation image domaincer"
                />
                <div>
                  <h1 className="graphics_subServiceTitle">2D Animation</h1>
                  <h2 className="graphics_subServiceSubTitle">
                    Storyboard Your Way Forward
                  </h2>
                </div>
              </div>
              <div className="graphics_subServiceParaContainer">
                <Container
                  maxWidth="lg"
                  className="graphics_subServiceFixedSection"
                >
                  <div className="graphics_glowEffectDigital"></div>

                  <div className="graphics_serviceContent">
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <h3 className="graphics_subServicePara">
                          Infographic videos are like a visual feast for your
                          brain! They're a delicious way to digest complex
                          information and ideas, with a side of eye-catching
                          graphics and animation. Imagine your favorite data and
                          insights served up in an irresistible,
                          easy-to-understand format. These videos are the
                          ultimate way to engage your audience and leave a
                          lasting impression.
                        </h3>
                      </Grid>
                    </Grid>
                  </div>
                </Container>
              </div>
            </div>
            <div>
              <div className="graphics_subServiceContaincer">
                <img
                  className="graphics_subServiceImg"
                  src={`${BASE_URL}/tab_graphics_icon3.png`}
                  alt="motion graphics image domaincer"
                />
                <div>
                  <h1 className="graphics_subServiceTitle">Motion Graphics</h1>
                  <h2 className="graphics_subServiceSubTitle">
                    Simple tales, big impact!
                  </h2>
                </div>
              </div>

              <div className="graphics_subServiceParaContainer">
                <Container
                  maxWidth="lg"
                  className="graphics_subServiceFixedSection"
                >
                  <div className="graphics_glowEffectWorkFlow"></div>

                  <div className="graphics_serviceContent">
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <h3 className="graphics_subServicePara">
                          Bring your message to life with whiteboard animation!
                          Imagine a skilled artist taking you on a journey,
                          drawing and narrating as they go. It's like a
                          live-action coloring book that brings your ideas to
                          life in a dynamic and engaging way. Perfect for
                          education, communication, and persuasion, It is the
                          ultimate tool for making a lasting impact.
                        </h3>
                      </Grid>
                    </Grid>
                  </div>
                </Container>
              </div>
            </div>
          </div>
          <ArrowForwardIosIcon
            className="subServiceTabRightArrowHome"
            onClick={() => {
              document.getElementById("strategyScroll").scrollLeft += 400;
            }}
          />
        </div>
      </Hidden>
    </div>
  );
};

export default ServiceContentSection;
