import React, { useState } from "react";
import { Grid, IconButton, TextField } from "@material-ui/core";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Hidden from '@material-ui/core/Hidden';
import { BASE_URL } from '../../../../config/config';


const SearchEngineQAPage = (props) => {
  const SEO_SLIDER = `${BASE_URL}/service_seo_header_image.png`;

  Array.prototype.removeValue = function (val) {
    for (var i = 0; i < this.length; i++) {
      if (this[i] === val) {
        this.splice(i, 1);
        i--;
      }
    }
    return this;
  };

  const [pageName] = useState("searchEngineOptimisationScreen");
  const [errorStatus, setErrorStatus] = useState(false);
  const [Q1A, setQ1A] = useState("");
  const [Q2A, setQ2A] = useState("");

  const handleClick = (e) => {
    e.preventDefault();
    const { userDetails } = props;
    const qadata = {
      id: "searchEngineOptimisationQA",
      Q1: {
        question: "What is your Brand URL?",
        answer: Q1A,
      },
      Q2: {
        question: "Who is your target audience?",
        answer: Q2A,
      },
    };
    if (Q1A === "") {
      setErrorStatus(true);
    } else if (Q2A === "") {
      setErrorStatus(true);
    } else {
      const data = props.serviceData.removeValue(pageName);
      props.prevServiceData.push(pageName);
      props.nextStep(
        data,
        props.prevServiceData,
        qadata,
        userDetails,
        pageName
      );
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  const handleBack = (e) => {
    e.preventDefault();
    const data = props.prevServiceData.removeValue(pageName);
    props.prevStep(data);
  };

  return (
    <div className="quotePage__body">
      <React.Fragment>
        <Grid container direction="row" justify="center" spacing={2}>
          <Grid item xs={12} sm={10} className="custom__stepOneGrid">
            <div className="prevStep__div">
              <IconButton>
                <ArrowBackIcon className="prevStep__btn" onClick={handleBack} />
              </IconButton>
            </div>
            <div>
              <h2 className="quote__question">Search Engine Optimisation</h2>
            </div>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
             <Hidden only={['sm', 'lg', 'md', 'xl']}>
              <Grid item xs={12} sm={4} className="animated__gif" style={{ textAlign: 'center' }}>
                <img
                  src={SEO_SLIDER}
                  alt="Best SEO image domaincer"
                  className="getQuote_brandingImage floatingUpDown"
                />
              </Grid>
              </Hidden>
              <Grid item xs={12} sm={8}>
                <div>
                  <div>
                    <h2 className="service__question">
                      1. What is your Brand URL?
                    </h2>
                    <TextField
                      id="outlined-multiline-static"
                      label="Enter Brand URL"
                      multiline
                      rows={1}
                      variant="outlined"
                      className="custom__textField"
                      onChange={(e) => {
                        setErrorStatus(false);
                        setQ1A(e.target.value);
                      }}
                    />
                  </div>
                  <div>
                    <h2 className="service__question">
                      2. Whats your target keywords?
                    </h2>
                    <TextField
                      id="outlined-multiline-static"
                      label="Enter Target keywords"
                      multiline
                      rows={2}
                      variant="outlined"
                      className="custom__textField"
                      onChange={(e) => {
                        setErrorStatus(false);
                        setQ2A(e.target.value);
                      }}
                    />
                  </div>
                </div>
                {errorStatus && (
                  <p style={{ color: "red", fontSize: 13, marginTop: 7 }}>
                    Please fill all the fields
                  </p>
                )}
              </Grid>
              <Hidden only={['xs']}>
              <Grid item xs={12} sm={4} className="animated__gif" style={{ textAlign: 'center' }}>
                <img
                  src={SEO_SLIDER}
                  alt="Best SEO image domaincer"

                  className="getQuote_brandingImage floatingUpDown"
                />
              </Grid>
              </Hidden>
            </Grid>
            <div className="nextStep__btnContainer">
              <h5 className="nextStep__btn" onClick={handleClick}>
                Continue
              </h5>
              <IconButton>
                <DoubleArrowIcon className="nextStep__btnIcon arrowani" />
              </IconButton>
            </div>
          </Grid>
        </Grid>
      </React.Fragment>
    </div>
  );
};

export default SearchEngineQAPage;
