import React from "react";
import "../website.css";
import { Container, Grid, Card } from "@material-ui/core";
import Hidden from "@material-ui/core/Hidden";
import { BASE_URL } from "../../../../config/config";

const WhyChooseUsWebsite = () => {
  const WHY_CHOOSE_US_IMAGE = `${BASE_URL}/about_why_choose_us.png`;

  return (
    <div className="whyChooseUsSection_website">
      <Container>
        <h1 className="whyChooseUsTitle_website">Why Domaincer</h1>
        <Grid container spacing={2} style={{ justifyContent: "center" }}>
          <Grid item xs={12} sm={3}>
            <div
              className="whyChooseColumnUpContainer_website"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <h2 className="chooseTitle_website">End-to-end solution</h2>
              <h3 className="choosePara_website">
                Your One-stop shop for creating a great website. We've got all
                the Tools and expertise for creating a show-stopping website.
              </h3>
            </div>
            <div
              className="whyChooseColumnDownContainer_website"
              data-aos="fade-right"
              data-aos-duration="1400"
            >
              <h2 className="chooseTitle_website">Transparency</h2>
              <h3 className="choosePara_website">
                We prioritize transparency and customer satisfaction through
                open communication and review meetings, user testing, and
                honesty.
              </h3>
            </div>
          </Grid>
          <Grid item xs={12} sm={4} className="chooseUsImageRow_website">
            <img
              src={WHY_CHOOSE_US_IMAGE}
              alt="why choose us image 3d domaincer"
              className="chooseUsMiddleImg_website floatingUpDown"
            />
          </Grid>

          <Hidden only={["sm", "lg", "md", "xl"]}>
            <Grid item xs={12} sm={3}>
              <div
                className="whyChooseColumn3UpContainer_website"
                data-aos="fade-up"
                data-aos-duration="1200"
              >
                <h2 className="chooseTitle_website">Data Security</h2>
                <h3 className="choosePara_website">
                  We Ensure the safety and security of your data with our top
                  priority on confidentiality, compliance, and regulation.
                </h3>
              </div>
              <div
                className="whyChooseColumn3DownContainer_website"
                data-aos="fade-up"
                data-aos-duration="1400"
              >
                <h2 className="chooseTitle_website">Support Services</h2>
                <h3 className="choosePara_website">
                  Our team goes above and beyond, offering ongoing support for
                  project success and issue resolution.
                </h3>
              </div>
            </Grid>
          </Hidden>
          <Hidden only={["xs"]}>
            <Grid item xs={12} sm={3}>
              <div
                className="whyChooseColumn3UpContainer_website"
                data-aos="fade-left"
                data-aos-duration="1200"
              >
                <h2 className="chooseTitle_website">Data Security</h2>
                <h3 className="choosePara_website">
                  We Ensure the safety and security of your data with our top
                  priority on confidentiality, compliance, and regulation.
                </h3>
              </div>
              <div
                className="whyChooseColumn3DownContainer_website"
                data-aos="fade-left"
                data-aos-duration="1600"
              >
                <h2 className="chooseTitle_website">Support Services</h2>
                <h3 className="choosePara_website">
                  Our team goes above and beyond, offering ongoing support for
                  project success and issue resolution.
                </h3>
              </div>
            </Grid>
          </Hidden>
        </Grid>
      </Container>
    </div>
  );
};
export default WhyChooseUsWebsite;
