import React from "react";
import "../website.css";
import { Container, Grid } from "@material-ui/core";
import Particles from "react-particles-js";

const HowServiceWillHelpYouWebsite = () => {
  return (
    <div>
      <Grid container>
        <Particles
          params={{
            particles: {
              number: {
                value: 350,
              },
              size: {
                value: 1,
              },
              color: {
                value: "#fff",
              },
              line_linked: {
                enable: false,
                distance: 120,
                color: "#fff",
                width: 1,
              },
            },
            interactivity: {
              events: {
                onhover: {
                  enable: true,
                  mode: "repulse",
                },
              },
            },
          }}
          className="particalsSection_website"
        />
        <Grid
          xs={12}
          sm={12}
          style={{ zIndex: -1, position: "relative", textAlign: "center" }}
        >
          <div className="BSHelpYouRightSection_website">
            <h1 className="BSHelpYouHeading_website">
              Step into the Digital World
            </h1>
            <h2
              className="BSHelpYouPara_website"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              Boost your online presence with our top-notch web design and
              development services. From stunning aesthetics to seamless
              functionality, we'll craft the ultimate digital destination for
              your business. Our team specializes in creating mesmerizing
              websites with unbeatable user experiences. Let’s turbocharge your
              growth with a website that wows! Don't just take our word for it,
              see the magic for yourself.
            </h2>
            <div
              data-aos="fade-up"
              data-aos-duration="1000"
              className="circleBSHelpYou_website"
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default HowServiceWillHelpYouWebsite;
