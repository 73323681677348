import React from "react";
import "./statements.style.css";

const CookiesPolicyStatement = () => {
  return (
    <div className="user_agreement_container">
      <div className="user_agreement_inner_container">
        <p className="user_agreement_header">Cookies Discolsure Statement</p>
        <div className="user_agreement_content_con">
          <div className="user_agreement_inital_container">
            <p className="user_agreement_content_para">
              This Cookies Disclosure Statement applies to the website of
              Domaincer and governs the use of cookies on our website.
            </p>
          </div>
          <div className="user_agreement_content_container">
            <h4 className="user_agreement_content_header">1. What are Cookies?</h4>
            <p className="user_agreement_content_inner_para">
              Cookies are small text files that are stored on your device when
              you visit a website. They are used to improve your online
              experience by allowing the website to remember your actions and
              preferences (such as login, language, font size, and other display
              preferences) over a period of time. This allows the website to
              provide you with a more personalized and efficient experience.
            </p>
            <p className="user_agreement_content_inner_para">
              Cookies may be either "persistent" cookies or "session" cookies. A
              persistent cookie will be stored by a web browser and will remain
              valid until its set expiration date, unless deleted by the user
              before the expiration date. A session cookie, on the other hand,
              will expire at the end of the user session, when the web browser
              is closed.
            </p>
          </div>
          <div className="user_agreement_content_container">
            <h4 className="user_agreement_content_header">
              2. How We Use Cookies
            </h4>
            <p className="user_agreement_content_inner_para">
              Domaincer uses cookies for a variety of purposes in order to
              improve your online experience and to make our website and
              services more convenient and efficient for you. These purposes
              include:
            </p>
            <ul className="user_agreement_content_inner_para">
              <li className="user_agreement_list_style">
                Storing your preferences and settings: We may use cookies to
                store your preferences and settings so that you don't have to
                enter them every time you visit our website.
              </li>
              <li className="user_agreement_list_style">
                Analyzing and improving our website and services: We may use
                cookies to analyze how our website and services are being used
                in order to identify and fix any problems, and to improve their
                performance and functionality.
              </li>
              <li className="user_agreement_list_style">
                Personalizing our website and services: We may use cookies to
                personalize our website and services based on your interests and
                preferences, such as by showing you relevant content or ads.
              </li>
            </ul>
          </div>
          <div className="user_agreement_content_container">
            <h4 className="user_agreement_content_header">
              3. Third-Party Cookies
            </h4>
            <p className="user_agreement_content_inner_para">
              We may also use third-party cookies from external service
              providers to improve our website and services. These third-party
              cookies may be used for various purposes, such as to analyze
              website usage, to personalize content and ads, and to provide
              social media features
            </p>
          </div>
          <div className="user_agreement_content_container">
            <h4 className="user_agreement_content_header">4. Managing Cookies</h4>
            <p className="user_agreement_content_inner_para">
              You can control and manage cookies through your web browser
              settings. You can choose to disable or delete cookies, or to be
              notified when cookies are being set. Please note that if you
              disable or delete cookies, some features of our website and
              services may not work properly.
            </p>
            <p className="user_agreement_content_inner_para">
              For more information on how to manage cookies, please refer to the
              documentation for your web browser.
            </p>
          </div>
          <div className="user_agreement_content_container">
            <h4 className="user_agreement_content_header">5. Contact Us</h4>
            <p className="user_agreement_content_inner_para">
              If you have any questions or concerns about our use of cookies,
              please don't hesitate to contact us. You can reach us at
              support@domaincer.com
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CookiesPolicyStatement;
