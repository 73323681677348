import React, { useState, useEffect } from "react";
import { Grid, Card, Button, Modal, Fade, Backdrop } from "@material-ui/core";
import "./casestudysection.css";
import CloseIcon from "@material-ui/icons/Close";
import PlayCircleFilledWhiteIcon from "@material-ui/icons/PlayCircleFilledWhite";
import { Link } from "react-router-dom";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import LockIcon from "@material-ui/icons/Lock";
import Skeleton from "react-loading-skeleton";
import LazyLoad from "react-lazyload";
import styled, { keyframes } from "styled-components";
import AOS from "aos";

const ImageWrapper = styled.div`
  position: relative;
`;

const Placeholder = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
`;

const CaseStudySection = () => {
  const [secureRiceportal, setsecureRiceportal] = useState(false);
  const [getPin, setgetPin] = useState("");
  const [secureRyzone, setsecureRyzone] = useState(false);
  const [getPinRyzone, setgetPinRyzone] = useState("");
  const [secureFPM, setsecureFPM] = useState(false);
  const [getPinFPM, setgetPinFPM] = useState("");
  const [securePraxis, setsecurePraxis] = useState(false);
  const [getPinPraxis, setgetPinPraxis] = useState("");
  const [skeletonLoader, setskeletonLoader] = useState(true);
  const [secureRyzoneError, setsecureRyzoneError] = useState(false);
  const [secureRicePortalError, setsecureRicePortalError] = useState(false);
  const [secureFPMError, setsecureFPMError] = useState(false);
  const [securePraxisError, setsecurePraxisError] = useState(false);

  const refPlaceholder = React.useRef();

  useEffect(() => {
    AOS.init();
  }, []);

  const removePlaceholder = () => {
    refPlaceholder.current.remove();
    setskeletonLoader(false);
  };

  const handleGetPinRyzone = (e) => {
    if (getPinRyzone == 1414) {
      setsecureRyzone(true);
    } else {
      setsecureRyzoneError(true);
      setgetPinRyzone("");
    }
  };

  const handleGetPin = () => {
    if (getPin == 1414) {
      setsecureRiceportal(true);
    } else {
      setsecureRicePortalError(true);
      setgetPin("");
    }
  };

  const handleGetPinFPM = () => {
    if (getPinFPM == 1414) {
      setsecureFPM(true);
    } else {
      setsecureFPMError(true);
      setgetPinFPM("");
    }
  };

  const handleGetPinPraxis = () => {
    if (getPinPraxis == 1414) {
      setsecurePraxis(true);
    } else {
      setsecurePraxisError(true);
      setgetPinPraxis("");
    }
  };

  return (
    <div data-aos="fade-up" data-aos-duration="3000">
      <div style={{ marginTop: -50, paddingBottom: 30, paddingTop: 10 }}>
        <h1 className="casestudy_subheading">Recent Works</h1>
        <h2 className="casestudy_subtext">
          We have put creative power to craft each work to extraordinary.
        </h2>
      </div>
      <div className="ourWorksCardsSection">
        <Grid container spacing={0} style={{ marginTop: 20 }}>
          <Grid item xs={12} sm={3} md={3}>
            <Card className="caseCard">
              <React.Fragment>
                <ImageWrapper>
                  <Placeholder ref={refPlaceholder} />
                  <LazyLoad>
                    <div className="caseCardBg_ryzone1">
                      <h1 className="cardHeading">Ryzone</h1>
                      <h2 className="cardSubHeading">
                        On-Demand Rice Delivery
                      </h2>
                      <div className="thumbnailImgContainer">
                        <PlayCircleFilledWhiteIcon className="thumbnailImg" />
                      </div>

                      <div>
                        {secureRyzone === true ? (
                          <Link to="/case-study-ryzone">
                            <Button className="caseBtn">
                              <DoubleArrowIcon className="ViewArrowSuccess" />
                              <h3 className="viewProject">
                                View<span className="viewProject_error">_</span>
                                Project
                              </h3>
                            </Button>
                          </Link>
                        ) : (
                          <Button className="caseBtn">
                            {getPinRyzone.length !== 0 ? (
                              <React.Fragment>
                                <DoubleArrowIcon className="ViewArrow" />
                              </React.Fragment>
                            ) : (
                              <LockIcon className="ViewArrow" />
                            )}

                            <div
                              className={
                                getPinRyzone.length !== 0
                                  ? "enterPIN_numberOnChange"
                                  : "enterPIN_number"
                              }
                            >
                              <input
                                placeholder={
                                  secureRyzoneError === true
                                    ? "Wrong PIN"
                                    : "Enter PIN"
                                }
                                className="getPin_input"
                                type="number"
                                value={getPinRyzone}
                                onChange={(e) =>
                                  setgetPinRyzone(e.target.value)
                                }
                              />
                            </div>
                            {getPinRyzone.length !== 0 && (
                              <h2
                                className="ok_button"
                                onClick={handleGetPinRyzone}
                              >
                                Enter
                              </h2>
                            )}
                          </Button>
                        )}
                      </div>
                    </div>
                  </LazyLoad>
                </ImageWrapper>
              </React.Fragment>
            </Card>
          </Grid>
          <Grid item xs={12} sm={3} md={3}>
            <Card className="caseCard">
              <React.Fragment>
                <ImageWrapper>
                  <Placeholder ref={refPlaceholder} />
                  <LazyLoad>
                    <div className="caseCardBg_ryzone2">
                      <h1 className="cardHeading">KK Inc Portal</h1>
                      <h2 className="cardSubHeading">
                        Workflow Automation for Rice Industry
                      </h2>
                      <div className="thumbnailImgContainer">
                        <PlayCircleFilledWhiteIcon className="thumbnailImg" />
                      </div>
                      <div>
                        {secureRiceportal === true ? (
                          <Link to="/case-study-kkrice">
                            <Button className="caseBtn">
                              <DoubleArrowIcon className="ViewArrowSuccess" />
                              <h3 className="viewProject">
                                View<span className="viewProject_error">_</span>
                                Project
                              </h3>
                            </Button>
                          </Link>
                        ) : (
                          <Button className="caseBtn">
                            {getPin.length !== 0 ? (
                              <DoubleArrowIcon className="ViewArrow" />
                            ) : (
                              <LockIcon className="ViewArrow" />
                            )}

                            <div
                              className={
                                getPin.length !== 0
                                  ? "enterPIN_numberOnChange"
                                  : "enterPIN_number"
                              }
                            >
                              <input
                                placeholder={
                                  secureRicePortalError === true
                                    ? "Wrong PIN"
                                    : "Enter PIN"
                                }
                                className="getPin_input"
                                type="number"
                                value={getPin}
                                onChange={(e) => setgetPin(e.target.value)}
                              />
                            </div>
                            {getPin.length !== 0 && (
                              <h2 className="ok_button" onClick={handleGetPin}>
                                Enter
                              </h2>
                            )}
                          </Button>
                        )}
                      </div>
                    </div>
                  </LazyLoad>
                </ImageWrapper>
              </React.Fragment>
            </Card>
          </Grid>

          <Grid item xs={12} sm={3} md={3}>
            <Card className="caseCard">
              <React.Fragment>
                <ImageWrapper>
                  <Placeholder ref={refPlaceholder} />
                  <LazyLoad>
                    <div className="caseCardBg_fpm1">
                      <h1 className="cardHeading">Fpmarkets</h1>
                      <h2 className="cardSubHeading">
                        $6.6 Trillion-A-Day Market Industry
                      </h2>
                      <div
                        className="thumbnailImgContainer"
                        // onClick={handleOpen}
                      >
                        <PlayCircleFilledWhiteIcon className="thumbnailImg" />
                      </div>

                      <div>
                        {secureFPM === true ? (
                          <Link to="/case-study-fpmarkets">
                            <Button className="caseBtn">
                              <DoubleArrowIcon className="ViewArrowSuccess" />
                              <h3 className="viewProject">
                                View<span className="viewProject_error">_</span>
                                Project
                              </h3>
                            </Button>
                          </Link>
                        ) : (
                          <Button className="caseBtn">
                            {getPinFPM.length !== 0 ? (
                              <DoubleArrowIcon className="ViewArrow" />
                            ) : (
                              <LockIcon className="ViewArrow" />
                            )}

                            <div
                              className={
                                getPinFPM.length !== 0
                                  ? "enterPIN_numberOnChange"
                                  : "enterPIN_number"
                              }
                            >
                              <input
                                placeholder={
                                  secureFPMError === true
                                    ? "Wrong PIN"
                                    : "Enter PIN"
                                }
                                className="getPin_input"
                                type="number"
                                value={getPinFPM}
                                onChange={(e) => setgetPinFPM(e.target.value)}
                              />
                            </div>
                            {getPinFPM.length !== 0 && (
                              <h2
                                className="ok_button"
                                onClick={handleGetPinFPM}
                              >
                                Enter
                              </h2>
                            )}
                          </Button>
                        )}
                      </div>
                    </div>
                  </LazyLoad>
                </ImageWrapper>
              </React.Fragment>
            </Card>
          </Grid>
          <Grid item xs={12} sm={3} md={3}>
            <Card className="caseCard">
              <React.Fragment>
                <ImageWrapper>
                  <Placeholder ref={refPlaceholder} />
                  <LazyLoad>
                    <div className="caseCardBg_fpm2">
                      <h1 className="cardHeading">Praxis Holidays</h1>
                      <h2 className="cardSubHeading">
                        Digital Suite for leading Tour Operator in India
                      </h2>
                      <div
                        className="thumbnailImgContainer"
                        // onClick={handleOpen}
                      >
                        <PlayCircleFilledWhiteIcon className="thumbnailImg" />
                      </div>

                      <div>
                        {securePraxis === true ? (
                          <Link to="/case-study-praxis-holidays">
                            <Button className="caseBtn">
                              <DoubleArrowIcon className="ViewArrowSuccess" />
                              <h3 className="viewProject">
                                View<span className="viewProject_error">_</span>
                                Project
                              </h3>
                            </Button>
                          </Link>
                        ) : (
                          <Button className="caseBtn">
                            {getPinPraxis.length !== 0 ? (
                              <DoubleArrowIcon className="ViewArrow" />
                            ) : (
                              <LockIcon className="ViewArrow" />
                            )}

                            <div
                              className={
                                getPinPraxis.length !== 0
                                  ? "enterPIN_numberOnChange"
                                  : "enterPIN_number"
                              }
                            >
                              <input
                                placeholder={
                                  securePraxisError === true
                                    ? "Wrong PIN"
                                    : "Enter PIN"
                                }
                                className="getPin_input"
                                type="number"
                                value={getPinPraxis}
                                onChange={(e) =>
                                  setgetPinPraxis(e.target.value)
                                }
                              />
                            </div>
                            {getPinPraxis.length !== 0 && (
                              <h2
                                className="ok_button"
                                onClick={handleGetPinPraxis}
                              >
                                Enter
                              </h2>
                            )}
                          </Button>
                        )}
                      </div>
                    </div>
                  </LazyLoad>
                </ImageWrapper>
              </React.Fragment>
            </Card>
          </Grid>
        </Grid>
      </div>

      {/* <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        className='modal_case_section'
        open={openModal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModal}>
          <div className='caseModalContainer'>
            <CloseIcon onClick={handleClose} className='closeCaseBtnModal' />
            <iframe
              src={`https://www.youtube.com/embed/d5vOzsuXNfQ`}
              frameborder='0'
              allow='autoplay; encrypted-media'
              allowfullscreen
              title='video'
              className='youtubeCaseVideo'
            />
          </div>
        </Fade>
      </Modal> */}
    </div>
  );
};

export default CaseStudySection;
