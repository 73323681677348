import React from "react";
import "../portal.css";
import { Container, Grid } from "@material-ui/core";
import Particles from "react-particles-js";

const HowServiceWillHelpYouPortal = () => {
  return (
    <div>
      <Grid container>
        <Particles
          params={{
            particles: {
              number: {
                value: 350,
              },
              size: {
                value: 1,
              },
              color: {
                value: "#fff",
              },
              line_linked: {
                enable: false,
                distance: 120,
                color: "#fff",
                width: 1,
              },
            },
            interactivity: {
              events: {
                onhover: {
                  enable: true,
                  mode: "repulse",
                },
              },
            },
          }}
          className="particalsSection_portal"
        />
        <Grid
          xs={12}
          sm={12}
          style={{ zIndex: -1, position: "relative", textAlign: "center" }}
        >
          <div className="BSHelpYouRightSection_portal">
            <h1 className="BSHelpYouHeading_portal">
              Solid & Customised
              <br /> Web App
            </h1>
            <h2
              className="BSHelpYouPara_portal"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              Achieve success in the market with our specialized portal
              development services. Our team excels in creating custom web
              applications that bridge the gap and provide complete process
              management for institutional businesses. From Roman people
              management tools to comprehensive solutions, trust us to help your
              business thrive.
            </h2>
            <div
              data-aos="fade-up"
              data-aos-duration="1000"
              className="circleBSHelpYou_portal"
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default HowServiceWillHelpYouPortal;
