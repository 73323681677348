import React from "react";
import "../socialmediamarketing.css";
import { Container, Grid } from "@material-ui/core";
import Particles from "react-particles-js";

const HowServiceWillHelpYouSocialMedia = () => {
  return (
    <div>
      <Grid container>
        <Particles
          params={{
            particles: {
              number: {
                value: 350,
              },
              size: {
                value: 1,
              },
              color: {
                value: "#fff",
              },
              line_linked: {
                enable: false,
                distance: 120,
                color: "#fff",
                width: 1,
              },
            },
            interactivity: {
              events: {
                onhover: {
                  enable: true,
                  mode: "repulse",
                },
              },
            },
          }}
          className="particalsSection_socialmedia"
        />
        <Grid
          xs={12}
          sm={12}
          style={{ zIndex: -1, position: "relative", textAlign: "center" }}
        >
          <div className="BSHelpYouRightSection_socialmedia">
            <h1 className="BSHelpYouHeading_socialmedia">
              Targeting the right markets, maximizing success
            </h1>
            <h2
              className="BSHelpYouPara_socialmedia"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              In today's world, where everyone is on social media, it's crucial
              to effectively manage your online assets. With our social media
              marketing expertise, we'll help you make a splash and connect with
              your ideal customers.
            </h2>
            <div
              data-aos="fade-up"
              data-aos-duration="1000"
              className="circleBSHelpYou_socialmedia"
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default HowServiceWillHelpYouSocialMedia;
