import React, { useState, useEffect } from "react";
import { Container, Grid, Button, Link } from "@material-ui/core";
import "../portal.css";
import Hidden from "@material-ui/core/Hidden";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { BASE_URL } from "../../../../config/config";

const STRATEGY = [
  {
    key: "CONTENT_SECTION_ONE",
    name: "Augmented reality",
    subtext: "AR",
    image: `${BASE_URL}/ar.png`,
    alt: "AR domaincer",
  },
  {
    key: "CONTENT_SECTION_TWO",
    name: "Virtual reality",
    subtext: "VR",
    image: `${BASE_URL}/vr.png`,
    alt: "VR image domaincer",
  },
  {
    key: "CONTENT_SECTION_THREE",
    name: "Mixed Reality",
    subtext: "MR",
    image: `${BASE_URL}/mixed_reality.png`,
    alt: "MR image domaincer",
  },
];

const ServiceContentSectionPortal = () => {
  const [activeTabService, setActiveTabService] = useState(
    "CONTENT_SECTION_ONE"
  );

  return (
    <div>
      <div style={{ paddingTop: 100, paddingBottom: 30 }}>
        <h1 className="service_subheading_portal">
          Building Blocks of the Metaverse
        </h1>
        <h2 className="service_subtext_portal">
          Welcome to the Metaverse, a virtual playground where you can immerse
          yourself in a world of endless possibilities. From AR adventures to VR
          escapades, the Metaverse is your gateway to a whole new realm of media
          and experience.
        </h2>
      </div>

      {/* visible only on desktop, laptop */}
      <Hidden only={["xs"]}>
        <div className="subServiceTabSection_portal">
          {STRATEGY.map((service, index) => (
            <div
              className="subServiceContaincer_portal"
              style={{
                borderColor:
                  service.key === activeTabService ? "#0077FF" : "#FFF",
              }}
              onClick={() => setActiveTabService(service.key)}
            >
              <img
                className="subServiceImg_portal"
                src={service.image}
                alt={service.alt}
              />
              <div>
                <h1 className="subServiceTitle_portal">{service.name}</h1>
                <h2 className="subServiceSubTitle_portal">{service.subtext}</h2>
              </div>
            </div>
          ))}
        </div>
        <div>
          {activeTabService === "CONTENT_SECTION_ONE" && (
            <div className="subServiceParaContainer_portal">
              <Container
                maxWidth="lg"
                className="subServiceFixedSection_portal"
              >
                <div className="glowEffectBrand_portal"></div>

                <div className="serviceContent_portal">
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <h3 className="subServicePara_portal">
                        Step into a whole new dimension with augmented reality!
                        With just a smartphone and a screen, you can enhance
                        your everyday surroundings and bring digital delights to
                        life right before your eyes. Imagine seeing the world in
                        a whole new way, with 3D images and virtual objects
                        seamlessly integrated into your surroundings.
                      </h3>
                    </Grid>
                  </Grid>
                </div>
              </Container>
            </div>
          )}
          {activeTabService === "CONTENT_SECTION_TWO" && (
            <div className="subServiceParaContainer_portal">
              <Container
                maxWidth="lg"
                className="subServiceFixedSection_portal"
              >
                <div className="glowEffectDigital_portal"></div>

                <div className="serviceContent_portal">
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <h3 className="subServicePara_portal">
                        Escape into a world of pure imagination with virtual
                        reality! Slip on a headset and let VR transport you to a
                        whole new reality, crafted by developers just for you.
                        Imagine feeling like you're truly living in a digital
                        world, with sights and sounds so immersive, you won't
                        even realize you're wearing a headset. It is like a
                        portal to endless possibilities, offering a
                        one-of-a-kind sensory experience that's sure to blow
                        your mind.
                      </h3>
                    </Grid>
                  </Grid>
                </div>
              </Container>
            </div>
          )}
          {activeTabService === "CONTENT_SECTION_THREE" && (
            <div className="subServiceParaContainer_portal">
              <Container
                maxWidth="lg"
                className="subServiceFixedSection_portal"
              >
                <div className="glowEffectWorkFlow_portal"></div>

                <div className="serviceContent_portal">
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <h3 className="subServicePara_portal">
                        Experience the best of both worlds with mixed reality!
                        It lets you blend the digital and physical realms like
                        never before, from interactive maps on your smartphone
                        to holographic concerts in your living room. Imagine
                        syncing the music and lights in your home with the touch
                        of a button, or using MR to bring your wildest dreams to
                        life. It is a truly revolutionary technology that's sure
                        to change the way you see the world.
                      </h3>
                    </Grid>
                  </Grid>
                </div>
              </Container>
            </div>
          )}
        </div>
      </Hidden>

      {/* visible only on mobile */}
      <Hidden only={["sm", "lg", "md", "xl"]}>
        <div style={{ position: "relative" }}>
          <ArrowBackIosIcon
            className="subServiceTabLeftArrowHome"
            onClick={() => {
              document.getElementById("strategyScroll").scrollLeft -= 400;
            }}
          />
          <div className="subServiceTabSection_portal" id="strategyScroll">
            <div>
              <div className="subServiceContaincer_portal">
                <img
                  className="subServiceImg_portal"
                  src={`${BASE_URL}/ar.png`}
                  alt="AR image domaincer"
                />
                <div>
                  <h1 className="subServiceTitle_portal">Augmented reality</h1>
                  <h2 className="subServiceSubTitle_portal">AR</h2>
                </div>
              </div>
              <div className="subServiceParaContainer_portal">
                <Container
                  maxWidth="lg"
                  className="subServiceFixedSection_portal"
                >
                  <div className="glowEffectBrand_portal"></div>

                  <div className="serviceContent_portalOne">
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <h3 className="subServicePara_portal">
                          Step into a whole new dimension with augmented
                          reality! With just a smartphone and a screen, you can
                          enhance your everyday surroundings and bring digital
                          delights to life right before your eyes. Imagine
                          seeing the world in a whole new way, with 3D images
                          and virtual objects seamlessly integrated into your
                          surroundings.
                        </h3>
                      </Grid>
                    </Grid>
                  </div>
                </Container>
              </div>
            </div>
            <div>
              <div className="subServiceContaincer_portal">
                <img
                  className="subServiceImg_portal"
                  src={`${BASE_URL}/vr.png`}
                  alt="VR image domaincer"
                />
                <div>
                  <h1 className="subServiceTitle_portal">Virtual reality</h1>
                  <h2 className="subServiceSubTitle_portal">VR</h2>
                </div>
              </div>
              <div className="subServiceParaContainer_portal">
                <Container
                  maxWidth="lg"
                  className="subServiceFixedSection_portal"
                >
                  <div className="glowEffectDigital_portal"></div>

                  <div className="serviceContent_portal">
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <h3 className="subServicePara_portal">
                          Escape into a world of pure imagination with virtual
                          reality! Slip on a headset and let VR transport you to
                          a whole new reality, crafted by developers just for
                          you. Imagine feeling like you're truly living in a
                          digital world, with sights and sounds so immersive,
                          you won't even realize you're wearing a headset. It is
                          like a portal to endless possibilities, offering a
                          one-of-a-kind sensory experience that's sure to blow
                          your mind.
                        </h3>
                      </Grid>
                    </Grid>
                  </div>
                </Container>
              </div>
            </div>
            <div>
              <div className="subServiceContaincer_portal">
                <img
                  className="subServiceImg_portal"
                  src={`${BASE_URL}/mixed_reality.png`}
                  alt="MR image domaincer"
                />
                <div>
                  <h1 className="subServiceTitle_portal">Mixed reality</h1>
                  <h2 className="subServiceSubTitle_portal">MR</h2>
                </div>
              </div>
              <div className="subServiceParaContainer_portal">
                <Container
                  maxWidth="lg"
                  className="subServiceFixedSection_portal"
                >
                  <div className="glowEffectWorkFlow_portal"></div>

                  <div className="serviceContent_portalThree">
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <h3 className="subServicePara_portal">
                          Experience the best of both worlds with mixed reality!
                          It lets you blend the digital and physical realms like
                          never before, from interactive maps on your smartphone
                          to holographic concerts in your living room. Imagine
                          syncing the music and lights in your home with the
                          touch of a button, or using MR to bring your wildest
                          dreams to life. It is a truly revolutionary technology
                          that's sure to change the way you see the world.
                        </h3>
                      </Grid>
                    </Grid>
                  </div>
                </Container>
              </div>
            </div>
          </div>
          <ArrowForwardIosIcon
            className="subServiceTabRightArrowHome"
            onClick={() => {
              document.getElementById("strategyScroll").scrollLeft += 400;
            }}
          />
        </div>
      </Hidden>
    </div>
  );
};

export default ServiceContentSectionPortal;
