import React, { useState } from 'react';
import { Grid, IconButton, TextField } from '@material-ui/core';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Hidden from '@material-ui/core/Hidden';
import { BASE_URL } from '../../../../config/config';

const SocialMediaMarketingQAPage = (props) => {
  const SOCIAL_SLIDER = `${BASE_URL}/service_social_header_image.png`;

  Array.prototype.removeValue = function (val) {
    for (var i = 0; i < this.length; i++) {
      if (this[i] === val) {
        this.splice(i, 1);
        i--;
      }
    }
    return this;
  };

  const [pageName] = useState('socialMediaMarketingScreen');
  const [errorStatus, setErrorStatus] = useState(false);
  const [Q1A, setQ1A] = useState([]);
  const [Q2A, setQ2A] = useState('');
  const [Q3A, setQ3A] = useState([]);
  const [Q4A, setQ4A] = useState('');

  const handleClick = (e) => {
    e.preventDefault();
    const { userDetails } = props;
    const qadata = {
      id: 'socialMediaMarketingQA',
      Q1: {
        question: 'What Social Media you want to target?',
        answer: Q1A,
      },
      Q2: {
        question: 'Who is your target audience?',
        answer: Q2A,
      },
      Q3: {
        question: 'Whats the expectation from Social media?',
        answer: Q3A,
      },
      Q4: {
        question: 'Do you want to advertise in social media?',
        answer: Q4A,
      },
    };

    if (Q1A.length === 0) {
      setErrorStatus(true);
    } else if (Q2A === '') {
      setErrorStatus(true);
    } else if (Q3A.length === 0) {
      setErrorStatus(true);
    } else if (Q4A === '') {
      setErrorStatus(true);
    } else {
      const data = props.serviceData.removeValue(pageName);
      props.prevServiceData.push(pageName);
      props.nextStep(
        data,
        props.prevServiceData,
        qadata,
        userDetails,
        pageName
      );
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  const handleBack = (e) => {
    e.preventDefault();
    const data = props.prevServiceData.removeValue(pageName);
    props.prevStep(data);
  };

  return (
    <div className='quotePage__body'>
      <React.Fragment>
        <Grid container direction='row' justify='center' spacing={2}>
          <Grid item xs={12} sm={10} className='custom__stepOneGrid'>
            <div className='prevStep__div'>
              <IconButton>
                <ArrowBackIcon className='prevStep__btn' onClick={handleBack} />
              </IconButton>
            </div>
            <div>
              <h2 className='quote__question'>
                Social Media Marketing Questions?
              </h2>
            </div>
            <Grid
              container
              direction='row'
              justify='space-between'
              alignItems='center'
            >
              <Hidden only={['sm', 'lg', 'md', 'xl']}>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  className='animated__gif'
                  style={{ textAlign: 'center' }}
                >
                  <img
                    src={SOCIAL_SLIDER}
                    alt='social media marketing image domaincer'
                    className='getQuote_brandingImage floatingUpDown'
                  />
                </Grid>
              </Hidden>
              <Grid item xs={12} sm={8}>
                <div>
                  <div>
                    <h2 className='service__question'>
                      1. What Social Media you want to target?
                    </h2>
                  </div>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name='checkedFacebook'
                          className='custom__checkBox'
                          onChange={(evt, data) => {
                            if (data === true) {
                              setQ1A([...Q1A, 'Facebook']);
                              setErrorStatus(false);
                            } else {
                              const newQ1A = Q1A.removeValue('Facebook');
                              setQ1A(newQ1A);
                            }
                          }}
                        />
                      }
                      label='Facebook'
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name='checkedInstagram'
                          className='custom__checkBox'
                          onChange={(evt, data) => {
                            if (data === true) {
                              setQ1A([...Q1A, 'Instagram']);
                              setErrorStatus(false);
                            } else {
                              const newQ1A = Q1A.removeValue('Instagram');
                              setQ1A(newQ1A);
                            }
                          }}
                        />
                      }
                      label='Instagram'
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name='checkedTwitter'
                          className='custom__checkBox'
                          onChange={(evt, data) => {
                            if (data === true) {
                              setQ1A([...Q1A, 'Twitter']);
                              setErrorStatus(false);
                            } else {
                              const newQ1A = Q1A.removeValue('Twitter');
                              setQ1A(newQ1A);
                            }
                          }}
                        />
                      }
                      label='Twitter'
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name='checkedSnapchat'
                          className='custom__checkBox'
                          onChange={(evt, data) => {
                            if (data === true) {
                              setQ1A([...Q1A, 'Snapchat']);
                              setErrorStatus(false);
                            } else {
                              const newQ1A = Q1A.removeValue('Snapchat');
                              setQ1A(newQ1A);
                            }
                          }}
                        />
                      }
                      label='Snapchat'
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name='checkedWhatsApp'
                          className='custom__checkBox'
                          onChange={(evt, data) => {
                            if (data === true) {
                              setQ1A([...Q1A, 'WhatsApp']);
                              setErrorStatus(false);
                            } else {
                              const newQ1A = Q1A.removeValue('WhatsApp');
                              setQ1A(newQ1A);
                            }
                          }}
                        />
                      }
                      label='WhatsApp'
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name='checkedLinkedIn'
                          className='custom__checkBox'
                          onChange={(evt, data) => {
                            if (data === true) {
                              setQ1A([...Q1A, 'LinkedIn']);
                              setErrorStatus(false);
                            } else {
                              const newQ1A = Q1A.removeValue('LinkedIn');
                              setQ1A(newQ1A);
                            }
                          }}
                        />
                      }
                      label='LinkedIn'
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name='checkedYouTube'
                          className='custom__checkBox'
                          onChange={(evt, data) => {
                            if (data === true) {
                              setQ1A([...Q1A, 'YouTube']);
                              setErrorStatus(false);
                            } else {
                              const newQ1A = Q1A.removeValue('YouTube');
                              setQ1A(newQ1A);
                            }
                          }}
                        />
                      }
                      label='YouTube'
                    />
                  </FormGroup>
                  <div>
                    <h2 className='service__question'>
                      2. Who is your target audience?
                    </h2>
                    <TextField
                      id='outlined-multiline-static'
                      label='Enter your target audience'
                      multiline
                      rows={2}
                      variant='outlined'
                      className='custom__textField'
                      onChange={(e) => {
                        setErrorStatus(false);
                        setQ2A(e.target.value);
                      }}
                    />
                  </div>
                  <div>
                    <h2 className='service__question'>
                      3. Whats the expectation from Social media?
                    </h2>
                  </div>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name='checkedAwareness'
                          className='custom__checkBox'
                          onChange={(evt, data) => {
                            if (data === true) {
                              setQ3A([...Q3A, 'Awareness']);
                              setErrorStatus(false);
                            } else {
                              const newQ3A = Q3A.removeValue('Awareness');
                              setQ3A(newQ3A);
                            }
                          }}
                        />
                      }
                      label='Awareness'
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name='checkedGettingNewClients'
                          className='custom__checkBox'
                          onChange={(evt, data) => {
                            if (data === true) {
                              setQ3A([...Q3A, 'Getting New Client']);
                              setErrorStatus(false);
                            } else {
                              const newQ3A = Q3A.removeValue(
                                'Getting New Client'
                              );
                              setQ3A(newQ3A);
                            }
                          }}
                        />
                      }
                      label='Getting New Clients'
                    />
                  </FormGroup>
                  <div>
                    <h2 className='service__question'>
                      4. Do you want to advertise in social media?
                    </h2>
                    <TextField
                      id='outlined-multiline-static'
                      label='Enter advertise in social media'
                      multiline
                      rows={2}
                      variant='outlined'
                      className='custom__textField'
                      onChange={(e) => {
                        setErrorStatus(false);
                        setQ4A(e.target.value);
                      }}
                    />
                  </div>
                </div>
                {errorStatus && (
                  <p style={{ color: 'red', fontSize: 13, marginTop: 7 }}>
                    Please fill all the fields
                  </p>
                )}
              </Grid>
              <Hidden only={['xs']}>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  className='animated__gif'
                  style={{ textAlign: 'center' }}
                >
                  <img
                    src={SOCIAL_SLIDER}
                    alt='social media marketing image domaincer'
                    className='getQuote_brandingImage floatingUpDown'
                  />
                </Grid>
              </Hidden>
            </Grid>
            <div className='nextStep__btnContainer'>
              <h5 className='nextStep__btn' onClick={handleClick}>
                Continue
              </h5>
              <IconButton>
                <DoubleArrowIcon className='nextStep__btnIcon arrowani' />
              </IconButton>
            </div>
          </Grid>
        </Grid>
      </React.Fragment>
    </div>
  );
};

export default SocialMediaMarketingQAPage;
