import React, { useState } from "react";
import { Grid, IconButton, Hidden } from "@material-ui/core";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import { BASE_URL } from "../../config/config";

const GetQuoteStepFive = (props) => {
  const SERVICE_STRATEGY_ACTIVE_IMAGE = `${BASE_URL}/service_strategy_active_img.png`;
  const SERVICE_STRATEGY_INACTIVE_IMAGE = `${BASE_URL}/service_strategy_inactive_img.png`;

  const SERVICE_DESIGN_ACTIVE_IMAGE = `${BASE_URL}/service_branding_active_img.png`;
  const SERVICE_DESIGN_INACTIVE_IMAGE = `${BASE_URL}/service_branding_inactive_img.png`;

  const SERVICE_DEVELOPMENT_ACTIVE_IMAGE = `${BASE_URL}/service_development_active_img.png`;
  const SERVICE_DEVELOPMENT_INACTIVE_IMAGE = `${BASE_URL}/service_development_inactive_img.png`;

  const SERVICE_MARKETING_ACTIVE_IMAGE = `${BASE_URL}/service_marketing_active_img.png`;
  const SERVICE_MARKETING_INACTIVE_IMAGE = `${BASE_URL}/service_marketing_inactive_img.png`;

  Array.prototype.removeValue = function (val) {
    for (var i = 0; i < this.length; i++) {
      if (this[i] === val) {
        this.splice(i, 1);
        i--;
      }
    }
    return this;
  };

  const [selectedServices, setSelectedServices] = useState([]);
  const [services, setServices] = useState([]);
  const [qutoinaredata, setQutoinareData] = useState({});
  const [errorStatus, setErrorStatus] = useState(false);
  const [pageName, setPageName] = useState("servicesScreen");

  const [state, setState] = useState({
    checkedStrategy: true,
    checkedDesign: false,
    checkedDevelopment: false,
    checkedMarketing: false,
    checkedHumanResources: false,
    checkedBrandStrategy: false,
    checkedDigitalTransformation: false,
    checkedWorkflowAutomation: false,
    checkedBranding: false,
    checkedUiUx: false,
    checkedMotionGraphics: false,
    checkedMobileApps: false,
    checkedPortal: false,
    checkedWebsite: false,
    checkedDevops: false,
    checkedSocialMarketing: false,
    checkedSearchOptimisation: false,
    checkedComtentMarketing: false,
    checkedOnlineReputation: false,
    checkedDedicatedResource: false,
    checkedOutsourcing: false,
  });

  const handleServices = (event, data) => {
    setErrorStatus(false);
    if (event === true) {
      setSelectedServices([...selectedServices, data]);
    } else {
      const newSelectedServices = selectedServices.removeValue(data);
      setSelectedServices(newSelectedServices);
    }
  };

  const handleMainServices = (event, mainServiceName) => {
    if (event === false && mainServiceName === "STRATEGY") {
      const strategyServices = [
        "brandStrategyScreen",
        "digitalTransformationScreen",
        "workflowAutomationScreen",
      ];
      const sortedServices = selectedServices.filter(
        (service) => !strategyServices.includes(service)
      );
      setSelectedServices(sortedServices);
      setState({
        ...state,
        checkedStrategy: false,
        checkedBrandStrategy: false,
        checkedDigitalTransformation: false,
        checkedWorkflowAutomation: false,
      });
    } else if (event === false && mainServiceName === "DESIGN") {
      const designSerives = [
        "brandingScreen",
        "uiuxScreen",
        "motionGraphicsScreen",
      ];
      const sortedServices = selectedServices.filter(
        (service) => !designSerives.includes(service)
      );
      setSelectedServices(sortedServices);
      setState({
        ...state,
        checkedDesign: false,
        checkedBranding: false,
        checkedUiUx: false,
        checkedMotionGraphics: false,
      });
    } else if (event === false && mainServiceName === "DEVELOPMENT") {
      const developmentSerives = [
        "mobileAppsScreen",
        "portalScreen",
        "websiteScreen",
        "devopsScreen",
      ];
      const sortedServices = selectedServices.filter(
        (service) => !developmentSerives.includes(service)
      );
      setSelectedServices(sortedServices);
      setState({
        ...state,
        checkedDevelopment: false,
        checkedMobileApps: false,
        checkedPortal: false,
        checkedWebsite: false,
        checkedDevops: false,
      });
    } else if (event === false && mainServiceName === "MARKETING") {
      const marketingSerives = [
        "socialMediaMarketingScreen",
        "searchEngineOptimisationScreen",
        "contentMarketingScreen",
        "onlineReputationManagementScreen",
      ];
      const sortedServices = selectedServices.filter(
        (service) => !marketingSerives.includes(service)
      );
      setSelectedServices(sortedServices);
      setState({
        ...state,
        checkedMarketing: false,
        checkedSocialMarketing: false,
        checkedSearchOptimisation: false,
        checkedComtentMarketing: false,
        checkedOnlineReputation: false,
      });
    }
  };

  const handleClickDetailed = (e) => {
    console.log("Detailed Button");
    e.preventDefault();
    const { userDetails } = props;
    console.log(
      selectedServices,
      props.prevServiceData,
      qutoinaredata,
      userDetails,
      pageName
    );
    if (selectedServices.length > 0) {
      const { userDetails } = props;
      props.prevServiceData.push(pageName);
      props.nextStep(
        selectedServices,
        props.prevServiceData,
        qutoinaredata,
        userDetails,
        " "
        // pageName
      );
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    } else {
      setErrorStatus(true);
    }
  };

  const handleClickExpressed = (e) => {
    console.log("Express Button");
    e.preventDefault();
    const { userDetails } = props;
    if (selectedServices.length > 0) {
      const userData = {
        name: userDetails.name,
        email: userDetails.email,
        mobile: userDetails.mobile,
        selectedValue: userDetails.selectedValue,
        companyName: userDetails.companyName,
        selectedServices: selectedServices,
      };
      props.nextStep(
        [],
        props.prevServiceData,
        qutoinaredata,
        userData,
        "servicesScreen"
      );
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    } else {
      setErrorStatus(true);
    }
  };

  return (
    <div className="quotePage__body">
      <React.Fragment>
        <Grid container direction="row" justify="center" spacing={3}>
          <Grid item xs={12} sm={10}>
            <div className="prevStep__div"></div>
            <h2 className="quote__question">What services do you require ?</h2>
            <React.Fragment>
              <div style={{ marginTop: 30 }}>
                {/* visible for laptop, desktop */}
                <Hidden only={["xs"]}>
                  <div className="brandingService__row">
                    <div className="service__container">
                      <IconButton
                        onClick={() => {
                          setState({
                            ...state,
                            checkedStrategy: !state.checkedStrategy,
                          });
                          handleMainServices(
                            !state.checkedStrategy,
                            "STRATEGY"
                          );
                        }}
                      >
                        <img
                          src={
                            state.checkedStrategy === true
                              ? SERVICE_STRATEGY_ACTIVE_IMAGE
                              : SERVICE_STRATEGY_INACTIVE_IMAGE
                          }
                          alt="enquiry strategy icon domaincer"
                          className="service__buttonIcon"
                        />
                      </IconButton>
                      <span className="service__title">Strategy</span>
                    </div>

                    <div className="service__container">
                      <IconButton
                        onClick={() => {
                          setState({
                            ...state,
                            checkedDesign: !state.checkedDesign,
                          });
                          handleMainServices(!state.checkedDesign, "DESIGN");
                        }}
                      >
                        <img
                          src={
                            state.checkedDesign === true
                              ? SERVICE_DESIGN_ACTIVE_IMAGE
                              : SERVICE_DESIGN_INACTIVE_IMAGE
                          }
                          alt="enquiry design icon domaincer"
                          className="service__buttonIcon"
                        />
                      </IconButton>
                      <span className="service__title">Design</span>
                    </div>

                    <div className="service__container">
                      <IconButton
                        onClick={() => {
                          setState({
                            ...state,
                            checkedDevelopment: !state.checkedDevelopment,
                          });
                          handleMainServices(
                            !state.checkedDevelopment,
                            "DEVELOPMENT"
                          );
                        }}
                      >
                        <img
                          src={
                            state.checkedDevelopment === true
                              ? SERVICE_DEVELOPMENT_ACTIVE_IMAGE
                              : SERVICE_DEVELOPMENT_INACTIVE_IMAGE
                          }
                          alt="enquiry development icon domaincer"
                          className="service__buttonIcon"
                        />
                      </IconButton>
                      <span className="service__title">Development</span>
                    </div>
                    <div className="service__container">
                      <IconButton
                        onClick={() => {
                          setState({
                            ...state,
                            checkedMarketing: !state.checkedMarketing,
                          });
                          handleMainServices(
                            !state.checkedMarketing,
                            "MARKETING"
                          );
                        }}
                      >
                        <img
                          src={
                            state.checkedMarketing === true
                              ? SERVICE_MARKETING_ACTIVE_IMAGE
                              : SERVICE_MARKETING_INACTIVE_IMAGE
                          }
                          alt="enquiry marketing icon domaincer"
                          className="service__buttonIcon"
                        />
                      </IconButton>
                      <span className="service__title">Marketing</span>
                    </div>
                  </div>
                </Hidden>

                {/* visible for only for mobile */}
                <Hidden only={["sm", "lg", "md", "xl"]}>
                  <div className="brandingService__rowMobileOne">
                    <div className="service__container">
                      <IconButton
                        onClick={() => {
                          setState({
                            ...state,
                            checkedStrategy: !state.checkedStrategy,
                          });
                          handleMainServices(
                            !state.checkedStrategy,
                            "STRATEGY"
                          );
                        }}
                      >
                        <img
                          src={
                            state.checkedStrategy === true
                              ? SERVICE_STRATEGY_ACTIVE_IMAGE
                              : SERVICE_STRATEGY_INACTIVE_IMAGE
                          }
                          alt="enquiry strategy icon domaincer"
                          className="service__buttonIcon"
                        />
                      </IconButton>
                      <span className="service__title">Strategy</span>
                    </div>

                    <div className="service__container">
                      <IconButton
                        onClick={() => {
                          setState({
                            ...state,
                            checkedDesign: !state.checkedDesign,
                          });
                          handleMainServices(!state.checkedDesign, "DESIGN");
                        }}
                      >
                        <img
                          src={
                            state.checkedDesign === true
                              ? SERVICE_DESIGN_ACTIVE_IMAGE
                              : SERVICE_DESIGN_INACTIVE_IMAGE
                          }
                          alt="enquiry design icon domaincer"
                          className="service__buttonIcon"
                        />
                      </IconButton>
                      <span className="service__title">Design</span>
                    </div>
                  </div>
                  <div className="brandingService__rowMobileOne">
                    <div className="service__container">
                      <IconButton
                        onClick={() => {
                          setState({
                            ...state,
                            checkedDevelopment: !state.checkedDevelopment,
                          });
                          handleMainServices(
                            !state.checkedDevelopment,
                            "DEVELOPMENT"
                          );
                        }}
                      >
                        <img
                          src={
                            state.checkedDevelopment === true
                              ? SERVICE_DEVELOPMENT_ACTIVE_IMAGE
                              : SERVICE_DEVELOPMENT_INACTIVE_IMAGE
                          }
                          alt="enquiry development icon domaincer"
                          className="service__buttonIcon"
                        />
                      </IconButton>
                      <span className="service__title">Development</span>
                    </div>
                    <div className="service__container">
                      <IconButton
                        onClick={() => {
                          setState({
                            ...state,
                            checkedMarketing: !state.checkedMarketing,
                          });
                          handleMainServices(
                            !state.checkedMarketing,
                            "MARKETING"
                          );
                        }}
                      >
                        <img
                          src={
                            state.checkedMarketing === true
                              ? SERVICE_MARKETING_ACTIVE_IMAGE
                              : SERVICE_MARKETING_INACTIVE_IMAGE
                          }
                          alt="enquiry marketing icon domaincer"
                          className="service__buttonIcon"
                        />
                      </IconButton>
                      <span className="service__title">Marketing</span>
                    </div>
                  </div>
                </Hidden>
              </div>
            </React.Fragment>

            <div style={{ marginTop: 50, marginBottom: -20 }}>
              <h4 className="quote__question">Select Sub Category</h4>
            </div>
            {errorStatus === true ? (
              <React.Fragment>
                <div className="subService__row">
                  <p style={{ color: "red" }}>
                    Please select atleast one service to proceed!
                  </p>
                </div>
              </React.Fragment>
            ) : (
              ""
            )}
            {state.checkedStrategy && (
              <React.Fragment>
                <div className="subService__row">
                  <div
                    className={
                      state.checkedBrandStrategy === true
                        ? "subService__sectionActive"
                        : "subService__section"
                    }
                  >
                    <span
                      className={
                        state.checkedBrandStrategy === true
                          ? "subService__titleActive"
                          : "subService__title"
                      }
                      onClick={() => {
                        setState({
                          ...state,
                          ["checkedBrandStrategy"]: !state.checkedBrandStrategy,
                        });
                        handleServices(
                          !state.checkedBrandStrategy,
                          "brandStrategyScreen"
                        );
                      }}
                    >
                      Brand Strategy
                    </span>
                  </div>
                  <div
                    className={
                      state.checkedDigitalTransformation === true
                        ? "subService__sectionActive"
                        : "subService__section"
                    }
                  >
                    <span
                      className={
                        state.checkedDigitalTransformation === true
                          ? "subService__titleActive"
                          : "subService__title"
                      }
                      onClick={() => {
                        setState({
                          ...state,
                          ["checkedDigitalTransformation"]: !state.checkedDigitalTransformation,
                        });
                        handleServices(
                          !state.checkedDigitalTransformation,
                          "digitalTransformationScreen"
                        );
                      }}
                    >
                      Digital Transformation
                    </span>
                  </div>
                  <div
                    className={
                      state.checkedWorkflowAutomation === true
                        ? "subService__sectionActive"
                        : "subService__section"
                    }
                  >
                    <span
                      className={
                        state.checkedWorkflowAutomation === true
                          ? "subService__titleActive"
                          : "subService__title"
                      }
                      onClick={() => {
                        setState({
                          ...state,
                          ["checkedWorkflowAutomation"]: !state.checkedWorkflowAutomation,
                        });
                        handleServices(
                          !state.checkedWorkflowAutomation,
                          "workflowAutomationScreen"
                        );
                      }}
                    >
                      Workflow Automation
                    </span>
                  </div>
                </div>
              </React.Fragment>
            )}

            {state.checkedDesign && (
              <React.Fragment>
                <div className="subService__row">
                  <div
                    className={
                      state.checkedBranding === true
                        ? "subService__sectionActive"
                        : "subService__section"
                    }
                  >
                    <span
                      className={
                        state.checkedBranding === true
                          ? "subService__titleActive"
                          : "subService__title"
                      }
                      onClick={() => {
                        setState({
                          ...state,
                          ["checkedBranding"]: !state.checkedBranding,
                        });
                        handleServices(
                          !state.checkedBranding,
                          "brandingScreen"
                        );
                      }}
                    >
                      Branding
                    </span>
                  </div>
                  <div
                    className={
                      state.checkedUiUx === true
                        ? "subService__sectionActive"
                        : "subService__section"
                    }
                  >
                    <span
                      className={
                        state.checkedUiUx === true
                          ? "subService__titleActive"
                          : "subService__title"
                      }
                      onClick={() => {
                        setState({
                          ...state,
                          ["checkedUiUx"]: !state.checkedUiUx,
                        });
                        handleServices(!state.checkedUiUx, "uiuxScreen");
                      }}
                    >
                      UI / UX
                    </span>
                  </div>
                  <div
                    className={
                      state.checkedMotionGraphics === true
                        ? "subService__sectionActive"
                        : "subService__section"
                    }
                  >
                    <span
                      className={
                        state.checkedMotionGraphics === true
                          ? "subService__titleActive"
                          : "subService__title"
                      }
                      onClick={() => {
                        setState({
                          ...state,
                          ["checkedMotionGraphics"]: !state.checkedMotionGraphics,
                        });
                        handleServices(
                          !state.checkedMotionGraphics,
                          "motionGraphicsScreen"
                        );
                      }}
                    >
                      Motion Graphics
                    </span>
                  </div>
                </div>
              </React.Fragment>
            )}

            {state.checkedDevelopment && (
              <React.Fragment>
                <div className="subService__row">
                  <div
                    className={
                      state.checkedMobileApps === true
                        ? "subService__sectionActive"
                        : "subService__section"
                    }
                  >
                    <span
                      className={
                        state.checkedMobileApps === true
                          ? "subService__titleActive"
                          : "subService__title"
                      }
                      onClick={() => {
                        setState({
                          ...state,
                          ["checkedMobileApps"]: !state.checkedMobileApps,
                        });
                        handleServices(
                          !state.checkedMobileApps,
                          "mobileAppsScreen"
                        );
                      }}
                    >
                      Mobile App Development
                    </span>
                  </div>
                  <div
                    className={
                      state.checkedPortal === true
                        ? "subService__sectionActive"
                        : "subService__section"
                    }
                  >
                    <span
                      className={
                        state.checkedPortal === true
                          ? "subService__titleActive"
                          : "subService__title"
                      }
                      onClick={() => {
                        setState({
                          ...state,
                          ["checkedPortal"]: !state.checkedPortal,
                        });
                        handleServices(!state.checkedPortal, "portalScreen");
                      }}
                    >
                      Portal Development
                    </span>
                  </div>
                  <div
                    className={
                      state.checkedWebsite === true
                        ? "subService__sectionActive"
                        : "subService__section"
                    }
                  >
                    <span
                      className={
                        state.checkedWebsite === true
                          ? "subService__titleActive"
                          : "subService__title"
                      }
                      onClick={() => {
                        setState({
                          ...state,
                          ["checkedWebsite"]: !state.checkedWebsite,
                        });
                        handleServices(!state.checkedWebsite, "websiteScreen");
                      }}
                    >
                      Website Development
                    </span>
                  </div>
                  <div
                    className={
                      state.checkedDevops === true
                        ? "subService__sectionActive"
                        : "subService__section"
                    }
                  >
                    <span
                      className={
                        state.checkedDevops === true
                          ? "subService__titleActive"
                          : "subService__title"
                      }
                      onClick={() => {
                        setState({
                          ...state,
                          ["checkedDevops"]: !state.checkedDevops,
                        });
                        handleServices(!state.checkedDevops, "devopsScreen");
                      }}
                    >
                      DevOps
                    </span>
                  </div>
                </div>
              </React.Fragment>
            )}

            {state.checkedMarketing && (
              <React.Fragment>
                <div className="subService__row">
                  <div
                    className={
                      state.checkedSocialMarketing === true
                        ? "subService__sectionActive"
                        : "subService__section"
                    }
                  >
                    <span
                      className={
                        state.checkedSocialMarketing === true
                          ? "subService__titleActive"
                          : "subService__title"
                      }
                      onClick={() => {
                        setState({
                          ...state,
                          ["checkedSocialMarketing"]: !state.checkedSocialMarketing,
                        });
                        handleServices(
                          !state.checkedSocialMarketing,
                          "socialMediaMarketingScreen"
                        );
                      }}
                    >
                      Social Media Marketing
                    </span>
                  </div>
                  <div
                    className={
                      state.checkedSearchOptimisation === true
                        ? "subService__sectionActive"
                        : "subService__section"
                    }
                  >
                    <span
                      className={
                        state.checkedSearchOptimisation === true
                          ? "subService__titleActive"
                          : "subService__title"
                      }
                      onClick={() => {
                        setState({
                          ...state,
                          ["checkedSearchOptimisation"]: !state.checkedSearchOptimisation,
                        });
                        handleServices(
                          !state.checkedSearchOptimisation,
                          "searchEngineOptimisationScreen"
                        );
                      }}
                    >
                      Search Engine Optimisation
                    </span>
                  </div>
                  {/* <div
                    className={
                      state.checkedComtentMarketing === true
                        ? "subService__sectionActive"
                        : "subService__section"
                    }
                  >
                    <span
                      className={
                        state.checkedComtentMarketing === true
                          ? "subService__titleActive"
                          : "subService__title"
                      }
                      onClick={() => {
                        setState({
                          ...state,
                          ["checkedComtentMarketing"]: !state.checkedComtentMarketing
                        });
                        handleServices(
                          !state.checkedComtentMarketing,
                          "contentMarketingScreen"
                        );
                      }}
                    >
                      Content Marketing
                    </span>
                  </div> */}
                  {/* <div
                    className={
                      state.checkedOnlineReputation === true
                        ? "subService__sectionActive"
                        : "subService__section"
                    }
                  >
                    <span
                      className={
                        state.checkedOnlineReputation === true
                          ? "subService__titleActive"
                          : "subService__title"
                      }
                      onClick={() => {
                        setState({
                          ...state,
                          ["checkedOnlineReputation"]: !state.checkedOnlineReputation
                        });
                        handleServices(
                          !state.checkedOnlineReputation,
                          "onlineReputationManagementScreen"
                        );
                      }}
                    >
                      Online Reputation Management
                    </span>
                  </div> */}
                </div>
              </React.Fragment>
            )}

            {/* {state.checkedHumanResources && (
              <React.Fragment>
                <div className="subService__row">
                  <div
                    className={
                      state.checkedDedicatedResource === true
                        ? "subService__sectionActive"
                        : "subService__section"
                    }
                  >
                    <span
                      className={
                        state.checkedDedicatedResource === true
                          ? "subService__titleActive"
                          : "subService__title"
                      }
                      onClick={() => {
                        setState({
                          ...state,
                          ["checkedDedicatedResource"]: !state.checkedDedicatedResource
                        });
                        handleServices(
                          !state.checkedDedicatedResource,
                          "dedicatedResourceScreen"
                        );
                      }}
                    >
                      Dedicated Resource
                    </span>
                  </div>

                  <div
                    className={
                      state.checkedOutsourcing === true
                        ? "subService__sectionActive"
                        : "subService__section"
                    }
                  >
                    <span
                      className={
                        state.checkedOutsourcing === true
                          ? "subService__titleActive"
                          : "subService__title"
                      }
                      onClick={() => {
                        setState({
                          ...state,
                          ["checkedOutsourcing"]: !state.checkedOutsourcing
                        });
                        handleServices(
                          !state.checkedOutsourcing,
                          "outsourcingScreen"
                        );
                      }}
                    >
                      Outsourcing
                    </span>
                  </div>
                </div>
              </React.Fragment>
            )} */}

            {props.userDetails.selectedValue === "EXPRESSED" ? (
              <div className="nextStep__btnContainer">
                <h5 className="nextStep__btn" onClick={handleClickExpressed}>
                  Continue
                </h5>
                <IconButton>
                  <DoubleArrowIcon className="nextStep__btnIcon arrowani" />
                </IconButton>
              </div>
            ) : (
              <div className="nextStep__btnContainer">
                <h5 className="nextStep__btn" onClick={handleClickDetailed}>
                  Continue
                </h5>
                <IconButton>
                  <DoubleArrowIcon className="nextStep__btnIcon arrowani" />
                </IconButton>
              </div>
            )}
          </Grid>
        </Grid>
      </React.Fragment>
    </div>
  );
};

export default GetQuoteStepFive;
