import React from "react";
import "./applications.css";
import { Grid, Card } from "@material-ui/core";
import { BASE_URL } from "../../../../config/config";

const Benefits = [
  {
    img: `${BASE_URL}/blockFinance.png`,
    heading: "FINANCIAL SERVICES",
    description:
      "With a decentralized network of digitally recorded transactions, companies can reduce costs and streamline processes like never before. It's like a digital marathon, with blockchain as the ultimate guide to a smoother, more cost-effective business model.",
  },
  {
    img: `${BASE_URL}/blockLogistics.png`,
    heading: "MONITOR LOGISTICS",
    description:
      "With the power of automation and reduced paperwork, companies can streamline processes and make their supply chains more secure. By using blockchain to verify the authenticity and origin of products, you can ensure that your business is running smoothly and without any hiccups.",
  },
  {
    img: `${BASE_URL}/blockHealthCare.png`,
    heading: "HEALTHCARE",
    description:
      "With its secure ledger technology, researchers can unlock the secrets of the genetic code by safely transferring patient medical records and managing the drug supply chain.",
  },
  {
    img: `${BASE_URL}/blockVoting.png`,
    heading: "VOTING",
    description:
      "Using blockchain technology can make sure that nobody votes twice, only eligible voters are able to vote, and votes cannot be tampered with. What's more, it can increase access to voting by making it as simple as pressing a few buttons on your smartphone.",
  },
  {
    img: `${BASE_URL}/blockLending.png`,
    heading: "LENDING",
    description:
      "Smart contracts built on the blockchain allow certain events to automatically trigger things like a service payment, a margin call, full repayment of the loan, and release of collateral.",
  },
  {
    img: `${BASE_URL}/blockInsurance.png`,
    heading: "INSURANCE",
    description:
      "Using smart contracts on a blockchain can provide greater transparency for customers and insurance providers. Recording all claims on a blockchain would keep customers from making duplicate claims for the same event.",
  },
  {
    img: `${BASE_URL}/blockInformation.png`,
    heading: "SECURE INFORMATION",
    description:
      "Keeping data such as your Social Security number, date of birth, and other identifying information on a public ledger (e.g., a blockchain) may actually be more secure than current systems more susceptible to hacks.",
  },
  {
    img: `${BASE_URL}/blockIot.png`,
    heading: "INTERNET OF THINGS",
    description:
      "Blockchain technology can provide greater security by storing passwords and other data on a decentralized network instead of a centralized server. Additionally, it offers protection against data tampering since a blockchain is practically immutable.",
  },
  {
    img: `${BASE_URL}/blockStorage.png`,
    heading: "DATA STORAGE",
    description:
      "Adding blockchain technology to a data storage solution can provide greater security and integrity. Since data can be stored in a decentralized manner, it will be more difficult to hack into and wipe out all the data on the network.",
  },
];

const BlockchainApplications = () => {
  return (
    <>
      <div className="benefits-container-blockchain">
        <div className="card-header-blockchain">
          <h4 className="card-header">Blockchain Use Cases</h4>
        </div>
        <div>
          <Grid container spacing={1}>
            {Benefits.map((each) => (
              <>
                <Grid xs={12} sm={4} className="card-inner-grid">
                  <Card className="card-blockchain">
                    <div className="card-container">
                      <div className="image-container">
                        <img src={each.img} className="block-images"></img>
                      </div>
                      {/* <hr className="card-line"></hr> */}

                      <div className="card-innerContainer">
                        <h4 className="each-header-block">{each.heading}</h4>
                        <p className="each-description-block">
                          {each.description}
                        </p>
                      </div>
                    </div>
                  </Card>
                </Grid>
              </>
            ))}
          </Grid>
        </div>
      </div>
    </>
  );
};

export default BlockchainApplications;
