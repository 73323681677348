import React from 'react';
// import COMPANY_LOGO_ANIMATION from '../../images/domaincer-loader.gif';
import './loader.css';
import { BASE_URL } from '../../config/config';

const LoaderComponent = () => {
  return (
    <React.Fragment>
      <img
        src={`${BASE_URL}/domaincer-loader.gif`}
        className='domaincer__loader'
      />
    </React.Fragment>
  );
};

export default LoaderComponent;
