import React, { useState } from "react";
import { Grid, IconButton, TextField } from "@material-ui/core";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";


const OutsourcingQAPage = (props) => {
  Array.prototype.removeValue = function (val) {
    for (var i = 0; i < this.length; i++) {
      if (this[i] === val) {
        this.splice(i, 1);
        i--;
      }
    }
    return this;
  };

  const [pageName, setPageName] = useState("outsourcingScreen");
  const handleClick = (e) => {
    e.preventDefault();
    const { userDetails } = props;
    const qadata = {
      id: "outsourcingQA",
      Q1A: "Digital Values",
      Q2A: "Digital Passage",
    };
    const data = props.serviceData.removeValue(pageName);
    props.prevServiceData.push(pageName);
    props.nextStep(data, props.prevServiceData, qadata, userDetails, pageName);
  };

  const handleBack = (e) => {
    e.preventDefault();
    const data = props.prevServiceData.removeValue(pageName);
    props.prevStep(data);
  };

  return (
    <div className="quotePage__body">
      <React.Fragment>
        <Grid container direction="row" justify="center" spacing={2}>
          <Grid item xs={10} className="custom__stepOneGrid">
            <div className="prevStep__div">
              <IconButton>
                <ArrowBackIcon className="prevStep__btn" onClick={handleBack} />
              </IconButton>

              {/* <p className='quote__questionDescription'>Step __/05</p> */}
            </div>
            <div>
              <h2 className="quote__question">Outsourcing Questions?</h2>
            </div>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <Grid item xs={8}>
                <div>
                  <div>
                    <h2 className="service__question">
                      1. Choose the one that fits you best.?
                    </h2>
                  </div>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="checkedLogoDesign"
                          className="custom__checkBox"
                        />
                      }
                      label="The emblem"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="checkedUIUX"
                          className="custom__checkBox"
                        />
                      }
                      label="Mascots"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="checkedExplainerVideo"
                          className="custom__checkBox"
                        />
                      }
                      label="Wordmarks"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="checkedLogoAnimation"
                          className="custom__checkBox"
                        />
                      }
                      label="Pictorial marks"
                    />
                  </FormGroup>
                  <div>
                    <h2 className="service__question">
                      2. Do you have any specific imagery in mind for your
                      piece?
                    </h2>
                    <TextField
                      id="outlined-multiline-static"
                      label="Reference"
                      multiline
                      rows={4}
                      defaultValue="enter something"
                      variant="outlined"
                      className="custom__textField"
                    />
                  </div>
                </div>
              </Grid>


            </Grid>
            <div className="nextStep__btnContainer" onClick={handleClick}>
              <h5 className="nextStep__btn">Continue</h5>
              <IconButton>
                <DoubleArrowIcon className="nextStep__btnIcon arrowani" />
              </IconButton>
            </div>
          </Grid>
        </Grid>
      </React.Fragment>
    </div>
  );
};

export default OutsourcingQAPage;
