import React, { useState } from "react";
import { Grid, IconButton, TextField } from "@material-ui/core";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Hidden from '@material-ui/core/Hidden';
import { BASE_URL } from '../../../../config/config';

const DigitalTransformationQAPage = (props) => {
  const UIUX_SLIDER= `${BASE_URL}/service_digital_header_image.png`;
  Array.prototype.removeValue = function (val) {
    for (var i = 0; i < this.length; i++) {
      if (this[i] === val) {
        this.splice(i, 1);
        i--;
      }
    }
    return this;
  };

  const [pageName] = useState("digitalTransformationScreen");
  const [errorStatus, setErrorStatus] = useState(false);
  const [Q1A, setQ1A] = useState("");

  const handleClick = (e) => {
    e.preventDefault();
    const { userDetails } = props;
    const qadata = {
      id: "digitalTransformationQA",
      Q1: {
        question:
          "How do you want to transform your business to digital? We want to hear from your side.",
        answer: Q1A,
      },
    };
    if (Q1A.length > 0) {
      const data = props.serviceData.removeValue(pageName);
      props.prevServiceData.push(pageName);
      props.nextStep(
        data,
        props.prevServiceData,
        qadata,
        userDetails,
        pageName
      );
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    } else {
      setErrorStatus(true);
    }
  };

  const handleBack = (e) => {
    e.preventDefault();
    const data = props.prevServiceData.removeValue(pageName);
    props.prevStep(data);
  };

  return (
    <div className="quotePage__body">
      <React.Fragment>
        <Grid container direction="row" justify="center" spacing={2}>
          <Grid item xs={12} sm={10} className="custom__stepOneGrid">
            <div className="prevStep__div">
              <IconButton>
                <ArrowBackIcon className="prevStep__btn" onClick={handleBack} />
              </IconButton>
            </div>
            <div>
              <h2 className="quote__question">Digital Transformation</h2>
            </div>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
               <Hidden only={['sm', 'lg', 'md', 'xl']}>
              <Grid item xs={12} sm={4} className="animated__gif" style={{ textAlign: 'center' }}>
                <img
                  src={UIUX_SLIDER}
                  alt='Best digital transformation company image domaincer'
                  className="getQuote_brandingImage floatingUpDown"
                />
              </Grid>
              </Hidden>
              <Grid item xs={12} sm={8}>
                <div>
                  <div>
                    <h2 className="service__question">
                      1. How do you want to transform your business to digital?
                      We want to hear from your side.
                    </h2>
                    <TextField
                      id="outlined-multiline-static"
                      label="Reference"
                      multiline
                      rows={4}
                      defaultValue="Enter something"
                      variant="outlined"
                      className="custom__textField"
                      onChange={(e) => {
                        setErrorStatus(false);
                        setQ1A(e.target.value)
                      }}
                    />
                  </div>
                </div>
                {errorStatus && (
                  <p style={{ color: "red", fontSize: 13, marginTop: 7 }}>
                    Please enter something to proceed.
                  </p>
                )}
              </Grid>
              <Hidden only={['xs']}>
              <Grid item xs={12} sm={4} className="animated__gif">
                <img
                  src={UIUX_SLIDER}
                  alt='Best digital transformation company image domaincer'
                  className="getQuote_brandingImage floatingUpDown"
                />
              </Grid>
              </Hidden>
            </Grid>
            <div className="nextStep__btnContainer">
              <h5 className="nextStep__btn" onClick={handleClick}>
                Continue
              </h5>
              <IconButton>
                <DoubleArrowIcon className="nextStep__btnIcon arrowani" />
              </IconButton>
            </div>
          </Grid>
        </Grid>
      </React.Fragment>
    </div>
  );
};

export default DigitalTransformationQAPage;
