import React, { useEffect } from "react";
import { Container, Grid, Avatar, Button, Card } from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import AOS from "aos";
import { BASE_URL } from "../../../config/config";
import FollowUs from "../../../commons/followus/followus.component";

const CaseStudyKKRice = (props) => {
  const RYZONE_REACT_IMAGE = `${BASE_URL}/ryzone_casestudy_react_image.png`;
  const RYZONE_MOBILE_IMAGE = `${BASE_URL}/ryzone_casestudy_mobile_image.png`;
  const KKRICE_PROCESS_IMAGE = `${BASE_URL}/kkrice_casestudy_process_image.png`;
  const RYZONE_PHONE_ICON = `${BASE_URL}/ryzone_casestudy_mobile_icon.png`;
  const RYZONE_REACT_ICON = `${BASE_URL}/ryzone_casestudy_react_icon.png`;
  const KKRICE_PROCESS_ICON = `${BASE_URL}/kkrice_casestudy_process_icon.png`;
  const RYZONE_LOGO_ICON = `${BASE_URL}/kkrice_casestudy_logo.png`;
  const RYZONE_IMAGE_HEADER = `${BASE_URL}/kkrice_casestudy_header.png`;

  useEffect(() => {
    AOS.init();
  });

  return (
    <div class="mobileMarginTop">
      <FollowUs history={props.history} />
      <Container>
        <Grid container>
          <Grid xs={12} sm={6} style={{ alignSelf: "center" }}>
            <img
              src={RYZONE_IMAGE_HEADER}
              alt="KK Inc icon domaincer"
              className="riceportalHeaderImage"
            />
          </Grid>
          <Grid xs={12} sm={5}>
            <div className="logoTextContaincer">
              <Avatar src={RYZONE_LOGO_ICON} className="caseStudiesLogo" />
              <h1 className="caseStudieTitle">KK Inc Portal</h1>
            </div>
            <h2 className="caseStudiesSubTitle">
              <strong>Workflow Automation for Rice Industry</strong>
            </h2>
            <p className="caseStudiesDescript">
              Workflow automation starts with a detailed analysis of the rice
              business, logics, process followed, target audience, demographics,
              etc. It involved both tweaking the process by providing a strategy
              to minimise the functions and secondly by implementing the manual
              process digitally. This predominantly increased the profitability
              and ease of data access at fingertips.
            </p>
            <div className="labelsSection" style={{ alignItems: "center" }}>
              <div className="labelsContainerReact_ryzone">
                <img
                  src={RYZONE_REACT_ICON}
                  className="labelsIcon"
                  style={{ height: "87%" }}
                  alt="react development icon domaincer"
                />
                <p className="labelsText">React Portal</p>
              </div>

              <div className="labelsContainer_kkrice">
                <img
                  src={KKRICE_PROCESS_ICON}
                  alt="process automation 3d icon domaincer"
                  className="labelsIcon_kkrice"
                />
                <p className="labelsText">Process Automation</p>
              </div>

              <div className="labelsContainer_ryzone">
                <img
                  src={RYZONE_PHONE_ICON}
                  alt="app development icon domaincer"
                  className="labelsIcon"
                />
                <p className="labelsText">Range of Apps</p>
              </div>
            </div>

            <div style={{ marginBottom: 100 }}>
              <a href="https://admin.kkricemills.in/#/login" target="_blank">
                <Button className="viewWebsiteContainer">
                  Visit Portal
                  <ChevronRightIcon className="viewWebsite__arrow arrowani" />
                </Button>
              </a>
            </div>
          </Grid>
        </Grid>
      </Container>

      <div className="caseStudiesSectionTwo_ryzone">
        <Container>
          <Grid container>
            <Grid
              xs={12}
              sm={6}
              style={{ position: "relative" }}
              data-aos="fade-down"
              data-aos-duration="1000"
            >
              <div className="logoTextSectionTwo">
                <div className="circleDirector__caseStudy"></div>
                <div className="dottedLineContainer">
                  <p className="dottedLine1">. . . . . . . . .</p>
                  <p className="dottedLine1">. . . . . . . . .</p>
                  <p className="dottedLine1">. . . . . . . . .</p>
                  <p className="dottedLine1">. . . . . . . . .</p>
                  <p className="dottedLine1">. . . . . . . . .</p>
                  <p className="dottedLine1">. . . . . . . . .</p>
                  <p className="dottedLine1">. . . . . . . . .</p>
                  <p className="dottedLine1">. . . . . . . . .</p>
                </div>
                <div className="casestudy_icon_row">
                  <h1 className="caseStudieSectionTwoTitle">React Portal</h1>
                </div>
              </div>
              <p className="caseStudiesSectionTwoDescript">
                The Super admin portal is developed in the state of art
                technology - React JS. Giants like Amazon Web Services &
                Flipkart have already started developing their portal for top
                notch performance and speed. KK Inc gets its react portal with
                all functionalities like Dashboard, Sales, Merchant CRM, Vendors
                CRM, Reports, Help Desk, etc.
              </p>
            </Grid>
            <Grid xs={12} sm={6}>
              <img
                src={RYZONE_REACT_IMAGE}
                className="caseStudiesRightImg"
                alt="react stage icon domaincer"
              />
            </Grid>
          </Grid>
        </Container>
      </div>

      <div className="caseStudiesSectionThree_ryzone">
        <Container>
          <Grid container>
            <Grid
              xs={12}
              sm={6}
              style={{ position: "relative" }}
              data-aos="fade-down"
              data-aos-duration="1000"
            >
              <img
                src={KKRICE_PROCESS_IMAGE}
                className="caseStudiesleftImg"
                alt="process automation stage icon domaincer"
              />
            </Grid>
            <Grid xs={12} sm={6}>
              <div className="logoTextSectionTwo">
                <div className="casestudy_icon_row">
                  <h1 className="caseStudieSectionThreeTitle">
                    Process Automation
                  </h1>
                </div>
              </div>
              <p className="caseStudiesSectionThreeDescript">
                {" "}
                From Paddy to Rice, it involves a huge man power and supply
                chain to make the rice reach customers. With a digital hand in
                Process automation, it makes a tight workflow automation
                increasing the potential to reach wider audience pan india. The
                process and workflow automation is done with react and react
                native technology for the marketing executives, merchants, super
                admin and drivers.
              </p>
            </Grid>
          </Grid>
        </Container>
      </div>

      <div className="caseStudiesSectionTwo_ryzone">
        <Container>
          <Grid container>
            <Grid
              xs={12}
              sm={6}
              style={{ position: "relative" }}
              data-aos="fade-down"
              data-aos-duration="1000"
            >
              <div className="logoTextSectionTwo">
                <div className="circleDirector__caseStudy"></div>
                <div className="dottedLineContainer">
                  <p className="dottedLine1">. . . . . . . . .</p>
                  <p className="dottedLine1">. . . . . . . . .</p>
                  <p className="dottedLine1">. . . . . . . . .</p>
                  <p className="dottedLine1">. . . . . . . . .</p>
                  <p className="dottedLine1">. . . . . . . . .</p>
                  <p className="dottedLine1">. . . . . . . . .</p>
                  <p className="dottedLine1">. . . . . . . . .</p>
                  <p className="dottedLine1">. . . . . . . . .</p>
                </div>
                <div className="casestudy_icon_row">
                  <h1 className="caseStudieSectionTwoTitle">Range of Apps</h1>
                </div>
              </div>

              <p className="caseStudiesSectionTwoDescript">
                {" "}
                KK Inc portal involves the full spectrum of services starting
                for all the stake holders. Mobile app is developed for
                Merchants, Marketing Executives and Drivers while web app is
                used for the super admin to manage orders, manage customers,
                merchants, marketing executives and billing.
              </p>
            </Grid>
            <Grid xs={12} sm={6}>
              <img
                src={RYZONE_MOBILE_IMAGE}
                className="caseStudiesRightImg"
                alt="mobile app stage icon domaincer"
              />
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
};

export default CaseStudyKKRice;
