import React from "react";
import "./benefitsStyles.css";
import { Grid, Card } from "@material-ui/core";
import { BASE_URL } from "../../../../config/config";

const Benefits = [
  {
    img: `${BASE_URL}/blockBuisnessValue.png`,
    heading: "New Business Value",
    description:
      "From reinforcing supply chains to speeding up cross-border transactions and safeguarding data using a decentralized ledger, it helps create a smoother and more effective enterprise model. Like a lock and key, blockchain helps secure your business and keep it running smoothly.",
  },
  {
    img: `${BASE_URL}/blockRisk.png`,
    heading: "Reduced Risk",
    description:
      "Using a decentralized, unchanging and restricted ledger, you can create secure and efficient digital agreements called smart contracts to automate processes like payment settlement, ID verification and legal arbitration. It's like having a virtual assistant for your business, working behind the scenes to streamline and secure your processes.",
  },
  {
    img: `${BASE_URL}/blockTrusted.png`,
    heading: "Trusted Ecosystem",
    description:
      "With a blockchain, your data becomes tamper-proof, reliable and more easily accessible. The transactions within your network become traceable and verifiable, creating a strong foundation of trust with your partners, suppliers and customers. It's like building a castle of security and reliability for your business!",
  },
];

const BlockChainBenefits = () => {
  return (
    <>
      <div className="benefits-container-blockchain">
        <div className="card-header-blockchain">
          <h4 className="card-header">Blockchain Benefits</h4>
        </div>
        <div className="card-grid">
          <Grid container spacing={1}>
            {Benefits.map((each) => (
              <>
                <Grid xs={12} sm={4} className="card-inner-grid">
                  <Card className="card-blockchain">
                    <div className="card-container">
                      <div className="image-container">
                        <div className="circle">
                          <img src={each.img} className="block-images"></img>
                        </div>
                      </div>
                      {/* <hr className="card-line"></hr> */}

                      <div className="card-innerContainer">
                        <h4 className="each-header-block">{each.heading}</h4>
                        <p className="each-description-block">
                          {each.description}
                        </p>
                      </div>
                    </div>
                  </Card>
                </Grid>
              </>
            ))}
          </Grid>
        </div>
      </div>
    </>
  );
};

export default BlockChainBenefits;
