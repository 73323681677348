import React, { useState, useEffect } from "react";
import "./website_stats.css";
import { Grid, Modal, Fade, Backdrop, Hidden } from "@material-ui/core";
import PlayCircleFilledWhiteIcon from "@material-ui/icons/PlayCircleFilledWhite";
import CloseIcon from "@material-ui/icons/Close";
import AOS from "aos";
import { BASE_URL } from "../../../../config/config";
import CountUp from "react-countup";
import Skeleton from "react-loading-skeleton";
import LazyLoad from "react-lazyload";
import styled, { keyframes } from "styled-components";

const ImageWrapper = styled.div`
  position: relative;
`;

const Placeholder = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
`;

const ValubleStats = () => {
  const MOBILE_APPS = `${BASE_URL}/tab_mobile_icon3.png`;
  const STATS_MAINIMG = `${BASE_URL}/Stats-value.jpg`;
  const STATS_MAINIMG_MOBILE = `${BASE_URL}/Statsmobile.jpg`;

  const refPlaceholder = React.useRef();
  const [openModal, setModalOpen] = useState(false);

  useEffect(() => {
    AOS.init();
  });

  const moving = () => {
    // Init
    var container = document.getElementById("container"),
      inner = document.getElementById("inner");

    // Mouse
    var mouse = {
      _x: 0,
      _y: 0,
      x: 0,
      y: 0,
      updatePosition: function (event) {
        var e = event || window.event;
        this.x = e.clientX - this._x;
        this.y = (e.clientY - this._y) * -1;
      },
      setOrigin: function (e) {
        this._x = e.offsetLeft + Math.floor(e.offsetWidth / 2);
        this._y = e.offsetTop + Math.floor(e.offsetHeight / 2);
      },
      show: function () {
        return "(" + this.x + ", " + this.y + ")";
      },
    };

    // Track the mouse position relative to the center of the container.
    mouse.setOrigin(container);

    //-----------------------------------------

    var counter = 0;
    var updateRate = 10;
    var isTimeToUpdate = function () {
      return counter++ % updateRate === 0;
    };

    //-----------------------------------------

    var onMouseEnterHandler = function (event) {
      update(event);
    };

    var onMouseLeaveHandler = function () {
      inner.style = "";
    };

    var onMouseMoveHandler = function (event) {
      if (isTimeToUpdate()) {
        update(event);
      }
    };

    //-----------------------------------------

    var update = function (event) {
      mouse.updatePosition(event);
      updateTransformStyle(
        (mouse.y / inner.offsetHeight / 2).toFixed(2),
        (mouse.x / inner.offsetWidth / 2).toFixed(2)
      );
    };

    var updateTransformStyle = function (x, y) {
      var style = "rotateX(" + x + "deg) rotateY(" + y + "deg)";
      inner.style.transform = style;
      inner.style.webkitTransform = style;
      inner.style.mozTransform = style;
      inner.style.msTransform = style;
      inner.style.oTransform = style;
    };

    //-----------------------------------------

    container.onmouseenter = onMouseEnterHandler;
    container.onmouseleave = onMouseLeaveHandler;
    container.onmousemove = onMouseMoveHandler;
  };

  const handleOpen = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  return (
    <div className="StatsContainer">
      <Grid container>
        <Hidden only={["xs"]}>
          <Grid xs={12} sm={12}>
            <div>
              <h1 className="StatsTitle">Domaincer in Numbers</h1>
            </div>
            <React.Fragment>
              <ImageWrapper>
                <LazyLoad>
                  <img
                    alt=""
                    src={STATS_MAINIMG}
                    style={{ width: "100%", height: "100%" }}
                  />
                </LazyLoad>
              </ImageWrapper>
            </React.Fragment>
            <div
              data-aos="fade-up"
              data-aos-duration="1000"
              className="telegramImgStats"
            >
              <img
                className="telegramImg floatingVerticalIcon"
                alt="rocket image 3d domaincer"
                src={`${BASE_URL}/about_rocket_image.png`}
              />
            </div>
          </Grid>
        </Hidden>
        <Hidden only={["sm", "lg", "md", "xl"]}>
          <div>
            <h1 className="StatsTitle">Domaincer in Numbers</h1>
          </div>
          <React.Fragment>
            <ImageWrapper>
              <LazyLoad>
                <img
                  alt=""
                  src={STATS_MAINIMG_MOBILE}
                  style={{ width: "100%", height: "100%" }}
                />
              </LazyLoad>
            </ImageWrapper>
          </React.Fragment>
        </Hidden>
        <div
          className="MobileAppStatsContainer"
          data-aos="fade-right"
          data-aos-duration="1000"
        >
          <span className="diamondiconMobileApp">&#9830;</span>
          <div className="mobileappsInnerContainer">
            <CountUp
              className="statsValueMobileAppText"
              start={0}
              end={100}
              duration={10}
              suffix="+"
            />
          </div>
          <h1 className="statsMobileAppName">Websites</h1>
        </div>
        <div
          className="DownloadStatsContainer"
          data-aos="fade-right"
          data-aos-duration="2000"
        >
          <span className="diamondiconDownload">&#9830;</span>
          <div className="downloadInnerContainer">
            <CountUp
              className="statsValueDownloadText"
              start={0}
              end={25}
              duration={10}
              suffix="M+"
            />
          </div>
          <h1 className="statsDownloadName">Combined Visitors</h1>
        </div>
        <div
          className="YearsStatsContainer"
          data-aos="fade-right"
          data-aos-duration="1500"
        >
          <span className="diamondiconYears">&#9830;</span>
          <div className="yearsInnerContainer">
            <CountUp
              className="statsValueYearsText"
              start={0}
              end={15}
              duration={10}
              suffix="+"
            />
          </div>
          <h1 className="statsYearsName">Years Experience</h1>
        </div>
        <div
          className="ReviewStatsContainer"
          data-aos="fade-right"
          data-aos-duration="1500"
        >
          <span className="diamondiconReview">&#9830;</span>
          <div className="ReviewInnerContainer">
            <CountUp
              className="statsValueReviewText"
              start={0}
              end={250}
              duration={10}
              suffix="+"
            />
          </div>
          <h1 className="statsReviewName">5 Star Reviews</h1>
        </div>
      </Grid>
    </div>
  );
};

export default ValubleStats;
