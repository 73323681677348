import React, { useState, useEffect } from "react";
import { Container, Grid } from "@material-ui/core";
import "./bannersection.css";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import Hidden from "@material-ui/core/Hidden";
import { Link } from "react-router-dom";
import { BASE_URL } from "../../../config/config";
import Skeleton from "react-loading-skeleton";
import LazyLoad from "react-lazyload";
import styled, { keyframes } from "styled-components";
import { createPopup } from "@typeform/embed";
import "@typeform/embed/build/css/popup.css";

const ImageWrapper = styled.div`
  position: relative;
`;

const Placeholder = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
`;

const BannerSection = () => {
  const DOMAINCER_VIDEO = `${BASE_URL}/domaincer_banner_video_new.mp4`;
  const [skeletonLoader, setskeletonLoader] = useState(true);
  const [videoSection, setvideoSection] = useState(false);
  const refPlaceholder = React.useRef();

  const removePlaceholder = () => {
    refPlaceholder.current.remove();
    setskeletonLoader(false);
  };

  const openPopup = (event) => {
    createPopup("r3BLnIaA").open();
  };

  return (
    <div>
      <Container fixed>
        <Grid container>
          <Hidden only={["xs"]}>
            <Grid xs={12} sm={6}>
              <div className="bannerLeftSection">
                <h1 className="bannertitle">Let's make IT great together.</h1>
                <h2 className="bannersubtext">
                  Dream IT, Design IT & Succeed IT with Domaincer! From wireframe
                  to reality, we'll help you craft the perfect product before
                  your competitors catch up. Make your move today !{" "}
                </h2>
                <Link
                  onClick={() => {
                    openPopup();
                  }}
                >
                  <h2 className="bannerleftlink">
                    Let's Talk Business{" "}
                    <DoubleArrowIcon className="signupArrow arrowani" />
                  </h2>
                </Link>
              </div>
            </Grid>
            <Grid xs={12} sm={6} className="header_temp_image_section">
              <React.Fragment>
                {skeletonLoader === false ? (
                  <LazyLoad>
                    <video
                      style={{ width: "100%", height: "100%" }}
                      loop
                      autoPlay
                      muted
                      playsInline
                      preload="yes"
                    >
                      <source src={DOMAINCER_VIDEO} type="video/mp4" />
                    </video>
                  </LazyLoad>
                ) : (
                  <React.Fragment>
                    {skeletonLoader === true && (
                      <Skeleton width="80%" height="400px" />
                    )}
                    <ImageWrapper>
                      <Placeholder ref={refPlaceholder} />
                      <LazyLoad>
                        <img
                          src={`${BASE_URL}/Logo-with-Registered.png`}
                          alt="best landing page image domaincer"
                          className="header_temp_image"
                          onLoad={removePlaceholder}
                          onError={removePlaceholder}
                        />
                      </LazyLoad>
                    </ImageWrapper>
                  </React.Fragment>
                )}
              </React.Fragment>
            </Grid>
          </Hidden>

          <Hidden only={["sm", "lg", "md", "xl"]}>
            <Grid xs={12} sm={6} className="header_temp_image_section">
              <React.Fragment>
                {skeletonLoader === false ? (
                  <LazyLoad>
                    <video
                      style={{ width: "100%", height: "100%" }}
                      loop
                      autoPlay
                      muted
                      playsInline
                      preload="yes"
                    >
                      <source src={DOMAINCER_VIDEO} type="video/mp4" />
                    </video>
                  </LazyLoad>
                ) : (
                  <React.Fragment>
                    {skeletonLoader === true && (
                      <Skeleton width="100%" height="300px" />
                    )}
                    <ImageWrapper>
                      <Placeholder ref={refPlaceholder} />
                      <LazyLoad>
                        <img
                          src={`${BASE_URL}/Logo-with-Registered.png`}
                          className="header_temp_image"
                          onLoad={removePlaceholder}
                          onError={removePlaceholder}
                        />
                      </LazyLoad>
                    </ImageWrapper>
                  </React.Fragment>
                )}
              </React.Fragment>
            </Grid>
            <Grid xs={12} sm={6}>
              <div className="bannerLeftSection">
                <h1 className="bannertitle"> Let's make IT great together.</h1>
                <h2 className="bannersubtext">
                  Domaincer helps in crafting your dream product right from
                  wireframe to success. Make a move before your competitor does.{" "}
                </h2>
                <Link
                  onClick={() => {
                    openPopup();
                  }}
                >
                  <h2 className="bannerleftlink">
                    Let's Talk Business{" "}
                    <DoubleArrowIcon className="signupArrow arrowani" />
                  </h2>
                </Link>
              </div>
            </Grid>
          </Hidden>
        </Grid>
      </Container>
    </div>
  );
};

export default BannerSection;
