import React, { useEffect, useState } from "react";
import { Avatar, Container, Modal, Fade, Backdrop } from "@material-ui/core";
import "./FAQsection.css";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import CloseIcon from "@material-ui/icons/Close";
import AOS from "aos";
import { Link } from "react-router-dom";
import { BASE_URL } from "../../../config/config";
import { createPopup } from "@typeform/embed";
import "@typeform/embed/build/css/popup.css";

const FAQSection = () => {
  const DOMAINCER_FAQ_ICON = `${BASE_URL}/faq_flat_icon.svg`;

  const [queationOne, setQueationOne] = useState(false);
  const [queationTwo, setQueationTwo] = useState(false);
  const [queationThree, setQueationThree] = useState(false);
  const [queationFour, setQueationFour] = useState(false);
  const [queationFive, setQueationFive] = useState(false);
  const [queationSix, setQueationSix] = useState(false);
  const [queationSeven, setQueationSeven] = useState(false);
  const [queationEight, setQueationEight] = useState(false);
  // const [queationNine, setQueationNine] = useState(false);
  const [queationTen, setQueationTen] = useState(false);
  const [queationEleven, setQueationEleven] = useState(false);

  useEffect(() => {
    AOS.init();
  });

  const openPopup = (event) => {
    createPopup("r3BLnIaA").open();
  };

  return (
    <div
      className="sectionone"
      id="black"
      data-aos="fade-up"
      data-aos-duration="3000"
    >
      <Container>
        <div className="container">
          <h2 data-aos="fade-up" className="subtext">
            You've found India's finest digital agency. Cease the search,{" "}
            <span className="heading1">
              Brandify your Business and Rise to the Top of your Industry!{" "}
            </span>
          </h2>
          <Link>
            <h2
              data-aos="fade-up"
              className="explore"
              onClick={() => {
                openPopup();
              }}
            >
              Get Quote <DoubleArrowIcon className="exploreArrow arrowani" />
            </h2>
          </Link>
        </div>
      </Container>
      <div data-aos="fade-up" className="carselsectionone">
        <div className="cardSection" onClick={() => setQueationOne(true)}>
          <Avatar src={DOMAINCER_FAQ_ICON} className="smalllogo" />
          <h2 className="cardTitle">
            <span className="cardSubtitle">
              How much does it cost for{" "}
              <span className="cardTitleTextBold">digital marketing?</span>
            </span>
          </h2>
        </div>
        <div className="cardSection" onClick={() => setQueationTwo(true)}>
          <Avatar src={DOMAINCER_FAQ_ICON} className="smalllogo" />
          <h2 className="cardTitle">
            <span className="cardSubtitle">
              Why should you{" "}
              <span className="cardTitleTextBold">hire a digital agency?</span>
            </span>
          </h2>
        </div>
        <div className="cardSection" onClick={() => setQueationThree(true)}>
          <Avatar src={DOMAINCER_FAQ_ICON} className="smalllogo" />
          <h2 className="cardTitle">
            <span className="cardSubtitle">
              How much does it cost for{" "}
              <span className="cardTitleTextBold">mobile app development?</span>
            </span>
          </h2>
        </div>
        <div className="cardSection" onClick={() => setQueationFour(true)}>
          <Avatar src={DOMAINCER_FAQ_ICON} className="smalllogo" />
          <h2 className="cardTitle">
            <span className="cardSubtitle">
              How to{" "}
              <span className="cardTitleTextBold">
                choose the best digital agency?
              </span>
            </span>
          </h2>
        </div>
        <div className="cardSection" onClick={() => setQueationFive(true)}>
          <Avatar src={DOMAINCER_FAQ_ICON} className="smalllogo" />
          <h2 className="cardTitle">
            <span className="cardSubtitle">
              Does <span className="cardTitleTextBold">SEO work in 2023?</span>
            </span>
          </h2>
        </div>
        <div className="cardSection" onClick={() => setQueationSix(true)}>
          <Avatar src={DOMAINCER_FAQ_ICON} className="smalllogo" />
          <h2 className="cardTitle">
            <span className="cardSubtitle">
              What services does it include in{" "}
              <span className="cardTitleTextBold">digital marketing?</span>
            </span>
          </h2>
        </div>
        <div className="cardSection" onClick={() => setQueationSeven(true)}>
          <Avatar src={DOMAINCER_FAQ_ICON} className="smalllogo" />
          <h2 className="cardTitle">
            <span className="cardSubtitle">
              What’s the{" "}
              <span className="cardTitleTextBold">
                latest trend in Mobile Apps?
              </span>
            </span>
          </h2>
        </div>
        <div className="cardSection" onClick={() => setQueationEight(true)}>
          <Avatar src={DOMAINCER_FAQ_ICON} className="smalllogo" />
          <h2 className="cardTitle">
            <span className="cardSubtitle">
              How much does it cost for{" "}
              <span className="cardTitleTextBold">Branding?</span>
            </span>
          </h2>
        </div>

        <div className="cardSection" onClick={() => setQueationTen(true)}>
          <Avatar src={DOMAINCER_FAQ_ICON} className="smalllogo" />
          <h2 className="cardTitle">
            <span className="cardSubtitle">
              How much does it cost for{" "}
              <span className="cardTitleTextBold">
                Website development cost?
              </span>
            </span>
          </h2>
        </div>
        <div className="cardSection" onClick={() => setQueationEleven(true)}>
          <Avatar src={DOMAINCER_FAQ_ICON} className="smalllogo" />
          <h2 className="cardTitle">
            <span className="cardSubtitle">
              Benefits of using a{" "}
              <span className="cardTitleTextBold">
                digital agency over a freelancer?
              </span>
            </span>
          </h2>
        </div>
      </div>

      <div className="carselsectionotwo">
        <div className="cardSection" onClick={() => setQueationOne(true)}>
          <Avatar src={DOMAINCER_FAQ_ICON} className="smalllogo" />
          <h2 className="cardTitle">
            <span className="cardSubtitle">
              How much does it cost for{" "}
              <span className="cardTitleTextBold">digital marketing?</span>
            </span>
          </h2>
        </div>
        <div className="cardSection" onClick={() => setQueationTwo(true)}>
          <Avatar src={DOMAINCER_FAQ_ICON} className="smalllogo" />
          <h2 className="cardTitle">
            <span className="cardSubtitle">
              Why should you{" "}
              <span className="cardTitleTextBold">hire a digital agency?</span>
            </span>
          </h2>
        </div>
        <div className="cardSection" onClick={() => setQueationThree(true)}>
          <Avatar src={DOMAINCER_FAQ_ICON} className="smalllogo" />
          <h2 className="cardTitle">
            <span className="cardSubtitle">
              How much does it cost for{" "}
              <span className="cardTitleTextBold">mobile app development?</span>
            </span>
          </h2>
        </div>
        <div className="cardSection" onClick={() => setQueationFour(true)}>
          <Avatar src={DOMAINCER_FAQ_ICON} className="smalllogo" />
          <h2 className="cardTitle">
            <span className="cardSubtitle">
              How to{" "}
              <span className="cardTitleTextBold">
                choose the best digital agency?
              </span>
            </span>
          </h2>
        </div>
        <div className="cardSection" onClick={() => setQueationFive(true)}>
          <Avatar src={DOMAINCER_FAQ_ICON} className="smalllogo" />
          <h2 className="cardTitle">
            <span className="cardSubtitle">
              Does <span className="cardTitleTextBold">SEO work in 2023?</span>
            </span>
          </h2>
        </div>
        <div className="cardSection" onClick={() => setQueationSix(true)}>
          <Avatar src={DOMAINCER_FAQ_ICON} className="smalllogo" />
          <h2 className="cardTitle">
            <span className="cardSubtitle">
              What services does it include in{" "}
              <span className="cardTitleTextBold">digital marketing?</span>
            </span>
          </h2>
        </div>
        <div className="cardSection" onClick={() => setQueationSeven(true)}>
          <Avatar src={DOMAINCER_FAQ_ICON} className="smalllogo" />
          <h2 className="cardTitle">
            <span className="cardSubtitle">
              What’s the{" "}
              <span className="cardTitleTextBold">
                latest trend in Mobile Apps?
              </span>
            </span>
          </h2>
        </div>
        <div className="cardSection" onClick={() => setQueationEight(true)}>
          <Avatar src={DOMAINCER_FAQ_ICON} className="smalllogo" />
          <h2 className="cardTitle">
            <span className="cardSubtitle">
              How much does it cost for{" "}
              <span className="cardTitleTextBold">Branding?</span>
            </span>
          </h2>
        </div>

        <div className="cardSection" onClick={() => setQueationTen(true)}>
          <Avatar src={DOMAINCER_FAQ_ICON} className="smalllogo" />
          <h2 className="cardTitle">
            <span className="cardSubtitle">
              How much does it cost for{" "}
              <span className="cardTitleTextBold">
                Website development cost?
              </span>
            </span>
          </h2>
        </div>
        <div className="cardSection" onClick={() => setQueationEleven(true)}>
          <Avatar src={DOMAINCER_FAQ_ICON} className="smalllogo" />
          <h2 className="cardTitle">
            <span className="cardSubtitle">
              Benefits of using a{" "}
              <span className="cardTitleTextBold">
                digital agency over a freelancer?
              </span>
            </span>
          </h2>
        </div>
      </div>

      <div className="carselsectionothird">
        <div className="cardSection" onClick={() => setQueationOne(true)}>
          <Avatar src={DOMAINCER_FAQ_ICON} className="smalllogo" />
          <h2 className="cardTitle">
            <span className="cardSubtitle">
              How much does it cost for{" "}
              <span className="cardTitleTextBold">digital marketing?</span>
            </span>
          </h2>
        </div>
        <div className="cardSection" onClick={() => setQueationTwo(true)}>
          <Avatar src={DOMAINCER_FAQ_ICON} className="smalllogo" />
          <h2 className="cardTitle">
            <span className="cardSubtitle">
              Why should you{" "}
              <span className="cardTitleTextBold">hire a digital agency?</span>
            </span>
          </h2>
        </div>
        <div className="cardSection" onClick={() => setQueationThree(true)}>
          <Avatar src={DOMAINCER_FAQ_ICON} className="smalllogo" />
          <h2 className="cardTitle">
            <span className="cardSubtitle">
              How much does it cost for{" "}
              <span className="cardTitleTextBold">mobile app development?</span>
            </span>
          </h2>
        </div>
        <div className="cardSection" onClick={() => setQueationFour(true)}>
          <Avatar src={DOMAINCER_FAQ_ICON} className="smalllogo" />
          <h2 className="cardTitle">
            <span className="cardSubtitle">
              How to{" "}
              <span className="cardTitleTextBold">
                choose the best digital agency?
              </span>
            </span>
          </h2>
        </div>
        <div className="cardSection" onClick={() => setQueationFive(true)}>
          <Avatar src={DOMAINCER_FAQ_ICON} className="smalllogo" />
          <h2 className="cardTitle">
            <span className="cardSubtitle">
              Does <span className="cardTitleTextBold">SEO work in 2023?</span>
            </span>
          </h2>
        </div>
        <div className="cardSection" onClick={() => setQueationSix(true)}>
          <Avatar src={DOMAINCER_FAQ_ICON} className="smalllogo" />
          <h2 className="cardTitle">
            <span className="cardSubtitle">
              What services does it include in{" "}
              <span className="cardTitleTextBold">digital marketing?</span>
            </span>
          </h2>
        </div>
        <div className="cardSection" onClick={() => setQueationSeven(true)}>
          <Avatar src={DOMAINCER_FAQ_ICON} className="smalllogo" />
          <h2 className="cardTitle">
            <span className="cardSubtitle">
              What’s the{" "}
              <span className="cardTitleTextBold">
                latest trend in Mobile Apps?
              </span>
            </span>
          </h2>
        </div>
        <div className="cardSection" onClick={() => setQueationEight(true)}>
          <Avatar src={DOMAINCER_FAQ_ICON} className="smalllogo" />
          <h2 className="cardTitle">
            <span className="cardSubtitle">
              How much does it cost for{" "}
              <span className="cardTitleTextBold">Branding?</span>
            </span>
          </h2>
        </div>

        <div className="cardSection" onClick={() => setQueationTen(true)}>
          <Avatar src={DOMAINCER_FAQ_ICON} className="smalllogo" />
          <h2 className="cardTitle">
            <span className="cardSubtitle">
              How much does it cost for{" "}
              <span className="cardTitleTextBold">
                Website development cost?
              </span>
            </span>
          </h2>
        </div>
        <div className="cardSection" onClick={() => setQueationEleven(true)}>
          <Avatar src={DOMAINCER_FAQ_ICON} className="smalllogo" />
          <h2 className="cardTitle">
            <span className="cardSubtitle">
              Benefits of using a{" "}
              <span className="cardTitleTextBold">
                digital agency over a freelancer?
              </span>
            </span>
          </h2>
        </div>
      </div>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="modal_section"
        open={queationOne}
        onClose={() => setQueationOne(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={queationOne}>
          <div className="modalContainer">
            <div className="modalHeader">
              <h2 id="transition-modal-title" className="modal_heading">
                How much does it cost for digital marketing?
              </h2>
              <CloseIcon
                onClick={() => setQueationOne(false)}
                className="closeBtnModal"
              />
            </div>
            <h3 id="transition-modal-description" className="modal_para">
              Digital Marketing is a big spectrum which includes SEO, SMO, ORM,
              PPC, Content Marketing, Forum Marketing, etc. Choosing the right
              digital marketing service for your business is the key to success.
              For every brand, all digital marketing services won’t be required.
              A detailed case study has to be done by the digital marketing
              agency before choosing services suitable for the business.
            </h3>
            <h3 id="transition-modal-description" className="modal_para">
              After drilling that down, agency shall discuss resources to be
              used, marketing spend, budget, intensity, target audience,
              demographics, etc. Cost depends on the quality of work and
              resources spent based on the services rendered. There is no bar or
              package in digital that can come with a budget - Every project is
              customised and unique.
            </h3>
            <h3 id="transition-modal-description" className="modal_para">
              At Domaincer we have a dedicated Digital Strategy team who can
              analyse, detect and provide customised digital marketing
              solutions.
            </h3>
          </div>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="modal_section"
        open={queationTwo}
        onClose={() => setQueationTwo(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={queationTwo}>
          <div className="modalContainer">
            <div className="modalHeader">
              <h2 id="transition-modal-title" className="modal_heading">
                Why should you hire a digital agency?
              </h2>
              <CloseIcon
                onClick={() => setQueationTwo(false)}
                className="closeBtnModal"
              />
            </div>
            <h3 id="transition-modal-description" className="modal_para">
              If you're looking to get the most out of your digital marketing
              efforts, consider hiring a digital agency like Domaincer. With
              expertise in a wide range of domains including design, marketing,
              development, cyber security, and data analytics, Domaincer has the
              skills and resources to help your business thrive in the digital
              world. A digital agency can bring a wealth of industry knowledge,
              flexibility, objectivity, talent, and partnership to your
              marketing efforts. They can help you develop a customized,
              data-driven marketing plan that is tailored to your business goals
              and target audience, and provide you with measurable results.
              Choose a digital agency like Domaincer and watch your business
              succeed in the digital world.
            </h3>
            {/* <h3 id="transition-modal-description" className="modal_para">
              A digital agency can help in multiple areas based on their
              expertise. For instance, Domaincer has over 12 services including,
              Digital Transformation, Workflow automation, Branding, UI/UX,
              Motion Graphics, Mobile App Development, Web Portal Development,
              Website Development, DevOps, Social Media Marketing, Search Engine
              Optimisation(SEO).
            </h3> */}
          </div>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="modal_section"
        open={queationThree}
        onClose={() => setQueationThree(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={queationThree}>
          <div className="modalContainer">
            <div className="modalHeader">
              <h2 id="transition-modal-title" className="modal_heading">
                How much does it cost for mobile app development?
              </h2>
              <CloseIcon
                onClick={() => setQueationThree(false)}
                className="closeBtnModal"
              />
            </div>
            <h3 id="transition-modal-description" className="modal_para">
              As the demand for mobile apps continues to grow, now is the
              perfect time to invest in a high-quality app for your business.
              But with so many factors influencing the cost of app development,
              it can be tough to know where to start.
            </h3>
            <h3 id="transition-modal-description" className="modal_para">
              Here's the good news: whether you need a simple app or a more
              complex one with dynamic features like database integration and
              user roles, a professional digital agency like Domaincer has the
              expertise and resources to deliver a top-notch product that meets
              your needs and budget. With a team of skilled developers and a
              flexible approach to project scope, Domaincer can help you bring
              your vision to life – and set your business up for success in the
              digital world.
            </h3>
          </div>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="modal_section"
        open={queationFour}
        onClose={() => setQueationFour(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={queationFour}>
          <div className="modalContainer">
            <div className="modalHeader">
              <h2 id="transition-modal-title" className="modal_heading">
                How to choose the best digital agency?
              </h2>
              <CloseIcon
                onClick={() => setQueationFour(false)}
                className="closeBtnModal"
              />
            </div>
            <h3 id="transition-modal-description" className="modal_para">
              Selecting the best digital agency is not always straightforward,
              and it may not be feasible to go through a trial-and-error
              process. One aspect that can be indicative of a trustworthy agency
              is the length of time they have been in operation. For instance,
              Domaincer has more than a decade of experience in various areas
              such as branding, web development, mobile app development, and
              SEO. To gauge the quality, commitment, and responsiveness of a
              digital agency, it may be helpful to start with smaller projects.
            </h3>
            {/* <h3 id="transition-modal-description" className="modal_para">
              There are several factors where companies cannot fake. One such
              thing is the establishment year of the company. An older firm
              equals experience and excellence. Domaincer has about 12 years of
              experience in Branding, UI/UX, Motion Graphics, Mobile App
              Development, Web Portal Development, Website Development, DevOps,
              Social Media Marketing, Search Engine Optimisation(SEO).
            </h3>
            <h3 id="transition-modal-description" className="modal_para">
              Try out with smaller projects to get to know the gist of Quality,
              commitment and responsiveness of the digital agency.
            </h3> */}
          </div>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="modal_section"
        open={queationFive}
        onClose={() => setQueationFive(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={queationFive}>
          <div className="modalContainer">
            <div className="modalHeader">
              <h2 id="transition-modal-title" className="modal_heading">
                Does SEO work in 2023?
              </h2>
              <CloseIcon
                onClick={() => setQueationFive(false)}
                className="closeBtnModal"
              />
            </div>
            <h3 id="transition-modal-description" className="modal_para">
              The future of SEO is always a hot topic, and with the rapid
              changes we've seen in recent years, it's no surprise that people
              are wondering what's in store for the next few years. One thing is
              for certain: SEO is here to stay! So what can we expect for SEO in
              2023?
            </h3>
            <h3 id="transition-modal-description" className="modal_para">
              One trend to keep an eye on is the rise of voice search
              optimization. With more and more households turning to smart
              speakers and devices, it's crucial for businesses to optimize
              their content for voice search.
            </h3>
            <h3 id="transition-modal-description" className="modal_para">
              Artificial intelligence is also playing a big role in the
              evolution of SEO. Google's RankBrain is just one example of how AI
              is being used to improve search results and deliver the best
              possible experience to users.
            </h3>
            <h3 id="transition-modal-description" className="modal_para">
              So don't miss out on these SEO trends – stay ahead of the game and
              keep your website at the top of the search rankings in 2023!
            </h3>

            {/* <li id="transition-modal-description" className="modal_para">
              Voice Search Optimization - It is expected that 50% of the
              households are going to become smart households in the coming
              years. You are slacking if you haven’t invested in these yet!
            </li>
            <li id="transition-modal-description" className="modal_para">
              Artificial Intelligence Search - Over the last couple of years, AI
              has become a pioneering force in the evolution of SEO. Google is
              also trying to serve its customers the best using RankBrain
            </li>
            <li id="transition-modal-description" className="modal_para">
              Grab a seat in the Knowledge Panel - Google’s knowledge Panel is
              an essential part of the search matrix. Google started allowing
              entities to claim their knowledge panel thereby giving them access
              to edit and control the information available to users. It is a
              great advantage as it gives easy access to your site but also
              displays trust.
            </li> */}
          </div>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="modal_section"
        open={queationSix}
        onClose={() => setQueationSix(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={queationSix}>
          <div className="modalContainer">
            <div className="modalHeader">
              <h2 id="transition-modal-title" className="modal_heading">
                What services does it include in digital marketing?
              </h2>
              <CloseIcon
                onClick={() => setQueationSix(false)}
                className="closeBtnModal"
              />
            </div>
            <h3 id="transition-modal-description" className="modal_para">
              The question you have written is grammatically correct. To provide
              a complete answer, digital marketing typically includes the
              following services:
            </h3>
            <li id="transition-modal-description" className="modal_para">
              Search Engine Optimisation (SEO)
            </li>
            <li id="transition-modal-description" className="modal_para">
              Pay per click (PPC)
            </li>
            <li id="transition-modal-description" className="modal_para">
              Social media marketing
            </li>
            <li id="transition-modal-description" className="modal_para">
              Analytics and conversion rate optimisation
            </li>
            <li id="transition-modal-description" className="modal_para">
              Content creating and marketing
            </li>
            <li id="transition-modal-description" className="modal_para">
              Email Marketing
            </li>
            <li id="transition-modal-description" className="modal_para">
              Marketing Automation.
            </li>
            <li id="transition-modal-description" className="modal_para">
              Influencer marketing
            </li>
            <li id="transition-modal-description" className="modal_para">
              Video marketing
            </li>
          </div>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="modal_section"
        open={queationSeven}
        onClose={() => setQueationSeven(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={queationSeven}>
          <div className="modalContainer">
            <div className="modalHeader">
              <h2 id="transition-modal-title" className="modal_heading">
                What’s the latest trend in Mobile Apps?
              </h2>
              <CloseIcon
                onClick={() => setQueationSeven(false)}
                className="closeBtnModal"
              />
            </div>
            <h3 id="transition-modal-description" className="modal_para">
              The mobile app development industry is advancing at high-speed and
              surviving the progress, you have adapt the changing mobile app
              trend. The latest trends are :
            </h3>
            <li id="transition-modal-description" className="modal_para">
              Augmented reality (AR) and virtual reality (VR)
            </li>
            <li id="transition-modal-description" className="modal_para">
              IoT
            </li>
            <li id="transition-modal-description" className="modal_para">
              Artificial Intelligence (AI) and Chatbots
            </li>
            <li id="transition-modal-description" className="modal_para">
              Beacons Technology
            </li>
            <li id="transition-modal-description" className="modal_para">
              Wearable Apps
            </li>
            <li id="transition-modal-description" className="modal_para">
              Digital payment integration
            </li>
            <li id="transition-modal-description" className="modal_para">
              On-demand app development
            </li>
          </div>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="modal_section"
        open={queationEight}
        onClose={() => setQueationEight(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={queationEight}>
          <div className="modalContainer">
            <div className="modalHeader">
              <h2 id="transition-modal-title" className="modal_heading">
                How much does it cost for Branding?
              </h2>
              <CloseIcon
                onClick={() => setQueationEight(false)}
                className="closeBtnModal"
              />
            </div>
            <h3 id="transition-modal-description" className="modal_para">
              The cost of building a successful brand depends on various
              factors, including the competitive landscape and the effort
              required to effectively communicate your brand message and style.
              Building a successful brand requires ongoing investment, including
              discipline, consistency, and persistence. The return on this
              investment can be significant, but it is important to keep in mind
              that it takes time and effort to achieve success.
            </h3>
          </div>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="modal_section"
        open={queationTen}
        onClose={() => setQueationTen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={queationTen}>
          <div className="modalContainer">
            <div className="modalHeader">
              <h2 id="transition-modal-title" className="modal_heading">
                How much does it cost for Website development cost?
              </h2>
              <CloseIcon
                onClick={() => setQueationTen(false)}
                className="closeBtnModal"
              />
            </div>
            <h3 id="transition-modal-description" className="modal_para">
              Every business has different your primary goal that your website.
              We always provide our best to make our prices as reasonable as
              possible and do we yearly market research. On costs in the web
              development sector. The whole sum expected to build up a website
              site relies upon work costs and the number of experts associated
              with the project. Website creation involves unique concept
              development, designing architecture framework, programming
              language to be used, installation and customizing, text-writing,
              graphics resources, translating and testing. A website can be
              either cost less and template-based or more expensive and
              original.
            </h3>
          </div>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="modal_section"
        open={queationEleven}
        onClose={() => setQueationEleven(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={queationEleven}>
          <div className="modalContainer">
            <div className="modalHeader">
              <h2 id="transition-modal-title" className="modal_heading">
                Benefits of using a digital agency over a freelancer?
              </h2>
              <CloseIcon
                onClick={() => setQueationEleven(false)}
                className="closeBtnModal"
              />
            </div>
            <h3 id="transition-modal-description" className="modal_para">
              I can assure you that hiring a digital agency means you hire a
              whole team instead of hiring an individual. The digital agency is
              a team of a creative head assured to help deliver results for the
              clients.
            </h3>
            <h3 id="transition-modal-description" className="modal_para">
              Benefits of Hiring Digital agency over Freelancers
            </h3>
            <li id="transition-modal-description" className="modal_para">
              You no need to train agencies
            </li>
            <li id="transition-modal-description" className="modal_para">
              You can easily upscale your marketing efforts.
            </li>
            <li id="transition-modal-description" className="modal_para">
              You gain access to the latest technologies and tools
            </li>
            <li id="transition-modal-description" className="modal_para">
              You can maintain efficiency among your existing staff.
            </li>
            <li id="transition-modal-description" className="modal_para">
              You can carry on with the latest marketing trends, without a
              learning curve.
            </li>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default FAQSection;
