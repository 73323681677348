import React from "react";
import "../graphics.css";
import { Container, Grid, Card } from "@material-ui/core";
import Hidden from "@material-ui/core/Hidden";
import { BASE_URL } from "../../../../config/config";

const WhyChooseUs = () => {
  const WHY_CHOOSE_US_IMAGE = `${BASE_URL}/about_why_choose_us.png`;

  return (
    <div className="graphics_whyChooseUsSection">
      <Container>
        <h1 className="graphics_whyChooseUsTitle">Why Domaincer</h1>
        <Grid container spacing={2} style={{ justifyContent: "center" }}>
          <Grid item xs={12} sm={3}>
            <div
              className="graphics_whyChooseColumnUpContainer"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <h2 className="graphics_chooseTitle">Range of Portfolio</h2>
              <h3 className="graphics_choosePara">
                No project is too big or small for us. With our diverse
                experience across industries and budgets, we can tackle any
                challenge.
              </h3>
            </div>
            <div
              className="graphics_whyChooseColumnDownContainer"
              data-aos="fade-right"
              data-aos-duration="1400"
            >
              <h2 className="graphics_chooseTitle">Creative Team</h2>
              <h3 className="graphics_choosePara">
                Our Expert team creates stunning animated explainer videos with
                a seamless process for successful results.
              </h3>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            className="graphics_about__chooseUsImageRow"
          >
            <img
              src={WHY_CHOOSE_US_IMAGE}
              alt="why choose us image 3d domaincer"
              className="graphics_about_chooseUsMiddleImg floatingUpDown"
            />
          </Grid>

          <Hidden only={["sm", "lg", "md", "xl"]}>
            <Grid item xs={12} sm={3}>
              <div
                className="graphics_whyChooseColumn3UpContainer"
                data-aos="fade-up"
                data-aos-duration="1200"
              >
                <h2 className="graphics_chooseTitle">Tailor-made Script</h2>
                <h3 className="graphics_choosePara">
                  We create Professional content for your audience through
                  videos, podcasts, social media, motion graphics, and
                  collaborations.
                </h3>
              </div>
              <div
                className="graphics_whyChooseColumn3DownContainer"
                data-aos="fade-up"
                data-aos-duration="1400"
              >
                <h2 className="graphics_chooseTitle">Proven Process</h2>
                <h3 className="graphics_choosePara">
                  We specialize in creating high-quality graphics tailored to
                  your requirements. Our reliable process ensures excellent
                  results.
                </h3>
              </div>
            </Grid>
          </Hidden>
          <Hidden only={["xs"]}>
            <Grid item xs={12} sm={3}>
              <div
                className="graphics_whyChooseColumn3UpContainer"
                data-aos="fade-left"
                data-aos-duration="1200"
              >
                <h2 className="graphics_chooseTitle">Tailor-made Script</h2>
                <h3 className="graphics_choosePara">
                  We create Professional content for your audience through
                  videos, podcasts, social media, motion graphics, and
                  collaborations.
                </h3>
              </div>
              <div
                className="graphics_whyChooseColumn3DownContainer"
                data-aos="fade-left"
                data-aos-duration="1600"
              >
                <h2 className="graphics_chooseTitle">Proven Process</h2>
                <h3 className="graphics_choosePara">
                  We specialize in creating high-quality graphics tailored to
                  your requirements. Our reliable process ensures excellent
                  results.
                </h3>
              </div>
            </Grid>
          </Hidden>
        </Grid>
      </Container>
    </div>
  );
};
export default WhyChooseUs;
