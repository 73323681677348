import React, { useState, useEffect } from "react";
import "./devops.css";
import { Container, Grid, Hidden, Paper, Tab, Tabs } from "@material-ui/core";
import AOS from "aos";
import HowServiceWillHelpYouDevOps from "./howservicewillhelpyou_devops/howservicewillhelpyou_devops.component";
import ServiceContentSectionDevOps from "./servicecontentsection_devops/servicecontentsection_devops.component";
import BlogSectionDevOps from "./blogsection_devops/blogsection_devops.component";
import WhyChooseUsDevOps from "./whychooseus_devops/whychooseus_devops.component";
// import OurProcessDevOps from './ourprocess_devops/ourprocess_devops.component';
import CaseStudySection from "../../homepage/casestudyssection/casestudysection.component";
import FollowUs from "../../../commons/followus/followus.component";
import { BASE_URL } from "../../../config/config";

const DevOps = (props) => {
  const DEVOPS_SLIDER = `${BASE_URL}/service_devops_header_image.png`;

  useEffect(() => {
    AOS.init();
  });
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleTabScroll = (value) => {
    if (value === "OVER_VIEW") {
      window.scrollTo({
        top: 1000,
        behavior: "smooth",
      });
    } else if (value === "BLOG") {
      window.scrollTo({
        top: 1750,
        behavior: "smooth",
      });
    } else if (value === "WHY_DOMAINCER") {
      window.scrollTo({
        top: 2500,
        behavior: "smooth",
      });
    } else if (value === "RECENT_WORKS") {
      window.scrollTo({
        top: 3100,
        behavior: "smooth",
      });
    }
  };

  const handleTabScrollMobile = (value) => {
    if (value === "OVER_VIEW") {
      window.scrollTo({
        top: 1500,
        behavior: "smooth",
      });
    } else if (value === "BLOG") {
      window.scrollTo({
        top: 2400,
        behavior: "smooth",
      });
    } else if (value === "WHY_DOMAINCER") {
      window.scrollTo({
        top: 3300,
        behavior: "smooth",
      });
    } else if (value === "RECENT_WORKS") {
      window.scrollTo({
        top: 4500,
        behavior: "smooth",
      });
    }
  };

  return (
    <div class="mobileMarginTop">
      <FollowUs history={props.history} />
      <Container>
        <Grid container>
          <Grid
            xs={12}
            sm={6}
            className="BSSliderImgContainer_devops"
            // data-aos="fade-up"
            data-aos-duration="1000"
          >
            <img
              src={DEVOPS_SLIDER}
              alt="Best DevOps image domaincer"
              className="brandStrategySliderImg_devops floatingUpDown"
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <div
              className="BSSliderRightSection_devops"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <h1 className="sliderHeading_devops">DevOps</h1>
              <h2 className="sliderSubHeading_devops">
                Take your application delivery to the next level with
                Domaincer's DevOps consulting services. Our expert team helps
                you implement DevOps methodologies that streamline your team's
                workflow, improving communication and collaboration between
                development and operational services. Experience the power of
                DevOps and watch your business thrive.
              </h2>
              <Hidden only={["xs"]}>
                <div className="serviceTabsSection_devops">
                  <Paper square className="MTabsWidth_devops">
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      indicatorColor="primary"
                      textColor="primary"
                      aria-label="tabs example"
                      className="MTabsContainer"
                    >
                      <Tab label="About" className="MTabBtn" />
                      <Tab
                        label="Overview"
                        className="MTabBtn"
                        onClick={() => handleTabScroll("OVER_VIEW")}
                      />
                      <Tab
                        label="Blog"
                        className="MTabBtn"
                        onClick={() => handleTabScroll("BLOG")}
                      />
                      <Tab
                        label="Why Domaincer"
                        className="MTabBtn"
                        onClick={() => handleTabScroll("WHY_DOMAINCER")}
                      />
                      {/* <Tab
                        label="Recent Works"
                        className="MTabBtn"
                        onClick={() => handleTabScroll("RECENT_WORKS")}
                      /> */}
                    </Tabs>
                  </Paper>
                </div>
              </Hidden>
              <Hidden only={["sm", "md", "lg", "xl"]}>
                <div className="serviceTabsSection_devops">
                  <Paper square className="MTabsWidth_devops">
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      indicatorColor="primary"
                      textColor="primary"
                      aria-label="tabs example"
                      className="MTabsContainer"
                    >
                      <Tab label="About" className="MTabBtn" />
                      <Tab
                        label="Overview"
                        className="MTabBtn"
                        onClick={() => handleTabScrollMobile("OVER_VIEW")}
                      />
                      <Tab
                        label="Blog"
                        className="MTabBtn"
                        onClick={() => handleTabScrollMobile("BLOG")}
                      />
                      <Tab
                        label="Why Domaincer"
                        className="MTabBtn"
                        onClick={() => handleTabScrollMobile("WHY_DOMAINCER")}
                      />
                      {/* <Tab
                        label="Recent Works"
                        className="MTabBtn"
                        onClick={() => handleTabScrollMobile("RECENT_WORKS")}
                      /> */}
                    </Tabs>
                  </Paper>
                </div>
              </Hidden>
            </div>
          </Grid>
        </Grid>
      </Container>
      <HowServiceWillHelpYouDevOps />
      <ServiceContentSectionDevOps />
      <BlogSectionDevOps />
      <WhyChooseUsDevOps />
      {/* <OurProcessDevOps /> */}
      {/* <CaseStudySection /> */}
    </div>
  );
};

export default DevOps;
