import React, { useState, useEffect } from "react";
import { Route } from "react-router-dom";
import Navbar from "../commons/navbar/navbar.component";
import Footer from "../commons/footer/footer.component";
// import LoaderComponent from "../pages/loader/loader.component";

const DefaultLayout = ({ component: Component, common, ...rest }) => {
  // const [showLoading, setShowLoading] = useState(true);

  // useEffect(() => {
  //   let timer = setTimeout(() => setShowLoading(false), 200);
  //   return () => {
  //     clearTimeout(timer);
  //   };
  // }, []);

  return (
    <Route
      {...rest}
      render={(matchProps) => (
        // <div>
        //   {showLoading ? (
        //     <LoaderComponent />
        //   ) : (
        //     <div>
        //       <Navbar {...matchProps} />
        //       <Component {...matchProps} />
        //       <Footer {...matchProps} />
        //     </div>
        //   )}
        // </div>
        <div>
          <Navbar {...matchProps} />
          <Component {...matchProps} />
          <Footer {...matchProps} />
        </div>
      )}
    />
  );
};

export default DefaultLayout;
