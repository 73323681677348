import React from "react";
import "./whychooseus.css";
import { Container, Grid, Hidden } from "@material-ui/core";
import { BASE_URL } from "../../../config/config";

const WhyChooseUs = () => {
  const WHY_CHOOSE_US_IMAGE = `${BASE_URL}/about_why_choose_us.png`;
  return (
    <div className="whyChooseUsSection">
      <Container>
        <h1 className="whyChooseUsTitle">Why Domaincer</h1>
        <Grid container spacing={2} style={{ justifyContent: "center" }}>
          <Grid item xs={12} sm={4} md={4} lg={3}>
            <div
              className="whyChooseColumnUpContainer"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <h2 className="chooseTitle">15 years of Experience</h2>
              <h3 className="choosePara">
                With 15 years of proven experience, we are able to identify the
                most effective solutions for the growth of your business.
              </h3>
            </div>
            <div
              className="whyChooseColumnDownContainer"
              data-aos="fade-right"
              data-aos-duration="1400"
            >
              <h2 className="chooseTitle">Top-Notch Teams</h2>
              <h3 className="choosePara">
                Our team at Domaincer is committed to delivering exceptional
                results through expertise, creativity, and a dedication to
                quality.
              </h3>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            md={4}
            lg={3}
            className="about__chooseUsImageRow"
          >
            <img
              src={WHY_CHOOSE_US_IMAGE}
              alt="why choose us image 3d domaincer"
              className="about_chooseUsMiddleImg floatingUpDown"
            />
          </Grid>
          {/* visible for Mobile */}
          <Hidden only={["sm", "lg", "md", "xl"]}>
            <Grid item xs={12} sm={3}>
              <div
                className="whyChooseColumn3UpContainer"
                data-aos="fade-up"
                data-aos-duration="1200"
              >
                <h2 className="chooseTitle">Creative development</h2>
                <h3 className="choosePara">
                  Solutions will be effective and user-friendly with great user
                  experience implemented with trending modern technologies.
                </h3>
              </div>
              <div
                className="whyChooseColumn3DownContainer"
                data-aos="fade-up"
                data-aos-duration="1400"
              >
                <h2 className="chooseTitle">Reliable Teams</h2>
                <h3 className="choosePara">
                  Domaincer has a team of passional engineers committed to and
                  are willing to go the extra mile for your success.
                </h3>
              </div>
            </Grid>
          </Hidden>

          {/* visible for tab */}
          <Hidden only={["xs", "lg", "xl"]}>
            <Grid item xs={12} sm={4} md={4} lg={3}>
              <div className="whyChooseColumn3UpContainer" data-aos="fade-up">
                <h2 className="chooseTitle">Latest Technologies</h2>
                <h3 className="choosePara">
                  Lead the way with our knowledge of programming languages like
                  Flutter, Node.js, and React Native, plus specialized services
                  in data analytics and cybersecurity.
                </h3>
              </div>
              <div className="whyChooseColumn3DownContainer" data-aos="fade-up">
                <h2 className="chooseTitle">Strong Portfolio</h2>
                <h3 className="choosePara">
                  Our portfolio features successful projects for Fortune 500
                  companies and clients in various industries.
                </h3>
              </div>
            </Grid>
          </Hidden>

          {/* visible for Desktop & Laptop */}
          <Hidden only={["xs", "sm", "md"]}>
            <Grid item xs={12} sm={4} md={4} lg={3}>
              <div
                className="whyChooseColumn3UpContainer"
                data-aos="fade-left"
                data-aos-duration="1200"
              >
                <h2 className="chooseTitle">Latest Technologies</h2>
                <h3 className="choosePara">
                  Lead the way with our knowledge of programming languages like
                  Flutter, Node.js, and React Native, plus specialized services
                  in data analytics and cybersecurity.
                </h3>
              </div>
              <div
                className="whyChooseColumn3DownContainer"
                data-aos="fade-left"
                data-aos-duration="1600"
              >
                <h2 className="chooseTitle">Strong Portfolio</h2>
                <h3 className="choosePara">
                  Our portfolio features successful projects for Fortune 500
                  companies and clients in various industries.
                </h3>
              </div>
            </Grid>
          </Hidden>
        </Grid>
      </Container>
    </div>
  );
};
export default WhyChooseUs;
