import React, { useState, useEffect } from "react";
import { Container, Grid, Button, Link } from "@material-ui/core";
import "../socialmediamarketing.css";
import Hidden from "@material-ui/core/Hidden";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { BASE_URL } from "../../../../config/config";

const STRATEGY = [
  {
    key: "CONTENT_SECTION_ONE",
    name: "Creative Posts",
    subtext: "Revitalize your marketing magic",
    image: `${BASE_URL}/tab_social_icon1.png`,
    alt: "creative post image domaincer",
  },
  {
    key: "CONTENT_SECTION_TWO",
    name: "Management",
    subtext: "Websites, perfectly fine-tuned",
    image: `${BASE_URL}/tab_social_icon2.png`,
    alt: "Management image domaincer",
  },
  {
    key: "CONTENT_SECTION_THREE",
    name: "Advertising",
    subtext: "Marketing magic starts with mesmerizing stories",
    image: `${BASE_URL}/tab_social_icon3.png`,
    alt: "Explainer video image domaincer",
  },
];

const ServiceContentSectionSocialMedia = () => {
  const [activeTabService, setActiveTabService] = useState(
    "CONTENT_SECTION_ONE"
  );

  return (
    <div>
      <div style={{ paddingTop: 100, paddingBottom: 30 }}>
        <h1 className="service_subheading_socialmedia">
          About Social Media marketing
        </h1>
        <h2 className="service_subtext_socialmedia">
          Looking for a surefire way to elevate your business? Look no further
          than social media marketing! With the power to reach a vast audience,
          this strategy is a game-changer for businesses of all sizes.
        </h2>
      </div>
      {/* visible only on desktop, laptop */}
      <Hidden only={["xs"]}>
        <div className="subServiceTabSection_socialmedia">
          {STRATEGY.map((service, index) => (
            <div
              className="subServiceContaincer_socialmedia"
              style={{
                borderColor:
                  service.key === activeTabService ? "#0077FF" : "#FFF",
              }}
              onClick={() => setActiveTabService(service.key)}
            >
              <img
                className="subServiceImg_socialmedia"
                src={service.image}
                alt={service.alt}
              />
              <div>
                <h1 className="subServiceTitle_socialmedia">{service.name}</h1>
                <h2 className="subServiceSubTitle_socialmedia">
                  {service.subtext}
                </h2>
              </div>
            </div>
          ))}
        </div>
        <div>
          {activeTabService === "CONTENT_SECTION_ONE" && (
            <div className="subServiceParaContainer_socialmedia">
              <Container
                maxWidth="lg"
                className="subServiceFixedSection_socialmedia"
              >
                <div className="glowEffectBrand_socialmedia"></div>
                <div className="serviceContent_socialmedia">
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <h3 className="subServicePara_socialmedia">
                        In today's competitive marketplace, having a strong
                        presence on social media can make all the difference.
                        Let us help you optimize your social platforms and
                        engage with your audience for sustained ROI growth
                      </h3>
                    </Grid>
                  </Grid>
                </div>
              </Container>
            </div>
          )}
          {activeTabService === "CONTENT_SECTION_TWO" && (
            <div className="subServiceParaContainer_socialmedia">
              <Container
                maxWidth="lg"
                className="subServiceFixedSection_socialmedia"
              >
                <div className="glowEffectDigital_socialmedia"></div>

                <div className="serviceContent_socialmedia">
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <h3 className="subServicePara_socialmedia">
                        Welcome to the future of marketing: microblogging! By
                        the end of the decade, global spending on content
                        marketing is estimated to reach a whopping $600 billion
                        – double what it was just a few years ago. And it's no
                        wonder why – compared to traditional marketing, content
                        marketing costs a fraction of the price and generates
                        six times as many leads as paid search advertising. In
                        fact, 75% of marketers are already producing at least
                        one piece of content per day.
                      </h3>
                    </Grid>
                  </Grid>
                </div>
              </Container>
            </div>
          )}
          {activeTabService === "CONTENT_SECTION_THREE" && (
            <div className="subServiceParaContainer_socialmedia">
              <Container
                maxWidth="lg"
                className="subServiceFixedSection_socialmedia"
              >
                <div className="glowEffectWorkFlow_socialmedia"></div>

                <div className="serviceContent_socialmedia">
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <h3 className="subServicePara_socialmedia">
                        Get your brand's story in front of more eyes with our
                        expert video marketing services! With the ability to
                        make information more digestible and easier to
                        understand, it's no wonder why 80% of marketers are
                        incorporating videos into their strategies.
                      </h3>
                    </Grid>
                  </Grid>
                </div>
              </Container>
            </div>
          )}
        </div>
      </Hidden>

      {/* visible only on mobile */}
      <Hidden only={["sm", "lg", "md", "xl"]}>
        <div style={{ position: "relative" }}>
          <ArrowBackIosIcon
            className="subServiceTabLeftArrowHome"
            onClick={() => {
              document.getElementById("strategyScroll").scrollLeft -= 400;
            }}
          />
          <div className="subServiceTabSection_socialmedia" id="strategyScroll">
            <div>
              <div className="subServiceContaincer_socialmedia">
                <img
                  className="subServiceImg_socialmedia"
                  src={`${BASE_URL}/tab_social_icon1.png`}
                  alt="creative post image domaincer"
                />
                <div>
                  <h1 className="subServiceTitle_socialmedia">
                    Creative Posts
                  </h1>
                  <h2 className="subServiceSubTitle_socialmedia">
                    Revitalize your marketing magic
                  </h2>
                </div>
              </div>
              <div className="subServiceParaContainer_socialmedia">
                <Container
                  maxWidth="lg"
                  className="subServiceFixedSection_socialmedia"
                >
                  <div className="glowEffectBrand_socialmedia"></div>

                  <div className="serviceContent_socialmediaOne">
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <h3 className="subServicePara_socialmedia">
                          In today's competitive marketplace, having a strong
                          presence on social media can make all the difference.
                          Let us help you optimize your social platforms and
                          engage with your audience for sustained ROI growth.
                        </h3>
                      </Grid>
                    </Grid>
                  </div>
                </Container>
              </div>
            </div>

            <div>
              <div className="subServiceContaincer_socialmedia">
                <img
                  className="subServiceImg_socialmedia"
                  src={`${BASE_URL}/tab_social_icon2.png`}
                  alt="Management image domaincer"
                />
                <div>
                  <h1 className="subServiceTitle_socialmedia">Management</h1>
                  <h2 className="subServiceSubTitle_socialmedia">
                    Websites, perfectly fine-tuned.
                  </h2>
                </div>
              </div>

              <div className="subServiceParaContainer_socialmedia">
                <Container
                  maxWidth="lg"
                  className="subServiceFixedSection_socialmedia"
                >
                  <div className="glowEffectDigital_socialmedia"></div>

                  <div className="serviceContent_socialmedia">
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <h3 className="subServicePara_socialmedia">
                          Welcome to the future of marketing: microblogging! By
                          the end of the decade, global spending on content
                          marketing is estimated to reach a whopping $600
                          billion – double what it was just a few years ago. And
                          it's no wonder why – compared to traditional
                          marketing, content marketing costs a fraction of the
                          price and generates six times as many leads as paid
                          search advertising. In fact, 75% of marketers are
                          already producing at least one piece of content per
                          day.
                        </h3>
                      </Grid>
                    </Grid>
                  </div>
                </Container>
              </div>
            </div>

            <div>
              <div className="subServiceContaincer_socialmedia">
                <img
                  className="subServiceImg_socialmedia"
                  src={`${BASE_URL}/tab_social_icon3.png`}
                  alt="Explainer video image domaincer"
                />
                <div>
                  <h1 className="subServiceTitle_socialmedia">Advertising</h1>
                  <h2 className="subServiceSubTitle_socialmedia">
                    Marketing magic starts with mesmerizing stories
                  </h2>
                </div>
              </div>
              <div className="subServiceParaContainer_socialmedia">
                <Container
                  maxWidth="lg"
                  className="subServiceFixedSection_socialmedia"
                >
                  <div className="glowEffectWorkFlow_socialmedia"></div>

                  <div className="serviceContent_socialmediaThree">
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <h3 className="subServicePara_socialmedia">
                          Get your brand's story in front of more eyes with our
                          expert video marketing services! With the ability to
                          make information more digestible and easier to
                          understand, it's no wonder why 80% of marketers are
                          incorporating videos into their strategies.
                        </h3>
                      </Grid>
                    </Grid>
                  </div>
                </Container>
              </div>
            </div>
          </div>
          <ArrowForwardIosIcon
            className="subServiceTabRightArrowHome"
            onClick={() => {
              document.getElementById("strategyScroll").scrollLeft += 400;
            }}
          />
        </div>
      </Hidden>
    </div>
  );
};

export default ServiceContentSectionSocialMedia;
