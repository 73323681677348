import React, { useState } from 'react';
import { Grid, IconButton, TextField } from '@material-ui/core';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import { Link } from 'react-router-dom';
import { BASE_URL } from '../../config/config';

const MessageBoxScreen = (props) => {
  const GETQUOTE_USER_ICON = `${BASE_URL}/getquote_user_icon.png`;
  const GETQUOTE_MAIL_ICON = `${BASE_URL}/getquote_mail_icon.png`;
  const GETQUOTE_MOBILE_ICON = `${BASE_URL}/getquote_phone_icon.png`;
  const GETQUOTE_COMPANY_ICON = `${BASE_URL}/getquote_company_icon.png`;

  const [userData] = useState(props.userDetails);
  const [errorStatus, setErrorStatus] = useState(false);
  const [message, setMessage] = useState('');

  const handleClick = (e) => {
    e.preventDefault();
    if (message === '') {
      setErrorStatus(true);
    } else {
      if (userData.selectedValue === 'EXPRESSED') {
        console.log('Express');

        fetch('https://domaincer.com/api/v1/leadinsert.php', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            name: userData.name,
            email: userData.email,
            phone: userData.mobile,
            company: userData.companyName,
            services: userData.selectedServices,
            submitType: userData.selectedValue,
            description: message,
          }),
        })
          .then((res) => res.json())
          .then((json) => console.log(json)).catch((err)=> console.log(err))
        // props.nextStep(
        //   [],
        //   props.prevServiceData,
        //   {},
        //   userDetails,
        //   'thankyouScreen'
        // );
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      } else if (userData.selectedValue === 'DETAILED') {
        console.log('Detail');

        fetch('https://domaincer.com/api/v1/leadinsert.php', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            name: userData.name,
            email: userData.email,
            phone: userData.mobile,
            company: userData.companyName,
            questions: props.QuestioniareData,
            submitType: userData.selectedValue,
            description: message,
          }),
        })
          .then((res) => res.json())
          .then((json) => console.log(json)).catch((err)=> console.log(err))

        // props.nextStep(
        //   [],
        //   props.prevServiceData,
        //   {},
        //   userDetails,
        //   'thankyouScreen'
        // );
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      } else {
        console.log('Not Found');
      }
    }
  };

  return (
    <div className='quotePage__body'>
      <React.Fragment>
        <Grid container direction='row' justify='center' spacing={2}>
          <Grid item sm={10} xs={12} className='custom__stepOneGrid'>
            <div style={{ marginTop: 20, marginBottom: 30 }}>
              <h2 className='quote__question'>Details</h2>
            </div>
            <Grid
              container
              direction='row'
              justify='space-between'
              alignItems='center'
            >
              <Grid item sm={8} xs={12}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: 15,
                  }}
                >
                  <img src={GETQUOTE_USER_ICON} alt="user name icon domaincer" className='userDetailsIcon' />
                  <p className='userDetailsTitle'>Name :</p>
                  <p className='userDetailsData'>{userData.name}</p>
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: 15,
                  }}
                >
                  <img src={GETQUOTE_MOBILE_ICON} alt="phone icon domaincer" className='userDetailsIcon' />
                  <p className='userDetailsTitle'>Mobile :</p>
                  <p className='userDetailsData'>{userData.mobile}</p>
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: 15,
                  }}
                >
                  <img src={GETQUOTE_MAIL_ICON} alt="email icon domaincer" className='userDetailsIcon' />
                  <p className='userDetailsTitle'>Email :</p>
                  <p className='userDetailsData'>{userData.email}</p>
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: 15,
                  }}
                >
                  <img
                    src={GETQUOTE_COMPANY_ICON}
                    alt="organization icon domaincer"
                    className='userDetailsIcon'
                  />
                  <p className='userDetailsTitle'>Company name :</p>
                  <p className='userDetailsData'>{userData.companyName}</p>
                </div>
                <div style={{ marginTop: 30 }}>
                  <h2 className='service__question'>Description</h2>
                  <TextField
                    id='outlined-multiline-static'
                    label='Enter your requirements'
                    multiline
                    rows={5}
                    variant='outlined'
                    className='custom__textField'
                    onChange={(e) => {
                      setErrorStatus(false);
                      setMessage(e.target.value);
                    }}
                  />
                </div>
                {errorStatus && (
                  <p style={{ color: 'red', fontSize: 13, marginTop: 7 }}>
                    Please list out your requirements
                  </p>
                )}
              </Grid>
            </Grid>
            <div className='nextStep__btnContainer'>
              <h5 className='nextStep__btn' onClick={handleClick}>
                <Link to='/get-quote-thank-you'>Continue</Link>
              </h5>
              <IconButton>
                <DoubleArrowIcon className='nextStep__btnIcon arrowani' />
              </IconButton>
            </div>
          </Grid>
        </Grid>
      </React.Fragment>
    </div>
  );
};

export default MessageBoxScreen;
