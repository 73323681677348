import React, { useEffect, useState } from "react";
import "./languages_styles.css";
import {
  Container,
  Grid,
  Card,
  Button,
  Paper,
  Tab,
  Tabs,
} from "@material-ui/core";
import AOS from "aos";
import Hidden from "@material-ui/core/Hidden";
import { BASE_URL } from "../../../../config/config";
import { Link } from "react-router-dom";
import TelegramIcon from "@material-ui/icons/Telegram";
import LazyLoad from "react-lazyload";
import styled, { keyframes } from "styled-components";
import { createPopup } from "@typeform/embed";
import "@typeform/embed/build/css/popup.css";

const DEMO_IMG1 = `${BASE_URL}/dao.png`;
const DEMO_IMG2 = `${BASE_URL}/defi.png`;
const DEMO_IMG3 = `${BASE_URL}/nft.png`;

const ImageWrapper = styled.div`
  position: relative;
`;

const Placeholder = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
`;

const LanguagesSection = () => {
  const [showFirstIMG, setShowFirstIMG] = useState(true);
  const [showSecondIMG, setShowSecondIMG] = useState(false);
  const [showThirdIMG, setShowThirdIMG] = useState(false);
  const [cursorHover, setCursorHover] = useState(false);
  const [handShakeHover, setHankShakeHover] = useState(false);
  const [value, setValue] = useState(0);

  const refPlaceholder = React.useRef();

  const removePlaceholder = () => {
    // refPlaceholder.current.remove();
  };

  useEffect(() => {
    AOS.init();
    window.addEventListener("scroll", listenScrollEvent);
  }, []);

  const listenScrollEvent = (event) => {
    if (window.scrollY < 1447) {
      setShowFirstIMG(true);
      setShowSecondIMG(false);
      setShowThirdIMG(false);
    } else if (window.scrollY > 1447 && window.scrollY < 2200) {
      setShowFirstIMG(false);
      setShowSecondIMG(true);
      setShowThirdIMG(false);
    } else if (window.scrollY > 2200 && window.scrollY < 3093) {
      setShowFirstIMG(false);
      setShowSecondIMG(false);
      setShowThirdIMG(true);
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const openPopup = (event) => {
    createPopup("r3BLnIaA").open();
  };

  return (
    <React.Fragment>
      <Hidden only={["xs"]}>
        <div className="ourProcessContainer">
          <div className="fpFnAT">
            {/* <p className='ourProcessMainHeading'>Our Process</p> */}
            {/* <div className="ijZHsr" data-aos="fade-up" data-aos-duration="1000">
              <div className="cKSIpx_lang">We Specialise in: On Demand</div>
              <div className="cAPXpg">
                We are in an era of On-demand mobile app development, which is
                creating a buzz nowadays, and we are constantly rolling and is
                reaching new heights with every passing day.
              </div>
            </div> */}

            <div className="ijZHsr" data-aos="fade-up" data-aos-duration="1000">
              <h1 className="cKSIpx_lang">DeFi</h1>
              <div className="cAPXpg">
                Decentralized finance (DeFi) is an emerging financial technology
                based on secure distributed ledgers similar to those used by
                cryptocurrencies. The system removes the control banks and
                institutions have on money, financial products, and financial
                services. <br />
                <br />
                {/* Combined with its cross-platform compatibility, these
                characteristics have made a feasible solution for businesses
                looking to launch an app across multiple platforms without
                hustle. Considering the complexity of the code structure,
                choosing the nest react native development company is very
                vital. */}
              </div>
              <div style={{ marginLeft: -30 }}>
                {/* <Link to="/get-quote"> */}
                <Button
                  className="contactUs__button"
                  onMouseEnter={() => {
                    setHankShakeHover(true);
                    setCursorHover(true);
                  }}
                  onMouseLeave={() => {
                    setCursorHover(false);
                    setHankShakeHover(false);
                  }}
                  onClick={() => {
                    openPopup();
                  }}
                >
                  {handShakeHover === true ? (
                    <TelegramIcon
                      style={{
                        paddingLeft: 30,
                        paddingRight: 30,
                        fontSize: 22,
                      }}
                    />
                  ) : (
                    <span style={{ paddingLeft: 5, paddingRight: 5 }}>
                      Get Quote
                    </span>
                  )}
                </Button>
                {/* </Link> */}
              </div>
            </div>
            <div className="ijZHsr" data-aos="fade-up" data-aos-duration="1000">
              <h1 className="cKSIpx_lang">DAO</h1>
              <div className="cAPXpg">
                A decentralized autonomous organization (DAO), sometimes called
                a decentralized autonomous corporation (DAC),Its is an
                organization represented by rules encoded as a computer program
                that is transparent, controlled by the organization members and
                not influenced by a central government. A blockchain is used to
                keep track of a DAO's financial transactions and programme
                regulations. <br />
                <br />
                {/* Develop a conventional native mobile app for your business by
                our leading mobile app development company in Chennai to
                experience world-class solutions that cater to your specific
                requirements. */}
              </div>
              <div style={{ marginLeft: -30 }}>
                {/* <Link to="/get-quote"> */}
                <Button
                  className="contactUs__button"
                  onMouseEnter={() => {
                    setHankShakeHover(true);
                    setCursorHover(true);
                  }}
                  onMouseLeave={() => {
                    setCursorHover(false);
                    setHankShakeHover(false);
                  }}
                  onClick={() => {
                    openPopup();
                  }}
                >
                  {handShakeHover === true ? (
                    <TelegramIcon
                      style={{
                        paddingLeft: 30,
                        paddingRight: 30,
                        fontSize: 22,
                      }}
                    />
                  ) : (
                    <span style={{ paddingLeft: 5, paddingRight: 5 }}>
                      Get Quote
                    </span>
                  )}
                </Button>
                {/* </Link> */}
              </div>
            </div>
            <div className="ijZHsr" data-aos="fade-up" data-aos-duration="1000">
              <h1 className="cKSIpx_lang">NFT</h1>
              <div className="cAPXpg">
                A non-fungible token (NFT) is a non-transferable data unit held
                on a blockchain, which is a type of digital ledger. NFTs can
                also be linked to digital items that can be reproduced, such as
                images, movies, and audio. NFTs use a digital ledger to offer a
                public certificate of authenticity or proof of ownership, but
                they don't limit sharing or copying of the underlying digital
                files, or the creation of identical NFTs. <br />
                <br />
                {/* Leverage the technology of full-cycle flutter app development by
                the top mobile app development company in Chennai to make the
                overall look and feel of an app delightful along with a native
                experience. */}
              </div>
              <div style={{ marginLeft: -30 }}>
                {/* <Link to="/get-quote"> */}
                <Button
                  className="contactUs__button"
                  onMouseEnter={() => {
                    setHankShakeHover(true);
                    setCursorHover(true);
                  }}
                  onMouseLeave={() => {
                    setCursorHover(false);
                    setHankShakeHover(false);
                  }}
                  onClick={() => {
                    openPopup();
                  }}
                >
                  {handShakeHover === true ? (
                    <TelegramIcon
                      style={{
                        paddingLeft: 30,
                        paddingRight: 30,
                        fontSize: 22,
                      }}
                    />
                  ) : (
                    <span style={{ paddingLeft: 5, paddingRight: 5 }}>
                      Get Quote
                    </span>
                  )}
                </Button>
                {/* </Link> */}
              </div>
            </div>
          </div>
          <div className="ffCCAC">
            <div>
              <React.Fragment>
                <div className="staticImgContainer">
                  {showFirstIMG && (
                    <div style={{ textAlign: "center", alignSelf: "center" }}>
                      <React.Fragment>
                        <ImageWrapper>
                          <Placeholder ref={refPlaceholder} />
                          <LazyLoad>
                            <img
                              src={DEMO_IMG2}
                              alt=""
                              className="hZWWsa_flutter"
                              onLoad={removePlaceholder}
                              onError={removePlaceholder}
                            />
                          </LazyLoad>
                        </ImageWrapper>
                      </React.Fragment>
                    </div>
                  )}
                  {showSecondIMG && (
                    <div
                      data-aos="fade-left"
                      data-aos-duration="500"
                      style={{ textAlign: "center", alignSelf: "center" }}
                    >
                      <ImageWrapper>
                        <Placeholder ref={refPlaceholder} />
                        <LazyLoad>
                          <img
                            src={DEMO_IMG1}
                            alt=""
                            className="hZWWsa_reactnative"
                            onLoad={removePlaceholder}
                            onError={removePlaceholder}
                          />
                        </LazyLoad>
                      </ImageWrapper>
                    </div>
                  )}
                  {showThirdIMG && (
                    <div
                      data-aos="fade-left"
                      data-aos-duration="500"
                      style={{ textAlign: "center", alignSelf: "center" }}
                    >
                      <ImageWrapper>
                        <Placeholder ref={refPlaceholder} />
                        <LazyLoad>
                          <img
                            src={DEMO_IMG3}
                            alt=""
                            className="hZWWsa_native"
                            onLoad={removePlaceholder}
                            onError={removePlaceholder}
                          />
                        </LazyLoad>
                      </ImageWrapper>
                    </div>
                  )}
                </div>
              </React.Fragment>
            </div>
          </div>
        </div>
      </Hidden>
      <Hidden only={["sm", "lg", "md", "xl"]}>
        <div className="languageContainer_mobileapp">
          <Paper
            square
            className="MTabsWidthProcess_mobileapp languagesMobileTabs"
          >
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              aria-label="tabs example"
              className="MTabsContainer"
            >
              <Tab
                label="NFT"
                className="MTabBtn"
                onClick={() => setValue(0)}
              />
              <Tab
                label="DeFi"
                className="MTabBtn"
                onClick={() => setValue(1)}
              />
              <Tab
                label="DAO"
                className="MTabBtn"
                onClick={() => setValue(2)}
              />
            </Tabs>
          </Paper>
          {value === 0 && (
            <div>
              <div
                className="ijZHsr_strategy"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <div className="cKSIpx_strategy">NFT</div>
                <div className="cAPXpg_strategy">
                  The ability to build a native application for Android and iOS
                  from a single codebase is gaining in demand. Leverage the
                  technology full-cycle flutter development by the top mobile
                  app development company in Chennai <br />
                  <br />
                  {/* Leverage the technology of full-cycle flutter app development
                  by the top mobile app development company in Chennai to make
                  the overall look and feel of an app delightful along with a
                  native experience. */}
                </div>
              </div>
              <div className="staticImgContainer">
                <div>
                  <div
                    data-aos="fade-up"
                    data-aos-duration="500"
                    style={{ textAlign: "center", alignSelf: "center" }}
                  >
                    <ImageWrapper>
                      <Placeholder ref={refPlaceholder} />
                      <LazyLoad>
                        <img
                          src={DEMO_IMG3}
                          alt=""
                          className="hZWWsa_flutter"
                          onLoad={removePlaceholder}
                          onError={removePlaceholder}
                        />
                      </LazyLoad>
                    </ImageWrapper>
                  </div>
                </div>
              </div>
            </div>
          )}

          {value === 1 && (
            <div>
              <div
                className="ijZHsr_strategy"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <div className="cKSIpx_strategy">DeFi</div>
                <div className="cAPXpg_strategy">
                  React Native is a front-end development eco-system that has
                  been evolving continuously. Combined with the cross-platform
                  compatibility, these characteristics have made a feasible
                  solution for Businesses liking to launch an app across
                  multiple platform without hustle. <br />
                  <br />
                  {/* Combined with its cross-platform compatibility, these
                  characteristics have made a feasible solution for businesses
                  looking to launch an app across multiple platforms without
                  hustle. Considering the complexity of the code structure,
                  choosing the nest react native development company is very
                  vital. */}
                </div>
              </div>
              <div className="staticImgContainer">
                <div>
                  <div
                    data-aos="fade-up"
                    data-aos-duration="500"
                    style={{ textAlign: "center", alignSelf: "center" }}
                  >
                    <ImageWrapper>
                      <Placeholder ref={refPlaceholder} />
                      <LazyLoad>
                        <img
                          src={DEMO_IMG2}
                          alt=""
                          className="hZWWsa_reactnative"
                          onLoad={removePlaceholder}
                          onError={removePlaceholder}
                        />
                      </LazyLoad>
                    </ImageWrapper>
                  </div>
                </div>
              </div>
            </div>
          )}
          {value === 2 && (
            <div>
              <div
                className="ijZHsr_strategy"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <div className="cKSIpx_strategy">DAO</div>
                <div className="cAPXpg_strategy">
                  Building a devoted mobile app is not an option anymore, though
                  it has become a necessity rather than a priority for
                  businesses. Develop a conventional Native mobile app for your
                  business by our leading mobile app development company in
                  Chennai <br />
                  <br />
                  {/* Develop a conventional native mobile app for your business by
                  our leading mobile app development company in Chennai to
                  experience world-class solutions that cater to your specific
                  requirements. */}
                </div>
              </div>
              <div className="staticImgContainer">
                <div>
                  <div
                    data-aos="fade-up"
                    data-aos-duration="500"
                    style={{ textAlign: "center", alignSelf: "center" }}
                  >
                    <ImageWrapper>
                      <Placeholder ref={refPlaceholder} />
                      <LazyLoad>
                        <img
                          src={DEMO_IMG1}
                          alt=""
                          className="hZWWsa_native"
                          onLoad={removePlaceholder}
                          onError={removePlaceholder}
                        />
                      </LazyLoad>
                    </ImageWrapper>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </Hidden>
    </React.Fragment>
  );
};

export default LanguagesSection;
