
//STEP_FIVE
export const SUBMISSION_SCREEN = 'submissionScreen';
export const NAME_SCREEN = 'nameScreen';
export const DETAILS_SCREEN = 'detailsScreen';
export const ORGANIZATION_SCREEN = 'organizationScreen';
export const SERVICES_SCREEN = 'servicesScreen';
export const BRAND_STRATEGY_SCREEN = 'brandStrategyScreen';
export const DIGITAL_TRANSFORMATION_SCREEN = 'digitalTransformationScreen';
export const WORKFLOW_AUTOMATION_SCREEN = 'workflowAutomationScreen';
export const BRANDING_SCREEN = 'brandingScreen';
export const UIUX_SCREEN = 'uiuxScreen';
export const MOTION_GRAPHICS_SCREEN = 'motionGraphicsScreen';
export const MOBILE_APPS_SCREEN = 'mobileAppsScreen';
export const PORTAL_SCREEN = 'portalScreen';
export const WEBSITE_SCREEN = 'websiteScreen';
export const DEVOPS_SCREEN = 'devopsScreen';
export const SOCIALMEDIA_MARKETING_SCREEN = 'socialMediaMarketingScreen';
export const SEARCH_ENGINE_OPTIMISATION = 'searchEngineOptimisationScreen';
export const CONTENT_MARKETING = 'contentMarketingScreen';
export const ONLINE_REPUTATION_MANAGEMENT = 'onlineReputationManagementScreen';
export const DEDICATED_RESOURCE = 'dedicatedResourceScreen';
export const OUTSOURCING = 'outsourcingScreen';
export const MESSAGEBOX_SCREEN = 'messageBoxScreen';
export const THANKYOU_SCREEN = 'thankyouScreen';
//EXPRESS_SUBMISSION
// export const ORGANIZATION_EXPRESS_SCREEN = 'organizationScreenExpress';
//EXPRESS_SUBMISSION


