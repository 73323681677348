import React, { useState, useEffect } from "react";
import { Container, Grid, Paper, Tab, Tabs } from "@material-ui/core";
import AOS from "aos";
import "./deployment.css";
import Hidden from "@material-ui/core/Hidden";
import { BASE_URL } from "../../../../config/config";
import Skeleton from "react-loading-skeleton";
import LazyLoad from "react-lazyload";
import styled, { keyframes } from "styled-components";

const GOOGLE_CLOUD = `${BASE_URL}/googlecloud.png`;
const AWS = `${BASE_URL}/aws.png`;
const ALIBABA = `${BASE_URL}/alibaba.png`;
const MICROSOFT = `${BASE_URL}/azure.png`;

const ImageWrapper = styled.div`
  position: relative;
`;

const Placeholder = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
`;

const WebsiteDeploymentPartners = (props) => {
  const MOBILE_APPS = `${BASE_URL}/tab_mobile_icon3.png`;
  const [skeletonLoader, setskeletonLoader] = useState(true);
  const refPlaceholder = React.useRef();

  const removePlaceholder = () => {
    refPlaceholder.current.remove();
    setskeletonLoader(false);
  };

  useEffect(() => {
    AOS.init();
  });

  return (
    <React.Fragment>
      <div className="partnerSection">
        <div>
          <h1 className="deploymentPartnerHeading">Our Deployment Partners</h1>
          {/* <h3 className="deploymentPartnerSubHeading">
            The right deployment partner will help you find the best apps,
            install add-ons and support.
          </h3> */}
        </div>
        <Container>
          <Grid container>
            <Hidden only={["xs"]}>
              <Grid sm={2}>
                <div></div>
              </Grid>
              <Grid
                xs={12}
                sm={2}
                data-aos="fade-left"
                data-aos-duration="2000"
              >
                <div className="partnersCardContainer">
                  <div className="partnersImgContainer">
                    <React.Fragment>
                      {skeletonLoader === true && (
                        <Skeleton width="100px" height="100px" circle="true" />
                      )}
                      <ImageWrapper>
                        <Placeholder ref={refPlaceholder} />
                        <LazyLoad>
                          <img
                            src={GOOGLE_CLOUD}
                            alt=""
                            style={{ width: "100%", height: "100%" }}
                            onLoad={removePlaceholder}
                            onError={removePlaceholder}
                          />
                        </LazyLoad>
                      </ImageWrapper>
                    </React.Fragment>
                  </div>

                  <h1 className="deploymentPartnerCardHeading">Google Cloud</h1>
                </div>
              </Grid>
              <Grid
                xs={12}
                sm={2}
                data-aos="fade-left"
                data-aos-duration="1000"
              >
                <div className="partnersCardContainer">
                  <div className="partnersImgContainer">
                    <React.Fragment>
                      {skeletonLoader === true && (
                        <Skeleton width="100px" height="100px" circle="true" />
                      )}
                      <ImageWrapper>
                        <Placeholder ref={refPlaceholder} />
                        <LazyLoad>
                          <img
                            src={AWS}
                            alt=""
                            style={{ width: "100%", height: "100%" }}
                            onLoad={removePlaceholder}
                            onError={removePlaceholder}
                          />
                        </LazyLoad>
                      </ImageWrapper>
                    </React.Fragment>
                  </div>

                  <h1 className="deploymentPartnerCardHeading">AWS</h1>
                </div>
              </Grid>
            </Hidden>
            <Hidden only={["sm", "lg", "md", "xl"]}>
              <Grid
                xs={6}
                sm={2}
                data-aos="fade-right"
                data-aos-duration="2000"
              >
                <div className="partnersCardContainer">
                  <div className="partnersImgContainer">
                    <React.Fragment>
                      {skeletonLoader === true && (
                        <Skeleton width="100px" height="100px" circle="true" />
                      )}
                      <ImageWrapper>
                        <Placeholder ref={refPlaceholder} />
                        <LazyLoad>
                          <img
                            src={GOOGLE_CLOUD}
                            alt=""
                            style={{ width: "100%", height: "100%" }}
                            onLoad={removePlaceholder}
                            onError={removePlaceholder}
                          />
                        </LazyLoad>
                      </ImageWrapper>
                    </React.Fragment>
                  </div>

                  <h1 className="deploymentPartnerCardHeading">Google Cloud</h1>
                </div>
              </Grid>
              <Grid
                xs={6}
                sm={2}
                data-aos="fade-right"
                data-aos-duration="1000"
              >
                <div className="partnersCardContainer">
                  <div className="partnersImgContainer">
                    <React.Fragment>
                      {skeletonLoader === true && (
                        <Skeleton width="100px" height="100px" circle="true" />
                      )}
                      <ImageWrapper>
                        <Placeholder ref={refPlaceholder} />
                        <LazyLoad>
                          <img
                            src={AWS}
                            alt=""
                            style={{ width: "100%", height: "100%" }}
                            onLoad={removePlaceholder}
                            onError={removePlaceholder}
                          />
                        </LazyLoad>
                      </ImageWrapper>
                    </React.Fragment>
                  </div>

                  <h1 className="deploymentPartnerCardHeading">AWS</h1>
                </div>
              </Grid>
            </Hidden>
            <Grid xs={6} sm={2} data-aos="fade-right" data-aos-duration="1000">
              <div className="partnersCardContainer">
                <div className="partnersImgContainer">
                  <React.Fragment>
                    {skeletonLoader === true && (
                      <Skeleton width="100px" height="100px" circle="true" />
                    )}
                    <ImageWrapper>
                      <Placeholder ref={refPlaceholder} />
                      <LazyLoad>
                        <img
                          src={ALIBABA}
                          alt=""
                          style={{ width: "100%", height: "100%" }}
                          onLoad={removePlaceholder}
                          onError={removePlaceholder}
                        />
                      </LazyLoad>
                    </ImageWrapper>
                  </React.Fragment>
                </div>
                <h1 className="deploymentPartnerCardHeading">Alibaba</h1>
              </div>
            </Grid>
            <Grid xs={6} sm={2} data-aos="fade-right" data-aos-duration="2000">
              <div className="partnersCardContainer">
                <div className="partnersImgContainer">
                  <React.Fragment>
                    {skeletonLoader === true && (
                      <Skeleton width="100px" height="100px" circle="true" />
                    )}
                    <ImageWrapper>
                      <Placeholder ref={refPlaceholder} />
                      <LazyLoad>
                        <img
                          src={MICROSOFT}
                          alt=""
                          style={{ width: "100%", height: "100%" }}
                          onLoad={removePlaceholder}
                          onError={removePlaceholder}
                        />
                      </LazyLoad>
                    </ImageWrapper>
                  </React.Fragment>
                </div>{" "}
                <h1 className="deploymentPartnerCardHeading">Microsoft</h1>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default WebsiteDeploymentPartners;
