import React, { useState } from "react";
import { Grid, IconButton } from "@material-ui/core";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";

const GetQuoteStepFour = (props) => {
  const [onChangeOrg, setOnChangeOrg] = useState("");
  const [errorStatus, setErrorStatus] = useState(false);

  const handleSubmit_stepOne = (e) => {
    e.preventDefault();
    const { userDetails } = props;
    if (onChangeOrg === "") {
      setErrorStatus(true);
    } else {
      const userData = {
        name: userDetails.name,
        email: userDetails.email,
        mobile: userDetails.mobile,
        selectedValue: userDetails.selectedValue,
        companyName: onChangeOrg,
        selectedServices: props.userDetails.selectedServices,
      };
      props.nextStep(
        [],
        [],
        {},
        userData,
        "organizationScreen"
        // userDetails.selectedValue === "EXPRESSED"
        //   ? "organizationScreenExpress"
        //   : "organizationScreen"
      );
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className="quotePage__body">
      <React.Fragment>
        <Grid container direction="row" justify="center" spacing={2}>
          <Grid item sm={10} xs={12} className="custom__stepOneGrid">
            <div>
              <h2 className="quote__question">What's your organization?</h2>
            </div>
            <div className="textInput__container">
              <input
                id="my-name"
                placeholder="Your organization"
                className={
                  onChangeOrg.length > 0
                    ? "custom__onChangeInputField"
                    : errorStatus === true
                    ? "error__inputField"
                    : "custom__inputField"
                }
                onChange={(e) => setOnChangeOrg(e.target.value)}
              />
            </div>

            <div className="nextStep__btnContainer">
              <h5 className="nextStep__btn" onClick={handleSubmit_stepOne}>
                Continue
              </h5>
              <IconButton>
                <DoubleArrowIcon className="nextStep__btnIcon arrowani" />
              </IconButton>
            </div>
          </Grid>
        </Grid>
      </React.Fragment>
    </div>
  );
};

export default GetQuoteStepFour;
