import React from "react";
import "../searchengineoptimisation.css";
import { Container, Grid, Card } from "@material-ui/core";
import Hidden from "@material-ui/core/Hidden";
import { BASE_URL } from "../../../../config/config";

const WhyChooseUsSearchEngine = () => {
  const WHY_CHOOSE_US_IMAGE = `${BASE_URL}/about_why_choose_us.png`;

  return (
    <div className="whyChooseUsSection_searchengine">
      <Container>
        <h1 className="whyChooseUsTitle_searchengine">Why Domaincer</h1>
        <Grid container spacing={2} style={{ justifyContent: "center" }}>
          <Grid item xs={12} sm={3}>
            <div
              className="whyChooseColumnUpContainer_searchengine"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <h2 className="chooseTitle_searchengine">Guaranteed Results</h2>
              <h3 className="choosePara_searchengine">
                Achieve first-page dominance on Google with our diverse
                expertise and commitment to delivering results.
              </h3>
            </div>
            <div
              className="whyChooseColumnDownContainer_searchengine"
              data-aos="fade-right"
              data-aos-duration="1400"
            >
              <h2 className="chooseTitle_searchengine">
                Dedicated Project Manager
              </h2>
              <h3 className="choosePara_searchengine">
                Experience seamless coordination and clear communication with a
                dedicated project manager overseeing your work.
              </h3>
            </div>
          </Grid>
          <Grid item xs={12} sm={4} className="chooseUsImageRow_searchengine">
            <img
              src={WHY_CHOOSE_US_IMAGE}
              alt="why choose us image 3d domaincer"
              className="chooseUsMiddleImg_searchengine floatingUpDown"
            />
          </Grid>

          <Hidden only={["sm", "lg", "md", "xl"]}>
            <Grid item xs={12} sm={3}>
              <div
                className="whyChooseColumn3UpContainer_searchengine"
                data-aos="fade-up"
                data-aos-duration="1200"
              >
                <h2 className="chooseTitle_searchengine">
                  Performance Tracking
                </h2>
                <h3 className="choosePara_searchengine">
                  Track the success of your website and plan for the future with
                  our transparent daily, weekly, and monthly progress reports.
                </h3>
              </div>
              <div
                className="whyChooseColumn3DownContainer_searchengine"
                data-aos="fade-up"
                data-aos-duration="1400"
              >
                <h2 className="chooseTitle_searchengine">
                  Proven CRO Approach
                </h2>
                <h3 className="choosePara_searchengine">
                  Improve conversions with our team's data-driven approach to
                  optimization, addressing all aspects of your website.
                </h3>
              </div>
            </Grid>
          </Hidden>
          <Hidden only={["xs"]}>
            <Grid item xs={12} sm={3}>
              <div
                className="whyChooseColumn3UpContainer_searchengine"
                data-aos="fade-left"
                data-aos-duration="1200"
              >
                <h2 className="chooseTitle_searchengine">
                  Performance Tracking
                </h2>
                <h3 className="choosePara_searchengine">
                  Track the success of your website and plan for the future with
                  our transparent daily, weekly, and monthly progress reports.
                </h3>
              </div>
              <div
                className="whyChooseColumn3DownContainer_searchengine"
                data-aos="fade-left"
                data-aos-duration="1600"
              >
                <h2 className="chooseTitle_searchengine">
                  Proven CRO Approach
                </h2>
                <h3 className="choosePara_searchengine">
                  Improve conversions with our team's data-driven approach to
                  optimization, addressing all aspects of your website.
                </h3>
              </div>
            </Grid>
          </Hidden>
        </Grid>
      </Container>
    </div>
  );
};
export default WhyChooseUsSearchEngine;
