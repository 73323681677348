import React, { useState, useEffect, useRef, Fragment } from "react";
import "./mobileapp.css";
import {
  Container,
  Button,
  Modal,
  Grid,
  Hidden,
  Paper,
  Tab,
  Tabs,
} from "@material-ui/core";
import AOS from "aos";
import LanguagesSection from "./languages_section/languages_sections";
import CaseStudySection from "../../homepage/casestudyssection/casestudysection.component";
import BlogSectionMobileApp from "./blogsection_mobileapp/blogsection_mobileapp.component";
import { BASE_URL } from "../../../config/config";
import ServiceContentV2MobileApp from "./servicecontentv2_mobileapp/servicecontentv2_mobileapp.component";
import ProcessSection from "./processsection/process_section";
import StatsSection from "./statssection/stats_section";
import DeploymentPartners from "./deploymentpartners/deploymentpartners_sections";
import SpecializedIn from "./specializedin/specializedin";
import Skeleton from "react-loading-skeleton";
import LazyLoad from "react-lazyload";
import styled, { keyframes } from "styled-components";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import FollowUs from "../../../commons/followus/followus.component";

const ImageWrapper = styled.div`
  position: relative;
`;

const Placeholder = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
`;

const MobileApp = (props) => {
  const MOBILE_APPS_SLIDER = `${BASE_URL}/service_mobile_header_image.png`;
  const APPLICATION_VIDEO = `${BASE_URL}/domaincer_banner_video_new.mp4`;
  const [value, setValue] = useState(0);
  const [skeletonLoader, setskeletonLoader] = useState(true);
  const refPlaceholder = React.useRef();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const removePlaceholder = () => {
    refPlaceholder.current.remove();
    setskeletonLoader(false);
  };

  useEffect(() => {
    AOS.init();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleTabScroll = (value) => {
    if (value === "TECH") {
      window.scrollTo({
        top: 1200,
        behavior: "smooth",
      });
    } else if (value === "PROCESS") {
      window.scrollTo({
        top: 4700,
        behavior: "smooth",
      });
    } else if (value === "BLOG") {
      window.scrollTo({
        top: 5800,
        behavior: "smooth",
      });
    } else if (value === "RECENT_WORKS") {
      window.scrollTo({
        top: 6200,
        behavior: "smooth",
      });
    } else if (value === "STATS") {
      window.scrollTo({
        top: 6700,
        behavior: "smooth",
      });
    }
  };

  const handleTabScrollMobile = (value) => {
    if (value === "TECH") {
      window.scrollTo({
        top: 1000,
        behavior: "smooth",
      });
    } else if (value === "PROCESS") {
      window.scrollTo({
        top: 3550,
        behavior: "smooth",
      });
    } else if (value === "BLOG") {
      window.scrollTo({
        top: 4600,
        behavior: "smooth",
      });
    } else if (value === "RECENT_WORKS") {
      window.scrollTo({
        top: 5100,
        behavior: "smooth",
      });
    } else if (value === "STATS") {
      window.scrollTo({
        top: 6350,
        behavior: "smooth",
      });
    }
  };

  return (
    <div class="mobileMarginTop">
      <div>
        <FollowUs history={props.history} />
        <Container>
          <Grid container>
            <Grid
              xs={12}
              sm={6}
              className="BSSliderImgContainer_mobileapp"
              // data-aos="fade-up"
              data-aos-duration="1000"
              style={{ zIndex: 999 }}
            >
              <React.Fragment>
                {skeletonLoader === true && (
                  <Skeleton width="90%" height="400px" />
                )}
                <ImageWrapper>
                  <Placeholder ref={refPlaceholder} />
                  <LazyLoad>
                    <img
                      src={MOBILE_APPS_SLIDER}
                      alt="Best mobile app image domaincer"
                      className="brandStrategySliderImg_mobileapp floatingUpDown"
                      onLoad={removePlaceholder}
                      onError={removePlaceholder}
                    />
                  </LazyLoad>
                </ImageWrapper>
              </React.Fragment>
            </Grid>
            <Grid xs={12} sm={6}>
              <div
                className="BSSliderRightSection_mobileapp"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <h1 className="sliderHeading_mobileapp">
                  Mobile App Development
                </h1>
                <h2 className="sliderSubHeading_mobileapp">
                  <strong> Bringing app ideas to fruition - </strong>
                  Make your mobile app vision a reality with Domaincer, the
                  Finest app development company in Chennai. Our team
                  specializes in crafting high-performance, functional, and
                  immersive apps that provide transformative user experiences.
                  Whether you need a custom-coded solution for a specific
                  problem or a stunning mobile app to set you apart, we are the
                  best choice. Let us bring your idea to life and launch it with
                  excellence.
                </h2>
                {/* <Button
               className="video_play"
               onClick={handleOpen}
              >
                <PlayCircleOutlineIcon />
                <span style={{ paddingLeft: 5, paddingRight: 5 }}>
                    Watch video
                </span>
              </Button> */}
                <Modal open={open} onClose={handleClose}>
                  <Fragment>
                    <div className="video_closeIcon_container">
                      <CloseOutlinedIcon
                        onClick={handleClose}
                        className="video_closeIcon"
                      ></CloseOutlinedIcon>
                    </div>
                    <div className="video_Second_container">
                      <iframe
                        title="how Influencer Works"
                        className="video_iframe"
                        width="854"
                        height="480"
                        src="https://www.youtube.com/embed/v=si4Ak78y6Kg"
                        frameborder="0"
                        allow="autoplay; encrypted-media"
                        allowfullscreen
                      ></iframe>
                    </div>
                  </Fragment>
                </Modal>
                <Hidden only={["xs"]}>
                  <div className="serviceTabsSection_mobileapp">
                    <Paper square className="MTabsWidth_mobileapp">
                      <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        aria-label="tabs example"
                        className="MTabsContainer"
                      >
                        <Tab label="About" className="MTabBtn" />
                        <Tab
                          label="Technologies"
                          className="MTabBtn"
                          onClick={() => handleTabScroll("TECH")}
                        />

                        <Tab
                          label="Process"
                          className="MTabBtn"
                          onClick={() => handleTabScroll("PROCESS")}
                        />

                        <Tab
                          label="Blog"
                          className="MTabBtn"
                          onClick={() => handleTabScroll("BLOG")}
                        />
                        {/* <Tab
                          label='Recent works'
                          className='MTabBtn'
                          onClick={() => handleTabScroll('RECENT_WORKS')}
                        /> */}
                        <Tab
                          label="Stats"
                          className="MTabBtn"
                          onClick={() => handleTabScroll("STATS")}
                        />
                      </Tabs>
                    </Paper>
                  </div>
                </Hidden>
                <Hidden only={["sm", "md", "lg", "xl"]}>
                  <div className="serviceTabsSection_mobileapp">
                    <Paper square className="MTabsWidth_mobileapp">
                      <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        aria-label="tabs example"
                        className="MTabsContainer"
                      >
                        <Tab label="About" className="MTabBtn" />
                        <Tab
                          label="Technologies"
                          className="MTabBtn"
                          onClick={() => handleTabScrollMobile("TECH")}
                        />

                        <Tab
                          label="Process"
                          className="MTabBtn"
                          onClick={() => handleTabScrollMobile("PROCESS")}
                        />

                        <Tab
                          label="Blog"
                          className="MTabBtn"
                          onClick={() => handleTabScrollMobile("BLOG")}
                        />
                        {/* <Tab
                          label='Recent works'
                          className='MTabBtn'
                          onClick={() => handleTabScrollMobile('RECENT_WORKS')}
                        /> */}
                        <Tab
                          label="Stats"
                          className="MTabBtn"
                          onClick={() => handleTabScrollMobile("STATS")}
                        />
                      </Tabs>
                    </Paper>
                  </div>
                </Hidden>
              </div>
            </Grid>
          </Grid>
        </Container>
        <LanguagesSection />
        <ServiceContentV2MobileApp />
        {/* <WeSpecialized /> */}
        <SpecializedIn />
        <ProcessSection />
        <DeploymentPartners />
        <BlogSectionMobileApp />
        <div style={{ marginTop: 100 }}>
          {/* <CaseStudySection /> */}
          <StatsSection />
        </div>
        {/* <GetQuoteMobile /> */}
      </div>
    </div>
  );
};

export default MobileApp;
