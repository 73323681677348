import React, { useState, useEffect } from "react";
import "./digitalTransformation.css";
import { Container, Grid, Hidden, Paper, Tab, Tabs } from "@material-ui/core";
import WhyChooseUs from "./digital_whyChooseUs/digital_whychooseus.component";
// import OurProcess from './digital_ourProcess/digital_ourprocess.component';
import CaseStudySection from "../../homepage/casestudyssection/casestudysection.component";
import HowServiceWillHelpYou from "./digital_howServiceWillHelpYou/digital_howservicewillhelpyou.component";
import BlogSection from "./digital_blogSection/digital_blogsection.component";
import ServiceContentSection from "./digital_serviceContentSection/digital_servicecontentsection.component";
import AOS from "aos";
import FollowUs from "../../../commons/followus/followus.component";
import { BASE_URL } from "../../../config/config";

const DigitalTransformation = (props) => {
  const DIGITAL_TRANSFORMATION_SLIDER = `${BASE_URL}/service_digital_header_image.png`;

  useEffect(() => {
    AOS.init();
  });
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleTabScroll = (value) => {
    if (value === "OVER_VIEW") {
      window.scrollTo({
        top: 1050,
        behavior: "smooth",
      });
    } else if (value === "BLOG") {
      window.scrollTo({
        top: 1700,
        behavior: "smooth",
      });
    } else if (value === "WHY_DOMAINCER") {
      window.scrollTo({
        top: 2500,
        behavior: "smooth",
      });
    } else if (value === "RECENT_WORKS") {
      window.scrollTo({
        top: 3100,
        behavior: "smooth",
      });
    }
  };

  const handleTabScrollMobile = (value) => {
    if (value === "OVER_VIEW") {
      window.scrollTo({
        top: 1500,
        behavior: "smooth",
      });
    } else if (value === "BLOG") {
      window.scrollTo({
        top: 2500,
        behavior: "smooth",
      });
    } else if (value === "WHY_DOMAINCER") {
      window.scrollTo({
        top: 3300,
        behavior: "smooth",
      });
    } else if (value === "RECENT_WORKS") {
      window.scrollTo({
        top: 4500,
        behavior: "smooth",
      });
    }
  };

  return (
    <div class="mobileMarginTop">
      <FollowUs history={props.history} />
      <Container>
        <Grid container>
          <Grid
            xs={12}
            sm={6}
            className="digital_BSSliderImgContainer"
            // data-aos="fade-up"
            data-aos-duration="1000"
          >
            <img
              src={DIGITAL_TRANSFORMATION_SLIDER}
              alt="Best digital transformation company image domaincer"
              className="digitalTransformationSliderImg floatingUpDown"
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <div
              className="digital_BSSliderRightSection"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <h1 className="digital_sliderHeading">Digital Transformation</h1>
              <h2 className="digital_sliderSubHeading">
                <b>Repute your flow to digital - </b> We deliver service that
                stay connected and relevant to their prospects and customers
                across the entire journey by domaincer tailor-made digital
                transformation strategy and consulting service.
              </h2>
              <Hidden only={["xs"]}>
                <div className="digital_serviceTabsSection">
                  <Paper square className="digital_MTabsWidth">
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      indicatorColor="primary"
                      textColor="primary"
                      aria-label="tabs example"
                      className="digital_MTabsContainer"
                    >
                      <Tab
                        label="About"
                        className="digital_MTabBtn"
                        // onClick={() => handleTabScroll("ABOUT")}
                      />
                      <Tab
                        label="Overview"
                        className="digital_MTabBtn"
                        onClick={() => handleTabScroll("OVER_VIEW")}
                      />
                      <Tab
                        label="Blog"
                        className="digital_MTabBtn"
                        onClick={() => handleTabScroll("BLOG")}
                      />
                      <Tab
                        label="Why Domaincer"
                        className="digital_MTabBtn"
                        onClick={() => handleTabScroll("WHY_DOMAINCER")}
                      />
                      {/* <Tab
                        label="Recent Works"
                        className="digital_MTabBtn"
                        onClick={() => handleTabScroll("RECENT_WORKS")}
                      /> */}
                    </Tabs>
                  </Paper>
                </div>
              </Hidden>
              <Hidden only={["sm", "md", "lg", "xl"]}>
                <div className="serviceTabsSection">
                  <Paper square className="MTabsWidth">
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      indicatorColor="primary"
                      textColor="primary"
                      aria-label="tabs example"
                      className="MTabsContainer"
                    >
                      <Tab label="About" className="MTabBtn" />
                      <Tab
                        label="Overview"
                        className="MTabBtn"
                        onClick={() => handleTabScrollMobile("OVER_VIEW")}
                      />
                      <Tab
                        label="Blog"
                        className="MTabBtn"
                        onClick={() => handleTabScrollMobile("BLOG")}
                      />
                      <Tab
                        label="Why Domaincer"
                        className="MTabBtn"
                        onClick={() => handleTabScrollMobile("WHY_DOMAINCER")}
                      />
                      {/* <Tab
                        label="Recent Works"
                        className="MTabBtn"
                        onClick={() => handleTabScrollMobile("RECENT_WORKS")}
                      /> */}
                    </Tabs>
                  </Paper>
                </div>
              </Hidden>
            </div>
          </Grid>
        </Grid>
      </Container>
      <HowServiceWillHelpYou />
      <ServiceContentSection />
      <BlogSection />
      <WhyChooseUs />
      {/* <OurProcess /> */}
      {/* <CaseStudySection /> */}
    </div>
  );
};

export default DigitalTransformation;
