import React from 'react';
import '../workflowAutomation.css';
import { Container, Grid } from '@material-ui/core';
import Particles from 'react-particles-js';

const HowServiceWillHelpYou = () => {
  return (
    <div>
      <Grid container>
        <Particles
          params={{
            particles: {
              number: {
                value: 350,
              },
              size: {
                value: 1,
              },
              color: {
                value: '#fff',
              },
              line_linked: {
                enable: false,
                distance: 120,
                color: '#fff',
                width: 1,
              },
            },
            interactivity: {
              events: {
                onhover: {
                  enable: true,
                  mode: 'repulse',
                },
              },
            },
          }}
          className='workflow_particalsSection'
        />
        <Grid
          xs={12}
          sm={12}
          style={{ zIndex: -1, position: 'relative', textAlign: 'center' }}
        >
          <div className='workflow_BSHelpYouRightSection'>
            <h1 className='workflow_BSHelpYouHeading'>
              How Workflow Automation will help your Business?
            </h1>
            <h2
              className='workflow_BSHelpYouPara'
              data-aos='fade-up'
              data-aos-duration='1000'
            >
              About 86% of organizations are relied upon to hit their limit and require
              intelligent automation to help them keep up. The inevitability of it aside, there are various advantages to the automation of your business process as much as the process.
            </h2>
            <div
              data-aos='fade-up'
              data-aos-duration='1000'
              className='workflow_circleBSHelpYou'
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default HowServiceWillHelpYou;
