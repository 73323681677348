import React, { useState, useEffect } from "react";
import { Container, Grid, Button, Link } from "@material-ui/core";
import Hidden from "@material-ui/core/Hidden";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { BASE_URL } from "../../../../config/config";
import "../../metaverse/portal.css";

const Blockchain = [
  {
    key: "CONTENT_SECTION_ONE",
    name: "DECENTRALIZED",
    subtext: "",
    image: `${BASE_URL}/blockDecent.png`,
    alt: "Decentralized",
  },
  {
    key: "CONTENT_SECTION_TWO",
    name: "PERMISSIONED",
    subtext: "",
    image: `${BASE_URL}/blockPermission.png`,
    alt: "Permissioned",
  },
  {
    key: "CONTENT_SECTION_THREE",
    name: "DATA BACKUP",
    subtext: "",
    image: `${BASE_URL}/blockDataBackUp.png`,
    alt: "Data Backup",
  },
];

const WhyDomaincerBlockchain = () => {
  const [activeTabService, setActiveTabService] = useState(
    "CONTENT_SECTION_ONE"
  );

  return (
    <div>
      <div style={{ paddingTop: 100, paddingBottom: 30 }}>
        <h1 className="service_subheading_portal">Why Blockchain</h1>
        <h2 className="service_subtext_portal">
          Get ready to enter the future with our team of brilliant blockchain
          builders! We specialize in creating a wide range of applications with
          smart technology networks. Our team of experts has the skills and
          knowledge to bring your visions to life using the power of blockchain.
        </h2>
      </div>

      {/* visible only on desktop, laptop */}
      <Hidden only={["xs"]}>
        <div className="subServiceTabSection_portal">
          {Blockchain.map((service, index) => (
            <div
              className="subServiceContaincer_portal"
              style={{
                borderColor:
                  service.key === activeTabService ? "#0077FF" : "#FFF",
              }}
              onClick={() => setActiveTabService(service.key)}
            >
              <img
                className="subServiceImg_portal"
                src={service.image}
                alt={service.alt}
              />
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <h1 className="subServiceTitle_portal">{service.name}</h1>
                {/* <h2 className="subServiceSubTitle_portal">{service.subtext}</h2> */}
              </div>
            </div>
          ))}
        </div>
        <div>
          {activeTabService === "CONTENT_SECTION_ONE" && (
            <div className="subServiceParaContainer_portal">
              <Container
                maxWidth="lg"
                className="subServiceFixedSection_portal"
              >
                <div className="glowEffectBrand_portal"></div>

                <div className="serviceContent_portal">
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <h3 className="subServicePara_portal">
                        Welcome to the world of decentralization, where trust is
                        a thing of the past! In a decentralized blockchain
                        network, every member has their own copy of the exact
                        same data, stored in a trusty distributed ledger. And if
                        anyone tries to mess with their ledger, they'll be
                        rejected by the majority of the network. It's a system
                        that keeps everyone honest and ensures that the data
                        stays accurate and secure.
                      </h3>
                    </Grid>
                  </Grid>
                </div>
              </Container>
            </div>
          )}
          {activeTabService === "CONTENT_SECTION_TWO" && (
            <div className="subServiceParaContainer_portal">
              <Container
                maxWidth="lg"
                className="subServiceFixedSection_portal"
              >
                <div className="glowEffectDigital_portal"></div>

                <div className="serviceContent_portal">
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <h3 className="subServicePara_portal">
                        Looking for a secure and controlled environment for your
                        transactions? Look no further than permissioned
                        blockchain! This private, invitation-only distributed
                        ledger is only accessible to users with permission, and
                        they can only perform specific actions granted by the
                        ledger administrators. Everyone is required to identify
                        themselves through certificates or other digital means
                        to ensure the integrity of the network.
                      </h3>
                    </Grid>
                  </Grid>
                </div>
              </Container>
            </div>
          )}
          {activeTabService === "CONTENT_SECTION_THREE" && (
            <div className="subServiceParaContainer_portal">
              <Container
                maxWidth="lg"
                className="subServiceFixedSection_portal"
              >
                <div className="glowEffectWorkFlow_portal"></div>

                <div className="serviceContent_portal">
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <h3 className="subServicePara_portal">
                        Blockchain backups: your data's guardian angel! When you
                        want to verify whether your backups have changed or not,
                        blockchain has your back. It creates a special
                        certificate for each file that can be used to check if
                        the file has been altered in any way. Think of it like a
                        fingerprint for your data, helping you to keep track of
                        what's been modified and what's stayed the same.
                      </h3>
                    </Grid>
                  </Grid>
                </div>
              </Container>
            </div>
          )}
        </div>
      </Hidden>

      {/* visible only on mobile */}
      <Hidden only={["sm", "lg", "md", "xl"]}>
        <div style={{ position: "relative" }}>
          <ArrowBackIosIcon
            className="subServiceTabLeftArrowHome"
            onClick={() => {
              document.getElementById("strategyScroll").scrollLeft -= 400;
            }}
          />
          <div className="subServiceTabSection_portal" id="strategyScroll">
            <div>
              <div className="subServiceContaincer_portal">
                <img
                  className="subServiceImg_portal"
                  src={`${BASE_URL}/blockDecent.png`}
                  alt="AR image domaincer"
                />
                <div>
                  <h1 className="subServiceTitle_portal">DECENTRALIZED</h1>
                </div>
              </div>
              <div className="subServiceParaContainer_portal">
                <Container
                  maxWidth="lg"
                  className="subServiceFixedSection_portal"
                >
                  <div className="glowEffectBrand_portal"></div>

                  <div className="serviceContent_portalOne">
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <h3 className="subServicePara_portal">
                          Welcome to the world of decentralization, where trust
                          is a thing of the past! In a decentralized blockchain
                          network, every member has their own copy of the exact
                          same data, stored in a trusty distributed ledger. And
                          if anyone tries to mess with their ledger, they'll be
                          rejected by the majority of the network. It's a system
                          that keeps everyone honest and ensures that the data
                          stays accurate and secure.
                        </h3>
                      </Grid>
                    </Grid>
                  </div>
                </Container>
              </div>
            </div>
            <div>
              <div className="subServiceContaincer_portal">
                <img
                  className="subServiceImg_portal"
                  src={`${BASE_URL}/blockPermission.png`}
                  alt="VR image domaincer"
                />
                <div>
                  <h1 className="subServiceTitle_portal">PERMISSIONED</h1>
                </div>
              </div>
              <div className="subServiceParaContainer_portal">
                <Container
                  maxWidth="lg"
                  className="subServiceFixedSection_portal"
                >
                  <div className="glowEffectDigital_portal"></div>

                  <div className="serviceContent_portal">
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <h3 className="subServicePara_portal">
                          Looking for a secure and controlled environment for
                          your transactions? Look no further than permissioned
                          blockchain! This private, invitation-only distributed
                          ledger is only accessible to users with permission,
                          and they can only perform specific actions granted by
                          the ledger administrators. Everyone is required to
                          identify themselves through certificates or other
                          digital means to ensure the integrity of the network.
                        </h3>
                      </Grid>
                    </Grid>
                  </div>
                </Container>
              </div>
            </div>
            <div>
              <div className="subServiceContaincer_portal">
                <img
                  className="subServiceImg_portal"
                  src={`${BASE_URL}/blockDataBackUp.png`}
                  alt="MR image domaincer"
                />
                <div>
                  <h1 className="subServiceTitle_portal">DATA BACKUP</h1>
                </div>
              </div>
              <div className="subServiceParaContainer_portal">
                <Container
                  maxWidth="lg"
                  className="subServiceFixedSection_portal"
                >
                  <div className="glowEffectWorkFlow_portal"></div>

                  <div className="serviceContent_portalThree">
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <h3 className="subServicePara_portal">
                          Blockchain backups: your data's guardian angel! When
                          you want to verify whether your backups have changed
                          or not, blockchain has your back. It creates a special
                          certificate for each file that can be used to check if
                          the file has been altered in any way. Think of it like
                          a fingerprint for your data, helping you to keep track
                          of what's been modified and what's stayed the same.
                        </h3>
                      </Grid>
                    </Grid>
                  </div>
                </Container>
              </div>
            </div>
          </div>
          <ArrowForwardIosIcon
            className="subServiceTabRightArrowHome"
            onClick={() => {
              document.getElementById("strategyScroll").scrollLeft += 400;
            }}
          />
        </div>
      </Hidden>
    </div>
  );
};

export default WhyDomaincerBlockchain;
