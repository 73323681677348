import React, { useState, useEffect } from "react";
import "./socialmediamarketing.css";
import { Container, Grid, Hidden, Paper, Tab, Tabs } from "@material-ui/core";
import AOS from "aos";
import HowServiceWillHelpYouSocialMedia from "./howservicewillhelpyou_socialmedia/howservicewillhelpyou_socialmedia.component";
import ServiceContentSectionSocialMedia from "./servicecontentsection_socialmedia/servicecontentsection_socialmedia.component";
import BlogSectionSocialMedia from "./blogsection_socialmedia/blogsection_socialmedia.component";
import WhyChooseUsSocialMedia from "./whychooseus_socialmedia/whychooseus_socialmedia.component";
// import OurProcessSocialMedia from './ourprocess_socialmedia/ourprocess_socialmedia.component';
import CaseStudySection from "../../homepage/casestudyssection/casestudysection.component";
import FollowUs from "../../../commons/followus/followus.component";
import { BASE_URL } from "../../../config/config";

const SocialMediaMarketing = (props) => {
  const SOCIAL_MEDIA_MARKETING_SLIDER = `${BASE_URL}/service_social_header_image.png`;

  useEffect(() => {
    AOS.init();
  });
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleTabScroll = (value) => {
    if (value === "OVER_VIEW") {
      window.scrollTo({
        top: 1050,
        behavior: "smooth",
      });
    } else if (value === "BLOG") {
      window.scrollTo({
        top: 1700,
        behavior: "smooth",
      });
    } else if (value === "WHY_DOMAINCER") {
      window.scrollTo({
        top: 2000,
        behavior: "smooth",
      });
    } else if (value === "RECENT_WORKS") {
      window.scrollTo({
        top: 2600,
        behavior: "smooth",
      });
    }
  };

  const handleTabScrollMobile = (value) => {
    if (value === "OVER_VIEW") {
      window.scrollTo({
        top: 1500,
        behavior: "smooth",
      });
    } else if (value === "BLOG") {
      window.scrollTo({
        top: 2400,
        behavior: "smooth",
      });
    } else if (value === "WHY_DOMAINCER") {
      window.scrollTo({
        top: 3300,
        behavior: "smooth",
      });
    } else if (value === "RECENT_WORKS") {
      window.scrollTo({
        top: 4500,
        behavior: "smooth",
      });
    }
  };

  return (
    <div class="mobileMarginTop">
      <FollowUs history={props.history} />
      <Container>
        <Grid container>
          <Grid
            xs={12}
            sm={6}
            className="BSSliderImgContainer_socialmedia"
            // data-aos="fade-up"
            data-aos-duration="1000"
          >
            <img
              src={SOCIAL_MEDIA_MARKETING_SLIDER}
              alt="social media marketing image domaincer"
              className="brandStrategySliderImg_socialmedia floatingUpDown"
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <div
              className="BSSliderRightSection_socialmedia"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <h1 className="sliderHeading_socialmedia">
                Social Media Marketing
              </h1>
              <h2 className="sliderSubHeading_socialmedia">
                <strong>The audience comes first -</strong> Ready to turbocharge
                your business growth and reel in new leads like never before?
                Look no further than Domaincer! With our laser-focused ad spend
                and precise targeting, we'll help you get the most bang for your
                buck and boost your ROI sky-high. Don't let slow growth hold you
                back any longer
              </h2>
              <Hidden only={["xs"]}>
                <div className="serviceTabsSection_socialmedia">
                  <Paper square className="MTabsWidth_socialmedia">
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      indicatorColor="primary"
                      textColor="primary"
                      aria-label="tabs example"
                      className="MTabsContainer"
                    >
                      <Tab label="About" className="MTabBtn" />
                      <Tab
                        label="Overview"
                        className="MTabBtn"
                        onClick={() => handleTabScroll("OVER_VIEW")}
                      />
                      <Tab
                        label="Blog"
                        className="MTabBtn"
                        onClick={() => handleTabScroll("BLOG")}
                      />
                      <Tab
                        label="Why Domaincer"
                        className="MTabBtn"
                        onClick={() => handleTabScroll("WHY_DOMAINCER")}
                      />
                      {/* <Tab
                        label="Recent Works"
                        className="MTabBtn"
                        onClick={() => handleTabScroll("RECENT_WORKS")}
                      /> */}
                    </Tabs>
                  </Paper>
                </div>
              </Hidden>
              <Hidden only={["sm", "md", "lg", "xl"]}>
                <div className="serviceTabsSection_socialmedia">
                  <Paper square className="MTabsWidth_socialmedia">
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      indicatorColor="primary"
                      textColor="primary"
                      aria-label="tabs example"
                      className="MTabsContainer"
                    >
                      <Tab label="About" className="MTabBtn" />
                      <Tab
                        label="Overview"
                        className="MTabBtn"
                        onClick={() => handleTabScrollMobile("OVER_VIEW")}
                      />
                      <Tab
                        label="Blog"
                        className="MTabBtn"
                        onClick={() => handleTabScrollMobile("BLOG")}
                      />
                      <Tab
                        label="Why Domaincer"
                        className="MTabBtn"
                        onClick={() => handleTabScrollMobile("WHY_DOMAINCER")}
                      />
                      {/* <Tab
                        label="Recent Works"
                        className="MTabBtn"
                        onClick={() => handleTabScrollMobile("RECENT_WORKS")}
                      /> */}
                    </Tabs>
                  </Paper>
                </div>
              </Hidden>
            </div>
          </Grid>
        </Grid>
      </Container>
      <HowServiceWillHelpYouSocialMedia />
      <ServiceContentSectionSocialMedia />
      <BlogSectionSocialMedia />
      <WhyChooseUsSocialMedia />
      {/* <OurProcessSocialMedia /> */}
      {/* <CaseStudySection /> */}
    </div>
  );
};

export default SocialMediaMarketing;
