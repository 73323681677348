import React, { useState, useEffect } from 'react';
import { Container, Grid, Button, Link } from '@material-ui/core';
import '../workflowAutomation.css';
import Hidden from '@material-ui/core/Hidden';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { BASE_URL } from '../../../../config/config';

const STRATEGY = [
  {
    key: 'CONTENT_SECTION_ONE',
    name: 'Reports & Analytics',
    subtext: 'Skyrocket Business process',
    image: `${BASE_URL}/tab_workflow_icon1.png`,
    alt: 'report and analytics image domaincer',
  },
  {
    key: 'CONTENT_SECTION_TWO',
    name: ' Business Workflow',
    subtext: 'Maximize the business flow',
    image: `${BASE_URL}/tab_workflow_icon2.png`,
    alt: 'Business workflow image domaincer',
  },
  {
    key: 'CONTENT_SECTION_THREE',
    name: 'Marketing Workflow',
    subtext: 'Make use Business Intelligence',
    image: `${BASE_URL}/tab_workflow_icon3.png`,
    alt: 'marketing workflow image domaincer',
  },
];

const ServiceContentSection = () => {
  const [activeTabService, setActiveTabService] = useState(
    'CONTENT_SECTION_ONE'
  );

  return (
    <div>
      <div style={{ paddingTop: 100, paddingBottom: 30 }}>
        <h1 className='workflow_service_subheading'>
          About Workflow Automation
        </h1>
        <h2 className='workflow_service_subtext'>
          Data, Information and experience empower the culture of digital
          transformation, that gives seamless experience for your customer and
          measureable ROI for Your business.
        </h2>
      </div>
      {/* visible only on desktop, laptop */}
      <Hidden only={['xs']}>
        <div className='workflow_subServiceTabSection'>
          {STRATEGY.map((service, index) => (
            <div
              className='workflow_subServiceContaincer'
              style={{
                borderColor:
                  service.key === activeTabService ? '#0077FF' : '#FFF',
              }}
              onClick={() => setActiveTabService(service.key)}
            >
              <img
                className='workflow_subServiceImg'
                src={service.image}
                alt={service.alt}
              />
              <div>
                <h1 className='workflow_subServiceTitle'>{service.name}</h1>
                <h2 className='workflow_subServiceSubTitle'>
                  {service.subtext}
                </h2>
              </div>
            </div>
          ))}
        </div>
        <div>
          {activeTabService === 'CONTENT_SECTION_ONE' && (
            <div className='workflow_subServiceParaContainer'>
              <Container
                maxWidth='lg'
                className='workflow_subServiceFixedSection'
              >
                <div className='workflow_glowEffectBrand'></div>

                <div className='workflow_serviceContent'>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <h3 className='workflow_subServicePara'>
                        Our eccentric workflow automation solutions empower easy
                        point-and-click modelling, business activity monitoring
                        (BAM). Our work process automation that can be arranged
                        through a completely online interface to enable you to
                        graphic model and build processes that require neither
                        software development asset nor advanced IT assets.
                      </h3>
                    </Grid>
                  </Grid>
                </div>
              </Container>
            </div>
          )}
          {activeTabService === 'CONTENT_SECTION_TWO' && (
            <div className='workflow_subServiceParaContainer'>
              <Container
                maxWidth='lg'
                className='workflow_subServiceFixedSection'
              >
                <div className='workflow_glowEffectDigital'></div>

                <div className='workflow_serviceContent'>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <h3 className='workflow_subServicePara'>
                        With our workflow automation solution, clients can make
                        structures, work processes, and business rules
                        effectively and without any seek of advanced IT help.
                        Our work process automation solution guides you with
                        ensured fundamental reports, and basic data are properly
                        directed, surveyed, reviewed and revised.
                      </h3>
                    </Grid>
                  </Grid>
                </div>
              </Container>
            </div>
          )}
          {activeTabService === 'CONTENT_SECTION_THREE' && (
            <div className='workflow_subServiceParaContainer'>
              <Container
                maxWidth='lg'
                className='workflow_subServiceFixedSection'
              >
                <div className='workflow_glowEffectWorkFlow'></div>

                <div className='workflow_serviceContent'>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <h3 className='workflow_subServicePara'>
                        Our unique expertise team with strong business
                        knowledge, excellent delivery practices, and technology
                        expertise can help enterprises formulated solutions that
                        enable them to make informed and insightful business
                        decisions.
                      </h3>
                    </Grid>
                  </Grid>
                </div>
              </Container>
            </div>
          )}
        </div>
      </Hidden>

      {/* visible only on mobile */}
      <Hidden only={['sm', 'lg', 'md', 'xl']}>
        <div style={{ position: 'relative' }}>
          <ArrowBackIosIcon
            className='subServiceTabLeftArrowHome'
            onClick={() => {
              document.getElementById('strategyScroll').scrollLeft -= 400;
            }}
          />
          <div className='workflow_subServiceTabSection' id='strategyScroll'>
            <div>
              <div className='workflow_subServiceContaincer'>
                <img
                  className='workflow_subServiceImg'
                  src={`${BASE_URL}/tab_workflow_icon1.png`}
                  alt='report and analytics image domaincer'
                />
                <div>
                  <h1 className='workflow_subServiceTitle'>
                    Reports & Analytics
                  </h1>
                  <h2 className='workflow_subServiceSubTitle'>
                    Skyrocket Business process
                  </h2>
                </div>
              </div>
              <div className='workflow_subServiceParaContainer'>
                <Container
                  maxWidth='lg'
                  className='workflow_subServiceFixedSection'
                >
                  <div className='workflow_glowEffectBrand'></div>

                  <div className='workflow_serviceContent'>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <h3 className='workflow_subServicePara'>
                          Our eccentric workflow automation solutions empower
                          easy point-and-click modelling, business activity
                          monitoring (BAM). Our work process automation that can
                          be arranged through a completely online interface to
                          enable you to graphic model and build processes that
                          require neither software development asset nor
                          advanced IT assets.
                        </h3>
                      </Grid>
                    </Grid>
                  </div>
                </Container>
              </div>
            </div>
            <div>
              <div className='workflow_subServiceContaincer'>
                <img
                  className='workflow_subServiceImg'
                  src={`${BASE_URL}/tab_workflow_icon2.png`}
                  alt='Business workflow image domaincer'
                />
                <div>
                  <h1 className='workflow_subServiceTitle'>
                    {' '}
                    Business Workflow
                  </h1>
                  <h2 className='workflow_subServiceSubTitle'>
                    Maximize the business flow
                  </h2>
                </div>
              </div>
              <div className='workflow_subServiceParaContainer'>
                <Container
                  maxWidth='lg'
                  className='workflow_subServiceFixedSection'
                >
                  <div className='workflow_glowEffectDigital'></div>

                  <div className='workflow_serviceContentTwo'>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <h3 className='workflow_subServicePara'>
                          With our workflow automation solution, clients can
                          make structures, work processes, and business rules
                          effectively and without any seek of advanced IT help.
                          Our work process automation solution guides you with
                          ensured fundamental reports, and basic data are
                          properly directed, surveyed, reviewed and revised.
                        </h3>
                      </Grid>
                    </Grid>
                  </div>
                </Container>
              </div>
            </div>
            <div>
              <div className='workflow_subServiceContaincer'>
                <img
                  className='workflow_subServiceImg'
                  src={`${BASE_URL}/tab_workflow_icon3.png`}
                  alt='marketing workflow image domaincer'
                />
                <div>
                  <h1 className='workflow_subServiceTitle'>
                    Marketing Workflow
                  </h1>
                  <h2 className='workflow_subServiceSubTitle'>
                    Make use Business Intelligence
                  </h2>
                </div>
              </div>
              <div className='workflow_subServiceParaContainer'>
                <Container
                  maxWidth='lg'
                  className='workflow_subServiceFixedSection'
                >
                  <div className='workflow_glowEffectWorkFlow'></div>

                  <div className='workflow_serviceContentThree'>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <h3 className='workflow_subServicePara'>
                          Our unique expertise team with strong business
                          knowledge, excellent delivery practices, and
                          technology expertise can help enterprises formulated
                          solutions that enable them to make informed and
                          insightful business decisions.
                        </h3>
                      </Grid>
                    </Grid>
                  </div>
                </Container>
              </div>
            </div>
          </div>
          <ArrowForwardIosIcon
            className='subServiceTabRightArrowHome'
            onClick={() => {
              document.getElementById('strategyScroll').scrollLeft += 400;
            }}
          />
        </div>
      </Hidden>
    </div>
  );
};

export default ServiceContentSection;
