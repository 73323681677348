import React, { useState, useEffect } from "react";
import "./graphics.css";
import { Container, Grid, Hidden, Paper, Tab, Tabs } from "@material-ui/core";
import WhyChooseUs from "./graphics_whyChooseUs/graphics_whychooseus.component";
// import OurProcess from './graphics_ourProcess/graphics_ourprocess.component';
import CaseStudySection from "../../homepage/casestudyssection/casestudysection.component";
import HowServiceWillHelpYou from "./graphics_howServiceWillHelpYou/graphics_howservicewillhelpyou.component";
import BlogSection from "./graphics_blogSection/graphics_blogsection.component";
import ServiceContentSection from "./graphics_serviceContentSection/graphics_servicecontentsection.component";
import AOS from "aos";
import FollowUs from "../../../commons/followus/followus.component";
import { BASE_URL } from "../../../config/config";

const MotionGraphics = (props) => {
  const MOTION_GRAPHICS_SLIDER = `${BASE_URL}/service_graphics_header_image.png`;

  useEffect(() => {
    AOS.init();
  });
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleTabScroll = (value) => {
    if (value === "OVER_VIEW") {
      window.scrollTo({
        top: 950,
        behavior: "smooth",
      });
    } else if (value === "BLOG") {
      window.scrollTo({
        top: 1600,
        behavior: "smooth",
      });
    } else if (value === "WHY_DOMAINCER") {
      window.scrollTo({
        top: 2350,
        behavior: "smooth",
      });
    } else if (value === "RECENT_WORKS") {
      window.scrollTo({
        top: 3000,
        behavior: "smooth",
      });
    }
  };

  const handleTabScrollMobile = (value) => {
    if (value === "OVER_VIEW") {
      window.scrollTo({
        top: 1500,
        behavior: "smooth",
      });
    } else if (value === "BLOG") {
      window.scrollTo({
        top: 2300,
        behavior: "smooth",
      });
    } else if (value === "WHY_DOMAINCER") {
      window.scrollTo({
        top: 3000,
        behavior: "smooth",
      });
    } else if (value === "RECENT_WORKS") {
      window.scrollTo({
        top: 4400,
        behavior: "smooth",
      });
    }
  };

  return (
    <div class="mobileMarginTop">
      <FollowUs history={props.history} />
      <Container>
        <Grid container>
          <Grid
            xs={12}
            sm={6}
            className="graphics_BSSliderImgContainer"
            // data-aos="fade-up"
            data-aos-duration="1000"
          >
            <img
              src={MOTION_GRAPHICS_SLIDER}
              alt="Best motion graphics image domaincer"
              className="graphics_SliderImg floatingUpDown"
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <div
              className="graphics_BSSliderRightSection"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <h1 className="graphics_sliderHeading">Motion Graphics</h1>
              <h2 className="graphics_sliderSubHeading">
                <strong>Video vanguards:</strong> Our explainer videos weave
                stories of your organization's innovation, passion, and
                expertise, creating visuals that captivate and inspire. From
                cutting-edge tech to the great outdoors, we're masters at
                pioneering storytelling through motion.
              </h2>
              <Hidden only={["xs"]}>
                <div className="graphics_serviceTabsSection">
                  <Paper square className="graphics_MTabsWidth">
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      indicatorColor="primary"
                      textColor="primary"
                      aria-label="tabs example"
                      className="graphics_MTabsContainer"
                    >
                      <Tab label="About" className="graphics_MTabBtn" />
                      <Tab
                        label="Overview"
                        className="graphics_MTabBtn"
                        onClick={() => handleTabScroll("OVER_VIEW")}
                      />
                      <Tab
                        label="Blog"
                        className="graphics_MTabBtn"
                        onClick={() => handleTabScroll("BLOG")}
                      />
                      <Tab
                        label="Why Domaincer"
                        className="graphics_MTabBtn"
                        onClick={() => handleTabScroll("WHY_DOMAINCER")}
                      />
                      {/* <Tab
                        label="Recent Works"
                        className="graphics_MTabBtn"
                        onClick={() => handleTabScroll("RECENT_WORKS")}
                      /> */}
                    </Tabs>
                  </Paper>
                </div>
              </Hidden>
              <Hidden only={["sm", "md", "lg", "xl"]}>
                <div className="graphics_serviceTabsSection">
                  <Paper square className="graphics_MTabsWidth">
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      indicatorColor="primary"
                      textColor="primary"
                      aria-label="tabs example"
                      className="graphics_MTabsContainer"
                    >
                      <Tab label="About" className="graphics_MTabBtn" />
                      <Tab
                        label="Overview"
                        className="graphics_MTabBtn"
                        onClick={() => handleTabScrollMobile("OVER_VIEW")}
                      />
                      <Tab
                        label="Blog"
                        className="graphics_MTabBtn"
                        onClick={() => handleTabScrollMobile("BLOG")}
                      />
                      <Tab
                        label="Why Domaincer"
                        className="graphics_MTabBtn"
                        onClick={() => handleTabScrollMobile("WHY_DOMAINCER")}
                      />
                      {/* <Tab
                        label="Recent Works"
                        className="graphics_MTabBtn"
                        onClick={() => handleTabScrollMobile("RECENT_WORKS")}
                      /> */}
                    </Tabs>
                  </Paper>
                </div>
              </Hidden>
            </div>
          </Grid>
        </Grid>
      </Container>
      <HowServiceWillHelpYou />
      <ServiceContentSection />
      <BlogSection />
      <WhyChooseUs />
      {/* <OurProcess /> */}
      {/* <CaseStudySection /> */}
    </div>
  );
};

export default MotionGraphics;
