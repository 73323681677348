import React from "react";
import '../brandstrategy.css';
import { Container, Grid, Card } from "@material-ui/core";
import Hidden from "@material-ui/core/Hidden";
import { BASE_URL } from '../../../../config/config';


const WhyChooseUs = () => {
  const WHY_CHOOSE_US_IMAGE = `${BASE_URL}/about_why_choose_us.png`
 
  return (
    <div className="whyChooseUsSection_strategy">
      <Container>
        <h1 className="whyChooseUsTitle_strategy">Why Domaincer</h1>
        <Grid container spacing={2} style={{ justifyContent: "center" }}>
          <Grid item xs={12} sm={3}>
            <div
              className="whyChooseColumnUpContainer_strategy"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <h2 className="chooseTitle_strategy">Strategy</h2>
              <h3 className="choosePara_strategy">
                We provide a strategy that involves of right integration of
                storytelling that convinces your audience.
              </h3>
            </div>
            <div
              className="whyChooseColumnDownContainer_strategy"
              data-aos="fade-right"
              data-aos-duration="1400"
            >
              <h2 className="chooseTitle_strategy">Methodology</h2>
              <h3 className="choosePara_strategy">
                We believe in creating customer-focused communication that's
                simple, clear & Engaging.
              </h3>
            </div>
          </Grid>
          <Grid item xs={12} sm={4} className="chooseUsImageRow_strategy">
            <img
              src={WHY_CHOOSE_US_IMAGE}
              alt = "why choose us image 3d domaincer"

              className="chooseUsMiddleImg_strategy floatingUpDown"
            />
          </Grid>

          <Hidden only={["sm", "lg", "md", "xl"]}>
            <Grid item xs={12} sm={3}>
              <div
                className="whyChooseColumn3UpContainer_strategy"
                data-aos="fade-up"
                data-aos-duration="1200"
              >
                <h2 className="chooseTitle_strategy">Brainstorm</h2>
                <h3 className="choosePara_strategy">
                  We have creative heads get together to deliver intense
                  brainstorming.
                </h3>
              </div>
              <div
                className="whyChooseColumn3DownContainer_strategy"
                data-aos="fade-up"
                data-aos-duration="1400"
              >
                <h2 className="chooseTitle_strategy">Experience</h2>
                <h3 className="choosePara_strategy">
                  We hold more than a decade of experience to re-imagining your
                  association to outstanding.
                </h3>
              </div>
            </Grid>
          </Hidden>
          <Hidden only={["xs"]}>
            <Grid item xs={12} sm={3}>
              <div
                className="whyChooseColumn3UpContainer_strategy"
                data-aos="fade-left"
                data-aos-duration="1200"
              >
                <h2 className="chooseTitle_strategy">Brainstorm</h2>
                <h3 className="choosePara_strategy">
                  We have creative heads get together to deliver intense
                  brainstorming.
                </h3>
              </div>
              <div
                className="whyChooseColumn3DownContainer_strategy"
                data-aos="fade-left"
                data-aos-duration="1600"
              >
                <h2 className="chooseTitle_strategy">Experience</h2>
                <h3 className="choosePara_strategy">
                  We hold more than a decade of experience to re-imagining your
                  association to outstanding.
                </h3>
              </div>
            </Grid>
          </Hidden>
        </Grid>
      </Container>
    </div>
  );
};
export default WhyChooseUs;
