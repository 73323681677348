import React, { useState, useEffect } from "react";
import { Container, Grid, Button, Link } from "@material-ui/core";
import "../devops.css";
import Hidden from "@material-ui/core/Hidden";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { BASE_URL } from "../../../../config/config";

const STRATEGY = [
  {
    key: "CONTENT_SECTION_ONE",
    name: "Cloud Hosting",
    subtext: "Fueled by data, driven by automation",
    image: `${BASE_URL}/tab_devops_icon1.png`,
    alt: "cloud hosting image domaincer",
  },
  {
    key: "CONTENT_SECTION_TWO",
    name: "Architecture SetUp",
    subtext: "Evaluate quality conditioning",
    image: `${BASE_URL}/tab_devops_icon2.png`,
    alt: "Architecture image domaincer",
  },
  {
    key: "CONTENT_SECTION_THREE",
    name: "Cloud Migration",
    subtext: "Process-boosted performance",
    image: `${BASE_URL}/tab_devops_icon3.png`,
    alt: "cloud migration image domaincer",
  },
];

const ServiceContentSectionDevOps = () => {
  const [activeTabService, setActiveTabService] = useState(
    "CONTENT_SECTION_ONE"
  );

  return (
    <div>
      <div style={{ paddingTop: 100, paddingBottom: 30 }}>
        <h1 className="service_subheading_devops">About DevOps</h1>
        <h2 className="service_subtext_devops">
          Get a competitive edge with our DevOps as a service. We help
          organizations implement continuous delivery and integration,
          eliminating long app development cycles and legacy processes. Let us
          help you leverage the power of DevOps and achieve new levels of
          success.
        </h2>
      </div>
      {/* visible only on desktop, laptop */}
      <Hidden only={["xs"]}>
        <div className="subServiceTabSection_devops">
          {STRATEGY.map((service, index) => (
            <div
              className="subServiceContaincer_devops"
              style={{
                borderColor:
                  service.key === activeTabService ? "#0077FF" : "#FFF",
              }}
              onClick={() => setActiveTabService(service.key)}
            >
              <img
                className="subServiceImg_devops"
                src={service.image}
                alt={service.alt}
              />
              <div>
                <h1 className="subServiceTitle_devops">{service.name}</h1>
                <h2 className="subServiceSubTitle_devops">{service.subtext}</h2>
              </div>
            </div>
          ))}
        </div>
        <div>
          {activeTabService === "CONTENT_SECTION_ONE" && (
            <div className="subServiceParaContainer_devops">
              <Container
                maxWidth="lg"
                className="subServiceFixedSection_devops"
              >
                <div className="glowEffectBrand_devops"></div>

                <div className="serviceContent_devops">
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <h3 className="subServicePara_devops">
                        From ideation to implementation, DevOps streamlines the
                        development process with task automation. Our skilled
                        engineers create sturdy systems that eliminate tedious
                        manual processes, allowing for seamless updates and
                        quick bug fixes.
                      </h3>
                    </Grid>
                  </Grid>
                </div>
              </Container>
            </div>
          )}
          {activeTabService === "CONTENT_SECTION_TWO" && (
            <div className="subServiceParaContainer_devops">
              <Container
                maxWidth="lg"
                className="subServiceFixedSection_devops"
              >
                <div className="glowEffectDigital_devops"></div>

                <div className="serviceContent_devops">
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <h3 className="subServicePara_devops">
                        Get a top-to-bottom assessment of the tools you need to
                        pinpoint issues and boost agility. Our expert team has
                        the know-how to keep your operations running smoothly
                        through systematic evaluation. Upgrade to extraordinary
                        quality with our services.
                      </h3>
                    </Grid>
                  </Grid>
                </div>
              </Container>
            </div>
          )}
          {activeTabService === "CONTENT_SECTION_THREE" && (
            <div className="subServiceParaContainer_devops">
              <Container
                maxWidth="lg"
                className="subServiceFixedSection_devops"
              >
                <div className="glowEffectWorkFlow_devops"></div>
                <div className="serviceContent_devops">
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <h3 className="subServicePara_devops">
                        From beginner to pro, our team of automation consultants
                        has you covered. We'll craft a strategy that's perfect
                        for your business, whether you're just getting started
                        with automation or looking to outsource marketing work.
                        Take your business to new heights with our personalized
                        approach.
                      </h3>
                    </Grid>
                  </Grid>
                </div>
              </Container>
            </div>
          )}
        </div>
      </Hidden>

      {/* visible only on mobile */}
      <Hidden only={["sm", "lg", "md", "xl"]}>
        <div style={{ position: "relative" }}>
          <ArrowBackIosIcon
            className="subServiceTabLeftArrowHome"
            onClick={() => {
              document.getElementById("strategyScroll").scrollLeft -= 400;
            }}
          />
          <div className="subServiceTabSection_devops" id="strategyScroll">
            <div>
              <div className="subServiceContaincerOne_devops">
                <img
                  className="subServiceImg_devops"
                  src={`${BASE_URL}/tab_devops_icon1.png`}
                  alt="cloud hosting image domaincer"
                />
                <div>
                  <h1 className="subServiceTitle_devops">Cloud Hosting</h1>
                  <h2 className="subServiceSubTitle_devops">
                    Fueled by data, driven by automation
                  </h2>
                </div>
              </div>
              <div className="subServiceParaContainer_devops">
                <Container
                  maxWidth="lg"
                  className="subServiceFixedSection_devops"
                >
                  <div className="glowEffectBrand_devops"></div>

                  <div className="serviceContent_devops">
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <h3 className="subServicePara_devops">
                          From ideation to implementation, DevOps streamlines
                          the development process with task automation. Our
                          skilled engineers create sturdy systems that eliminate
                          tedious manual processes, allowing for seamless
                          updates and quick bug fixes.
                        </h3>
                      </Grid>
                    </Grid>
                  </div>
                </Container>
              </div>
            </div>

            <div>
              <div className="subServiceContaincer_devops">
                <img
                  className="subServiceImg_devops"
                  src={`${BASE_URL}/tab_devops_icon2.png`}
                  alt="Architecture image domaincer"
                />
                <div>
                  <h1 className="subServiceTitle_devops">Architecture SetUp</h1>
                  <h2 className="subServiceSubTitle_devops">
                    Evaluate quality conditioning
                  </h2>
                </div>
              </div>
              <div className="subServiceParaContainer_devops">
                <Container
                  maxWidth="lg"
                  className="subServiceFixedSection_devops"
                >
                  <div className="glowEffectDigital_devops"></div>

                  <div className="serviceContent_devopsTwo">
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <h3 className="subServicePara_devops">
                          Get a top-to-bottom assessment of the tools you need
                          to pinpoint issues and boost agility. Our expert team
                          has the know-how to keep your operations running
                          smoothly through systematic evaluation. Upgrade to
                          extraordinary quality with our services.
                        </h3>
                      </Grid>
                    </Grid>
                  </div>
                </Container>
              </div>
            </div>
            <div>
              <div className="subServiceContaincer_devops">
                <img
                  className="subServiceImg_devops"
                  src={`${BASE_URL}/tab_devops_icon3.png`}
                  alt="cloud migration image domaincer"
                />
                <div>
                  <h1 className="subServiceTitle_devops">Cloud Migration</h1>
                  <h2 className="subServiceSubTitle_devops">
                    Process-boosted performance
                  </h2>
                </div>
              </div>

              <div className="subServiceParaContainer_devops">
                <Container
                  maxWidth="lg"
                  className="subServiceFixedSection_devops"
                >
                  <div className="glowEffectWorkFlow_devops"></div>
                  <div className="serviceContent_devopsThree">
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <h3 className="subServicePara_devops">
                          From beginner to pro, our team of automation
                          consultants has you covered. We'll craft a strategy
                          that's perfect for your business, whether you're just
                          getting started with automation or looking to
                          outsource marketing work. Take your business to new
                          heights with our personalized approach.
                        </h3>
                      </Grid>
                    </Grid>
                  </div>
                </Container>
              </div>
            </div>
          </div>
          <ArrowForwardIosIcon
            className="subServiceTabRightArrowHome"
            onClick={() => {
              document.getElementById("strategyScroll").scrollLeft += 400;
            }}
          />
        </div>
      </Hidden>
    </div>
  );
};

export default ServiceContentSectionDevOps;
