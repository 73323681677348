import React from 'react';
import '../workflowAutomation.css';
import { Container, Grid, Card } from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';
import { BASE_URL } from '../../../../config/config';

const WhyChooseUs = () => {
  const WHY_CHOOSE_US_IMAGE = `${BASE_URL}/about_why_choose_us.png`;

  return (
    <div className='workflow_whyChooseUsSection'>
      <Container>
        <h1 className='workflow_whyChooseUsTitle'>Why Domaincer</h1>
        <Grid container spacing={2} style={{ justifyContent: 'center' }}>
          <Grid item xs={12} sm={3}>
            <div
              className='workflow_whyChooseColumnUpContainer'
              data-aos='fade-right'
              data-aos-duration='1000'
            >
              <h2 className='workflow_chooseTitle'>Analyze</h2>
              <h3 className='workflow_choosePara'>
                We analyze the existing infrastructure, business processes, and
                operations. We asses your business requirements before
              </h3>
            </div>
            <div
              className='workflow_whyChooseColumnDownContainer'
              data-aos='fade-right'
              data-aos-duration='1400'
            >
              <h2 className='workflow_chooseTitle'>Integrate</h2>
              <h3 className='workflow_choosePara'>
                We are expert in integrating reliable data and Automation
                add-ons at point-of-use in organization systems to maximize your
                workflow efficiency.
              </h3>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            className='workflow_about__chooseUsImageRow'
          >
            <img
              src={WHY_CHOOSE_US_IMAGE}
              alt='why choose us image 3d domaincer'
              className='workflow_about_chooseUsMiddleImg floatingUpDown'
            />
          </Grid>

          <Hidden only={['sm', 'lg', 'md', 'xl']}>
            <Grid item xs={12} sm={3}>
              <div
                className='workflow_whyChooseColumn3UpContainer'
                data-aos='fade-up'
                data-aos-duration='1200'
              >
                <h2 className='workflow_chooseTitle'>Implement</h2>
                <h3 className='workflow_choosePara'>
                  We implement a robust workflow system that involves defining
                  quality goals, monitoring and adjusting the plans to ensure
                  the workflow meets the needs of the organization.
                </h3>
              </div>
              <div
                className='workflow_whyChooseColumn3DownContainer'
                data-aos='fade-up'
                data-aos-duration='1400'
              >
                <h2 className='workflow_chooseTitle'>Support & Maintenance</h2>
                <h3 className='workflow_choosePara'>
                  we provide expert support system focuses on seamlessly
                  maintaining and transforming your business-critical workflow
                  to meet changing business needs.
                </h3>
              </div>
            </Grid>
          </Hidden>
          <Hidden only={['xs']}>
            <Grid item xs={12} sm={3}>
              <div
                className='workflow_whyChooseColumn3UpContainer'
                data-aos='fade-left'
                data-aos-duration='1200'
              >
                <h2 className='workflow_chooseTitle'>Implement</h2>
                <h3 className='workflow_choosePara'>
                  We implement a robust workflow system that involves defining
                  quality goals, monitoring and adjusting the plans to ensure
                  the workflow meets the needs of the organization.
                </h3>
              </div>
              <div
                className='workflow_whyChooseColumn3DownContainer'
                data-aos='fade-left'
                data-aos-duration='1600'
              >
                <h2 className='workflow_chooseTitle'>Support & Maintenance</h2>
                <h3 className='workflow_choosePara'>
                  we provide expert support system focuses on seamlessly
                  maintaining and transforming your business-critical workflow
                  to meet changing business needs.
                </h3>
              </div>
            </Grid>
          </Hidden>
        </Grid>
      </Container>
    </div>
  );
};
export default WhyChooseUs;
