import React, { useEffect } from "react";
import { Container, Grid, Avatar, Button, Card } from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import AOS from "aos";
import FollowUs from "../../../commons/followus/followus.component";
import { BASE_URL } from "../../../config/config";

const CaseStudyFpmarkets = (props) => {
  const FPM_DESIGN_IMAGE = `${BASE_URL}/fpm_casestudy_design_image.png`;
  const FPM_DEVELOPMENT_IMAGE = `${BASE_URL}/fpm_casestudy_development_image.png`;
  const FPM_MARKETING_IMAGE = `${BASE_URL}/fpm_casestudy_marketing_image.png`;
  const FPM_DESIGN_ICON = `${BASE_URL}/fpm_casestudy_design_icon.png`;
  const FPM_DEVELOPMENT_ICON = `${BASE_URL}/fpm_casestudy_development_icon.png`;
  const FPM_MARKETING_ICON = `${BASE_URL}/fpm_casestudy_marketing_icon.png`;
  const FPM_LOGO_ICON = `${BASE_URL}/fpm_casestudy_logo.png`;
  const FPM_IMAGE_HEADER = `${BASE_URL}/fpm_casestudy_header.png`;

  useEffect(() => {
    AOS.init();
  });

  return (
    <div class="mobileMarginTop">
      <FollowUs history={props.history} />
      <Container>
        <Grid container>
          <Grid xs={12} sm={6} style={{ alignSelf: "center" }}>
            <img
              src={FPM_IMAGE_HEADER}
              alt="Fp market case study domaincer"
              className="fpmarketsHeaderImage"
            />
          </Grid>
          <Grid xs={12} sm={5}>
            <div className="logoTextContaincer">
              <Avatar src={FPM_LOGO_ICON} className="caseStudiesLogo" />
              <h1 className="caseStudieTitle">FP Markets</h1>
            </div>
            <h2 className="caseStudiesSubTitle">
              <strong>$6.6 Trillion-A-Day Market Industry</strong>
            </h2>
            <p className="caseStudiesDescript">
              FP Markets, with 15 years of excellence in forex, CFDs,
              currencies, cryptocurrencies and commodities chose Domaincer for
              their expansion plan with big dreams on becoming the #1 player in
              forex.
            </p>
            <div className="labelsSection" style={{ alignItems: "center" }}>
              <div className="labelsContainerReact_ryzone">
                <img
                  src={FPM_DESIGN_ICON}
                  className="labelsIcon"
                  style={{ height: "87%" }}
                  alt="design icon domaincer"
                />
                <p className="labelsText">Design</p>
              </div>

              <div className="labelsContainer_ryzone">
                <img
                  src={FPM_DEVELOPMENT_ICON}
                  alt="development icon domaincer"
                  className="labelsIcon"
                />
                <p className="labelsText">Develop</p>
              </div>

              <div className="labelsContainer_ryzone">
                <img
                  src={FPM_MARKETING_ICON}
                  alt="digital marekting icon domaincer"
                  className="labelsIcon"
                />
                <p className="labelsText">Digital Marketing</p>
              </div>
            </div>

            <div style={{ marginBottom: 100 }}>
              <a href="https://www.fpmarkets.com/int/" target="_blank">
                <Button className="viewWebsiteContainer">
                  View Website
                  <ChevronRightIcon className="viewWebsite__arrow arrowani" />
                </Button>
              </a>
            </div>
          </Grid>
        </Grid>
      </Container>

      <div className="caseStudiesSectionTwo_ryzone">
        <Container>
          <Grid container>
            <Grid
              xs={12}
              sm={6}
              style={{ position: "relative" }}
              data-aos="fade-down"
              data-aos-duration="1000"
            >
              <div className="logoTextSectionTwo">
                <div className="circleDirector__caseStudy"></div>
                <div className="dottedLineContainer">
                  <p className="dottedLine1">. . . . . . . . .</p>
                  <p className="dottedLine1">. . . . . . . . .</p>
                  <p className="dottedLine1">. . . . . . . . .</p>
                  <p className="dottedLine1">. . . . . . . . .</p>
                  <p className="dottedLine1">. . . . . . . . .</p>
                  <p className="dottedLine1">. . . . . . . . .</p>
                  <p className="dottedLine1">. . . . . . . . .</p>
                  <p className="dottedLine1">. . . . . . . . .</p>
                </div>
                <div className="casestudy_icon_row">
                  <h1 className="caseStudieSectionTwoTitle">Design</h1>
                </div>
              </div>
              <p className="caseStudiesSectionTwoDescript">
                Stunning revamp of website pages, different portal UI/UX
                including client portal, affiliate portal and social media
                designs. Being in the market for about 15 years, FP Markets
                chose to stick with new upcoming trends in design spectrum and
                did the right thing to improve their marketing investment by
                more than 400%.
              </p>
            </Grid>
            <Grid xs={12} sm={6}>
              <img
                src={FPM_DESIGN_IMAGE}
                className="caseStudiesRightImg"
                alt="design stage icon domaincer"
              />
            </Grid>
          </Grid>
        </Container>
      </div>

      <div className="caseStudiesSectionThree_ryzone">
        <Container>
          <Grid container>
            <Grid
              xs={12}
              sm={6}
              style={{ position: "relative" }}
              data-aos="fade-down"
              data-aos-duration="1000"
            >
              <img
                src={FPM_DEVELOPMENT_IMAGE}
                className="caseStudiesleftImg"
                alt="development stage icon domaincer"
              />
            </Grid>
            <Grid xs={12} sm={6}>
              <div className="logoTextSectionTwo">
                <div className="casestudy_icon_row">
                  <h1 className="caseStudieSectionThreeTitle">Development</h1>
                </div>
              </div>
              <p className="caseStudiesSectionThreeDescript">
                {" "}
                With more than 500,000 hits per day, its complex to develop
                something that is scalable, efficient and super-fast.
                Development includes, website, social integration, chatbot,
                client portal, affiliate portal, IB portal, landing pages &
                more.
              </p>
            </Grid>
          </Grid>
        </Container>
      </div>

      <div className="caseStudiesSectionTwo_ryzone">
        <Container>
          <Grid container>
            <Grid
              xs={12}
              sm={6}
              style={{ position: "relative" }}
              data-aos="fade-down"
              data-aos-duration="1000"
            >
              <div className="logoTextSectionTwo">
                <div className="circleDirector__caseStudy"></div>
                <div className="dottedLineContainer">
                  <p className="dottedLine1">. . . . . . . . .</p>
                  <p className="dottedLine1">. . . . . . . . .</p>
                  <p className="dottedLine1">. . . . . . . . .</p>
                  <p className="dottedLine1">. . . . . . . . .</p>
                  <p className="dottedLine1">. . . . . . . . .</p>
                  <p className="dottedLine1">. . . . . . . . .</p>
                  <p className="dottedLine1">. . . . . . . . .</p>
                  <p className="dottedLine1">. . . . . . . . .</p>
                </div>
                <div className="casestudy_icon_row">
                  <h1 className="caseStudieSectionTwoTitle">
                    Digital Marketing
                  </h1>
                </div>
              </div>

              <p className="caseStudiesSectionTwoDescript">
                {" "}
                Every penny counts makes sense while we manage to tweak many
                marketing strategies that can impact ROI in a big way.
                Successfully running campaigns via Google Ads, Facebook Ads,
                Criteo, Taboola & more.
              </p>
            </Grid>
            <Grid xs={12} sm={6}>
              <img
                src={FPM_MARKETING_IMAGE}
                className="caseStudiesRightImg"
                alt="digital marketing stage icon domaincer"
              />
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
};

export default CaseStudyFpmarkets;
