import React, { useState, useEffect } from "react";
import "./portal.css";
import { Container, Grid, Paper, Tab, Tabs, Hidden } from "@material-ui/core";
import AOS from "aos";
import WhyChooseUsPortal from "./whychooseus_portal/whychooseus_portal.component";
// import OurProcessPortal from './ourprocess_portal/ourprocess_portal.component';
import CaseStudySection from "../../homepage/casestudyssection/casestudysection.component";
import HowServiceWillHelpYouPortal from "./howservicewillhelpyou_portal/howservicewillhelpyou_portal.component";
import BlogSectionPortal from "./blogsection_portal/blogsection_portal.component";
import ServiceContentSectionPortal from "./servicecontentsection_portal/servicecontentsection_portal.component";
import FollowUs from "../../../commons/followus/followus.component";
import { BASE_URL } from "../../../config/config";
import MetaverseApplications from "./metaverse_applications/metaverseApplications.component";

const Metaverse = (props) => {
  const PORTAL_SLIDER = `${BASE_URL}/metaverse_main.png`;

  useEffect(() => {
    AOS.init();
  });
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleTabScroll = (value) => {
    if (value === "OVER_VIEW") {
      window.scrollTo({
        top: 1100,
        behavior: "smooth",
      });
    } else if (value === "BLOG") {
      window.scrollTo({
        top: 3300,
        behavior: "smooth",
      });
    } else if (value === "WHY_DOMAINCER") {
      window.scrollTo({
        top: 3800,
        behavior: "smooth",
      });
    } else if (value === "RECENT_WORKS") {
      window.scrollTo({
        top: 3150,
        behavior: "smooth",
      });
    } else if (value === "BENEFITS") {
      window.scrollTo({
        top: 1800,
        behavior: "smooth",
      });
    } else if (value === "APPLICATIONS") {
      window.scrollTo({
        top: 1800,
        behavior: "smooth",
      });
    }
  };

  const handleTabScrollMobile = (value) => {
    console.log("mobile", value);
    if (value === "OVER_VIEW") {
      window.scrollTo({
        top: 1400,
        behavior: "smooth",
      });
    } else if (value === "BLOG") {
      window.scrollTo({
        top: 7300,
        behavior: "smooth",
      });
    } else if (value === "WHY_DOMAINCER") {
      window.scrollTo({
        top: 7800,
        behavior: "smooth",
      });
    } else if (value === "RECENT_WORKS") {
      window.scrollTo({
        top: 4400,
        behavior: "smooth",
      });
    } else if (value === "BENEFITS") {
      window.scrollTo({
        top: 2150,
        behavior: "smooth",
      });
    } else if (value === "APPLICATIONS") {
      window.scrollTo({
        top: 2300,
        behavior: "smooth",
      });
    }
  };

  return (
    <div class="mobileMarginTop metaverse-page">
      <FollowUs history={props.history} />
      <Container>
        <Grid container>
          <Grid
            xs={12}
            sm={6}
            className="BSSliderImgContainer_portal"
            // data-aos="fade-up"
            data-aos-duration="1000"
          >
            <img
              src={PORTAL_SLIDER}
              alt="best portal development image domaincer"
              className="brandStrategySliderImg_portal floatingUpDown"
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <div
              className="BSSliderRightSection_portal"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <h1 className="sliderHeading_portal">Metaverse</h1>
              <h2 className="sliderSubHeading_portal">
                <strong> Internet, Reimagined </strong>- Enter a world beyond
                the screen - the metaverse. With Domaincer, you can turn your
                virtual visions into a vibrant reality. Escape to a limitless
                world of possibility. The metaverse awaits, ready for you to
                socialize, create, and explore. Escape to a limitless world of
                possibility with Domaincer.
              </h2>
              <Hidden only={["xs"]}>
                <div className="serviceTabsSection_portal">
                  <Paper square className="MTabsWidth_portal">
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      indicatorColor="primary"
                      textColor="primary"
                      aria-label="tabs example"
                      className="MTabsContainer"
                    >
                      <Tab label="About" className="MTabBtn" />
                      <Tab
                        label="Overview"
                        className="MTabBtn"
                        onClick={() => handleTabScroll("OVER_VIEW")}
                      />
                      {/* <Tab
                        label="Benefits"
                        className="MTabBtn"
                        onClick={() => handleTabScroll("BENEFITS")}
                      /> */}

                      <Tab
                        label="Use Cases"
                        className="MTabBtn"
                        onClick={() => handleTabScroll("APPLICATIONS")}
                      />
                      <Tab
                        label="Blog"
                        className="MTabBtn"
                        onClick={() => handleTabScroll("BLOG")}
                      />
                      <Tab
                        label="Why Domaincer"
                        className="MTabBtn"
                        onClick={() => handleTabScroll("WHY_DOMAINCER")}
                      />
                      {/* <Tab
                        label="Recent Works"
                        className="MTabBtn"
                        onClick={() => handleTabScroll("RECENT_WORKS")}
                      /> */}
                    </Tabs>
                  </Paper>
                </div>
              </Hidden>
              <Hidden only={["sm", "md", "lg", "xl"]}>
                <div className="serviceTabsSection_portal">
                  <Paper square className="MTabsWidth_portal">
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      indicatorColor="primary"
                      textColor="primary"
                      aria-label="tabs example"
                      className="MTabsContainer"
                    >
                      <Tab label="About" className="MTabBtn" />
                      <Tab
                        label="Overview"
                        className="MTabBtn"
                        onClick={() => handleTabScrollMobile("OVER_VIEW")}
                      />
                      {/* <Tab
                        label="Benefits"
                        className="MTabBtn"
                        onClick={() => handleTabScrollMobile("BENEFITS")}
                      /> */}

                      <Tab
                        label="Use Cases "
                        className="MTabBtn"
                        onClick={() => handleTabScrollMobile("APPLICATIONS")}
                      />
                      <Tab
                        label="Blog"
                        className="MTabBtn"
                        onClick={() => handleTabScrollMobile("BLOG")}
                      />
                      <Tab
                        label="Why Domaincer"
                        className="MTabBtn"
                        onClick={() => handleTabScrollMobile("WHY_DOMAINCER")}
                      />
                      {/* <Tab
                        label="Recent Works"
                        className="MTabBtn"
                        onClick={() => handleTabScrollMobile("RECENT_WORKS")}
                      /> */}
                    </Tabs>
                  </Paper>
                </div>
              </Hidden>
            </div>
          </Grid>
        </Grid>
      </Container>
      <HowServiceWillHelpYouPortal />
      <ServiceContentSectionPortal />
      {/* <MetaverseBenefit /> */}
      <MetaverseApplications />
      <div style={{ marginTop: "100px" }}>
        <BlogSectionPortal />
      </div>
      <WhyChooseUsPortal />
      {/* <OurProcessPortal /> */}
      {/* <CaseStudySection /> */}
    </div>
  );
};

export default Metaverse;
