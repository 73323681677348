import React, { useState, useEffect } from "react";
import { Container, Grid, Button, Link } from "@material-ui/core";
import "../website.css";
import Hidden from "@material-ui/core/Hidden";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { BASE_URL } from "../../../../config/config";

const STRATEGY = [
  {
    key: "CONTENT_SECTION_ONE",
    name: "Static",
    subtext: "Igniting success, online",
    image: `${BASE_URL}/tab_website_icon1.png`,
    alt: "static website image domaincer",
  },
  {
    key: "CONTENT_SECTION_TWO",
    name: "CMS",
    subtext: "Unlock limitless growth",
    image: `${BASE_URL}/tab_website_icon2.png`,
    alt: "cms website image domaincer",
  },
  {
    key: "CONTENT_SECTION_THREE",
    name: "Custom Web App",
    subtext: "Bring your vision to life",
    image: `${BASE_URL}/tab_website_icon3.png`,
    alt: "custom web app image domaincer",
  },
];

const ServiceContentSectionWebsite = () => {
  const [activeTabService, setActiveTabService] = useState(
    "CONTENT_SECTION_ONE"
  );

  return (
    <div>
      <div style={{ paddingTop: 100, paddingBottom: 30 }}>
        <h1 className="service_subheading_website">
          About Website Development
        </h1>
        <h2 className="service_subtext_website">
          Connect with your audience like never before with a website crafted by
          our talented team. We believe a website is a bridge that connects the
          users and businesses, and we're here to help you build that bridge.
          We'll create a stunning, effective website that leaves a lasting
          impression. Make the connection today!
        </h2>
      </div>
      {/* visible only on desktop, laptop */}
      <Hidden only={["xs"]}>
        <div className="subServiceTabSection_website">
          {STRATEGY.map((service, index) => (
            <div
              className="subServiceContaincer_website"
              style={{
                borderColor:
                  service.key === activeTabService ? "#0077FF" : "#FFF",
              }}
              onClick={() => setActiveTabService(service.key)}
            >
              <img
                className="subServiceImg_website"
                src={service.image}
                alt={service.alt}
              />
              <div>
                <h1 className="subServiceTitle_website">{service.name}</h1>
                <h2 className="subServiceSubTitle_website">
                  {service.subtext}
                </h2>
              </div>
            </div>
          ))}
        </div>
        <div>
          {activeTabService === "CONTENT_SECTION_ONE" && (
            <div className="subServiceParaContainer_website">
              <Container
                maxWidth="lg"
                className="subServiceFixedSection_website"
              >
                <div className="glowEffectBrand_website"></div>

                <div className="serviceContent_website">
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <h3 className="subServicePara_website">
                        Make a lasting impression with a professional, clean
                        homepage designed by our team. Your homepage is the face
                        of your business online, and we'll make sure it's one
                        that keeps visitors coming back. Our web development
                        services are tailored to your specific business needs,
                        helping you achieve maximum online success and boost
                        revenues.
                      </h3>
                    </Grid>
                  </Grid>
                </div>
              </Container>
            </div>
          )}
          {activeTabService === "CONTENT_SECTION_TWO" && (
            <div className="subServiceParaContainer_website">
              <Container
                maxWidth="lg"
                className="subServiceFixedSection_website"
              >
                <div className="glowEffectDigital_website"></div>

                <div className="serviceContent_website">
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <h3 className="subServicePara_website">
                        Shop for success with our e-commerce storefront
                        development services. Our experts have a decade's worth
                        of experience in platforms like WooCommerce, OpenCart,
                        Shopify, Magento, PrestaShop, BigCommerce, Drupal, and
                        custom solutions. We've helped numerous businesses find
                        their footing in the digital marketplace and earned a
                        reputation as a go-to destination for e-commerce web
                        development.
                      </h3>
                    </Grid>
                  </Grid>
                </div>
              </Container>
            </div>
          )}
          {activeTabService === "CONTENT_SECTION_THREE" && (
            <div className="subServiceParaContainer_website">
              <Container
                maxWidth="lg"
                className="subServiceFixedSection_website"
              >
                <div className="glowEffectWorkFlow_website"></div>

                <div className="serviceContent_website">
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <h3 className="subServicePara_website">
                        Streamline your social media efforts and take your
                        business to the next level with our custom applications.
                        We help you connect smarter, grow faster, and serve
                        better through automation, efficient process management,
                        and integrated systems. Our solutions create unique
                        advantages for your business, helping you stand out in
                        the crowded world of social media.
                      </h3>
                    </Grid>
                  </Grid>
                </div>
              </Container>
            </div>
          )}
        </div>
      </Hidden>

      {/* visible only on mobile */}
      <Hidden only={["sm", "lg", "md", "xl"]}>
        <div style={{ position: "relative" }}>
          <ArrowBackIosIcon
            className="subServiceTabLeftArrowHome"
            onClick={() => {
              document.getElementById("strategyScroll").scrollLeft -= 400;
            }}
          />
          <div className="subServiceTabSection_website" id="strategyScroll">
            <div>
              <div className="subServiceContaincer_website">
                <img
                  className="subServiceImg_website"
                  src={`${BASE_URL}/tab_website_icon1.png`}
                  alt="static website image domaincer"
                />
                <div>
                  <h1 className="subServiceTitle_website">Static</h1>
                  <h2 className="subServiceSubTitle_website">
                    Igniting success, online
                  </h2>
                </div>
              </div>
              <div className="subServiceParaContainer_website">
                <Container
                  maxWidth="lg"
                  className="subServiceFixedSection_website"
                >
                  <div className="glowEffectBrand_website"></div>

                  <div className="serviceContent_websiteOne">
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <h3 className="subServicePara_website">
                          Make a lasting impression with a professional, clean
                          homepage designed by our team. Your homepage is the
                          face of your business online, and we'll make sure it's
                          one that keeps visitors coming back. Our web
                          development services are tailored to your specific
                          business needs, helping you achieve maximum online
                          success and boost revenues.
                        </h3>
                      </Grid>
                    </Grid>
                  </div>
                </Container>
              </div>
            </div>
            <div>
              <div className="subServiceContaincer_website">
                <img
                  className="subServiceImg_website"
                  src={`${BASE_URL}/tab_website_icon2.png`}
                  alt="cms website image domaincer"
                />
                <div>
                  <h1 className="subServiceTitle_website">CMS</h1>
                  <h2 className="subServiceSubTitle_website">
                    Business beyond the limits
                  </h2>
                </div>
              </div>
              <div className="subServiceParaContainer_website">
                <Container
                  maxWidth="lg"
                  className="subServiceFixedSection_website"
                >
                  <div className="glowEffectDigital_website"></div>

                  <div className="serviceContent_website">
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <h3 className="subServicePara_website">
                          Shop for success with our e-commerce storefront
                          development services. Our experts have a decade's
                          worth of experience in platforms like WooCommerce,
                          OpenCart, Shopify, Magento, PrestaShop, BigCommerce,
                          Drupal, and custom solutions. We've helped numerous
                          businesses find their footing in the digital
                          marketplace and earned a reputation as a go-to
                          destination for e-commerce web development.
                        </h3>
                      </Grid>
                    </Grid>
                  </div>
                </Container>
              </div>
            </div>
            <div>
              <div className="subServiceContaincer_website">
                <img
                  className="subServiceImg_website"
                  src={`${BASE_URL}/tab_website_icon3.png`}
                  alt="custom web app image domaincer"
                />
                <div>
                  <h1 className="subServiceTitle_website">Custom Web App</h1>
                  <h2 className="subServiceSubTitle_website">
                    Be Imagine, Be Artistic and let’s Engage
                  </h2>
                </div>
              </div>
              <div className="subServiceParaContainer_website">
                <Container
                  maxWidth="lg"
                  className="subServiceFixedSection_website"
                >
                  <div className="glowEffectWorkFlow_website"></div>

                  <div className="serviceContent_websiteThree">
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <h3 className="subServicePara_website">
                          Streamline your social media efforts and take your
                          business to the next level with our custom
                          applications. We help you connect smarter, grow
                          faster, and serve better through automation, efficient
                          process management, and integrated systems. Our
                          solutions create unique advantages for your business,
                          helping you stand out in the crowded world of social
                          media.
                        </h3>
                      </Grid>
                    </Grid>
                  </div>
                </Container>
              </div>
            </div>
          </div>
          <ArrowForwardIosIcon
            className="subServiceTabRightArrowHome"
            onClick={() => {
              document.getElementById("strategyScroll").scrollLeft += 400;
            }}
          />
        </div>
      </Hidden>
    </div>
  );
};

export default ServiceContentSectionWebsite;
