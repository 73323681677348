import React from "react";
import "./statements.style.css";


const PrivacyStatementComponent = () => {
  return (
    <div className="user_agreement_container">
      <div className="user_agreement_inner_container">
        <p className="user_agreement_header">Privacy Policy</p>
        <div className="user_agreement_content_con">
          <div className="user_agreement_content_container">
            <h4 className="user_agreement_content_header">
              1. Privacy statement
            </h4>
            <p className="user_agreement_content_para">
              This privacy statement applies to the website of Domaincer and
              governs the privacy of its users who choose to use it. The policy
              sets out the different areas where user privacy is concerned and
              outlines the obligations & requirements of the users, the website
              and website owners. Furthermore, the way this website processes,
              stores and protects user data and information will also be
              detailed within this policy.
            </p>
          </div>

          <div className="user_agreement_content_container">
            <h4 className="user_agreement_content_header">2. The website</h4>
            <p className="user_agreement_content_para">
              This website and its owners take a proactive approach to user
              privacy and ensure the necessary steps are taken to protect the
              privacy of its users throughout their visiting experience. This
              website complies with all national and international data
              protection and privacy laws and requirements.
            </p>
          </div>

          <div className="user_agreement_content_container">
            <h4 className="user_agreement_content_header">
              3. Use of Cookies
            </h4>
            <p className="user_agreement_content_inner_para">
              3.1 This website uses cookies to better the users experience
              while visiting the website. Where applicable, this website uses a
              cookie control system allowing the user on their first visit to
              the website to allow or disallow the use of cookies on their
              computer / device. This complies with recent legislation
              requirements for websites to obtain explicit consent from users
              before leaving behind or reading files such as cookies on a user's
              computer / device.
            </p>

            <p className="user_agreement_content_inner_para">
              3.2 Cookies are small files saved to the user's computers hard
              drive that track, save and store information about the user's
              interactions and usage of the website. This allows the website,
              through its server, to provide the users with a tailored
              experience within this website.
            </p>

            <p className="user_agreement_content_inner_para">
              3.3 Users are advised that if they wish to deny the use and
              saving of cookies from this website on to their computers hard
              drive they should take necessary steps within their web browsers
              security settings to block all cookies from this website and its
              external serving vendors.
            </p>
          </div>

          <div className="user_agreement_content_container">
            <h4 className="user_agreement_content_header">
              4. Contact & Communication
            </h4>
            <p className="user_agreement_content_inner_para">
              4.1 Users contacting this website and/or its owners do so at
              their own discretion and provide any such personal details
              requested at their own risk. Your personal information is kept
              private and stored securely until a time it is no longer required
              or has no use.
            </p>
            <p className="user_agreement_content_inner_para">
              4.2 Every effort has been made to ensure a safe and secure form
              to email submission process but advise users using such form to
              email processes that they do so at their own risk.
            </p>
            <p className="user_agreement_content_inner_para">
              4.3 This website and its owners use any information submitted to
              provide you with further information about the products / services
              they offer or to assist you in answering any questions or queries
              you may have submitted. This includes using your details to
              subscribe you to any email newsletter program the website operates
              but only if this was made clear to you and your express permission
              was granted when submitting any form to email process. Or whereby
              you the consumer have previously purchased from or enquired about
              purchasing from the company a product or service that the email
              newsletter relates to. This is by no means an entire list of your
              user rights in regard to receiving email marketing material. Your
              details are not passed on to any third parties.
            </p>
          </div>

          <div className="user_agreement_content_container">
            <h4 className="user_agreement_content_header">
              5. External Links
            </h4>
            <p className="user_agreement_content_inner_para">
              5.1 Although this website only looks to include quality, safe and
              relevant external links users should always adopt a policy of
              caution before clicking any external web links mentioned
              throughout this website.
            </p>
            <p className="user_agreement_content_inner_para">
              5.2 The owners of this website cannot guarantee or verify the
              contents of any externally linked website despite their best
              efforts. Users should therefore note they click on external links
              at their own risk and this website and its owners cannot be held
              liable for any damages or implications caused by visiting any
              external links mentioned.
            </p>
          </div>

          <div className="user_agreement_content_container">
            <h4 className="user_agreement_content_header">
              6. Adverts and Sponsored Links
            </h4>
            <p className="user_agreement_content_inner_para">
              6.1 This website may contain sponsored links and adverts. These
              will typically be served through our advertising partners, to whom
              may have detailed privacy policies relating directly to the
              adverts they serve.
            </p>
            <p className="user_agreement_content_inner_para">
              6.2 Clicking on any such adverts will send you to the
              advertiser's website through a referral program which may use
              cookies and will track the number of referrals sent from this
              website. This may include the use of cookies which may in turn be
              saved on your computer's hard drive. Users should therefore note
              they click on sponsored external
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyStatementComponent;
