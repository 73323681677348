import React, { useEffect } from "react";
import { Container, Button, Grid } from "@material-ui/core";
import "./contactus.css";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { Link } from "react-router-dom";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";
import FollowUs from "../../commons/followus/followus.component";
import { BASE_URL } from "../../config/config";
import { createPopup } from "@typeform/embed";
import "@typeform/embed/build/css/popup.css";

const ContactUs = (props) => {
  const MapWithAMarker = withScriptjs(
    withGoogleMap((props) => (
      <GoogleMap
        defaultZoom={17}
        defaultCenter={{ lat: 13.0805, lng: 80.1971 }}
      >
        <Marker position={{ lat: 13.0805, lng: 80.1971 }} />
      </GoogleMap>
    ))
  );
  const openPopup = (event) => {
    createPopup("r3BLnIaA").open();
  };

  return (
    <div class="mobileMarginTop">
      <FollowUs history={props.history} />
      <Container>
        <div className="bannerBg">
          <div className="contactus_banner_section">
            <h1 className="contactusHeading">Contact Us</h1>
            <p className="contactusSubHeading">
              If you have any questions about our services, we are here to help
              you. Reach out to us to get proposal for your project
            </p>
            {/* <Link to='/get-quote'> */}
            <Button
              className="help_center_btn"
              onClick={() => {
                openPopup();
              }}
            >
              Get Quote
            </Button>
            {/* </Link> */}
          </div>
        </div>
        <Grid container spacing={3} style={{ marginBottom: 50 }}>
          <Grid item xs={12} sm={3}>
            <div className="contactUsCards">
              <div>
                <img
                  src={`${BASE_URL}/contact_email_icon.png`}
                  alt="email icon 3d domaincer"
                  className="contactCardIcon"
                />
              </div>
              <p className="contactCardTitle">Email</p>
              <p className="contactCardSubTitle">
                We reply as soon as possible
              </p>
              <a
                href="https://mail.google.com/mail/?view=cm&fs=1&to=support@domaincer.com"
                target="_blank"
                rel="noreferrer"
              >
                <p className="contactCardLink">
                  Send us a email{" "}
                  <ChevronRightIcon className="cardArrow arrowani" />{" "}
                </p>
              </a>
            </div>
          </Grid>
          <Grid item xs={12} sm={3}>
            <div className="contactUsCards">
              <div>
                <img
                  src={`${BASE_URL}/contact_chat_icon.png`}
                  alt="message icon 3d domaincer"
                  className="contactCardIcon"
                />
              </div>
              <p className="contactCardTitle">Chat</p>
              <p className="contactCardSubTitle">
                Available from : Mon - Sat 9:30 - 21:00 Hrs
              </p>
              <a
                href="https://api.whatsapp.com/send/?phone=%2B918190007007&text&type=phone_number&app_absent=0"
                target="_blank"
                rel="noreferrer"
              >
                <p className="contactCardLink">
                  Chat with us{" "}
                  <ChevronRightIcon className="cardArrow arrowani" />{" "}
                </p>
              </a>
            </div>
          </Grid>
          <Grid item xs={12} sm={3}>
            <div className="contactUsCards">
              <div>
                <img
                  src={`${BASE_URL}/contact_phone_icon.png`}
                  alt="phone icon 3d domaincer"
                  className="contactCardIcon"
                />
              </div>
              <p className="contactCardTitle">Phone</p>
              <p className="contactCardSubTitle">
                Available from : Mon - Sat 9:30 - 21:00 Hrs
              </p>
              <p className="contactCardLink">
                <a href="tel:+91-8190007007">
                  Call +91 8190 007 007{" "}
                  <ChevronRightIcon className="cardArrow arrowani" />{" "}
                </a>
              </p>
            </div>
          </Grid>
          <Grid item xs={12} sm={3}>
            <div className="contactUsCards">
              <div>
                <img
                  src={`${BASE_URL}/contact_marker_icon.png`}
                  alt="location icon 3d domaincer"
                  className="contactCardIcon"
                />
              </div>
              <p className="contactCardTitle">Office</p>
              <p className="contactCardAddress">
                The Hive, 3rd Floor, Madras House Building VR Mall, Anna Nagar,
                <br />
                Chennai - 600 040.
              </p>
              <a
                href="https://g.page/domaincer?share"
                target="_blank"
                rel="noreferrer"
              >
                <p className="contactCardLink">
                  Get directions{" "}
                  <ChevronRightIcon className="cardArrow arrowani" />{" "}
                </p>
              </a>
            </div>
          </Grid>
        </Grid>
      </Container>
      <MapWithAMarker
        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDqoRDBvTO6HJ0TriJVd5NxvMN8tYwi1vg&v=3.exp&libraries=geometry,drawing,places"
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `450px` }} />}
        mapElement={<div style={{ height: `100%` }} />}
      />
    </div>
  );
};

export default ContactUs;
