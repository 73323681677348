import React, { useState, useEffect } from "react";
import "./portal.css";
import { Container, Grid, Paper, Tab, Tabs, Hidden } from "@material-ui/core";
import AOS from "aos";
import WhyChooseUsPortal from "./whychooseus_portal/whychooseus_portal.component";
// import OurProcessPortal from './ourprocess_portal/ourprocess_portal.component';
import CaseStudySection from "../../homepage/casestudyssection/casestudysection.component";
import HowServiceWillHelpYouPortal from "./howservicewillhelpyou_portal/howservicewillhelpyou_portal.component";
import BlogSectionPortal from "./blogsection_portal/blogsection_portal.component";
import ServiceContentSectionPortal from "./servicecontentsection_portal/servicecontentsection_portal.component";
import FollowUs from "../../../commons/followus/followus.component";
import { BASE_URL } from "../../../config/config";

const Portal = (props) => {
  const PORTAL_SLIDER = `${BASE_URL}/service_portals_header_image.png`;

  useEffect(() => {
    AOS.init();
  });
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleTabScroll = (value) => {
    if (value === "OVER_VIEW") {
      window.scrollTo({
        top: 1100,
        behavior: "smooth",
      });
    } else if (value === "BLOG") {
      window.scrollTo({
        top: 1750,
        behavior: "smooth",
      });
    } else if (value === "WHY_DOMAINCER") {
      window.scrollTo({
        top: 2500,
        behavior: "smooth",
      });
    } else if (value === "RECENT_WORKS") {
      window.scrollTo({
        top: 3150,
        behavior: "smooth",
      });
    }
  };

  const handleTabScrollMobile = (value) => {
    if (value === "OVER_VIEW") {
      window.scrollTo({
        top: 1500,
        behavior: "smooth",
      });
    } else if (value === "BLOG") {
      window.scrollTo({
        top: 2300,
        behavior: "smooth",
      });
    } else if (value === "WHY_DOMAINCER") {
      window.scrollTo({
        top: 3000,
        behavior: "smooth",
      });
    } else if (value === "RECENT_WORKS") {
      window.scrollTo({
        top: 4400,
        behavior: "smooth",
      });
    }
  };

  return (
    <div class="mobileMarginTop">
      <FollowUs history={props.history} />
      <Container>
        <Grid container>
          <Grid
            xs={12}
            sm={6}
            className="BSSliderImgContainer_portal"
            // data-aos="fade-up"
            data-aos-duration="1000"
          >
            <img
              src={PORTAL_SLIDER}
              alt="best portal development image domaincer"
              className="brandStrategySliderImg_portal floatingUpDown"
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <div
              className="BSSliderRightSection_portal"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <h1 className="sliderHeading_portal">Web Portal Development</h1>
              <h2 className="sliderSubHeading_portal">
                Boost your business to new heights with our full range of web
                and enterprise portal development services. Our team specializes
                in streamlining processes, improving communication and
                collaboration, and delivering information through web and
                application services. Let us help you bring all your content,
                data sources, and applications together in one place and make it
                all easily accessible to your users.
              </h2>
              <Hidden only={["xs"]}>
                <div className="serviceTabsSection_portal">
                  <Paper square className="MTabsWidth_portal">
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      indicatorColor="primary"
                      textColor="primary"
                      aria-label="tabs example"
                      className="MTabsContainer"
                    >
                      <Tab label="About" className="MTabBtn" />
                      <Tab
                        label="Overview"
                        className="MTabBtn"
                        onClick={() => handleTabScroll("OVER_VIEW")}
                      />
                      <Tab
                        label="Blog"
                        className="MTabBtn"
                        onClick={() => handleTabScroll("BLOG")}
                      />
                      <Tab
                        label="Why Domaincer"
                        className="MTabBtn"
                        onClick={() => handleTabScroll("WHY_DOMAINCER")}
                      />
                      {/* <Tab
                        label="Recent Works"
                        className="MTabBtn"
                        onClick={() => handleTabScroll("RECENT_WORKS")}
                      /> */}
                    </Tabs>
                  </Paper>
                </div>
              </Hidden>
              <Hidden only={["sm", "md", "lg", "xl"]}>
                <div className="serviceTabsSection_portal">
                  <Paper square className="MTabsWidth_portal">
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      indicatorColor="primary"
                      textColor="primary"
                      aria-label="tabs example"
                      className="MTabsContainer"
                    >
                      <Tab label="About" className="MTabBtn" />
                      <Tab
                        label="Overview"
                        className="MTabBtn"
                        onClick={() => handleTabScrollMobile("OVER_VIEW")}
                      />
                      <Tab
                        label="Blog"
                        className="MTabBtn"
                        onClick={() => handleTabScrollMobile("BLOG")}
                      />
                      <Tab
                        label="Why Domaincer"
                        className="MTabBtn"
                        onClick={() => handleTabScrollMobile("WHY_DOMAINCER")}
                      />
                      {/* <Tab
                        label="Recent Works"
                        className="MTabBtn"
                        onClick={() => handleTabScrollMobile("RECENT_WORKS")}
                      /> */}
                    </Tabs>
                  </Paper>
                </div>
              </Hidden>
            </div>
          </Grid>
        </Grid>
      </Container>
      <HowServiceWillHelpYouPortal />
      <ServiceContentSectionPortal />
      <BlogSectionPortal />
      <WhyChooseUsPortal />
      {/* <OurProcessPortal /> */}
      {/* <CaseStudySection /> */}
    </div>
  );
};

export default Portal;
