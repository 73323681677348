import React, { useState } from "react";
import { Grid, IconButton, TextField } from "@material-ui/core";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Hidden from '@material-ui/core/Hidden';
import { BASE_URL } from '../../../../config/config';



const UIUXQAPage = (props) => {
  const UIUX_SLIDER = `${BASE_URL}/service_uiux_header_image.png`;

  Array.prototype.removeValue = function (val) {
    for (var i = 0; i < this.length; i++) {
      if (this[i] === val) {
        this.splice(i, 1);
        i--;
      }
    }
    return this;
  };

  const [pageName] = useState("uiuxScreen");
  const [errorStatus, setErrorStatus] = useState(false);
  const [Q1A, setQ1A] = useState("");
  const [Q2A, setQ2A] = useState("");
  const [Q3A, setQ3A] = useState([]);

  const handleClick = (e) => {
    e.preventDefault();
    const { userDetails } = props;
    const qadata = {
      id: "uiuxQA",
      Q1: {
        question: "Product?",
        answer: Q1A,
      },
      Q2: {
        question: "Screens?",
        answer: Q2A,
      },
      Q3: {
        question: "Style?",
        answer: Q3A,
      },
    };

    if (Q1A === "") {
      setErrorStatus(true);
    } else if (Q2A === "") {
      setErrorStatus(true);
    } else if (Q3A.length === 0) {
      setErrorStatus(true);
    } else {
      const data = props.serviceData.removeValue(pageName);
      props.prevServiceData.push(pageName);
      props.nextStep(
        data,
        props.prevServiceData,
        qadata,
        userDetails,
        pageName
      );
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  const handleBack = (e) => {
    e.preventDefault();
    const data = props.prevServiceData.removeValue(pageName);
    props.prevStep(data);
  };

  return (
    <div className="quotePage__body">
      <React.Fragment>
        <Grid container direction="row" justify="center" spacing={2}>
          <Grid item xs={12} sm={10} className="custom__stepOneGrid">
            <div className="prevStep__div">
              <IconButton>
                <ArrowBackIcon className="prevStep__btn" onClick={handleBack} />
              </IconButton>
            </div>
            <div>
              <h2 className="quote__question">UI / UX</h2>
            </div>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
             <Hidden only={['sm', 'lg', 'md', 'xl']}>
              <Grid item xs={12} sm={4} className="animated__gif" style={{ textAlign: 'center' }}>
                <img
                  src={UIUX_SLIDER}
                  alt="best ui/ux icon 3d domaincer"
                  className="getQuote_brandingImage floatingUpDown"
                />
              </Grid>
              </Hidden>
              <Grid item sm={8} xs={12}>
                <div>
                  <div>
                    <h2 className="service__question">1. Product</h2>
                    <TextField
                      id="outlined-multiline-static"
                      label="Enter Product"
                      multiline
                      rows={2}
                      variant="outlined"
                      className="custom__textField"
                      onChange={(e) => {
                        setErrorStatus(false);
                        setQ1A(e.target.value);
                      }}
                    />
                  </div>
                  <div>
                    <h2 className="service__question">2. Screens</h2>
                    <TextField
                      id="outlined-multiline-static"
                      label="Enter Screens"
                      multiline
                      rows={1}
                      variant="outlined"
                      className="custom__textField"
                      onChange={(e) => {
                        setErrorStatus(false);
                        setQ2A(e.target.value);
                      }}
                    />
                  </div>
                  <div>
                    <h2 className="service__question">3. Style</h2>
                  </div>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="checkedMinimalistic"
                          className="custom__checkBox"
                          onChange={(evt, data) => {
                            if (data === true) {
                              setQ3A([...Q3A, "Minimalistic"]);
                              setErrorStatus(false);
                            } else {
                              const newQ3A = Q3A.removeValue("Minimalistic");
                              setQ3A(newQ3A);
                            }
                          }}
                        />
                      }
                      label="Minimalistic"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="checkedSophisticated"
                          className="custom__checkBox"
                          onChange={(evt, data) => {
                            if (data === true) {
                              setQ3A([...Q3A, "Sophisticated"]);
                              setErrorStatus(false);
                            } else {
                              const newQ3A = Q3A.removeValue("Sophisticated");
                              setQ3A(newQ3A);
                            }
                          }}
                        />
                      }
                      label="Sophisticated"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="checkedSimple"
                          className="custom__checkBox"
                          onChange={(evt, data) => {
                            if (data === true) {
                              setQ3A([...Q3A, "Simple"]);
                              setErrorStatus(false);
                            } else {
                              const newQ3A = Q3A.removeValue("Simple");
                              setQ3A(newQ3A);
                            }
                          }}
                        />
                      }
                      label="Simple"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="checkedTrendy"
                          className="custom__checkBox"
                          onChange={(evt, data) => {
                            if (data === true) {
                              setQ3A([...Q3A, "Trendy"]);
                              setErrorStatus(false);
                            } else {
                              const newQ3A = Q3A.removeValue("Trendy");
                              setQ3A(newQ3A);
                            }
                          }}
                        />
                      }
                      label="Trendy"
                    />
                  </FormGroup>
                </div>
                {errorStatus && (
                  <p style={{ color: "red", fontSize: 13, marginTop: 7 }}>
                    Please fill all the fields
                  </p>
                )}
              </Grid>
              <Hidden only={['xs']}>
              <Grid item xs={12} sm={4} className="animated__gif" style={{ textAlign: 'center' }}>
                <img
                  src={UIUX_SLIDER}
                  alt="best ui/ux icon 3d domaincer"

                  className="getQuote_brandingImage floatingUpDown"
                />
              </Grid>
              </Hidden>
            </Grid>
            <div className="nextStep__btnContainer">
              <h5 className="nextStep__btn" onClick={handleClick}>
                Continue
              </h5>
              <IconButton>
                <DoubleArrowIcon className="nextStep__btnIcon arrowani" />
              </IconButton>
            </div>
          </Grid>
        </Grid>
      </React.Fragment>
    </div>
  );
};

export default UIUXQAPage;
