import React, { useEffect } from "react";
import FAQSection from "./FAQsection/FAQsection.component";
import BannerSection from "./bannersection/bannersection.component";
import TechStackSection from "./techstacksection/techstacksection.component";
import FollowUs from "../../commons/followus/followus.component";
import CaseStudySection from "./casestudyssection/casestudysection.component";
import BlogSectionHome from "./blogsectionhome/blogsectionhome.component";
import InstagramFeed from "./instagramFeed/instagramFeed.component";
import ServicesV2Component from "./servicersv2/servicesv2.component";
import AboutDomaincer from "./aboutDomaincer/aboutDomaincer.component";
import { createPopup } from "@typeform/embed";
import "@typeform/embed/build/css/popup.css";
import "./home.style.css";

const HomePage = (props) => {
  const openPopup = (event) => {
    createPopup("r3BLnIaA").open();
  };

  return (
    <React.Fragment>

      <div class="mobileMarginTop">
        <FollowUs history={props.history} />
        <BannerSection />
        {/* <AboutDomaincer /> */}
        <ServicesV2Component />
        {/* <CaseStudySection /> */}
        <InstagramFeed />
        <BlogSectionHome />
        <FAQSection />
        <TechStackSection />
      </div>
    </React.Fragment>
  );
};

export default HomePage;
