import React, { useState, useEffect } from "react";
import "../website.css";
import { Container, Grid, Hidden } from "@material-ui/core";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";

const BlogSectionWebsite = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    fetch(
      "https://domaincer.com/blog/getlatestpost.php?cat=website-development"
    )
      .then((res) => res.json())
      .then((json) => {
        console.log(json);
        const data = json.reverse();
        const newData = data.slice(0, 6);
        setPosts(newData);
      }).catch((err)=> console.log(err))
  }, []);

  const capitalize = (str) => {
    const newStr = str.replace(/-/g, " ");
    return newStr.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };
  return (
    <div className="serviceSection_blogSection">
      <Container>
        <div className="serviceSection_blogHeaderContainer">
          <div className="serviceSection_blogTitleRow">
            <h1 className="serviceSection_blogHeading">Blog</h1>
            <Hidden only={["xs"]}>
              <div
                style={{
                  alignSelf: "center",
                  textAlign: "center",
                  marginTop: 10,
                  marginLeft: 20,
                }}
              >
                <ChevronLeftIcon
                  onClick={() => {
                    document.getElementById("currenyScroll").scrollLeft -= 400;
                  }}
                  className="serviceSection_leftCaroselIcon"
                />
              </div>
              <div
                style={{
                  alignSelf: "center",
                  textAlign: "center",
                  marginTop: 10,
                }}
              >
                <ChevronRightIcon
                  onClick={() => {
                    document.getElementById("currenyScroll").scrollLeft += 400;
                  }}
                  className="serviceSection_rightCaroselIcon"
                />
              </div>
            </Hidden>
          </div>
          <h1 className="serviceSection_blogViewAll">
            <a href="https://domaincer.com/blog/" target="_blank">
              View all
            </a>
          </h1>
        </div>
      </Container>
      <div className="serviceSection_blogSectionContainer">
        <div className="serviceSection_blogMiddleSection" id="currenyScroll">
          {posts.map((post, index) => (
            <Grid
              item
              xs={12}
              sm={4}
              data-aos="fade-right"
              data-aos-duration="1000"
              key={index}
            >
              <a href={post.link} target="_blank">
                <div className="serviceSection_blogSectionCardContainer">
                  <div>
                    <img
                      alt=""
                      src={post.image}
                      className="serviceSection_blogSectionImg"
                    />
                  </div>
                  <h1 className="blogSectionHeading">
                    {capitalize(post.slug)}
                  </h1>
                  <h2 className="blogSectionSubText">{post.excerpt} ...</h2>
                </div>
              </a>
            </Grid>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BlogSectionWebsite;
