import React, { useState, useEffect, useRef, Fragment } from "react";
import "./mobileapp.css";
import {
  Container,
  Button,
  Modal,
  Grid,
  Hidden,
  Paper,
  Tab,
  Tabs,
} from "@material-ui/core";
import AOS from "aos";
import LanguagesSection from "./languages_section/languages_sections";
import CaseStudySection from "../../homepage/casestudyssection/casestudysection.component";
import BlogSectionMobileApp from "./blogsection_mobileapp/blogsection_mobileapp.component";
import { BASE_URL } from "../../../config/config";
import ServiceContentV2MobileApp from "./servicecontentv2_mobileapp/servicecontentv2_mobileapp.component";
import ProcessSection from "./processsection/process_section";
import StatsSection from "./statssection/stats_section";
import DeploymentPartners from "./deploymentpartners/deploymentpartners_sections";
import SpecializedIn from "./specializedin/specializedin";
import Skeleton from "react-loading-skeleton";
import LazyLoad from "react-lazyload";
import styled, { keyframes } from "styled-components";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import FollowUs from "../../../commons/followus/followus.component";
import BlockChainBenefits from "./blockChain_benefits/blockChainBenefits.components";
import WhyDomaincerBlockchain from "./whyChooseDomaincerBlockChain/domaincerBlockChain.component";
import BlockchainApplications from "./applicationsofBlockchain/applications.component";
import BlockchainTech from "./blockchainTech/blockchainTech.component";
import WhyChooseUsBlockChain from "./whyChooseus_blockChain/whychooseus_blockChain";

const ImageWrapper = styled.div`
  position: relative;
`;

const Placeholder = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
`;

const Blockchain = (props) => {
  const MOBILE_APPS_SLIDER = `${BASE_URL}/blockchain_main.png`;
  const APPLICATION_VIDEO = `${BASE_URL}/blockchain_main.png`;
  const [value, setValue] = useState(0);
  const [skeletonLoader, setskeletonLoader] = useState(true);
  const refPlaceholder = React.useRef();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const removePlaceholder = () => {
    refPlaceholder.current.remove();
    setskeletonLoader(false);
  };

  useEffect(() => {
    AOS.init();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleTabScroll = (value) => {
    if (value === "TECH") {
      window.scrollTo({
        top: 650,
        behavior: "smooth",
      });
    } else if (value === "BENEFITS") {
      window.scrollTo({
        top: 2000,
        behavior: "smooth",
      });
    } else if (value === "WHYBLOCKCHAIN") {
      window.scrollTo({
        top: 1300,
        behavior: "smooth",
      });
    } else if (value === "WHYDOMAINCER") {
      window.scrollTo({
        top: 4600,
        behavior: "smooth",
      });
    } else if (value === "APPLICATIONS") {
      window.scrollTo({
        top: 2600,
        behavior: "smooth",
      });
    } else if (value === "BLOG") {
      window.scrollTo({
        top: 4100,
        behavior: "smooth",
      });
    }
  };

  const handleTabScrollMobile = (value) => {
    console.log(value, "block");
    if (value === "TECH") {
      window.scrollTo({
        top: 800,
        behavior: "smooth",
      });
    } else if (value === "BENEFITS") {
      window.scrollTo({
        top: 2250,
        behavior: "smooth",
      });
    } else if (value === "WHYBLOCKCHAIN") {
      window.scrollTo({
        top: 1600,
        behavior: "smooth",
      });
    } else if (value === "PROCESS") {
      window.scrollTo({
        top: 9000,
        behavior: "smooth",
      });
    } else if (value === "APPLICATIONS") {
      window.scrollTo({
        top: 4000,
        behavior: "smooth",
      });
    } else if (value === "BLOG") {
      window.scrollTo({
        top: 9000,
        behavior: "smooth",
      });
    } else if (value === "WHYDOMAINCER") {
      window.scrollTo({
        top: 9400,
        behavior: "smooth",
      });
    }
  };

  return (
    <div class="mobileMarginTop blockchain-page">
      <div>
        <FollowUs history={props.history} />
        <Container>
          <Grid container>
            <Grid
              xs={12}
              sm={6}
              className="BSSliderImgContainer_mobileapp"
              // data-aos="fade-up"
              data-aos-duration="1000"
              style={{ zIndex: 999 }}
            >
              <React.Fragment>
                {skeletonLoader === true && (
                  <Skeleton width="90%" height="400px" />
                )}
                <ImageWrapper>
                  <Placeholder ref={refPlaceholder} />
                  <LazyLoad>
                    <img
                      src={MOBILE_APPS_SLIDER}
                      alt="Best mobile app image domaincer"
                      className="brandStrategySliderImg_mobileapp floatingUpDown"
                      onLoad={removePlaceholder}
                      onError={removePlaceholder}
                    />
                  </LazyLoad>
                </ImageWrapper>
              </React.Fragment>
            </Grid>
            <Grid xs={12} sm={6}>
              <div
                className="BSSliderRightSection_mobileapp"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <h1 className="sliderHeading_mobileapp">Blockchain</h1>
                <h2 className="sliderSubHeading_mobileapp">
                  <strong>Embrace the Future with Us </strong>- Welcome to the
                  next generation of the web! Domaincer is excited to explore
                  the potential of Web 3.0 and bring our expertise in blockchain
                  technology to the table. We are a team of skilled developers
                  ready to create custom decentralized apps using the power of
                  blockchain. Just like how blockchain helps keep track of
                  financial transactions in cryptocurrencies like Bitcoin, we
                  aim to harness its power to revolutionize the way we interact
                  and exchange information online.
                  <br />
                  {/* <div style={{ marginTop: 5 }}>
                    Domaincer is always entitled to be the best mobile app
                    development company in Chennai, we can help you create a
                    stunning mobile app that proves that there is a unique,
                    custom-coded, mobile solution to whatever the problem may
                    be.
                  </div> */}
                </h2>
                {/* <Button
               className="video_play"
               onClick={handleOpen}
              >
                <PlayCircleOutlineIcon />
                <span style={{ paddingLeft: 5, paddingRight: 5 }}>
                    Watch video
                </span>
              </Button> */}
                <Modal open={open} onClose={handleClose}>
                  <Fragment>
                    <div className="video_closeIcon_container">
                      <CloseOutlinedIcon
                        onClick={handleClose}
                        className="video_closeIcon"
                      ></CloseOutlinedIcon>
                    </div>
                    <div className="video_Second_container">
                      <iframe
                        title="how Influencer Works"
                        className="video_iframe"
                        width="854"
                        height="480"
                        src="https://www.youtube.com/embed/v=si4Ak78y6Kg"
                        frameborder="0"
                        allow="autoplay; encrypted-media"
                        allowfullscreen
                      ></iframe>
                    </div>
                  </Fragment>
                </Modal>
                <Hidden only={["xs"]}>
                  <div className="serviceTabsSection_mobileapp">
                    <Paper square className="MTabsWidth_socialmedia">
                      <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        aria-label="tabs example"
                        className="MTabsContainer checkMaxWidth_block"
                      >
                        <Tab label="About" className="MTabBtn" />
                        <Tab
                          label="Technologies"
                          className="MTabBtn"
                          onClick={() => handleTabScroll("TECH")}
                        />
                        <Tab
                          label="Why Blockchain"
                          className="MTabBtn"
                          onClick={() => handleTabScroll("WHYBLOCKCHAIN")}
                        />

                        <Tab
                          label="Benefits"
                          className="MTabBtn"
                          onClick={() => handleTabScroll("BENEFITS")}
                        />
                        <Tab
                          label="Use Cases"
                          className="MTabBtn"
                          onClick={() => handleTabScroll("APPLICATIONS")}
                        />

                        <Tab
                          label="Blog"
                          className="MTabBtn"
                          onClick={() => handleTabScroll("BLOG")}
                        />

                        <Tab
                          label="Why Domaincer"
                          className="MTabBtn"
                          onClick={() => handleTabScroll("WHYDOMAINCER")}
                        />

                        {/* <Tab
                          label='Recent works'
                          className='MTabBtn'
                          onClick={() => handleTabScroll('RECENT_WORKS')}
                        /> */}
                        {/* <Tab
                          label="Blog"
                          className="MTabBtn"
                          onClick={() => handleTabScroll("STATS")}
                        /> */}
                      </Tabs>
                    </Paper>
                  </div>
                </Hidden>
                <Hidden only={["sm", "md", "lg", "xl"]}>
                  <div className="serviceTabsSection_mobileapp">
                    <Paper square className="MTabsWidth_mobileapp">
                      <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        aria-label="tabs example"
                        className="MTabsContainer"
                      >
                        <Tab label="About" className="MTabBtn" />
                        <Tab
                          label="Technologies"
                          className="MTabBtn"
                          onClick={() => handleTabScrollMobile("TECH")}
                        />
                        <Tab
                          label="Why blockchain"
                          className="MTabBtn"
                          onClick={() => handleTabScrollMobile("WHYBLOCKCHAIN")}
                        />
                        <Tab
                          label="Benefits"
                          className="MTabBtn"
                          onClick={() => handleTabScrollMobile("BENEFITS")}
                        />
                        <Tab
                          label="Use Cases"
                          className="MTabBtn"
                          onClick={() => handleTabScrollMobile("APPLICATIONS")}
                        />

                        <Tab
                          label="Blog"
                          className="MTabBtn"
                          onClick={() => handleTabScrollMobile("PROCESS")}
                        />

                        <Tab
                          label="Why Domaincer"
                          className="MTabBtn"
                          onClick={() => handleTabScrollMobile("WHYDOMAINCER")}
                        />
                        {/* <Tab
                          label='Recent works'
                          className='MTabBtn'
                          onClick={() => handleTabScrollMobile('RECENT_WORKS')}
                        /> */}
                        {/* <Tab
                          label="Blog"
                          className="MTabBtn"
                          onClick={() => handleTabScrollMobile("STATS")}
                        /> */}
                      </Tabs>
                    </Paper>
                  </div>
                </Hidden>
              </div>
            </Grid>
          </Grid>
        </Container>
        {/* <LanguagesSection /> */}
        {/* <ServiceContentV2MobileApp /> */}
        {/* <WeSpecialized /> */}
        {/* <SpecializedIn />
        <ProcessSection /> */}
        {/* <DeploymentPartners /> */}
        <BlockchainTech />

        <WhyDomaincerBlockchain />
        <BlockChainBenefits />
        <BlockchainApplications />
        <div style={{ marginTop: "50px" }}>
          <BlogSectionMobileApp />
        </div>
        <WhyChooseUsBlockChain />

        <div>
          {/* <CaseStudySection /> */}
          {/* <StatsSection /> */}
        </div>
        {/* <GetQuoteMobile /> */}
      </div>
    </div>
  );
};

export default Blockchain;
