import React from "react";
import "../devops.css";
import { Container, Grid, Card } from "@material-ui/core";
import Hidden from "@material-ui/core/Hidden";
import { BASE_URL } from "../../../../config/config";

const WhyChooseUsDevOps = () => {
  const WHY_CHOOSE_US_IMAGE = `${BASE_URL}/about_why_choose_us.png`;

  return (
    <div className="whyChooseUsSection_devops">
      <Container>
        <h1 className="whyChooseUsTitle_devops">Why Domaincer</h1>
        <Grid container spacing={2} style={{ justifyContent: "center" }}>
          <Grid item xs={12} sm={3}>
            <div
              className="whyChooseColumnUpContainer_devops"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <h2 className="chooseTitle_devops">DevOps Consulting</h2>
              <h3 className="choosePara_devops">
                Assessing current practices? Check. Reviewing existing
                frameworks? Check. Let us guide you to success.
              </h3>
            </div>
            <div
              className="whyChooseColumnDownContainer_devops"
              data-aos="fade-right"
              data-aos-duration="1400"
            >
              <h2 className="chooseTitle_devops">DevOps Implementation</h2>
              <h3 className="choosePara_devops">
                We can assist you in choosing the right tools in building a
                coordinated, cloud-based, and efficient DevOps utopia.
              </h3>
            </div>
          </Grid>
          <Grid item xs={12} sm={4} className="chooseUsImageRow_devops">
            <img
              src={WHY_CHOOSE_US_IMAGE}
              alt="why choose us image 3d domaincer"
              className="chooseUsMiddleImg_devops floatingUpDown"
            />
          </Grid>

          <Hidden only={["sm", "lg", "md", "xl"]}>
            <Grid item xs={12} sm={3}>
              <div
                className="whyChooseColumn3UpContainer_devops"
                data-aos="fade-up"
                data-aos-duration="1200"
              >
                <h2 className="chooseTitle_devops">Automation & Integration</h2>
                <h3 className="choosePara_devops">
                  Power up your software development with our automated,
                  end-to-end delivery pipeline.
                </h3>
              </div>
              <div
                className="whyChooseColumn3DownContainer_devops"
                data-aos="fade-up"
                data-aos-duration="1400"
              >
                <h2 className="chooseTitle_devops">DevOps management</h2>
                <h3 className="choosePara_devops">
                  Leave DevOps management to us and watch your deployment
                  testing, observing, and feedback soar.
                </h3>
              </div>
            </Grid>
          </Hidden>
          <Hidden only={["xs"]}>
            <Grid item xs={12} sm={3}>
              <div
                className="whyChooseColumn3UpContainer_devops"
                data-aos="fade-left"
                data-aos-duration="1200"
              >
                <h2 className="chooseTitle_devops">Automation & Integration</h2>
                <h3 className="choosePara_devops">
                  Power up your software development with our automated,
                  end-to-end delivery pipeline.
                </h3>
              </div>
              <div
                className="whyChooseColumn3DownContainer_devops"
                data-aos="fade-left"
                data-aos-duration="1600"
              >
                <h2 className="chooseTitle_devops">DevOps management</h2>
                <h3 className="choosePara_devops">
                  Leave DevOps management to us and watch your deployment
                  testing, observing, and feedback soar.
                </h3>
              </div>
            </Grid>
          </Hidden>
        </Grid>
      </Container>
    </div>
  );
};
export default WhyChooseUsDevOps;
