import React, { useState, useEffect } from "react";
import "./workflowAutomation.css";
import { Container, Grid, Hidden, Paper, Tab, Tabs } from "@material-ui/core";
import WhyChooseUs from "./workflow_whyChooseUs/workflow_whychooseus.component";
// import OurProcess from './workflow_ourProcess/workflow_ourprocess.component';
import CaseStudySection from "../../homepage/casestudyssection/casestudysection.component";
import HowServiceWillHelpYou from "./workflow_howServiceWillHelpYou/workflow_howservicewillhelpyou.component";
import BlogSection from "./workflow_blogSection/workflow_blogsection.component";
import ServiceContentSection from "./workflow_serviceContentSection/workflow_servicecontentsection.component";
import AOS from "aos";
import FollowUs from "../../../commons/followus/followus.component";
import { BASE_URL } from "../../../config/config";

const WorkflowAutomation = (props) => {
  const WORKFLOW_AUTOMATION_SLIDER = `${BASE_URL}/service_workflow_header_image.png`;

  useEffect(() => {
    AOS.init();
  });
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleTabScroll = (value) => {
    if (value === "OVER_VIEW") {
      window.scrollTo({
        top: 1100,
        behavior: "smooth",
      });
    } else if (value === "BLOG") {
      window.scrollTo({
        top: 1750,
        behavior: "smooth",
      });
    } else if (value === "WHY_DOMAINCER") {
      window.scrollTo({
        top: 2500,
        behavior: "smooth",
      });
    } else if (value === "RECENT_WORKS") {
      window.scrollTo({
        top: 3150,
        behavior: "smooth",
      });
    }
  };

  const handleTabScrollMobile = (value) => {
    if (value === "OVER_VIEW") {
      window.scrollTo({
        top: 1600,
        behavior: "smooth",
      });
    } else if (value === "BLOG") {
      window.scrollTo({
        top: 2300,
        behavior: "smooth",
      });
    } else if (value === "WHY_DOMAINCER") {
      window.scrollTo({
        top: 3300,
        behavior: "smooth",
      });
    } else if (value === "RECENT_WORKS") {
      window.scrollTo({
        top: 4700,
        behavior: "smooth",
      });
    }
  };

  return (
    <div class="mobileMarginTop">
      <FollowUs history={props.history} />
      <Container>
        <Grid container>
          <Grid
            xs={12}
            sm={6}
            className="workflow_BSSliderImgContainer"
            // data-aos="fade-up"
            data-aos-duration="1000"
          >
            <img
              src={WORKFLOW_AUTOMATION_SLIDER}
              alt="Best workflow automation image domaincer"
              className="workflow_SliderImg floatingUpDown"
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <div
              className="workflow_BSSliderRightSection"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <h1 className="workflow_sliderHeading">Workflow Automation</h1>
              <h2 className="workflow_sliderSubHeading">
                <b>Stage-manage your workflow - </b> workflow automation focuses
                on process optimization. It goes beyond modelling your cloud
                workflow and ensure all business processes are executed in the
                best possible way. Key capabilities include full process
                automation, activity monitoring, deadline enforcement, Dynamic
                visbility, business analytics as well as enterprise system
                integration.
              </h2>
              <Hidden only={["xs"]}>
                <div className="workflow_serviceTabsSection">
                  <Paper square className="workflow_MTabsWidth">
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      indicatorColor="primary"
                      textColor="primary"
                      aria-label="tabs example"
                      className="workflow_MTabsContainer"
                    
                    >
                      <Tab label="About" className="workflow_MTabBtn" />
                      <Tab
                        label="Overview"
                        className="workflow_MTabBtn"
                        onClick={() => handleTabScroll("OVER_VIEW")}
                      />
                      <Tab
                        label="Blog"
                        className="workflow_MTabBtn"
                        onClick={() => handleTabScroll("BLOG")}
                      />
                      <Tab
                        label="Why Domaincer"
                        className="workflow_MTabBtn"
                        onClick={() => handleTabScroll("WHY_DOMAINCER")}
                      />
                      {/* <Tab
                        label="Recent Works"
                        className="workflow_MTabBtn"
                        onClick={() => handleTabScroll("RECENT_WORKS")}
                      /> */}
                    </Tabs>
                  </Paper>
                </div>
              </Hidden>
              <Hidden only={["sm", "md", "lg", "xl"]}>
                <div className="workflow_serviceTabsSection">
                  <Paper square className="workflow_MTabsWidth">
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      indicatorColor="primary"
                      textColor="primary"
                      aria-label="tabs example"
                      className="workflow_MTabsContainer"
                    >
                      <Tab label="About" className="workflow_MTabBtn" />
                      <Tab
                        label="Overview"
                        className="workflow_MTabBtn"
                        onClick={() => handleTabScrollMobile("OVER_VIEW")}
                      />
                      <Tab
                        label="Blog"
                        className="workflow_MTabBtn"
                        onClick={() => handleTabScrollMobile("BLOG")}
                      />
                      <Tab
                        label="Why Domaincer"
                        className="workflow_MTabBtn"
                        onClick={() => handleTabScrollMobile("WHY_DOMAINCER")}
                      />
                      {/* <Tab
                        label="Recent Works"
                        className="workflow_MTabBtn"
                        onClick={() => handleTabScrollMobile("RECENT_WORKS")}
                      /> */}
                    </Tabs>
                  </Paper>
                </div>
              </Hidden>
            </div>
          </Grid>
        </Grid>
      </Container>
      <HowServiceWillHelpYou />
      <ServiceContentSection />
      <BlogSection />
      <WhyChooseUs />
      {/* <OurProcess /> */}
      {/* <CaseStudySection /> */}
    </div>
  );
};

export default WorkflowAutomation;
