import React from "react";
import "./metaverseBenefits.css";
import { Grid, Card } from "@material-ui/core";
import { BASE_URL } from "../../../../config/config";

const Benefits = [
  {
    img: `${BASE_URL}/metaRemote.png`,
    heading: "Addresses the remote work challenges",
    description:
      "The Metaverse is here to revolutionize remote work! Say goodbye to communication barriers and hello to effective collaboration. With the ability to read body language and enjoy in-person interaction, managers can easily connect with their employees. Join the Metaverse and take your remote work to the next level !",
  },
  {
    img: `${BASE_URL}/metaHealthCare.png`,
    heading: "Great tool for healthcare professionals",
    description:
      "The Metaverse is a game-changer for healthcare! No more missed patient visits due to limitations - now, medical professionals can easily interact with patients and get a clear understanding of their health.",
  },
  {
    img: `${BASE_URL}/metaGame.png`,
    heading: "Making online games more exciting",
    description:
      "The Metaverse is a wild west of decentralized economics! In most games today, developers and publishers hold the reins on in-game asset ownership and distribution.",
  },
];

const MetaverseBenefit = () => {
  return (
    <>
      <div
        className="benefits-container-blockchain"
        style={{ marginBottom: "50px" }}
      >
        {/* <div className="card-header-blockchain">
          <h4 className="card-header">Metaverse Benefits</h4>
        </div> */}
        <div>
          <Grid container spacing={1}>
            {Benefits.map((each) => (
              <>
                <Grid xs={12} sm={4} className="card-grid">
                  <Card className="card-blockchain">
                    <div className="card-container">
                      <div className="image-container">
                        <div className="circle">
                          <img src={each.img} className="block-images"></img>
                        </div>
                      </div>
                      {/* <hr className="card-line"></hr> */}

                      <div className="card-innerContainer">
                        <h4 className="each-header-block">{each.heading}</h4>
                        <p className="each-description-block">
                          {each.description}
                        </p>
                      </div>
                    </div>
                  </Card>
                </Grid>
              </>
            ))}
          </Grid>
        </div>
      </div>
    </>
  );
};

export default MetaverseBenefit;
