import React, { useState, useEffect } from "react";
import {
  Avatar,
  Container,
  Grid,
  Card,
  CardContent,
  Button,
  Hidden,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import "./instagramFeed.css";
import Skeleton from "react-loading-skeleton";
import LazyLoad from "react-lazyload";
import styled, { keyframes } from "styled-components";
import AOS from "aos";
import IconButton from "@material-ui/core/IconButton";
import YouTubeIcon from "@material-ui/icons/YouTube";
import InstagramIcon from "@material-ui/icons/Instagram";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import FacebookIcon from "@material-ui/icons/Facebook";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import { Link } from "react-router-dom";

const ImageWrapper = styled.div`
  position: relative;
`;

const Placeholder = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
`;

const InstagramFeed = () => {
  const [instagramFeeds, setinstagramFeeds] = useState([]);
  const [skeletonLoader, setskeletonLoader] = useState(true);
  const [facebookFollowUs, setfacebookFollowUs] = useState(false);
  const [instagramFollowUs, setinstagramFollowUs] = useState(false);
  const [linkedInFollowUs, setlinkedInFollowUs] = useState(false);
  const [youtubeFollowUs, setyoutubeFollowUs] = useState(false);
  const [showInstagramTag, setshowInstagramTag] = useState({
    id: "",
  });
  const refPlaceholder = React.useRef();

  const removePlaceholder = () => {
    refPlaceholder.current.remove();
    setskeletonLoader(false);
  };

  useEffect(() => {
    fetchInstagramFeeds();
    AOS.init();
  }, []);

  const fetchInstagramFeeds = () => {
    fetch("https://domaincer.com/api/instagram/feed.php", {
      method: "GET",
    })
      .then((res) => res.json())
      .then((json) => {
        setinstagramFeeds(json);
      }).catch((err)=> console.log(err))
  };

  return (
    <div
      className="instagram_blogSection"
      data-aos="fade-up"
      data-aos-duration="3000"
    >
      <Container>
        <div className="instagram_blogHeaderContainer">
          <div className="instagramFeeds_titleRow">
            <h1 className="instagram_blogHeading">Instagram Feeds</h1>
            <Hidden only={["xs"]}>
              <div style={{ display: "flex", marginLeft: 10, marginTop: 5 }}>
                <div style={{ alignSelf: "center", textAlign: "center" }}>
                  <ChevronLeftIcon
                    onClick={() => {
                      document.getElementById(
                        "instagramBlogScroll"
                      ).scrollLeft -= 400;
                    }}
                    className="instagram_leftCaroselIcon"
                  />
                </div>
                <div style={{ alignSelf: "center", textAlign: "center" }}>
                  <ChevronRightIcon
                    onClick={() => {
                      document.getElementById(
                        "instagramBlogScroll"
                      ).scrollLeft += 400;
                    }}
                    className="instagram_rightCaroselIcon"
                  />
                </div>
              </div>
            </Hidden>
          </div>

          {/* <Hidden only={["xs"]}> */}
          <div className="socialMediaIcons_row">
            <React.Fragment>
              <a
                href="https://www.facebook.com/domaincer"
                target="_blank"
                className="facebookIcon_domaincer"
                onMouseEnter={() => setfacebookFollowUs(true)}
                onMouseLeave={() => setfacebookFollowUs(false)}
              >
                <FacebookIcon
                  className={
                    facebookFollowUs === true
                      ? "fade-in-right"
                      : "fade-in-right"
                  }
                />
                {facebookFollowUs === true && (
                  <span className="followUsButton_facebook fade-in-right">
                    follow Us
                  </span>
                )}
              </a>
            </React.Fragment>
            <React.Fragment>
              <a
                href="https://www.instagram.com/Domaincer/"
                target="_blank"
                className="instagramIcon_domaincer"
                onMouseEnter={() => setinstagramFollowUs(true)}
                onMouseLeave={() => setinstagramFollowUs(false)}
              >
                <InstagramIcon
                  className={instagramFollowUs === true && "fade-in-right"}
                />
                {instagramFollowUs === true && (
                  <span className="followUsButton_instagram fade-in-right">
                    follow Us
                  </span>
                )}
              </a>
            </React.Fragment>
            <React.Fragment>
              <a
                href="https://www.linkedin.com/company/domaincer/"
                target="_blank"
                className="linkedInIcon_domaincer"
                onMouseEnter={() => setlinkedInFollowUs(true)}
                onMouseLeave={() => setlinkedInFollowUs(false)}
              >
                <LinkedInIcon
                  className={linkedInFollowUs === true && "fade-in-right"}
                />
                {linkedInFollowUs === true && (
                  <span className="followUsButton_linkedIn fade-in-right">
                    follow Us
                  </span>
                )}
              </a>
            </React.Fragment>
            <React.Fragment>
              <a
                href="https://www.youtube.com/channel/UC_UC-pKGSDTlC6wtgMVY3kg"
                target="_blank"
                className="youtubeIcon_domaincer"
                onMouseEnter={() => setyoutubeFollowUs(true)}
                onMouseLeave={() => setyoutubeFollowUs(false)}
              >
                <YouTubeIcon
                  className={youtubeFollowUs === true && "fade-in-right"}
                />
                {youtubeFollowUs === true && (
                  <span className="followUsButton_youtube fade-in-right">
                    follow Us
                  </span>
                )}
              </a>
            </React.Fragment>
          </div>
          {/* </Hidden> */}
        </div>
      </Container>

      <div className="instagram_blogSectionContainer">
        <div className="instagram_blogMiddleSection" id="instagramBlogScroll">
          {instagramFeeds !== null && (
            <React.Fragment>
              {instagramFeeds.map((instagram, index) => (
                <Grid
                  item
                  xs={12}
                  sm={4}
                  data-aos="fade-right"
                  data-aos-duration="1000"
                  key={index}
                >
                  <div
                    className="instagram_blogSectionCardContainer"
                    onMouseEnter={() =>
                      setshowInstagramTag({ id: instagram.id })
                    }
                    onMouseLeave={() => setshowInstagramTag({ id: "" })}
                  >
                    <div>
                      <React.Fragment>
                        {skeletonLoader === true && (
                          <Skeleton width="100%" height="300px" />
                        )}
                        <ImageWrapper>
                          <Placeholder ref={refPlaceholder} />
                          <LazyLoad>
                            {instagram.media_url.indexOf(
                              "https://video-bom1-2.cdninstagram.com"
                            ) ? (
                              <img
                                alt=""
                                src={instagram.media_url}
                                className="instagram_blogSectionImg"
                                onLoad={removePlaceholder}
                                onError={removePlaceholder}
                              />
                            ) : (
                              <video className="instagram_blogSectionImg">
                                <source
                                  src={instagram.media_url}
                                  type="video/mp4"
                                />
                              </video>
                            )}
                          </LazyLoad>
                        </ImageWrapper>
                      </React.Fragment>
                    </div>
                    {showInstagramTag.id === instagram.id && (
                      <div className="instagram_captionHoverSection scale-up-center">
                        <p className="instagram_captionText">
                          {instagram.caption.substring(0, 90)} ...
                        </p>
                        <React.Fragment>
                          <a href={instagram.permalink} target="_blank">
                            <Button className="viewPost__button">
                              View Post
                            </Button>
                          </a>
                        </React.Fragment>
                      </div>
                    )}
                  </div>
                </Grid>
              ))}
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

export default InstagramFeed;
